import { Credentials } from '@biostrand/components';
import { createAction } from '@reduxjs/toolkit';
import { LOGIN_REQUEST,LOGIN_SUCCESS } from '../../pages/public/registration/registerTypes';

interface LoginRequestParams {
    credentials: Credentials;
    sourceUrl?: string;
}

export const loginRequest = createAction<LoginRequestParams>(LOGIN_REQUEST);
export const loginSuccess = createAction(LOGIN_SUCCESS);
