import {GroupIdMap, GroupingItemNode} from "../groupingTypes";
import {MetadataManagerGroupingType} from "@biostrand/biostrandapi/javascript/dist/GroupingApi";

export const mapGroupIds = (rootGroup:GroupingItemNode, map?: GroupIdMap):GroupIdMap => {
    const currentMap:GroupIdMap = map || {};
    currentMap[rootGroup.id || ''] = rootGroup;

    if (rootGroup.children){
        for (let i = 0; i <rootGroup.children.length; i++) {
            const g = rootGroup.children[i];
            mapGroupIds(g, currentMap);
        }
    }

    return currentMap
}

export const getChildType = (group: GroupingItemNode): MetadataManagerGroupingType | undefined => {
    if (group && group.itemType.children && group.itemType.children[0]) {
        return group.itemType.children[0].type;
    }
    return undefined
}
