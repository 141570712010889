import * as React from 'react';
import SettingsPageTemplate from '../SettingsPageTemplate';
import OrganizationSettingsContent from './OrganizationSettingsContent';
import {SettingsIds} from "@biostrand/components/src/applications/SettingsIds";

const OrganizationSettingsPage = (): JSX.Element => {
    return (
        <SettingsPageTemplate selectedItem={SettingsIds.ORGANIZATION}>
            <OrganizationSettingsContent />
        </SettingsPageTemplate>
    );
};

export default OrganizationSettingsPage;
