import * as React from "react";

//@ts-ignore
import Logo from "./Biostrand_White_lg_RGB_gray.svg";

export const BiostrandLogoGray = (props: { height?:number, style?: any}) => {
  const {height = 45, style} = props

  return (
    <img src={Logo} alt="BioStrand Logo" style={{...style, height}} />
  )
}
