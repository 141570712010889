import * as React from 'react';
import SettingsPageTemplate from '../SettingsPageTemplate';
import {SettingsIds} from "@biostrand/components/src/applications/SettingsIds";
import DatasetDetailsSettingsContent from "./DatasetDetailsSettingsContent";

const DatasetDetailsSettingsPage = (): JSX.Element => {
    return (
        <SettingsPageTemplate selectedItem={SettingsIds.DATASETS} >
            <DatasetDetailsSettingsContent />
        </SettingsPageTemplate>
    );
};

export default DatasetDetailsSettingsPage;
