import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ProtoJobType} from "@biostrand/biostrandapi/javascript/dist/JobManagerApi";
import {JobsHistoryItem} from "./jobsGrid/jobsGridColumnUtils";
import {JobRunInfo, JobsState} from "./jobsTypes";

export enum JobsActionTypes {
    REFRESH_JOB_TYPES = "jobs.REFRESH_JOB_TYPES",
    REFRESH_JOB_RUNS = "jobs.REFRESH_JOB_RUNS",

    JOB_TYPE_SELECTED = "jobs.JOB_TYPE_SELECTED",
    RUN_JOB_ACTION = "jobs.RUN_JOB_ACTION",

    RUN_JOB_FLOW_ACTION = "jobs.RUN_JOB_FLOW_ACTION",
}

const initialState: JobsState = {
    jobTypes: [],
    isJobTypesLoading: false,
    jobRuns: [],
    isJobRunsLoading: false,
};

export const jobsSlice = createSlice({
    name: 'jobs',
    initialState,
    reducers: {
        updateJobTypes: (state, action: PayloadAction<ProtoJobType[]>) => {
            state.jobTypes = action.payload.sort((jt1, jt2) => jt1.name && jt2.name && (jt1.name > jt2.name) ? 1: -1);
        },
        setJobTypesLoading: (state, action: PayloadAction<boolean>) => {
            state.isJobTypesLoading = action.payload;
        },
        updateJobRuns: (state, action: PayloadAction<JobsHistoryItem[]>) => {
            state.jobRuns = action.payload;
        },
        setJobRunsLoading: (state, action: PayloadAction<boolean>) => {
            state.isJobRunsLoading = action.payload;
        },
    },
});

export const refreshJobTypesAction = createAction(JobsActionTypes.REFRESH_JOB_TYPES);
export const refreshJobRunsAction = createAction(JobsActionTypes.REFRESH_JOB_RUNS);

export const runJobAction = createAction<JobRunInfo>(JobsActionTypes.RUN_JOB_ACTION)

export const jobTypeSelectedAction = createAction<ProtoJobType>(JobsActionTypes.JOB_TYPE_SELECTED)
export const runJobFlowAction = createAction<ProtoJobType>(JobsActionTypes.RUN_JOB_FLOW_ACTION)

export const { updateJobTypes, setJobTypesLoading, setJobRunsLoading, updateJobRuns } = jobsSlice.actions;

export default jobsSlice.reducer;

