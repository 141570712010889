import {AppWithDatasetsState} from "./datasetsTypes";
import {DatasetManagerDatasetEntity} from "@biostrand/biostrandapi/javascript/dist/DatasetManagerApi";

export const datasetsSelector = (state: AppWithDatasetsState):  DatasetManagerDatasetEntity[] | undefined => state.dataset.datasets;

export const datasetByIdSelector = (datasetId:string) =>  (state: AppWithDatasetsState):  DatasetManagerDatasetEntity | undefined => {
   return state.dataset.datasets?.find((ds: DatasetManagerDatasetEntity) => ds.id === datasetId);
};

export const datasetByNameSelector = (datasetName:string) =>  (state: AppWithDatasetsState):  DatasetManagerDatasetEntity | undefined => {
   return state.dataset.datasets?.find((ds: DatasetManagerDatasetEntity) => ds.name === datasetName);
};


export const isDatasetsLoadingSelector = (state: AppWithDatasetsState):  boolean => state.dataset.isLoading;
