import {DatasetManagerDatasetEntity} from '@biostrand/biostrandapi/javascript/dist/DatasetManagerApi';
import {Stack, Typography,} from '@mui/material';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {datasetsSelector} from "../../slices/datasets/datasetsSelectors";
import {DataGrid, GridRenderCellParams} from "@mui/x-data-grid";

interface DGSelectedItemsPlaceholderProps {
  selectedItemsIds?: string[];
}

interface DSMap {
  [key: string]: DatasetManagerDatasetEntity;
}

export const DGSelectedItemsPlaceholder = (props: DGSelectedItemsPlaceholderProps): JSX.Element => {
  const {selectedItemsIds} = props;

  const [itemsWithDSNames, setItemsWithDSNames] = useState([]);
  const datasets = useSelector(datasetsSelector);

  //const [expandedItems, setExpandedItems] = useState({});

  useEffect(() => {
    if (selectedItemsIds) {
      const datasetsById: DSMap = {};
      datasets?.forEach(ds => {
        datasetsById[ds.id || ''] = ds;
      })

      const withNames = selectedItemsIds.map(wn => {
        const parts = wn.split('/');
        parts[0] = datasetsById[parts[0]].name;
        return parts.join('/');
      })
      setItemsWithDSNames(withNames);
    }
  }, [datasets, selectedItemsIds]);

  const columns = [
    {
      field: 'name',
      headerName: 'name',
      minWidth: 170,
      flex: 1,
      renderCell: (params: GridRenderCellParams<any>) => {
        const parts = params.row.split(`/`);
        const dsName = parts.shift();
        const filePath = `/` + parts.join('/');
        return (
          <Stack direction={"row"}>
            <Typography variant={"body2"} sx={{fontWeight: "bold"}} title={params.row}>{dsName}</Typography>
            {parts.length > 0 ? (<Typography variant={"body2"} title={params.row}>{filePath}</Typography>) : null}
          </Stack>
        );
      },
      sortable: true,
    },
    // {
    //   field: 'size',
    //   headerName: ' ',
    //   width: 70,
    //   align: 'right',
    //   renderCell: (params: GridRenderCellParams<any>) => {
    //     if (params.row.object_type === SharedObjectType.FOLDER) {
    //       return "";
    //     }
    //     return (
    //       <Typography variant={'body2'}>{formatFileSize(parseInt(params.row.size || '0'))}</Typography>
    //     );
    //   },
    //   sortable: true,
    // },
  ]

  return (
    <Stack direction={'column'}
           sx={{minHeight: 300, overflowY: "scroll", flex: 1, minWidth: 300, alignItems: 'stretch'}} spacing={2}>
      <DataGrid
        sx={{
          '.MuiDataGrid-root': {border: '#f00 5px'},
          //  position: 'absolute',
          border: '5px',
          // top: 32,
          // bottom: 0,
          // left: -8,
          // right: -8,x
          flex: 1,
        }}
        initialState={{
          sorting: {
            //  sortModel: initialSort,
          },
        }}
        disableSelectionOnClick
        rows={itemsWithDSNames}
        loading={false}
        columns={columns}
        getRowId={row => row || ''}
        disableColumnFilter
        headerHeight={0}
        hideFooterRowCount
        hideFooterSelectedRowCount
        keepNonExistentRowsSelected
        density={'compact'}
      />
    </Stack>
  );
};
