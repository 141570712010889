import React from "react";
import {SvgIcon, SvgIconProps} from "@mui/material";

export const ArticleLensIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="48" height="48" viewBox="0 0 48 48" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M21.838 31.9992C21.838 28.5751 24.6139 25.7992 28.038 25.7992C31.4622 25.7992 34.238 28.5751 34.238 31.9992C34.238 35.4234 31.4622 38.1992 28.038 38.1992C24.6139 38.1992 21.838 35.4234 21.838 31.9992ZM28.038 22.1992C22.6256 22.1992 18.238 26.5868 18.238 31.9992C18.238 37.4116 22.6256 41.7992 28.038 41.7992C30.0939 41.7992 32.0018 41.1662 33.5777 40.0844L40.4118 46.9186C40.6071 47.1139 40.9237 47.1139 41.1189 46.9186L42.9574 45.0801C43.1527 44.8849 43.1527 44.5683 42.9574 44.373L36.1232 37.5388C37.205 35.963 37.838 34.055 37.838 31.9992C37.838 26.5868 33.4504 22.1992 28.038 22.1992Z"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M19.4404 8.00156C19.4404 5.46105 21.4999 3.40156 24.0404 3.40156C26.5809 3.40156 28.6404 5.46105 28.6404 8.00156C28.6404 10.5421 26.5809 12.6016 24.0404 12.6016C21.4999 12.6016 19.4404 10.5421 19.4404 8.00156ZM24.0404 0.601563C19.9535 0.601563 16.6404 3.91466 16.6404 8.00156C16.6404 11.9374 19.7131 15.1556 23.5906 15.3881L24.1995 20.0559C24.2352 20.3298 24.4861 20.5228 24.7599 20.4871L26.5448 20.2543C26.8186 20.2185 27.0117 19.9676 26.9759 19.6938L26.3674 15.0283C29.3143 14.0529 31.4404 11.2755 31.4404 8.00156C31.4404 3.91465 28.1273 0.601562 24.0404 0.601563Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M40.838 21.0031C40.838 19.7881 41.823 18.8031 43.038 18.8031C44.253 18.8031 45.238 19.7881 45.238 21.0031C45.238 22.2182 44.253 23.2031 43.038 23.2031C41.823 23.2031 40.838 22.2182 40.838 21.0031ZM43.038 17.2031C40.9393 17.2031 39.238 18.9044 39.238 21.0031C39.238 21.4554 39.317 21.8892 39.462 22.2915L37.037 23.8071C36.8028 23.9534 36.7317 24.2619 36.878 24.4961L37.196 25.0049C37.3424 25.239 37.6508 25.3102 37.885 25.1639L40.3098 23.6483C41.0005 24.3605 41.9676 24.8031 43.038 24.8031C45.1367 24.8031 46.838 23.1018 46.838 21.0031C46.838 18.9044 45.1367 17.2031 43.038 17.2031Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M8.20099 33.3485L7.55149 29.668C7.5035 29.396 7.68505 29.1367 7.95699 29.0887L8.94177 28.9149C9.21372 28.8669 9.47307 29.0485 9.52106 29.3204L10.1707 33.0016C12.8712 33.0715 15.0388 35.2826 15.0388 37.9999C15.0388 40.7613 12.8002 42.9999 10.0388 42.9999C7.27739 42.9999 5.03882 40.7613 5.03882 37.9999C5.03882 35.8874 6.34893 34.0809 8.20099 33.3485ZM7.03882 37.9999C7.03882 36.343 8.38196 34.9999 10.0388 34.9999C11.6957 34.9999 13.0388 36.343 13.0388 37.9999C13.0388 39.6568 11.6957 40.9999 10.0388 40.9999C8.38196 40.9999 7.03882 39.6568 7.03882 37.9999Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M12.6014 18.2578C13.01 19.0848 13.2396 20.016 13.2396 21.0008C13.2396 24.425 10.4638 27.2008 7.0396 27.2008C3.61543 27.2008 0.8396 24.425 0.8396 21.0008C0.8396 17.5767 3.61543 14.8008 7.0396 14.8008C8.61279 14.8008 10.0491 15.3868 11.1423 16.3523L15.3383 13.1436C15.5576 12.9759 15.8714 13.0177 16.0392 13.2371L16.8896 14.3492C17.0573 14.5685 17.0155 14.8823 16.7961 15.0501L12.6014 18.2578ZM3.2396 21.0008C3.2396 18.9021 4.94092 17.2008 7.0396 17.2008C9.13828 17.2008 10.8396 18.9021 10.8396 21.0008C10.8396 23.0995 9.13828 24.8008 7.0396 24.8008C4.94092 24.8008 3.2396 23.0995 3.2396 21.0008Z" />
    </SvgIcon>
  )
}
