import {LinkDescription} from "./linkTypes";
import React from "react";
import {useTranslation} from "react-i18next";
import {Typography, Link} from "@mui/material";
import {SxProps} from "@mui/system";

interface LinkItemProps  {
  description: LinkDescription,
  sx?: SxProps;
}

export const LinkItem = (props: LinkItemProps): JSX.Element => {
  const { description, sx } = props;
  const [t] = useTranslation();
  return (
    <Typography key={description.name} variant='body2' sx={sx}>
      <Link href={description.link} color='text.secondary' underline='none' target='_blank'>
        {t(description.name)}
      </Link>
    </Typography>
  );
};
