import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
    AlignmentSearchParameters,
    Dimension,
    DimensionFilter,
    ListViewSearchParameters,
    SearchActionTypes,
    SearchState
} from "./searchTypes";

import {SearchHistoryEntry} from "./searchHistoryTypes";
import {GridColumnVisibilityModel} from "@mui/x-data-grid";
import {ApplicationManagerDataSource} from '@biostrand/biostrandapi/javascript/dist/ApplicationManagerApi';
import {
    RetrieveAndRelateListResponse,
    RetrieveAndRelateQueryObject,
    RetrieveAndRelateTaxonomyResponse
} from "@biostrand/biostrandapi/javascript/dist/RetrieveAndRelateApi";
import {AlignAlignResponse} from "@biostrand/biostrandapi/javascript/dist/AlignApi";

const initialState: SearchState = {
    rootSearchRequestInProgress: false,
    rootSearchRequestInError: false,
    baseSearchRequestInProgress: false,
    baseSearchRequestInError: false,
    alignmentRequestInProgress: false,
    listRequestInProgress: false,
    rootQuery: '',
    baseQueryId: '',
    dimensions: [],
    currentDimension: [],
    queryMatches: 0,
    queryMetadata: undefined,
    isEnriching: false,
    isSearchInProgress: true,
};

export const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        setEnrichingRunning: (state, action: PayloadAction<boolean>) => {
            state.isEnriching = action.payload;
        },
        // Use the PayloadAction type to declare the contents of `action.payload`
        setSearchRunning: (state, action: PayloadAction<boolean>) => {
            state.isSearchInProgress = action.payload;
        },
        setRootSearchError: (state, action: PayloadAction<boolean>) => {
            state.rootSearchRequestInError = action.payload;
        },
        setAvailableDataSources: (state, action: PayloadAction<ApplicationManagerDataSource[]>) => {
            state.availableDatasets = action.payload;
        },
        setDimensionsAction: (state, action: PayloadAction<Dimension[]>) => {
            state.dimensions = action.payload;
        },
        setCurrentDimensionsAction: (state, action: PayloadAction<Dimension[]>) => {
            state.currentDimension = action.payload;
        },
        setRootQueryTaxonomy: (state, action: PayloadAction<RetrieveAndRelateTaxonomyResponse>) => {
            state.rootTaxonomy = action.payload;
        },
        setCurrentTaxonomy: (state, action: PayloadAction<RetrieveAndRelateTaxonomyResponse>) => {
            state.currentTaxonomy = action.payload;
        },
        setRootSearchResponse: (state, action:PayloadAction<RetrieveAndRelateQueryObject>) => {
            const resp = action.payload;
            state.queryMatches = resp.result?.total_count;
            state.queryMetadata = resp.metaData;
            state.rootQuery = resp.metaData?.query;
            state.baseQueryId = resp.query_id;
        },
        setBaseSearchResponse: (state, action:PayloadAction<RetrieveAndRelateQueryObject>) => {
            const resp = action.payload;
            state.queryMatches = resp.result?.total_count;
            state.queryMetadata = resp.metaData;
            state.rootQuery = resp.metaData?.query;
            state.baseQueryId = resp.query_id;
        },
        setSearchHistory: (state, action:PayloadAction<SearchHistoryEntry[]|undefined>) => {
            state.history = action.payload;
        },
        setAlignmentSearchInProgress: (state, action:PayloadAction<boolean>) => {
            state.alignmentRequestInProgress = action.payload;
        },
        setAlignmentResultViewData: (state, action:PayloadAction<AlignAlignResponse>) => {
            state.alignmentResultViewData = action.payload;
        },
        setListViewSearchInProgress: (state, action:PayloadAction<boolean>) => {
            state.listRequestInProgress = action.payload;
        },
        setListViewData: (state, action:PayloadAction<RetrieveAndRelateListResponse>) => {
            state.listViewResults = action.payload;
        },
        setLastFilters: (state, action:PayloadAction<DimensionFilter[]|undefined>) => {
            state.lastFilters = action.payload;
        },
        setListVisibleColumns: (state, action:PayloadAction<GridColumnVisibilityModel|undefined>) =>{
            state.listViewVisibleColumns = action.payload;
        }
    },
});

export const runEnrichQueryRequest = createAction<string>(SearchActionTypes.RUN_ENRICH_QUERY_REQUEST);
export const runSearchRequest = createAction<string>(SearchActionTypes.RUN_SEARCH_REQUEST);
export const clearSearchHistory = createAction(SearchActionTypes.CLEAR_SEARCH_HISTORY);
export const alignmentSearchRequest = createAction<AlignmentSearchParameters>(SearchActionTypes.ALIGNMENT_SEARCH_REQUEST);
export const listviewSearchRequest = createAction<ListViewSearchParameters>(SearchActionTypes.LISTVIEW_SEARCH_REQUEST);

export const showSequenceInfoPopup = createAction<string>(SearchActionTypes.SHOW_SEQUENCE_INFO_POPUP);

export const {
    setEnrichingRunning,
    setRootSearchError,
    setAvailableDataSources,
    setRootQueryTaxonomy,
    setSearchRunning,
    setCurrentTaxonomy,
    setDimensionsAction,
    setCurrentDimensionsAction,
    setRootSearchResponse,
    setBaseSearchResponse,
    setSearchHistory,

    setLastFilters,
    setAlignmentResultViewData,
    setAlignmentSearchInProgress,

    setListViewSearchInProgress,
    setListViewData,
    setListVisibleColumns
} = searchSlice.actions;

export default searchSlice.reducer;
