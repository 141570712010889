import {Stack, Tab, Tabs, Typography} from '@mui/material';
import {push} from 'connected-react-router';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {Route, Switch, useLocation} from 'react-router-dom';
import {RouteUrl} from '../../../../routeUrl';
import JobsHistory from './JobsHistory';
import JobsOverview from './JobsOverview';
import {refreshDatasetsAction} from "@biostrand/components";

const JobsSettingsContent = (): JSX.Element => {
    const location = useLocation();
    const [tabValue, setTabValue] = useState(location.pathname);
    const dispatch = useDispatch();
    const [t] = useTranslation();

    const onTabChange = (event, value) => {
        dispatch(push(`${value}${location.search}`));
    };

    useEffect(() =>  {
      dispatch(refreshDatasetsAction());
    }, [])

    useEffect(() => {
        if (location.pathname.indexOf(RouteUrl.JobsHistorySettings) > -1) {
            setTabValue(RouteUrl.JobsHistorySettings);
        } else {
            setTabValue(location.pathname);
        }
    }, [location.pathname]);

    return (
        <Stack direction={'column'} sx={{ m: 4, flex: 1 }} spacing={2}>
            <Typography variant={'h4'}>{t('Jobs')}</Typography>

            <Tabs value={tabValue} onChange={onTabChange}>
                <Tab label={t('Overview')} value={RouteUrl.JobsSettings} />
                <Tab label={t('History')} value={RouteUrl.JobsHistorySettings} />
            </Tabs>

            <Switch>
                <Route exact path={RouteUrl.JobsSettings}>
                    <JobsOverview />
                </Route>
                <Route exact path={RouteUrl.JobsHistorySettings}>
                    <JobsHistory />
                </Route>
            </Switch>
        </Stack>
    );
};

export default JobsSettingsContent;
