import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {OrganizationState} from "./organizationTypes";

const initialState: OrganizationState = {
    creating: false
};

export const organizationSlice = createSlice({
    name: 'organization',
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        setOrganizationCreating: (state, action: PayloadAction<boolean>) => {
            state.creating = action.payload;
        },
    },
});

export const { setOrganizationCreating } = organizationSlice.actions;

export default organizationSlice.reducer;
