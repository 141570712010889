import { CognitoUserPool, CognitoUserSession } from 'amazon-cognito-identity-js';
import * as AWS from 'aws-sdk/global';
import {RuntimeConfig} from "../../runtimeConfig/RuntimeConfig";
import {CommonRuntimeVariables} from "../../runtimeConfig/CommonRuntimeVariables";

export const cognitoConfig = {
  IdentityPoolId: RuntimeConfig.getRuntimeVariable(CommonRuntimeVariables.CognitoIdentityPoolId) as string,
  UserPoolId: RuntimeConfig.getRuntimeVariable(CommonRuntimeVariables.CognitoUserPoolId) as string,
  ClientId: RuntimeConfig.getRuntimeVariable(CommonRuntimeVariables.CognitoClientId) as string,
  AWSRegion: RuntimeConfig.getRuntimeVariable(CommonRuntimeVariables.CognitoRegion) as string,
};

export const currentCognitoUserPool = new CognitoUserPool(cognitoConfig);

export const getIsAuthenticated = (): Promise<boolean> => {
  return new Promise<boolean>((resolve, reject) => {
    const currentUser = currentCognitoUserPool.getCurrentUser();
    if (currentUser) {
      currentUser.getSession((err, session: CognitoUserSession) => {
        if (err) {
          reject(err);
        } else {
          resolve(session.isValid());
        }
      });
    } else {
      // no current user
      resolve();
    }
  });
};

export const getAccessToken = (): Promise<string> => {
  // eslint-disable-next-line
  return new Promise<string>((resolve, reject) => {
    const currentUser = currentCognitoUserPool.getCurrentUser();
    if (currentUser) {
      currentUser.getSession((err, session: CognitoUserSession) => {
        if (err) {
          reject(new Error('getAccessToken something went wrong'));
          return;
        }
        resolve('Bearer ' + session.getIdToken().getJwtToken());
      });
    } else {
      // no current user
      resolve();
    }
  });
};
// eslint-disable-next-line
export const refreshSession = (): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    const currentUser = currentCognitoUserPool.getCurrentUser();
    if (currentUser) {
      currentUser.getSession((err, session: CognitoUserSession) => {
        if (session.isValid()) {
          // eslint-disable-next-line
          currentUser.refreshSession(session.getRefreshToken(), (error, newSession) => {
            setAWSConfig(session);
            resolve();
          });
        } else {
          reject('No valid session available to conduct session refresh');
        }
      });
    } else {
      // no current user
      resolve()
    }
  });
};

export const setAWSConfig = (session: CognitoUserSession): void => {
  // POTENTIAL: Region needs to be set if not already set previously elsewhere.
  AWS.config.region = cognitoConfig.AWSRegion;

  const loginKey = `cognito-idp.${cognitoConfig.AWSRegion}.amazonaws.com/${cognitoConfig.UserPoolId}`;
  const logins = {};
  logins[loginKey] = session.getIdToken().getJwtToken();

  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: cognitoConfig.IdentityPoolId, // your identity pool id here
    Logins: logins,
  });
};

export const logout = (): void => {
  const currentUser = currentCognitoUserPool.getCurrentUser();
  if (currentUser) {
    currentUser.signOut();
  }
};

export interface AuthenticationResponse<T> {
  result?: T;
  errorCode?: string;
}
