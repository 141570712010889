import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Column, ColumnType, Order } from './gridTypes';

interface GridSortableHeaderProps<T> {
  onRequestSort: (columnId: keyof T) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  columns: Column<T>[];
  order?: Order;
  orderBy?: string;
}
/**
 * @deprecated, please use mui DataGrid
 * @param props
 */

export function GridSortableHeader<T>(props: GridSortableHeaderProps<T>): JSX.Element {
  const { columns, order, orderBy, onRequestSort } = props;
  const [t] = useTranslation();

  return (
    <TableHead>
      <TableRow>
        {columns.map((column, index )=> {
          if (column.type === ColumnType.MENU) {
            return <TableCell key={String(column.id)} style={{ width: 24 }} padding="none" />;
          }
          return (
            <TableCell key={String(column.id)} align={column.align} style={{ minWidth: column.minWidth, paddingLeft: index === 1 ? 8 : 16}}>
              {column.sortable ? (
                <TableSortLabel
                  active={column.id === orderBy}
                  direction={order}
                  onClick={() => {
                    onRequestSort(column.id);
                  }}
                >
                  {t(column.label)}
                </TableSortLabel>
              ) : (
                t(column.label)
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};
