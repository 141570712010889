import React, { ReactElement, useCallback } from 'react';
import {LabelRowProps} from "@biostrand/components/src/alignmentView/alignmentViewTypes";

const styles = {
    labelRowSelected: {
        minHeight: 15,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        height: 15,
        textAlign: 'left',
        fontSize: 11,
        backgroundColor: 'rgba(0, 222, 222, 0.2)',
        borderTop: 'solid 1px #0000ff',
        borderBottom: 'solid 1px #0000ff',
        paddingRight: 10,
        position: 'relative'
    },
    labelRow: {
        minHeight: 15,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        height: 15,
        textAlign: 'left',
        fontSize: 11,
        paddingRight: 10,
    }
}

const SelectSequenceLabelRow = (props: LabelRowProps): ReactElement => {
    const {row, onSelect, selected } = props;

//    const dispatch = useDispatch();

    const clickHandler = useCallback(() => {
        if (!onSelect) return;

        if (selected) {
            onSelect(null);
        } else {
            onSelect(row.id as string);
        }
    }, [onSelect, row.id, selected]);

    const title = row.name ? `${row.name}\n\nID: ${row.id}` : `ID: ${row.id}`;

    return (
        <div style={(selected ? styles.labelRowSelected : styles.labelRow) as React.CSSProperties} onClick={clickHandler}
               title={title}>
            {row.label}
        </div>
    );
};

export default SelectSequenceLabelRow;
