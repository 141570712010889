import {CircularProgress, Stack, TextField} from '@mui/material';
import debounce from 'lodash.debounce';
import * as React from 'react';
import {useEffect, useMemo, useState} from 'react';

function RawInput({onChange, initialValue, isValidating, value, ...rest}) {
  const [currentValue, setCurrentValue] = useState(initialValue || '');

  useEffect(() => {
    setCurrentValue(initialValue || '')
  }, [initialValue])

  const changeHandler = (event: { target: { value: any; }; }) => {
    setCurrentValue(event.target.value);
    onChange && onChange(event);
  };


  return (
    // eslint-disable-next-line react/jsx-no-undef
    <Stack sx={{position:"relative", color: 'grey.500'}}>
      <TextField
        value={currentValue}
        onChange={changeHandler}
        {...rest}
      />
      {isValidating && <CircularProgress size={20} color={'inherit'} sx={{position: 'absolute', top:10, right:8}}/>}
    </Stack>
  );
}
export function DebouncedTextInput({onChange, isValidating, delay = 400, ...rest}) {
  const debouncedChangeHandler = useMemo(
    () => debounce((value: string) => {
      onChange && onChange(value);
    }, delay)
    , [onChange, delay]);

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    }
  }, []);

  return <RawInput onChange={debouncedChangeHandler} {...rest}  isValidating={isValidating} />
}
