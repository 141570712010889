import * as React from "react";

import {ReactComponent as ReactLogo} from './Biostrand_White_lg_RGB.svg';

export const BiostrandLogo = (props: { height?:number, style?: any}) => {
  const {height =10, style} = props

  return (
    <ReactLogo title="BioStrand Logo" style={{...style, height}} />
  )
}
