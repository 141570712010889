import * as React from 'react';
import { SnackbarKey } from 'notistack';
import { useDispatch } from "react-redux";
import { IconButton } from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { closeSnackbarNotification } from './snackbarNotificationSlice';
import {faTimes} from "@fortawesome/pro-regular-svg-icons";

interface CloseNotificationButtonProps {
  notificationKey: SnackbarKey;
  children: JSX.Element | string;
}

const CloseNotificationButton = (props: CloseNotificationButtonProps): JSX.Element => {
  const {notificationKey} = props
  const dispatch = useDispatch();
  const onClose = () => {
    dispatch(closeSnackbarNotification(notificationKey))
  }

  return (<IconButton color="inherit" onClick={onClose} sx={{width:32, ml:2}}><FontAwesomeIcon icon={faTimes} size='sm' /></IconButton>)
};

export default CloseNotificationButton;
