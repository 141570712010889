import React, { forwardRef, ReactElement } from 'react';
import { BASE_SIZE } from '../AligmentBaseSize';

interface SelectionRectProps {
    x: number;
    y: number;
}

const CellSelectionRect = forwardRef<HTMLDivElement, SelectionRectProps>(
    (props: SelectionRectProps, ref): ReactElement | null => {
        const { x, y } = props;
        const isSelectionVisible = x !== null && y!==null;

        if (!isSelectionVisible) return null;

        return (

            <div
                ref={ref}
                style={{
                    border: 'solid 2px #222',
                    position: 'absolute',
                    width: BASE_SIZE + 4,
                    height: BASE_SIZE + 3,
                    top: y * BASE_SIZE,
                    left: x * BASE_SIZE,
                    margin: -2,
                    pointerEvents: 'none',
                }}>
                <div
                    style={{
                        border: 'solid 1px #fff',
                        position: 'absolute',
                        width: BASE_SIZE,
                        height: BASE_SIZE - 1,
                        top: 0,
                        left: 0,
                        pointerEvents: 'none',
                    }}
                />
            </div>
        );
    },
);

export default CellSelectionRect;
