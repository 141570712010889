import {
    DatasetManagerDatasetEntity,
    SharedObjectType,
} from '@biostrand/biostrandapi/javascript/dist/DatasetManagerApi';
import {getAccessToken, uApi,} from '@biostrand/components';
import {contentDispositionParser} from '@biostrand/components/src/utilites/contentDispositionParser';
import {FileDownload} from '@mui/icons-material';
import {Box, Button, Chip, CircularProgress, IconButton, LinearProgress, Tab, Tabs, Typography} from '@mui/material';
import {Stack} from '@mui/system';
import axios from 'axios';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {useDatasetFiles} from "@biostrand/components/src/datasets/useDatasetFiles";
import {DatasetFileSelectorCore} from "@biostrand/components/src/datasets/pickers/DatasetFileSelectorCore";
import {NGSFolders} from "@biostrand/components/src/datasets/pickers/ngsUtils";
import {NGSFacetCore} from "@biostrand/components/src/datasets/pickers/NGSFacetCore";
import {loadTextFile} from "@biostrand/components/src/datasets/fileViewer/loadFile";
import {FilePreviewComponent} from "@biostrand/components/src/datasets/fileViewer/FilePreviewComponent";

const DATASET_META_FILE_NAME = "dataset.meta.json";
const FILES_TAB = "Files::Tab";
const NGS_DEMO_TAB = "NGS_DEMO_TAB::Tab";

const isFileExist = (fileList, filePath) => {
    return fileList && filePath && fileList.find(fo => {
        return (fo.name === filePath || fo.name === '/' + filePath)
    });
}

interface DatasetDetailsProps {
    datasetName: string,
    datasetVersion?: string,
    datasetId?: string,
}

const DatasetDetailsSettingsContent = (props: DatasetDetailsProps): JSX.Element => {
    const {datasetName, datasetVersion, datasetId} = props;
    const [isDatasetDownloading, setIsDatasetDownloading] = useState<boolean>(false);
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [isNGSFacetVisible, setIsNGSFacetVisible] = useState<boolean>(false);
    const [currentSelection, setCurrentSelection] = useState<string[]>([]);
    const [dataset, setDataset] = useState<DatasetManagerDatasetEntity | undefined>();
   //means that dataset is loaded, files are fetched and meta is processed
    const [isDatasetReady, setIsDatasetReady] = useState<boolean>(false);

    const [selectedTab, setSelectedTab] = useState(FILES_TAB);

    const [dsFiles, areFilesLoading, error] = useDatasetFiles(dataset?.id);
    const [meta, setMeta] = useState();

    useEffect(() => {
        const loadDataset = async () => {
            try {
                const dsResult = await uApi
                    .getDatasetManagerApi()
                    .datasetManagerGetDatasets2(datasetName, datasetVersion, datasetId);
                     setDataset(dsResult.data?.data[0] as DatasetManagerDatasetEntity);
            } catch (e) {
            }
        };
        loadDataset();
    }, [datasetName, datasetVersion]);

    useEffect(() => {
        if (dsFiles) {
            let ngsLookupFolders = [`/${NGSFolders.BINDERS}/`, `/${NGSFolders.HITS}/`, `/${NGSFolders.PDBS}/`];
            for (let i = 0; i < dsFiles.length; i++) {
                const item = dsFiles[i];
                if (item.object_type === SharedObjectType.FOLDER && ngsLookupFolders.find(folder => item.name.indexOf(folder) > -1)) {
                    ngsLookupFolders = ngsLookupFolders.filter(name => item.name.indexOf(name) === -1);
                    if (ngsLookupFolders.length === 0) {
                        setIsNGSFacetVisible(true);
                        setSelectedTab(NGS_DEMO_TAB);
                        return;
                    }
                }
            }
            setIsNGSFacetVisible(false)
            const metaFile = dsFiles.find((item) => {
                return (item.object_type === SharedObjectType.FILE && item.name.indexOf(DATASET_META_FILE_NAME) > -1)
            });
            if (metaFile) {
                const loadMeta = async () => {
                    try {

                        const content = await loadTextFile(metaFile.name, dataset?.id);
                        const newMeta = JSON.parse(content);
                        setMeta(newMeta);
                        if (newMeta?.tabs[0]) {
                            setSelectedTab(newMeta.tabs[0].file)
                        }
                    } catch (e) {

                    } finally {
                        setIsDatasetReady(true);
                    }
                }
                loadMeta()
            } else {
                const loadTabs = async () => {
                    try {

                        const result = await uApi.getDatasetManagerApi().datasetManagerGetDatasetTabs(dataset?.id);
                        setMeta(result.data);
                        if (result.data?.tabs[0]) {
                            setSelectedTab(result.data.tabs[0].file)
                        }

                    } catch (e) {

                    }finally {
                        setIsDatasetReady(true);
                    }
                }
                loadTabs()
            }
        } else {
            setIsNGSFacetVisible(false)
        }

    }, [dsFiles]);

    const onDownloadDataset = async dataset => {
        setIsDatasetDownloading(true);

        const token = await getAccessToken();
        const result = await axios.get(
            `${window.location.origin}/api/datasets/${dataset.id}:downloadById`,
            {
                headers: {Authorization: `${token}`},
                responseType: 'blob',
            },
        );

        const blob = await result.data;
        setIsDatasetDownloading(false);
        const header = result.headers['content-disposition'];
        const contentDescription = contentDispositionParser(header);
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.download = contentDescription?.filename || `${dataset?.name || 'noname'}.zip`;
        link.href = href;
        link.click();
    };

    const onDownloadSelection = async () => {
        if (dataset?.id) {
            setIsDownloading(true);
            const token = await getAccessToken();
            const result = await axios.post(
                `${window.location.origin}/api/datasets/${dataset.id}:downloadById`,
                {
                    id: dataset.id,
                    file_filter: {
                        selected_items: currentSelection,
                    }
                },
                {
                    headers: {Authorization: `${token}`},
                    responseType: 'blob',
                },
            );

            const blob = await result.data;
            setIsDownloading(false);
            const header = result.headers['content-disposition'];
            const contentDescription = contentDispositionParser(header);
            const href = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.download = contentDescription?.filename || `${dataset?.name || 'noname'}.zip`;
            link.href = href;
            link.click();
        }
    };

    return (
        <Stack sx={{flex: 1, overflow: "hidden", position: "relative"}} direction={'column'}>
            {(dataset && isDatasetReady && !areFilesLoading) ? (
                <Stack sx={{flex: 1}} id="all">
                    <Stack direction={'row'} sx={{justifyItems: 'center', alignItems: 'center', minHeight: 40}}>
                        <Stack>
                            <Stack direction={'row'} sx={{justifyItems: 'center', alignItems: 'flex-end'}}>
                                <Typography variant='h5'>{dataset?.name}</Typography>
                                <Typography variant={'subtitle1'} sx={{ml: 1, mr: 2}}>
                                    ({dataset?.version})
                                </Typography>
                            </Stack>
                            <div>
                                {dataset?.status !== 'ACTIVE' && (
                                    <Chip label={dataset?.status?.toLowerCase()} size={'small'} sx={{height: 16}}/>
                                )}
                            </div>
                        </Stack>
                        <IconButton
                            disabled={isDatasetDownloading}
                            size={'small'}
                            title={'download dataset'}
                            onClick={() => dataset && onDownloadDataset(dataset)}>
                            {isDatasetDownloading ? <CircularProgress size={24}/> : <FileDownload color={'primary'}/>}
                        </IconButton>

                        {currentSelection && currentSelection.length ? (
                            <Button
                                title={currentSelection && currentSelection.join('\n')}
                                startIcon={isDownloading ? <CircularProgress size={24}/> : <FileDownload/>}
                                sx={{textTransform: 'none'}}
                                onClick={() => dataset && onDownloadSelection()}>
                                Download selected ({currentSelection.length})
                            </Button>
                        ) : null}
                    </Stack>

                    <Stack sx={{flex: 1, overflow: 'hidden'}}>
                        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                            <Tabs value={selectedTab} onChange={(e, value) => setSelectedTab(value)}>
                                {isNGSFacetVisible ? <Tab label={'NGS Results Facet'} value={NGS_DEMO_TAB}/> : null}
                                {meta?.tabs && meta.tabs.map(tab => {
                                    return (<Tab key={tab.file} label={tab.name} value={tab.file}/>)
                                })
                                }
                                <Tab label={'Files'} value={FILES_TAB}/>
                            </Tabs>
                        </Box>

                        {selectedTab === FILES_TAB ? <Stack sx={{mt: 2, flex: 1, overflow: 'hidden'}}>
                            {dataset && (
                                <DatasetFileSelectorCore
                                    dsFiles={dsFiles}
                                    isLoading={areFilesLoading}
                                    error={error}
                                    datasetId={dataset.id || ''}
                                    onSelectionChange={fileIds => {
                                        setCurrentSelection(fileIds);
                                    }}
                                />
                            )}
                        </Stack> : null}
                        {selectedTab === NGS_DEMO_TAB ?
                            <Stack id="NGSFacetCore" sx={{mt: 2, flex: 1, overflow: 'hidden'}}>
                                {dataset && (
                                    <NGSFacetCore
                                        dsFiles={dsFiles}
                                        isLoading={areFilesLoading}
                                        error={error}
                                        datasetId={dataset.id || ''}
                                        onSelectionChange={fileIds => {
                                            setCurrentSelection(fileIds);
                                        }}
                                    />
                                )}
                            </Stack> : null}
                        {selectedTab && dataset?.id && selectedTab !== NGS_DEMO_TAB && selectedTab !== FILES_TAB
                            ? isFileExist(dsFiles, selectedTab)
                                ? <FilePreviewComponent
                                    datasetId={dataset.id}
                                    filePath={selectedTab}
                                    fileList={dsFiles || []}/>
                                : <Stack sx={{mt:2}}>File not found: <strong>{selectedTab}</strong></Stack>
                            : null}
                    </Stack>
                </Stack>)
                : (<Stack sx={{mr: 2, flex: 1}}><LinearProgress/></Stack>)
            }

        </Stack>
    );
};

export default DatasetDetailsSettingsContent;
