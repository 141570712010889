import {Button, Menu, MenuItem, Stack} from '@mui/material';
import * as React from 'react';
import {useState} from 'react';
import {useGridApiContext} from "@mui/x-data-grid-pro";
import {ExportAsFilePopup} from "./ExportAsFilePopup";
import {PopupWindow} from "../../popup/PopupWindow";
import {ExportAsDatasetPopup} from "./ExportAsDatasetPopup";

const createDefaultFileName = (filePath: string) => {
  const fileName = filePath.split('/').pop().split('.').shift();
  return `${fileName}-export-${new Date().getTime()}.csv`;
}

const createDefaultDatasetName = (filePath: string) => {
  const fileName = filePath.split('/').pop().split('.').shift();
  return `${fileName}-export-${new Date().getTime()}`;
}

export const SelectedRowGridToolbar = ({exportSettings, datasetId}) => {
  const gridRef = useGridApiContext();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [exportFileIsOpen, setExportFileIsOpen] = useState(false);
  const [exportDatasetIsOpen, setExportDatasetIsOpen] = useState(false);
  const fileName = createDefaultFileName(exportSettings.filePath || '');
  const defaultDatasetName = createDefaultDatasetName(exportSettings.filePath || '');
  const hasSelectedRows = gridRef.current.getSelectedRows().size > 0;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack direction={"row"}>
      <Button
        id="basic-button"
        aria-controls={exportFileIsOpen ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={exportFileIsOpen ? 'true' : undefined}
        onClick={handleClick}

      >
        Export
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => {
          setExportFileIsOpen(true)
          setAnchorEl(null);
        }}>Export as file</MenuItem>
        <MenuItem
          disabled={!hasSelectedRows}
          onClick={() => {
            setExportDatasetIsOpen(true)
            setAnchorEl(null);
          }}>Export selected rows as dataset</MenuItem>
      </Menu>
      <PopupWindow title={"Export as file"} open={exportFileIsOpen} onClose={() => setExportFileIsOpen(false)}>
        <ExportAsFilePopup gridRef={gridRef} onClose={() => setExportFileIsOpen(false)} defaultFileName={fileName}/>
      </PopupWindow>
      <PopupWindow title={"Export selected rows as dataset"} open={exportDatasetIsOpen}
                   onClose={() => setExportDatasetIsOpen(false)}>
        <ExportAsDatasetPopup
          gridRef={gridRef}
          onClose={() => setExportDatasetIsOpen(false)}
          defaultFileName={fileName}
          defaultDatasetName={defaultDatasetName}
          originalFilePath={exportSettings.filePath}
          datasetId={datasetId}
        />
      </PopupWindow>
    </Stack>
  );
};
