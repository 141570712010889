export enum ColumnType {
    TEXT = 'text',
    NUMERIC = 'numeric',
    DATE = 'date',
    OTHER = 'other',
    MENU = 'menu',
}

export enum Order {
    ASC = 'asc',
    DESC = 'desc'
}

export interface Column<T> {
    id: keyof T;
    label: string;
    minWidth?: number;
    defaultWidth?: number;
    type?: ColumnType;
    align?: 'right' | 'left' | 'center';
    sortable?: boolean;
    sortFunction?: (item1: any, item2: any) => number
    format?: (value: any) => string;
}