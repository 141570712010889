import {push} from 'connected-react-router';

import {all, call, delay, fork, put, takeEvery} from 'redux-saga/effects';
import {ActionType} from 'typesafe-actions';

import {
    completePasswordChallengeRequest,
    confirmNewPasswordRequest,
    forgotPasswordRequestAction,
    setForgotPasswordInProgress,
    setForgotPasswordResult,
} from './registerSlice';
import {
    AuthenticationFeedbackType,
    ConfirmPasswordParameters,
    PasswordChallengeParameters,
    RegistrationActionTypes,
} from './registerTypes';
import {
    AuthenticationResponse,
    confirmNewPassword,
    finishSetupPasswordChallenge,
    forgotPassword
} from "../../../core/auth/cognitoUtils";
import {RouteUrl} from "../../../routeUrl";
import {AuthenticationStatus, handleLogout, showError, updateAuthStatus} from "@biostrand/components";
import {showSuccess} from "@biostrand/components/src/snackbarNotification/snackbarNotificationSagaHelpers";
import {initUser} from "@biostrand/components/src/applications/appCommonSagas";

export function* handleConfirmNewPassword(action: ActionType<typeof confirmNewPasswordRequest>) {
    try {
        const parameters: ConfirmPasswordParameters = action.payload;
        const result: AuthenticationResponse<AuthenticationFeedbackType> = yield call(
            confirmNewPassword,
            parameters,
        );

        if (result.errorCode) {
            yield showError(`Failed with error code: ${result.errorCode}`);
        } else if (result.result === AuthenticationFeedbackType.Success) {
            yield showSuccess(`Password was changed, `, {});
            yield delay(2000);
            yield put(push(RouteUrl.Login));
        }
    } catch (err) {
        yield showError(`Something went wrong`, err.toString());
    }
}

export function* handleCompletePasswordChallenge(action: ActionType<typeof completePasswordChallengeRequest>) {
    try {
        const parameters: PasswordChallengeParameters = action.payload;
        const result: AuthenticationResponse<AuthenticationFeedbackType> = yield call(
            finishSetupPasswordChallenge,
            parameters,
        );

        if (result.errorCode) {
            yield showError(`Failed with error code: ${result.errorCode}`);

        } else if (result.result === AuthenticationFeedbackType.Success) {

            const user = yield initUser();

            if (!user) {
                yield put(updateAuthStatus(AuthenticationStatus.NOT_AUTHENTICATED));
                yield showError('Failed to get user details, try again later');
                yield put(push(RouteUrl.Login));
                yield handleLogout();
                return;
            }

            yield put(updateAuthStatus(AuthenticationStatus.AUTHENTICATED));
            yield put(push(RouteUrl.Root));
        }
    } catch (err) {
        // todo: add notification  yield call(handleUnexpectedErrorWithToast, err);
        yield showError('Something went wrong', err.toString());
    }
}

export function* handleForgotPassword(action: ActionType<typeof forgotPasswordRequestAction>) {
    try {
        const email: string = action.payload;
        yield put(setForgotPasswordInProgress())
        const result: AuthenticationResponse<AuthenticationFeedbackType> = yield call(
            forgotPassword,
            email,
        );

        if (result.result === AuthenticationFeedbackType.Success) {
            yield delay(1000);
            yield put(setForgotPasswordResult(result));
            // use short delay to improve ux
            yield put(push(RouteUrl.PendingChangePasswordPage));
        } else {
            yield showError(`Something goes wrong: ${result.errorCode}, please try later`,  result.errorCode)
            yield put(setForgotPasswordResult(undefined));
        }
    } catch (err) {
        yield showError('Something goes wrong, please try later', err.toString())
        yield put(setForgotPasswordResult(undefined));
    }
}

function* watchRequests() {
    yield takeEvery(RegistrationActionTypes.FORGOT_PASSWORD_REQUEST, handleForgotPassword);
    yield takeEvery(RegistrationActionTypes.CONFIRM_NEW_PASSWORD_REQUEST, handleConfirmNewPassword);
    yield takeEvery(RegistrationActionTypes.COMPLETE_PASSWORD_CHALLENGE_REQUEST, handleCompletePasswordChallenge);
}

function* authenticationSaga() {
    yield all([fork(watchRequests)]);
}

export default authenticationSaga;
