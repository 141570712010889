import * as React from 'react';
import SettingsPageTemplate from '../SettingsPageTemplate';
import UsersSettingsContent from './UsersSettingsContent';
import {SettingsIds} from "@biostrand/components/src/applications/SettingsIds";

const UsersSettingsPage = (): JSX.Element => {
    return (
        <SettingsPageTemplate selectedItem={SettingsIds.USERS}>
            <UsersSettingsContent />
        </SettingsPageTemplate>
    );
};

export default UsersSettingsPage;
