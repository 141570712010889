import {organizationSelector, showError, uApi} from '@biostrand/components';
import {all, call, delay, put, select, takeEvery} from 'redux-saga/effects';
import {
    DELETE_USER,
    deleteUsersAction,
    INVITE_USER_REQUEST,
    inviteUserRequestAction,
    REFRESH_USERS,
    setUsersLoading,
    UPDATE_USER_ROLE,
    UPDATE_USER_STATUS,
    updateUserRoleAction,
    updateUsers,
    updateUserStatusAction
} from './usersSlice';
import {ActionType} from "typesafe-actions";
import {showSuccess} from "@biostrand/components/src/snackbarNotification/snackbarNotificationSagaHelpers";
import {UserManagerGetAllUsersOfOrganizationResponse} from "@biostrand/biostrandapi/javascript/dist/UserManagerApi";

function* handleRefreshUsers() {
    yield put(setUsersLoading(true));
    try {
        const organization = yield select(organizationSelector);
        const result = yield call([uApi.getUserManagerApi(), 'userManagerGetAllUsersOfOrganization'], organization.id);

        const usersResponse: UserManagerGetAllUsersOfOrganizationResponse = result.data;
        yield put(updateUsers(usersResponse.data || []));
    } catch (e) {
        yield showError('Get users list error', e.toString());
    }
    yield delay(1000);
    yield put(setUsersLoading(false));
}

function* silentRefreshUsers() {
    try {
        const organization = yield select(organizationSelector);
        const result = yield call([uApi.getUserManagerApi(), 'userManagerGetAllUsersOfOrganization'], organization.id);
        const usersResponse: UserManagerGetAllUsersOfOrganizationResponse = result.data;
        yield put(updateUsers(usersResponse.data || []));
    } catch (e) {
        yield showError('Failed to fetch users list', e.toString());
    }
}

function* handleInviteUsers(action: ActionType<typeof inviteUserRequestAction>) {
    const {invite, callback} = action.payload;
    //yield put(setUsersLoading(true));
    try {
        const organization = yield select(organizationSelector);

        const result = yield call([uApi.getUserManagerApi(), 'userManagerInviteUser'], organization.id,
            {...invite, organizationId: organization.id});

        if (result) {
            yield call(callback, undefined);
            yield showSuccess('Invite was sent');
            yield silentRefreshUsers()
        }
    } catch (err) {
        yield call(callback, err.toString());
    }
}

function* updateUser(updateBasicRequest) {
    const organization = yield select(organizationSelector);

    updateBasicRequest.organization_id = organization.id;

    try {
        const result = yield call([uApi.getUserManagerApi(), 'userManagerUpdateUser'],
            organization.id as string,
            updateBasicRequest.email as string,
            {...updateBasicRequest}
        );

        if (result) {
            yield showSuccess('User updated');
            yield silentRefreshUsers();
        }
    } catch (err) {
        yield showError(`Something went wrong`, err.toString());
    }
}

function* handleUpdateUserRole(action: ActionType<typeof updateUserRoleAction>) {
    const {user, role} = action.payload;

    const updateRequest = {
        email: user.email,
        status: user.status,
        organization_role: role,
    }
    yield updateUser(updateRequest);
}

function* handleUpdateUserStatus(action: ActionType<typeof updateUserStatusAction>) {
    const {user, status} = action.payload;

    const updateRequest = {
        email: user.email,
        status: status,
        organization_role: user.organization_role,
    }
    yield updateUser(updateRequest);
}

function* handleDeleteUser(action: ActionType<typeof deleteUsersAction>) {
    const user = action.payload;
    const {email}  = user;

    const organization = yield select(organizationSelector);

    try {
        const result = yield call([uApi.getUserManagerApi(), 'userManagerDeleteUser'],
            organization.id as string,
            email as string,
        );

        if (result) {
            yield showSuccess('User removed');
            yield silentRefreshUsers();
        }
    } catch (err) {
        yield showError(`Something went wrong`, err.toString() );
    }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* usersSaga() {
    yield all([
        yield takeEvery(REFRESH_USERS, handleRefreshUsers),
        yield takeEvery(UPDATE_USER_ROLE, handleUpdateUserRole),
        yield takeEvery(UPDATE_USER_STATUS, handleUpdateUserStatus),
        yield takeEvery(INVITE_USER_REQUEST, handleInviteUsers),
        yield takeEvery(DELETE_USER, handleDeleteUser)
    ]);
}
