import * as React from 'react';
import PublicPage from "../common/publicPage/PublicPage";
import RegisterOrganizationForm from "./RegisterOrganizationForm";

const RegisterOrganizationPageComponent = () => {
    return (
        <PublicPage>
            <RegisterOrganizationForm />
        </PublicPage>
    );
};

const RegisterOrganizationPage = RegisterOrganizationPageComponent;

export default RegisterOrganizationPage;
