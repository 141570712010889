export enum CommonQueryParameter {
  Popup = 'popup',
  ReturnUrl = 'returnUrl',
  Email = 'email',
  Context = 'context',
  TemporaryPassword = 'temporaryPassword',
  ConfirmationCode = 'accessCode',
  IsOnboarding = 'onboarding',
}

interface Params {
  [key: string]: string | number | boolean | undefined;
}

export const getEncodedObjectFromUrl =  <T>(
    queryParameters: URLSearchParams,
    param: CommonQueryParameter | string,
): T | undefined => {
  try {
    const encodedParam = queryParameters.get(param);
    if (encodedParam) {
      const decodedJSON = unescape(atob(encodedParam));
      return JSON.parse(decodedJSON) as T;
    }
  } catch (e) {
    return undefined;
  }
}

export class QueryParameterUtils {
  public static updateQueryParameters(parameters: Params, search: string): string {
    const queryParameters = new URLSearchParams(search);
    Object.keys(parameters).forEach(k => {
      if (parameters[k] === undefined) {
        queryParameters.delete(k);
      } else {
        queryParameters.set(k, parameters[k] as string);
      }
    });

    return queryParameters.toString();
  }

  public static getEncodedObjectFromUrl<T>(
    queryParameters: URLSearchParams,
    param: CommonQueryParameter | string,
  ): T | undefined {
    try {
      const encodedParam = queryParameters.get(param);
      if (encodedParam) {
        const decodedJSON = unescape(atob(encodedParam));
        return JSON.parse(decodedJSON) as T;
      }
    } catch (e) {
      return undefined;
    }
  }

  public static getStringFromUrl(
    queryParameters: URLSearchParams,
    param: CommonQueryParameter | string,
  ): string | undefined {
    return queryParameters.get(param) || undefined;
  }

  public static getArrayFromUrl(
    queryParameters: URLSearchParams,
    param: CommonQueryParameter | string,
  ): string[] | undefined {

    const value = queryParameters.get(param);

    if ( value ) return value.split(';');

    return undefined;
  }

  public static getNumberFromUrl(
    queryParameters: URLSearchParams,
    param: CommonQueryParameter | string,
  ): number | undefined {
    const paramString = queryParameters.get(param);
    if (paramString) {
      return parseInt(paramString, 10);
    }
  }

  public static getEnumFromUrl<T>(queryParameters: URLSearchParams, param: CommonQueryParameter | string): T | null {
    const paramString = queryParameters.get(param);
    if (paramString) {
      // TODO @Sam this can be done much more elegantly
      // @ts-ignore
      return paramString as T;
    }
    return null;
  }
}
