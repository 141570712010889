import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit';

import {RequestDetails} from "@biostrand/components";
import {ConfirmPasswordParameters, PasswordChallengeParameters, RegistrationActionTypes} from "./registerTypes";

interface RegistrationState {
    passwordConfirmationStatus?,
    forgotPasswordInProgress?: boolean,
    forgotPasswordResults?: any,
}

const initialState: RegistrationState = {
    passwordConfirmationStatus: undefined,
};

export const forgotPasswordRequestAction = createAction<string>(RegistrationActionTypes.FORGOT_PASSWORD_REQUEST);
//export const forgotPasswordSuccessAction = createAction(RegistrationActionTypes.FORGOT_PASSWORD_SUCCESS);

export const completePasswordChallengeRequest = createAction<PasswordChallengeParameters>(
    RegistrationActionTypes.COMPLETE_PASSWORD_CHALLENGE_REQUEST,
);

export const confirmNewPasswordRequest = createAction<ConfirmPasswordParameters>(
    RegistrationActionTypes.CONFIRM_NEW_PASSWORD_REQUEST,
);


export const registerSlice = createSlice({
    name: 'register',
    initialState,
    reducers: {
        passwordConfirmationStatus: (state, action: PayloadAction<RequestDetails>) => {
            state.passwordConfirmationStatus = action.payload;
        },
        setForgotPasswordInProgress: (state) => {
            state.forgotPasswordInProgress = true;
        },
        setForgotPasswordResult: (state,action: PayloadAction<any | undefined>) => {
            state.forgotPasswordInProgress = false;
            state.forgotPasswordResults = action.payload;
        }
    },
});

export const { passwordConfirmationStatus, setForgotPasswordInProgress, setForgotPasswordResult } = registerSlice.actions;
export default registerSlice.reducer;
