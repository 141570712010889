import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
  FileFinalizeErrorPayload,
  FilePartStatus,
  FileResourcePayload,
  FileUploadInfo,
  FileUploadManagerState,
  FileUploadManagerStatus,
  FileUploadPopupState,
  PartUploadErrorPayload,
  UploadCompletePayload,
  UploadProgressPayload,
} from './fileUploadTypes';

export const INIT_FILE_UPLOAD_MANAGER = '@fum/INIT_FILE_UPLOAD_MANAGER';
export const UPLOAD_MANAGER_SAVE_STATE = '@fum/UPLOAD_MANAGER_SAVE_STATE';
export const KICK_UPLOAD_PROCESS = '@fum/KICK_UPLOAD_PROCESS';

export const UPLOAD_PROGRESS = '@fum/UPLOAD_PROGRESS';
export const UPLOAD_CANCELLED = '@fum/UPLOAD_CANCELLED';
export const PART_IS_READY = '@fum/PART_IS_READY';
export const PART_ERROR = '@fum/PART_ERROR';

export const initFileUploadManager = createAction(INIT_FILE_UPLOAD_MANAGER);
export const uploadManagerSaveState = createAction(UPLOAD_MANAGER_SAVE_STATE);
export const kickUploadProcess = createAction(KICK_UPLOAD_PROCESS);

const initialState: FileUploadManagerState = {
  managerStatus: FileUploadManagerStatus.PENDING,
  currentFiles: [],
  popupState: FileUploadPopupState.HIDDEN,
};

export const fileUploadManagerSlice = createSlice({
  name: 'fileUploadManager',
  initialState,
  reducers: {
    setCurrentFiles: (state, action: PayloadAction<FileUploadInfo[]>) => {
      state.currentFiles = action.payload;
      if (action.payload && action.payload.length > 0 && state.popupState !== FileUploadPopupState.OPEN) {
        state.popupState = FileUploadPopupState.OPEN;
      }
    },
    addFile: (state, action: PayloadAction<FileUploadInfo>) => {
      state.currentFiles = [...state.currentFiles, action.payload];
      state.popupState =  FileUploadPopupState.OPEN;
    },
    removeFile: (state, action: PayloadAction<string>) => {
      const fileId = action.payload;
      state.currentFiles = state.currentFiles.filter(fs => fileId !== fs.fileId);
    },

    removeDataset: (state, action: PayloadAction<string>) => {
      const datasetId = action.payload;
      state.currentFiles = state.currentFiles.filter(fs => datasetId !== fs.context.dataset.id);
    },
    setFileManagerBusy: state => {
      state.managerStatus = FileUploadManagerStatus.BUSY;
    },
    setFileManagerPending: state => {
      state.managerStatus = FileUploadManagerStatus.PENDING;
    },
    partUploadProgress: (state, action: PayloadAction<UploadProgressPayload>) => {
      const { fileStatus, filePart, progress } = action.payload;
      const fileStatusToChange = state.currentFiles.find(fus => fus.fileId === fileStatus.fileId);
      if (fileStatusToChange) {
        const filePartToChange = fileStatusToChange.parts.find(fp => fp.index === filePart.index);

        if (filePartToChange) {
          filePartToChange.progress = progress;
        }
      }
    },
    partUploaded: (state, action: PayloadAction<UploadCompletePayload>) => {
      const { fileStatus, filePart, ETag } = action.payload;

      const fileStatusToChange = state.currentFiles.find(fus => fus.fileId === fileStatus.fileId);
      if (fileStatusToChange) {
        const filePartToChange = fileStatusToChange.parts.find(fp => fp.index === filePart.index);

        if (filePartToChange) {
          filePartToChange.ETag = ETag;
          filePartToChange.status = FilePartStatus.READY;
        }
      }
    },
    partUploadError: (state, action: PayloadAction<PartUploadErrorPayload>) => {
      const { fileStatus, filePart } = action.payload;
      const fileStatusToChange = state.currentFiles.find(fus => fus.fileId === fileStatus.fileId);
      if (fileStatusToChange) {
        const filePartToChange = fileStatusToChange.parts.find(fp => fp.index === filePart.index);

        if (filePartToChange) {
          filePartToChange.status = FilePartStatus.ERROR;
        }
      }
    },

    clearPartUploadError: (state, action: PayloadAction<string>) => {
      const fileId = action.payload;
      const fileStatusToChange = state.currentFiles.find(fus => fus.fileId === fileId);

      if (fileStatusToChange) {
        fileStatusToChange.parts.forEach(fp => {
          if (fp.status === FilePartStatus.ERROR) {
            fp.status = FilePartStatus.DRAFT;
          }
        });
      }
    },

    abortFileUpload: (state, action: PayloadAction<string>) => {
      const fileId = action.payload;
      const fileStatusToChange = state.currentFiles.find(fus => fus.fileId === fileId);
      if (fileStatusToChange) {
        fileStatusToChange.isAborted = true;
      }
    },
    setFileStatus: (state, action: PayloadAction<FileFinalizeErrorPayload>) => {
      const { fileId, status } = action.payload;
      const fileStatusToChange = state.currentFiles.find(fus => fus.fileId === fileId);
      if (fileStatusToChange) {
        fileStatusToChange.status = status;
      }
    },
    assignFileResource: (state, action: PayloadAction<FileResourcePayload>) => {
      const { fileId, file } = action.payload;
      const fileStatusToChange = state.currentFiles.find(fus => fus.fileId === fileId);
      if (fileStatusToChange) {
        fileStatusToChange.file = file;
      }
    },
    setFileUploadPopupState: (state, action:PayloadAction<FileUploadPopupState>) => {
      state.popupState = action.payload;
    }
  },
});

export const {
  setCurrentFiles,
  addFile,
  setFileManagerBusy,
  setFileManagerPending,
  removeFile,
  partUploadProgress,
  partUploaded,
  removeDataset,
  partUploadError,
  abortFileUpload,
  setFileStatus,
  assignFileResource,
  clearPartUploadError,
  setFileUploadPopupState
} = fileUploadManagerSlice.actions;

export default fileUploadManagerSlice.reducer;
