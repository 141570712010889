import * as React from 'react';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {dataFilterSelector} from "../../filters/dataFiltersSelectors";
import {FieldFilterType, FilterType} from "../../filters/dataFiltersTypes";
import {updateDataFilter} from '../../filters/dataFiltersSlice';
import {capitalize, Checkbox, Input, MenuItem, Select, Stack, Typography} from '@mui/material';


interface MultipleValueFilterSelectorProps {
  filterKey: string,
  filterItemKey?: string,
  field: string,
  fieldLabel?: string,
  values: any[],
}

export function MultipleValueFilterSelector(props: MultipleValueFilterSelectorProps) {
  const {filterKey, filterItemKey, field, values, fieldLabel} = props;
  const filters = useSelector(dataFilterSelector(filterKey));

  const [selectedItems, setSelectedItems] = useState( []);
  const dispatch = useDispatch();

  useEffect(() => {
    const initialFilter = filters?.find(filter => filter.filterItemKey === filterItemKey);
    if (initialFilter?.value?.in) {
      setSelectedItems(initialFilter?.value?.in);
    }
  }, [filters,filterKey])

  const updateFilters = (value: any) => {
    setSelectedItems(value)
    const newFilters = filters?.filter(filter => filter.filterItemKey !== filterItemKey) || [];
    if (value && value.length > 0) {
      let filter = {filterItemKey, type: FilterType.FIELD, value: {in: value}, field, fieldType: FieldFilterType.ENUM}
      newFilters.push(filter);
    }
    dispatch(updateDataFilter({key: filterKey, filters: newFilters}));
  };

  return <Select
    multiple
    size={"small"}
    id="multiple-limit-tags"
    value={selectedItems}
    displayEmpty
    onChange={(event) => {
      updateFilters(event.target.value);
    }}
    sx={{
      minWidth: 120,
    }}
    input={
      <Input disableUnderline id="select-multiple-chip" placeholder="Status"/>
    }
    renderValue={(selected) => {
      return (<Stack spacing={1} direction={'row'} sx={{alignItems:'center'}}>
          <Typography variant={"subtitle2"} sx={{mt: 0.2, pl: 2}}>{capitalize(fieldLabel ? fieldLabel : field)}</Typography>
          {selected.length > 0 ?
            <Typography variant={"subtitle2"} sx={{width: 24, height: 24, pt:0.25,  textAlign:'center', backgroundColor: 'primary.main', borderRadius:3, color: 'primary.contrastText'}}>{selected.length}</Typography> : null
          }
        </Stack>
      )
    }}
  >
    {values.map((item) => (
      <MenuItem
        key={item}
        value={item}
        sx={{pr: 2, pl: 0, minWidth: 120}}
      >
        <Checkbox checked={selectedItems.indexOf(item) > -1} size={"small"} sx={{height: 12}}/>
        <Typography variant={"body2"}>{item.toLowerCase()}</Typography>
      </MenuItem>
    ))}
  </Select>
}
