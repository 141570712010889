import {faTimes} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconButton, Modal, Paper, Typography} from '@mui/material';
import {Stack} from '@mui/system';
import * as React from 'react';
import {ReactElement} from 'react';
import {useWindowDimensions} from '../utilites/useWindowDimensions';

interface PopupProps {
  open?: boolean;
  disableClose?: boolean;
  title: string;
  onClose?: () => void;
  children?: JSX.Element | string;
}

export function FullScreenPopupWindow(props: PopupProps): ReactElement {
  const { open, title, onClose, children, disableClose } = props;

  const closeWindow = (event?: any, reason?: 'backdropClick' | 'escapeKeyDown') => {
    if (!onClose || reason === 'backdropClick') {
      event?.stopPropagation();
      return;
    }
    onClose();
  };

  return (
    <Modal
      open={!!open}
      onClose={closeWindow}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ position: 'fixed', display: 'flex', top: 0, bottom: 0, left: 0, right: 0, alignItems: 'center' }}
    >
      <Stack direction={'column'} sx={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: '#ffffff' }}>
        <Stack direction={'row'}>
          <Typography variant="h5" sx={{ flex: 1, fontWeight: 500, ml:2,  }}>
            {title}
          </Typography>
          <IconButton onClick={() => closeWindow()} sx={{ ml: 4 }} disabled={!!disableClose}>
            <FontAwesomeIcon icon={faTimes} style={{ width: 24 }} />
          </IconButton>
        </Stack>
        <Stack
          sx={{m:2, flex:1, overflow: 'auto'}}
        >
          {children}
        </Stack>
      </Stack>
    </Modal>
  );
}

export function PopupWindow(props: PopupProps): ReactElement {
  const { open, title, onClose, children, disableClose } = props;
  const { width = 200, height = 200 } = useWindowDimensions();

  const closeWindow = (event?: any, reason?: 'backdropClick' | 'escapeKeyDown') => {
    if (!onClose || reason === 'backdropClick') {
      event?.stopPropagation();
      return;
    }
    onClose();
  };

  return (
    <Modal
      open={!!open}
      onClose={closeWindow}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        position: 'fixed',
        display: 'flex',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Paper
        elevation={2}
        sx={{
          position: 'absolute' as 'absolute',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Stack spacing={1} direction={'column'}>
          <Stack sx={{ display: 'flex', alignItems: 'center', pl: 4, pt: 2, pr: 1 }} direction={'row'}>
            <Typography variant="h5" sx={{ flex: 1, fontWeight: 500 }}>
              {title}
            </Typography>
            <IconButton onClick={() => closeWindow()} sx={{ ml: 4 }} disabled={!!disableClose}>
              <FontAwesomeIcon icon={faTimes} style={{ width: 24 }} />
            </IconButton>
          </Stack>
          <Stack
            sx={{
              display: 'flex',
              flex: 1,
              alignItems: 'baseline',
              pl: 4,
              pt: 1,
              pr: 4,
              pb: 2,
              maxWidth: width - 100,
              maxHeight: height - 100,
              overflowY: 'hidden'
            }}
          >
            {children}
          </Stack>
        </Stack>
      </Paper>
    </Modal>
  );
}
