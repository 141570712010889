import * as React from 'react';
import {Stack, Typography} from "@mui/material";

interface Props {
    title?: string;
    subtitle?: string;
    children?: JSX.Element;
}

export const ErrorPanel = (props: Props): React.ReactElement => {
        const { children, title, subtitle } = props;

        return (
            <Stack spacing={2} sx={{mt:6, maxWidth:800}}>
                {title && <Typography variant={"h5"} sx={{fontWeight:500}}>{title}</Typography>}
                {subtitle && <Typography variant={"body1"}>{subtitle}</Typography>}
                {children}
            </Stack>
        );
}

