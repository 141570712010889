import {LinearProgress, Stack} from '@mui/material';
import * as React from 'react';
import {useEffect} from 'react';
import JobTypeCard from '@biostrand/components/src/jobs/JobTypeCard';
import {useDispatch, useSelector} from "react-redux";
import {isJobTypesLoadingSelector, jobTypesSelector} from "./jobsSelectors";
import {refreshJobTypesAction} from "@biostrand/components/src/jobs/jobsSlice";

const JobsOverview = (): JSX.Element => {
    const jobTypes = useSelector(jobTypesSelector);
    const isJobTypesLoading =  useSelector(isJobTypesLoadingSelector);
    const dispatch = useDispatch();

    useEffect(()=> {
        dispatch(refreshJobTypesAction());
    },[])

    console.log("isJobTypesLoading, jobTypes =>", isJobTypesLoading, jobTypes )

    return (
        <>
            <Stack direction={'row'} sx={{ flexWrap: 'wrap', position:'relative', overflowY:'auto' }}>

                {!isJobTypesLoading && jobTypes?.length && jobTypes.map(jobType => (
                    <JobTypeCard template={jobType} key={jobType.id} />
                ))}
                {isJobTypesLoading ? (
                    <Stack sx={{width: "100%"}}>
                        <LinearProgress />
                    </Stack>
                ) : null}
            </Stack>
        </>
    );
};

export default JobsOverview;
