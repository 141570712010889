import {Button, FormControl, IconButton, InputLabel, MenuItem, Select, Stack} from '@mui/material';
import * as React from 'react';
import {useState} from 'react';
import {useDispatch} from 'react-redux';
import {gridExpandedSortedRowEntriesSelector, useGridApiContext} from "@mui/x-data-grid-pro";
import {closePopup, showPopup} from "../../popup/popupsSlice";
import AlignedSequencesPopup from "./AlignedSequencesPopup";
import {ArrowBack} from "@mui/icons-material";
import {CSV_ROW_ID_FIELD} from "../../csvViewer/tableUtils";

export const ToolbarGridAlignSequence = () => {
  const apiRef = useGridApiContext();
  const columns = apiRef.current.getAllColumns();
  const sequenceColumns = columns
    .filter(column => column.type === 'sequence')
    .map(c => {
      return {
        label: c.headerName ? c.headerName : c.field,
        value: c.field
    }
    })

  const dispatch = useDispatch();
  const [selectedColumn, setSelectedColumn] = useState(sequenceColumns[0]);

  if (!(sequenceColumns && sequenceColumns.length > 0)) {
    return null;
  }

  return (
    <Stack direction={"row"}>
      <FormControl size={"small"} sx={{m: 1, width: 250}}>
        <InputLabel id="column-to-align">Column to align</InputLabel>
        <Select
          labelId="column-to-align"
          id="column-to-align-s"
          label="Column to align"
          value={selectedColumn}
          onChange={(event) => setSelectedColumn(event.target.value)}
        >
          {sequenceColumns && sequenceColumns.map(c => {
            return <MenuItem value={c.value} key={c.value}>{c.label}</MenuItem>
          })}
        </Select>
      </FormControl>
      <Button onClick={async () => {

        if (!selectedColumn) return;
        const selectedRows = apiRef.current.getSelectedRows();
        const rrr = gridExpandedSortedRowEntriesSelector(apiRef);
        const rows = selectedRows && selectedRows.size > 0 ? selectedRows : rrr;

        const sequences = []
        rows.forEach((s, key) => {
          sequences.push('>' + (s[CSV_ROW_ID_FIELD] || s.model[CSV_ROW_ID_FIELD])+ '\n' + (s[selectedColumn] || s.model[selectedColumn]));
        })

        dispatch(
          showPopup({
            fullscreen: true,
            key: 'a-key',
            title: (
              <Stack direction={'row'} sx={{mt: 2, alignItems: 'center'}} spacing={4}>
                <IconButton
                  onClick={() => {
                    dispatch(closePopup('a-key'));
                  }}
                >
                  <ArrowBack/>
                </IconButton>
                {'Aligned sequences'}
              </Stack>
            ),
            content: React.createElement(AlignedSequencesPopup, {
              data: sequences.join('\n'),
              popupKey: 'a-key',
            }),
          }),
        );

      }}>Align</Button>
    </Stack>
  );
};
