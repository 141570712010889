import { DatasetManagerValidateRegistrationResponse } from '@biostrand/biostrandapi/javascript/dist/DatasetManagerApi';
import { Button, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { closePopup } from '../popup/popupsSlice';
import {createAction} from "@reduxjs/toolkit";

export const UPDATE_DATASET_CHOSEN = 'ExistDatasetVersionWarningPopup/UPDATE_DATASET_CHOSEN';
export const EDIT_DATASET_CHOSEN = 'ExistDatasetVersionWarningPopup/EDIT_DATASET_CHOSEN';

const updateAction = createAction(UPDATE_DATASET_CHOSEN);
const editAction = createAction(EDIT_DATASET_CHOSEN);


interface Props {
  popupKey: string;
  validationResponse?: DatasetManagerValidateRegistrationResponse;
  datasetName: string;
}

export const ExistDatasetVersionWarningPopup = (props: Props): JSX.Element => {
  const { popupKey, datasetName } = props;

  const dispatch = useDispatch();

  const onEdit = () => {
    dispatch(editAction());
    dispatch(closePopup(popupKey));
  };

  const onUpdateDataset = () => {
    dispatch(updateAction());
    dispatch(closePopup(popupKey));
  };

  return (
    <Stack>
      <Typography>
        The dataset with name <b>{datasetName}</b> is already exist
      </Typography>
      <Typography>
        There are 2 options possible:
        <ol>
          <li>Update dataset. (It creates new version of dataset)</li>
          <li>Change dataset name</li>
        </ol>
      </Typography>

      <Stack direction={'row'} sx={{ justifyContent: 'center' }} spacing={2}>
        <Button variant={'outlined'} onClick={onUpdateDataset}>
          Update dataset
        </Button>
        <Button variant={'outlined'} onClick={onEdit}>
          Edit name
        </Button>
      </Stack>
    </Stack>
  );
};
