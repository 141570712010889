import Color from 'color';

export interface ColorMap {
    [key: string]: string;
}

export const pastelColor = (color:string, light: number):string => {
    const colorObj = new Color(color);
    return colorObj.lightness(light).hex();
}

export const makePastelColors = (cmap: ColorMap, light: number): ColorMap => {
    const newMap: ColorMap = {};
    Object.keys(cmap).forEach((key: string) => {
        newMap[key] = pastelColor(cmap[key], light);
    });
    return newMap;
}

const hexaString = (color:Color) => {
    //
    // const rgbArray = color.rgb().round().color;
    //
    // let alphaHex = Math.round(color.valpha * 255).toString(16).toUpperCase();
    // if (alphaHex.length === 1) {
    //     alphaHex = '0' + alphaHex;
    // }
    //
    // return colorString.to.hex(rgbArray) + alphaHex;

    return `rgba(${color.red()},${color.green()},${color.blue()},${color.alpha()})`;
}



export const addAlpha = (color: string, opacity: number): string => {
    const colorObj = new Color(color);
    return hexaString(colorObj.alpha(opacity));
}


