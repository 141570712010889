import {UserManagerUserEntity} from "@biostrand/biostrandapi/javascript/dist/UserManagerApi";

export interface UsersState {
    readonly users?: UserManagerUserEntity[];
    isLoading: boolean;
}

export interface AppWithOrganizationUsers {
    organizationUsers: UsersState;
}

export enum UserStatus {
    PENDING = 'PENDING',
    ACTIVE = 'ACTIVE',
    DEACTIVATED = 'DEACTIVATED',
}
