import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {UserContextState} from "./userTypes";
import {Apps} from "@biostrand/components";
import {
    UserManagerGetAuthorizationContextResponse,
    UserManagerUserResponse
} from "@biostrand/biostrandapi/javascript/dist/UserManagerApi";

const initialState: UserContextState = {
    currentApp: Apps.PORTAL,
};

export const userSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        updateUserContext: (state, action: PayloadAction<UserManagerGetAuthorizationContextResponse>) => {
            state.context = action.payload;
        },
        setCurrentApp: (state, action: PayloadAction<Apps>) => {
            state.currentApp = action.payload;
        },
        updateUserFaces: (state, action: PayloadAction< UserManagerUserResponse >) => {
            state.userFaces = action.payload;
        },

    },
});

export const { updateUserContext, setCurrentApp, updateUserFaces } = userSlice.actions;

export default userSlice.reducer;

