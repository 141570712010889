import { Box } from '@mui/material';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Apps } from '../../applications/applicationTypes';
import { RuntimeConfig } from '../../runtimeConfig/RuntimeConfig';
import { selectAuthStatus } from '../../slices/auth/authSelectors';
import { AuthenticationStatus } from '../../slices/auth/authTypes';
import { currentAppIDSelector } from '../../slices/user/userSelectors';
import BlankHeader from '../header/BlankHeader';
import PageHeader from '../header/PageHeader';
import PageFooter from '../PageFooter';
import NotFoundPageContent from './NotFoundPageContent';

const NotFoundPage = (): JSX.Element => {
  const currentAppId = useSelector(currentAppIDSelector) || Apps.PORTAL;
  const authStatus: AuthenticationStatus = useSelector(selectAuthStatus);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      {authStatus === AuthenticationStatus.AUTHENTICATED ? <PageHeader selectedAppId={currentAppId} /> : <BlankHeader />}

      <NotFoundPageContent currentApplicationId={currentAppId} portalLink={RuntimeConfig.getPortalLink()} />
      <PageFooter />
    </Box>
  );
};

export default NotFoundPage;
