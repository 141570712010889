import * as React from 'react';
import {CBox} from "../layout/CBox";
//@ts-ignore
import DnaIcon from "./dna-icon.svg";
import {Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {SxProps} from "@mui/system";

interface DNALoadingProps {
  message?:string,
  sx?: SxProps
}

export const DNALoading = ({message, sx}: DNALoadingProps) => {
  const [t] = useTranslation();
  return <CBox sx={{alignItems: 'center', justifyContent:'center', flex:1, ...sx}}>
    <img src={DnaIcon} alt="BioStrand Logo"  style={{  width: 80, height: 80 }} />
    <Typography>{message ? message : t('Loading...')}</Typography>
  </CBox>
}
