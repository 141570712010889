import {
    AuthenticationFeedbackType,
    Credentials,
    currentCognitoUserPool,
    setAWSConfig
} from "@biostrand/components";
import {AuthenticationDetails, CognitoUser, CognitoUserSession} from "amazon-cognito-identity-js";
import {ConfirmPasswordParameters, PasswordChallengeParameters} from "../../pages/public/registration/registerTypes";

const COGNITO_EXCEPTION_EXPIREDCODE = 'ExpiredCodeException';
const COGNITO_EXCEPTION_LIMIT_EXCEEDED = 'LimitExceededException';

export const authenticate = (credentials: Credentials): Promise<AuthenticationResponse<AuthenticationFeedbackType>> => {
    return new Promise((resolve) => {
        const authData = {
            Username: credentials.email,
            Password: credentials.password,
        };
        const authDetails = new AuthenticationDetails(authData);
        const userData = {
            Username: credentials.email,
            Pool: currentCognitoUserPool,
        };

        const cognitoUser = new CognitoUser(userData);
        cognitoUser.authenticateUser(authDetails, {
            onSuccess(session: CognitoUserSession) {
                setAWSConfig(session);
                resolve({ result: AuthenticationFeedbackType.Success });
            },
            // eslint-disable-next-line
            newPasswordRequired(userAttributes, requiredAttributes) {

                resolve({
                    errorCode: 'FailureCodes.AUTH2',
                    result: AuthenticationFeedbackType.Failure,
                });
            },
            onFailure() {
                resolve({
                    errorCode: 'FailureCodes.AUTH1',
                    result: AuthenticationFeedbackType.Failure,
                });

            },
        });
    });
};

export const forgotPassword = (email: string): Promise<AuthenticationResponse<AuthenticationFeedbackType>> => {
    // eslint-disable-next-line
    return new Promise((resolve, reject) => {
        const userData = {
            Username: email,
            Pool: currentCognitoUserPool,
        };

        const cognitoUser = new CognitoUser(userData);
        cognitoUser.forgotPassword({
            // eslint-disable-next-line
            onSuccess: (data: any) => {
                resolve({ result: AuthenticationFeedbackType.Success, data });
            },
            // eslint-disable-next-line
            onFailure: (err: Error) => {
                resolve({
                    result: AuthenticationFeedbackType.Failure,
                    errorCode: "FailureCodes.Unexpected",
                });
            },
        });
    });
}

export const confirmNewPassword = (
    parameters: ConfirmPasswordParameters,
): Promise<AuthenticationResponse<AuthenticationFeedbackType>> => {
    // eslint-disable-next-line
    return new Promise((resolve, reject) => {
        const userData = {
            Username: parameters.email,
            Pool: currentCognitoUserPool,
        };

        const cognitoUser = new CognitoUser(userData);
        cognitoUser.confirmPassword(parameters.confirmationCode, parameters.newPassword, {
            onSuccess() {
                resolve({ result: AuthenticationFeedbackType.Success });
            },
            onFailure(err: Error) {
                if (err.name === COGNITO_EXCEPTION_EXPIREDCODE) {
                    resolve({
                        result: AuthenticationFeedbackType.Failure,
                        errorCode: `FailureCodes.AUTH3: ${COGNITO_EXCEPTION_EXPIREDCODE}`,
                    });
                } else if (err.name === COGNITO_EXCEPTION_LIMIT_EXCEEDED) {
                    resolve({
                        result: AuthenticationFeedbackType.Failure,
                        errorCode: `FailureCodes.AUTH4: ${COGNITO_EXCEPTION_LIMIT_EXCEEDED}`,
                    });
                } else {
                    resolve({
                        result: AuthenticationFeedbackType.Failure,
                        errorCode: "FailureCodes.Unexpected: " + err,
                    });
                }
            },
        });
    });
}

export function finishSetupPasswordChallenge(
    parameters: PasswordChallengeParameters,
): Promise<AuthenticationResponse<AuthenticationFeedbackType>> {
    // eslint-disable-next-line
    return new Promise((resolve, reject) => {
        const authData = {
            Username: parameters.email,
            Password: parameters.temporaryPassword,
        };
        const authDetails = new AuthenticationDetails(authData);
        const userData = {
            Username: parameters.email,
            Pool: currentCognitoUserPool,
        };

        const cognitoUser = new CognitoUser(userData);

        cognitoUser.authenticateUser(authDetails, {
            // eslint-disable-next-line
            onSuccess(session: CognitoUserSession | any) {
                // eslint-disable-next-line
                setAWSConfig(session);
                resolve({ result: AuthenticationFeedbackType.Success });
            },
            // eslint-disable-next-line
            newPasswordRequired(userAttributes: any, requiredAttributes: any) {
                // resolve({ result: AuthenticationFeedbackType.NewPasswordRequired });
                cognitoUser.completeNewPasswordChallenge(parameters.newPassword, requiredAttributes, {
                    onSuccess(session: CognitoUserSession) {
                        setAWSConfig(session);
                        resolve({ result: AuthenticationFeedbackType.Success });
                    },
                    // eslint-disable-next-line
                    onFailure(err) {
                        resolve({
                            errorCode: "FailureCodes.Unexpected",
                        });
                    },
                });
            },
            // eslint-disable-next-line
            onFailure(err) {
                resolve({
                    errorCode: "FailureCodes.Unexpected",
                    result: AuthenticationFeedbackType.Failure,
                });
            },
        });
    });
}

export interface AuthenticationResponse<T> {
    result?: T;
    errorCode?: string;

    data?: never;
}
