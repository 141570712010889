import {ArrowForward} from '@mui/icons-material';
import {LoadingButton} from '@mui/lab';
import {Button, Stack, Step, StepLabel, Stepper} from '@mui/material';
import {Form, Formik} from 'formik';
import {FormikHelpers} from 'formik/dist/types';
import * as React from 'react';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {CreateS3DatasetValues, S3Settings} from "../datasetTypes";
import {Errors, s3Settings2Config} from "../datasetFormUtils";
import {indexPipeline, qualityCheckPipeline, uploadPipeline} from "../pipelines/PipelinesConstants";
import {createDatasetRequestAction} from "../../slices/datasets/datasetsSlice";
import {PipelineType} from "../pipelines/pipelineTypes";
import {closePopup} from '../../popup/popupsSlice';
import {CreateS3DatasetForm} from "../CreateS3DatasetForm";
import {SelectPipelinesStep} from "../SelectPipelinesStep";
import {AddDatasetOverview, AddDatasetOverviewLine} from "../AddDatasetOverview";

interface Props {
  popupKey: string;
}

export const AddDatasetsFromS3PopupContent = (props: Props): JSX.Element => {
  const { popupKey } = props;
  const [t] = useTranslation();
  const [isBusy] = useState<boolean>(false);
  const [activeStep, setActiveStep] = React.useState(0);

  const dispatch = useDispatch();
  const steps = [t('Settings'), t('Pipelines'), t('Overview')];

  const validate = (datasetSettings: CreateS3DatasetValues) => {
    const { datasetName, connectionConfig } = datasetSettings;
    const { s3_region } = connectionConfig;
    const errors: Errors = {};
    const connectionConfigErrors: S3Settings = {};
    if (!datasetName) {
      errors.datasetName = t('Dataset name is required');
    }

    if (!s3_region) {
      connectionConfigErrors.s3_region = t('S3 region is required');
    }
    if (Object.keys(connectionConfigErrors).length > 0) errors.connectionConfig = connectionConfigErrors;

    if (Object.keys(errors).length > 0) return errors;
    return undefined;
  };

  const initialValues: CreateS3DatasetValues = {
    datasetName: '',
    connectionConfig: {
      path: '',
      s3_region: '',
      secret_access_key: '',
    },
    pipelines: [
      { pipeline: uploadPipeline, selected: true },
      { pipeline: qualityCheckPipeline, selected: false, disabled: true },
      { pipeline: indexPipeline, selected: false, disabled: true },
    ],
    formConfirmed: false,
  };

  const handleSubmit = (values: CreateS3DatasetValues, actions: FormikHelpers<CreateS3DatasetValues>) => {

    const actualPipelines = values.pipelines.filter(p => p.selected);
    const uploadPipeline = actualPipelines.find(p => p.pipeline.type === PipelineType.UPLOAD);
    const jobs = uploadPipeline ? ['upload'] : undefined;

    dispatch(
      createDatasetRequestAction( { datasetRequest:{
          name: values.datasetName,
          config: s3Settings2Config(values.connectionConfig),
          jobs
        },
        //   actions: actions,

        callback: error => {
          actions.setSubmitting(false);
          if (error === 'edit') {
            setActiveStep(0);
            return;
          }
          if (!error) {
            onClose();
            return;
          }
        },
      }),
    );
  };

  const handleNextStep = () => {
    setActiveStep(activeStep + 1);
  };

  const handlePreviousStep = () => {
    setActiveStep(activeStep - 1);
  };

  const onClose = () => {
    dispatch(closePopup(popupKey));
  };

  return (
      <Stack sx={{ mt: 2 }}>
        <Stepper activeStep={activeStep} sx={{ mb: 2 }}>
          {steps.map((label, index) => {
            return (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit}>
          {({ isSubmitting, touched, handleSubmit, isValid, errors, values, setFieldValue, validateForm }) => {
            return (
              <Form id="DS" autoComplete="false">
                {activeStep === 0 ? <CreateS3DatasetForm /> : null}
                {activeStep === 1 ? <SelectPipelinesStep /> : null}
                {activeStep === 2 ? (
                  <AddDatasetOverview>
                    <>
                      <AddDatasetOverviewLine title={t('Dataset name')} value={values.datasetName} />
                      <AddDatasetOverviewLine title={t('S3 region')} value={values.connectionConfig.s3_region} />
                      <AddDatasetOverviewLine title={t('Access key')} value={values.connectionConfig.access_key_id} />
                      <AddDatasetOverviewLine
                        title={t('Secret access key')}
                        value={values.connectionConfig.secret_access_key}
                      />
                    </>
                  </AddDatasetOverview>
                ) : null}

                <Stack direction="row" sx={{ mt: 1, mb: 3 }}>
                  {activeStep === 0 ? (
                    <Button onClick={onClose} variant={'outlined'}>
                      {t('cancel')}{' '}
                    </Button>
                  ) : null}
                  {activeStep > 0 ? (
                    <Button onClick={handlePreviousStep} variant={'outlined'}>
                      {t('back')}{' '}
                    </Button>
                  ) : null}
                  <Stack sx={{ flex: 1 }} />
                  {activeStep !== 2 ? (
                    <Button
                      onClick={async () => {
                        const validatedResult = await validateForm();
                        if (validatedResult.datasetName || validatedResult.connectionConfig) {
                          return;
                        }
                        handleNextStep();
                      }}
                      variant={'contained'}
                      endIcon={<ArrowForward />}
                      disabled={!touched.datasetName || !isValid}
                    >
                      {t('continue')}{' '}
                    </Button>
                  ) : null}
                  {activeStep === 2 ? (
                    <LoadingButton
                      sx={{ mr: 2 }}
                      variant={'contained'}
                      loading={isSubmitting}
                      disabled={!values.formConfirmed || isSubmitting}
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      {t('Save dataset')}
                    </LoadingButton>
                  ) : null}
                </Stack>
              </Form>
            );
          }}
        </Formik>
      </Stack>
  );
};
