import { Box } from '@mui/material';
import { useField } from 'formik';
import * as React from 'react';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { CBox } from '../layout/CBox';
import {PipelinesListItem, PipelineType} from "./pipelines/pipelineTypes";
import {SimplePipelineItem} from "./pipelines/SimplePipelineItem";



export function SelectPipelinesStep(): ReactElement {
  const [t] = useTranslation();

  const [, meta, helpers] = useField({name:'pipelines'});
  const { value } = meta;
  const { setValue } = helpers;

  const handleSelectionChange = (pipelineItem: PipelinesListItem<any>, settings: any) => {

    const pipelineIndex = value.indexOf(pipelineItem)
    const updatedPipeline = {
      ...pipelineItem,
      selected: !pipelineItem.selected,
      settings
    }

    value[pipelineIndex] = updatedPipeline;
    setValue( [...value] );
  }

  const renderPipeline = (pipelineListItem: PipelinesListItem<any>) => {
    switch (pipelineListItem.pipeline.type) {
      case PipelineType.UPLOAD:
      case PipelineType.QUALITY_CHECK:
        return <SimplePipelineItem
          pipeline={pipelineListItem}
          onChange={handleSelectionChange}
          key={pipelineListItem.pipeline.type}/>
      // case PipelineType.QUALITY_CHECK:
      //   return <UploadPipelineItem onChange={handleSelectionChange}  key={pipelineType} selected={value.indexOf(pipelineType)> -1} />
    }

    return null
  }


  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', pt: 2, minWidth: 720 }}>
      <CBox sx={{mt:2, mb:3}}>
        {value.map(renderPipeline)}
      </CBox>
    </Box>
  );
}
