import React, {ReactElement} from 'react';

import {Stack} from "@mui/system";
import ReactJson from "@microlink/react-json-view";
import {Typography} from "@mui/material";

interface RVProps {
  data: string;
}

export const JsonPreview = (props: RVProps): ReactElement => {
  const {
    data
  } = props;
  try {
    const jso = JSON.parse(data);
    return (
      <Stack sx={{pr: 1, mb: 2}} spacing={1}>
        <ReactJson src={jso}/>
      </Stack>
    );
  } catch (e) {

  }
  return <Stack sx={{pr: 1, mb: 2}} spacing={1}>
    <Typography>The file does not contain valid JSON, the raw view:</Typography>
    <pre style={{maxWidth: 1200, fontSize: 12, backgroundColor: '#f0f0f0', padding: 8, overflow: 'scroll'}}>{data}</pre>
  </Stack>


}
