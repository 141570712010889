
export enum PipelineType {
    UPLOAD = 'upload',
    QUALITY_CHECK = 'quality_check',
    HYFT_INDEXING = 'hyft_indexing',
}



export interface PipelineDescription {
    type: PipelineType,
    name: string,
    description?: string,
}

export interface PipelinesListItem<T> {
    pipeline: PipelineDescription,
    selected?: boolean,
    disabled?: boolean,
    settings?: T,
}