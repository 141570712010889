import {faEye, faEyeSlash} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput} from '@mui/material';
import * as React from 'react';
import {useState} from 'react';

interface PasswordInputProps {
  password: string;
  onChange: {
    (e: React.ChangeEvent<any>): void;
    <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  ariaLabel: string;
  error?: boolean;
  helperText?: string;
}

export function PasswordInput(props: PasswordInputProps) {
  const [showPassword, setShowPassword] = useState(false);
  const {password, onChange, ariaLabel, error, helperText} = props;

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <FormControl
      variant="outlined"
      sx={{
        height: theme => theme.spacing(9),
        marginBottom: 3,
      }}
    >
      <InputLabel htmlFor="password">Password</InputLabel>
      <OutlinedInput
        id="password"
        type={showPassword ? 'text' : 'password'}
        value={password}
        name="password"
        label="Password"
        aria-describedby="password-helper-text"
        error={error}
        onChange={onChange}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label={ariaLabel}
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <FontAwesomeIcon icon={faEyeSlash}/> : <FontAwesomeIcon icon={faEye}/>}
            </IconButton>
          </InputAdornment>
        }
      />

      {error ? <FormHelperText id="password-helper-text" error={error}>{helperText}</FormHelperText> : null}
    </FormControl>
  );
}
