import * as React from 'react';
import SettingsPageTemplate from '../SettingsPageTemplate';
import DatasetsSettingsContent from './DatasetsSettingsContent';
import {SettingsIds} from "@biostrand/components/src/applications/SettingsIds";

const DatasetsSettingsPage = (): JSX.Element => {
    return (
        <SettingsPageTemplate selectedItem={SettingsIds.DATASETS} >
            <DatasetsSettingsContent  />
        </SettingsPageTemplate>
    );
};

export default DatasetsSettingsPage;
