import React, { ReactElement } from 'react';
import { BASE_SIZE } from '../AligmentBaseSize';

interface SubSelectionRectProps {
    start: number;
    end: number;
    row: number;
}

const SubSelectionRect = (props: SubSelectionRectProps): ReactElement => {
    const { start, end, row } = props;
    return (
        <div
            style={{
                backgroundColor: 'rgba(0,0,0,0.1)',
                borderBottom: 'solid 2px rgba(0,0,0,0.5)',
                pointerEvents: 'none',
                position: 'absolute',
                width: Math.abs(start - end) * BASE_SIZE + BASE_SIZE + 4,
                height: BASE_SIZE + 2,
                top: (row || 0) * BASE_SIZE,
                margin: -2,
                left: Math.min(start, end) * BASE_SIZE,
            }}
        />
    );
};

export default SubSelectionRect;
