import {
    AppWithDatasetsState,
    AppWithNotificationState,
    AppWithUserContextState,
    authReducer,
    datasetsReducer,
    snackbarNotificationReducer,
    userReducer,
} from '@biostrand/components';
import {PORTAL_BASE_NAME} from '@biostrand/components/src/applications/applicationUtils';
import popupsReducer, {AppWithPopupState} from '@biostrand/components/src/popup/popupsSlice';
import jobsReducer from "@biostrand/components/src/jobs/jobsSlice";
import fileUploadManagerReducer from '@biostrand/components/src/slices/datasets/fileUploadManagerSlice';
import {AppWithFileUploadManagerState} from '@biostrand/components/src/slices/datasets/fileUploadTypes';
import dataFiltersReducer from "@biostrand/components/src/filters/dataFiltersSlice";
import {configureStore} from '@reduxjs/toolkit';
import {connectRouter, routerMiddleware} from 'connected-react-router';
import {createBrowserHistory} from 'history';
import createSagaMiddleware from 'redux-saga';
import appSaga from './appSagas';
import organizationReducer from './core/organization/organizationSlice';
import usersReducer from './pages/private/settings/users/usersSlice';

import {AppWithOrganizationUsers} from './pages/private/settings/users/usersTypes';
import registerReducer from './pages/public/registration/registerSlice';
import {AppWithJobState} from "@biostrand/components/src/jobs/jobsTypes";
import {AppWithDataFiltersState} from "@biostrand/components/src/filters/dataFiltersTypes";

const sagaMiddleware = createSagaMiddleware();

export const history = createBrowserHistory({ basename: `/${PORTAL_BASE_NAME}` });
export const store = configureStore({
    reducer: {
        auth: authReducer,
        register: registerReducer,
        user: userReducer,
        organization: organizationReducer,
        snackbarNotifications: snackbarNotificationReducer,
        dataset: datasetsReducer,
        fileUploadManager: fileUploadManagerReducer,
        jobs: jobsReducer,
        organizationUsers: usersReducer,
        dataFilters: dataFiltersReducer,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        router: connectRouter(history),
        popups: popupsReducer,
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(routerMiddleware(history), sagaMiddleware),
});

sagaMiddleware.run(appSaga);

export type RootState = ReturnType<typeof store.getState> &
    AppWithNotificationState &
    AppWithUserContextState &
    AppWithDatasetsState &
    AppWithOrganizationUsers &
    AppWithFileUploadManagerState &
    AppWithPopupState &
    AppWithDataFiltersState &
    AppWithJobState;
