import {IconButton} from '@mui/material';
import {Stack} from '@mui/system';
import * as React from 'react';
import {ReactElement} from 'react';
import {closePopup} from "./popupsSlice";
import {ArrowBack} from "@mui/icons-material";
import {useDispatch} from "react-redux";

interface PopupProps {
  label: string;
  popupKey: string;
}

export function FullscreenPopupTitle(props: PopupProps): ReactElement {
  const {label, popupKey} = props;
  const dispatch = useDispatch()

  return (
    <Stack direction={'row'} sx={{mt: 2, alignItems: 'center'}} spacing={1}>
      <IconButton
        onClick={() => {
          dispatch(closePopup(popupKey));
        }}
      >
        <ArrowBack/>
      </IconButton>
      <div>
      {label}
      </div>
    </Stack>)
}
