import {Chip, TableCell, Typography} from '@mui/material';
import * as React from 'react';
import {UserManagerUserEntity} from "@biostrand/biostrandapi/javascript/dist/UserManagerApi";

interface Props {
    row: UserManagerUserEntity;
}

export const UserEmailCell = (props: Props): JSX.Element => {
    const { row } = props;
    return (
        <TableCell sx={{ pt: 1, pb: 1, pl: 1 }}>
            <Typography variant='subtitle2'>{row?.email}</Typography>
            {row?.status !== 'ACTIVE' && <Chip label={row?.status?.toLowerCase()} size={'small'} sx={{ height: 18, paddingTop: '1px' }} />}
        </TableCell>
    );
};
