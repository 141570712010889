import {
    DatasetManagerDatasetEntities,
    DatasetManagerDeletionType,
    DatasetManagerInitiateUploadRequest,
    DatasetManagerInitiateUploadResponse,
    DatasetManagerMultipartFile,
    DatasetManagerRegisterDatasetRequest,
    DatasetManagerValidateRegistrationRequest,
    DatasetManagerValidateRegistrationResponse,
} from '@biostrand/biostrandapi/javascript/dist/DatasetManagerApi';
import {all, call, delay, put, race, select, take, takeEvery} from 'redux-saga/effects';
import {ActionType} from 'typesafe-actions';
import {isDatasetsLoadingSelector, showError, uApi} from '../../index';
import {showSuccess} from '../../snackbarNotification/snackbarNotificationSagaHelpers';
import {
    createDatasetRequestAction,
    DELETE_DATASET,
    deleteDatasetsAction,
    REFRESH_DATASETS,
    runCreateFtpDatasetFlowAction,
    runCreateHttpDatasetFlowAction,
    runCreateS3DatasetFlowAction,
    runUploadDatasetFlowAction,
    setDatasetsLoading,
    UPDATE_DATASET,
    updateDatasets,
    updateDatasetsPipelineAction,
    uploadDatasetRequestAction,
} from './datasetsSlice';
import {addFile, kickUploadProcess, uploadManagerSaveState} from './fileUploadManagerSlice';
import {CHUNK_SIZE, FilePart, FilePartStatus, FileUploadInfo, FileUploadStatus} from './fileUploadTypes';
import {closePopup, showPopup} from "../../popup/popupsSlice";
import * as React from "react";
import {
    EDIT_DATASET_CHOSEN,
    ExistDatasetVersionWarningPopup,
    UPDATE_DATASET_CHOSEN
} from "../../datasets/ExistDatasetVersionWarningPopup";
import {AddDatasetsFromFtpPopupContent} from "../../datasets/popups/AddDatasetsFromFtpPopupContent";
import {AddDatasetsFromHttpLinksPopupContent} from "../../datasets/popups/AddDatasetsFromHttpLinksPopupContent";
import {AddDatasetsFromS3PopupContent} from "../../datasets/popups/AddDatasetsFromS3PopupContent";
import {UploadDatasetFromLocalFilesPopupContent} from "../../datasets/popups/UploadDatasetFromLocalFilesPopupContent";

function* validateDatasetVersion(datasetName: string) {
    const request: DatasetManagerValidateRegistrationRequest = {name: datasetName}
    const result = yield call([uApi.getDatasetManagerApi(), 'datasetManagerValidateRegistration'], request);
    const validationResponse: DatasetManagerValidateRegistrationResponse = result.data;

    const popupKey = 'validate-dataset-version-key';
    if (!validationResponse.valid) {
        yield put(
            showPopup({
                key: popupKey,
                title: ('Confirm dataset version'),
                content: React.createElement(ExistDatasetVersionWarningPopup, {
                    popupKey: popupKey,
                    validationResponse,
                    datasetName: datasetName,
                }),
            }),
        );
        const {cancel, editName} = yield race({
            editName: take(EDIT_DATASET_CHOSEN),
            updateDataset: take(UPDATE_DATASET_CHOSEN),
            cancel: take(action => action.type === closePopup.type && action.payload === popupKey),
        });
        if (cancel || editName) return EDIT_DATASET_CHOSEN;
    }
}

export function* handleRefreshDatasets() {
    const isDatasetLoading = yield select(isDatasetsLoadingSelector);
    if (isDatasetLoading) {
        return;
    }

    yield put(setDatasetsLoading(true));
    try {
        const result = yield call([uApi.getDatasetManagerApi(), 'datasetManagerGetDatasets']);
        const datasetsData: DatasetManagerDatasetEntities = result.data;
        yield put(updateDatasets(datasetsData.data || []));
    } catch (e) {
        yield showError('something went wrong', e.toString());
    }
    yield delay(1000);
    yield put(setDatasetsLoading(false));
}

const getParts = (fileId: string, parts: { [key: string]: string }): FilePart[] => {
    const partsKeys = Object.keys(parts);
    return partsKeys.map(index => ({
        fileId,
        index,
        uploadLink: parts[index],
        status: FilePartStatus.DRAFT,
    }));
};

export function* uploadDatasetFromLocalFiles() {
    const popupKey = 'upload-from-local-popup-key';

    yield put(
        showPopup({
            key: popupKey,
            title: 'Dataset from file',
            content: React.createElement(UploadDatasetFromLocalFilesPopupContent, {
                popupKey: popupKey,
            }),
        }),
    );

    while (true) {
        const {
            cancel,
            requestAction,
        } = yield race({
            requestAction: take(uploadDatasetRequestAction.type),
            cancel: take(action => action.type === closePopup.type && action.payload === popupKey),
        });

        if (cancel) {
            yield put(closePopup(popupKey));
            return;
        }

        if (requestAction) {
            const {callback, datasetSettings} = requestAction.payload;//action.payload;
            const result: Result = yield handleUploadDatasetRequest(datasetSettings);

            switch (result.resultType) {
                case "result":
                    yield showSuccess('Dataset created');
                    yield handleRefreshDatasets();
                    yield put(closePopup(popupKey));
                    return result.value;
                case "edit":
                    yield call(callback, 'edit');
                    break;
                case "error":
                    yield showError('Something went wrong!', result.value.toString())
                    break;
            }
        }
    }
}

const normalizeFileName = (name:string): string => {
    if (name.indexOf('/') === 0) {
        return name.substr(1);
    }
    return name
}

function* handleUploadDatasetRequest(datasetSettings) {
    const nextStep = yield validateDatasetVersion(datasetSettings.datasetName);

    if (nextStep === EDIT_DATASET_CHOSEN) {
        return {
            resultType: 'edit',
            value: undefined
        };
    }

    const fileList = datasetSettings?.files;

    try {
        const mappedFiles: (DatasetManagerMultipartFile & { file: File, index: number })[] = [];

        for (let i = 0; i < fileList.length; i++) {
            const file = fileList[i];
            if (file) {
                const parts_count = Math.ceil(file.size / CHUNK_SIZE);
                mappedFiles.push({
                    file_name: normalizeFileName(file.webkitRelativePath ? file.webkitRelativePath :  file.path),
                    parts_count: parts_count.toString(),
                    index: i,
                    file
                })
            }
        }

        const files = mappedFiles.map(({file_name, parts_count}) => {
            return {
                file_name,
                parts_count
            }
        });

        const request: DatasetManagerInitiateUploadRequest = {
            identifier: {
                name: datasetSettings.datasetName
            },
            files,
        };

        const result = yield call([uApi.getDatasetManagerApi(), 'datasetManagerInitiateUpload'], request);

        const data: DatasetManagerInitiateUploadResponse = result.data;

        if (data?.parts?.length) {
            for (let i = 0; i < data?.parts?.length; i++) {
                let part = data?.parts[i];
                const originalFile = mappedFiles.find(mf => mf.file_name === part.file_name);
                if (originalFile) {
                    const fileUploadStatus: FileUploadInfo = {
                        fileName: originalFile.file_name || '',
                        fileSize: originalFile.file.size,
                        fileId: part.upload_id || '',
                        fileKey: part.key || '',
                        totalParts: parseInt(originalFile.parts_count || ''),
                        status: FileUploadStatus.PENDING,
                        parts: getParts(part.upload_id || '', part.part_urls || {}),
                        context: {dataset: data.dataset},
                        file: originalFile.file
                    };
                    yield put(addFile(fileUploadStatus));
                }
            }
        }

        yield put(uploadManagerSaveState());
        yield put(kickUploadProcess());

        return {
            resultType: 'result',
            value: data.dataset
        };

    } catch (err) {
        return {
            resultType: 'error',
            value: err
        };
    }
}

interface Result {
    resultType: 'error' | 'edit' | 'result',
    value: never;
}

function* createDataset(request: DatasetManagerRegisterDatasetRequest) {
    const nextStep = yield validateDatasetVersion(request.name || '');
    if (nextStep === EDIT_DATASET_CHOSEN) {
        return {
            resultType: 'edit',
            value: undefined
        };
    }

    try {
        const result = yield call([uApi.getDatasetManagerApi(), 'datasetManagerRegisterDataset'], request);
        if (result) {
            return {
                resultType: 'result',
                value: result.data
            };
        }
    } catch (err) {
        return {
            resultType: 'error',
            value: err
        };
    }
}

function* handleDeleteDataset(action: ActionType<typeof deleteDatasetsAction>) {
    const datasetId = action.payload;

    try {
        const result = yield call([uApi.getDatasetManagerApi(), 'datasetManagerDeleteDataset'], datasetId, false, DatasetManagerDeletionType.DELETION_TYPE_UNSPECIFIED);
        if (!result) {
            return;
        }
        yield handleRefreshDatasets();
        yield showSuccess(`dataset removed`);
    } catch (err) {
        yield showError(`Something went wrong`, err.toString());
    }
}

function* handleDatasetEditRequest(action: ActionType<typeof updateDatasetsPipelineAction>) {
    const {datasetSettings, callback} = action.payload;
    try {
        const result = yield call(
            [uApi.getDatasetManagerApi(), 'datasetManagerUpdateDataset'],
            datasetSettings.id as string,
            datasetSettings,
        );
        yield handleRefreshDatasets();
        yield call(callback, undefined);
    } catch (err) {
        yield call(callback, err.toString());
    }
}

interface JobFlowSettings {
    popUpTitle: string,
    PopUpComponent: any,
}

function* datasetCreateFlow(settings: JobFlowSettings) {
    const {popUpTitle, PopUpComponent} = settings;
    const popupKey = 'ftp-popup-key';

    yield put(
        showPopup({
            key: popupKey,
            title: popUpTitle,
            content: React.createElement(PopUpComponent, {
                popupKey: popupKey,
            }),
        }),
    );

    while (true) {
        const {
            cancel,
            requestAction,
        } = yield race({
            requestAction: take(createDatasetRequestAction.type),
            cancel: take(action => action.type === closePopup.type && action.payload === popupKey),
        });

        if (cancel) {
            yield put(closePopup(popupKey));
            return;
        }

        if (requestAction) {
            const {callback, datasetRequest} = requestAction.payload;//action.payload;
            const result: Result = yield createDataset(datasetRequest);

            switch (result.resultType) {
                case "result":
                    yield showSuccess('Dataset created');
                    yield handleRefreshDatasets();
                    yield put(closePopup(popupKey));
                    return result.value;
                case "edit":
                    yield call(callback, 'edit');
                    break;
                case "error":
                    yield showError('Something went wrong!', result.value.toString())
                    break;
            }
        }
    }
}

export function* createFtpDataset() {
    const result = yield datasetCreateFlow({
        popUpTitle: 'Create dataset from ftp',
        PopUpComponent: AddDatasetsFromFtpPopupContent
    })
    return result;
}

export function* createHttpDataset() {
    const result = yield datasetCreateFlow({
        popUpTitle: 'Create dataset from http links',
        PopUpComponent: AddDatasetsFromHttpLinksPopupContent
    })
    return result;
}

export function* createS3Dataset() {
    const result = yield datasetCreateFlow({
        popUpTitle: 'Add dataset from AWS S3',
        PopUpComponent: AddDatasetsFromS3PopupContent
    })
    return result;
}

function* handleRunCreateS3DatasetFlow() {
    yield createS3Dataset();
}

function* handleRunCreateHTTPDatasetFlow( /* action: ActionType<typeof runCreateFtpDatasetFlowAction> */) {
    yield createHttpDataset();
}

function* handleRunCreateFTPDatasetFlow( /* action: ActionType<typeof runCreateFtpDatasetFlowAction> */) {
    yield createFtpDataset();
}

function* handleUploadDatasetFromLocalFilesFlow() {
    yield uploadDatasetFromLocalFiles();
}

export function* datasetsSaga() {
    yield all([
        yield takeEvery(runCreateFtpDatasetFlowAction.type, handleRunCreateFTPDatasetFlow),
        yield takeEvery(runCreateHttpDatasetFlowAction.type, handleRunCreateHTTPDatasetFlow),
        yield takeEvery(runCreateS3DatasetFlowAction.type, handleRunCreateS3DatasetFlow),
        yield takeEvery(runUploadDatasetFlowAction.type, handleUploadDatasetFromLocalFilesFlow),


        yield takeEvery(REFRESH_DATASETS, handleRefreshDatasets),
        yield takeEvery(UPDATE_DATASET, handleDatasetEditRequest),
        yield takeEvery(DELETE_DATASET, handleDeleteDataset),
    ]);
}
