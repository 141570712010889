import {Application, ApplicationsDropdownMenu, Apps} from '@biostrand/components';
import {IconButton, Popover} from '@mui/material';
import {Apps as AppsIcon} from '@mui/icons-material';
import * as React from 'react';

interface AppHeaderMenuProps {
    userApps: Application[],
    currentAppId: Apps

}

const AppHeaderMenu = (props: AppHeaderMenuProps):JSX.Element => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const {userApps, currentAppId} = props;

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
            <IconButton aria-describedby={id} sx={{
              color: theme => theme.palette.background.paper,
            }} size='medium' onClick={handleClick}>
                <AppsIcon />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}>
                <ApplicationsDropdownMenu applications={userApps} currentApplicationId={currentAppId} />
            </Popover>
        </div>
    );
};

export default AppHeaderMenu;
