export interface OrganizationState {
    creating?: boolean;
}

export enum OrganizationRole {
    REGULAR = 'REGULAR',
    ADMIN = 'ADMIN',
}

export interface RoleDescription {
    role: OrganizationRole,
    label: string
}

export const RoleList: RoleDescription[] = [
    {role: OrganizationRole.REGULAR, label:'Regular user'},
    {role: OrganizationRole.ADMIN, label:'Administrator'},
]
