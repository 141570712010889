import {Button, Stack, TextField, Typography,} from '@mui/material';

import LoadingButton from '@mui/lab/LoadingButton';

import {useFormik} from 'formik';
import * as React from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Link as RouterLink} from 'react-router-dom';
import * as yup from 'yup';
import {loginRequest} from '../../../core/auth/authActions';
import {RouteUrl} from '../../../routeUrl';
import {AuthenticationStatus, PasswordInput, selectAuthStatus} from "@biostrand/components";
import {isFeatureSupported, PortalRuntimeFeatures} from "../../../portalRuntimeConfig";

const validationSchema = yup.object({
    email: yup
        .string()
        .email('Enter a valid email')
        .defined('Email is required'),
    password: yup.string().required('Password is required'),
});

const LoginFormComponent = (props: WithTranslation) => {
    const dispatch = useDispatch();
    const authState = useSelector(selectAuthStatus);
    const params = (new URL(window.location.href)).searchParams;
    const sourceUrl = params.get('sourceUrl') || undefined;
    //const
    const {t} = props;

    const showRegistration = !isFeatureSupported(PortalRuntimeFeatures.DISABLE_REGISTRATION);

    const loginForm = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: validationSchema,
        onSubmit: values => {
            dispatch(loginRequest({sourceUrl, credentials: values}));
        },
    });

    return (
        <Stack spacing={3} sx={{
            flex: 1,
            ml: 12,
            alignSelf: 'stretch',
            justifySelf: "stretch",
            alignItems: 'stretch'
        }}>
            <Stack sx={{flex: 1}}>
                <Stack sx={{alignItems: "baseline", justifyContent: "flex-end", maxWidth: "800",mt:2, mr:5}} direction={"row"}>
                    <Typography variant='body1'>
                        {t("Don't have an account? ")}
                    </Typography>
                    {showRegistration ? <Button
                        disabled={authState === AuthenticationStatus.PENDING}
                        variant={"text"}
                        component={RouterLink}
                        to={RouteUrl.RegisterOrganization}
                        color='primary'
                        sx={{textTransform: "none"}}
                        size='large'>
                        {t('Sign up here')}
                    </Button> : null}
                </Stack>
            </Stack>
            <Typography variant='h4'>
                {t('Sign in')}
            </Typography>
            <Typography variant='body1'>
                {t('Please enter your credentials below')}
            </Typography>
            <form onSubmit={loginForm.handleSubmit}>
                <Stack spacing={3}
                       sx={{flex: 1, width: 400, alignItems: 'stretch'}}>
                    <TextField
                        id='email'
                        label={t('Email')}
                        variant='outlined'
                        name='email'
                        value={loginForm.values.email}
                        onChange={loginForm.handleChange}
                        error={loginForm.touched.email && Boolean(loginForm.errors.email)}
                        helperText={loginForm.touched.email && loginForm.errors.email}
                    />
                    <PasswordInput
                        password={loginForm.values.password}
                        ariaLabel={t('toggle password visibility')}
                        onChange={loginForm.handleChange}
                        error={loginForm.touched.password && Boolean(loginForm.errors.password)}
                        helperText={loginForm.touched.password ? loginForm.errors.password : undefined}
                    />
                    <Stack spacing={2} direction={"row"}>
                        <LoadingButton
                            variant='contained'
                            color='primary'
                            size='large'
                            loading={authState === AuthenticationStatus.PENDING}
                            onClick={() => {
                                loginForm.handleSubmit();
                            }}>
                            {t('Login')}
                        </LoadingButton>
                        <Button
                            disabled={authState === AuthenticationStatus.PENDING}
                            component={RouterLink}
                            to={RouteUrl.ForgotPassword}
                            color='primary'
                            size='large'
                            sx={{textTransform: "none"}}
                        >
                            {t('Forgot password?')}
                        </Button>

                    </Stack>
                </Stack>
            </form>
            <Stack sx={{flex: 1}} />
        </Stack>


    );
};

const LoginForm = withTranslation()(LoginFormComponent);

export default LoginForm;
