import {Button, LinearProgress, Stack} from '@mui/material';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import JobTypeListCard from "./JobTypeListCard";
import {isJobTypesLoadingSelector, jobTypesSelector} from "./jobsSelectors";
import {closePopup} from '../popup/popupsSlice';

interface PickUpJobPopupProps {
    popupKey: string;
}

export const PickUpJobTypePopup = (props: PickUpJobPopupProps): JSX.Element => {
    const {popupKey} = props;
    const [t] = useTranslation();
    const jobTypes = useSelector(jobTypesSelector);
    const isJobTypesLoading =  useSelector(isJobTypesLoadingSelector);

    const dispatch = useDispatch();

    const onClose = () => {
        dispatch(closePopup(popupKey));
    };

    return (
        <Stack sx={{overflowY: 'hidden', overflow: 'hidden', height: 600}}
               spacing={2}>
                <Stack sx={{ overflowY:'auto' }}>

                    {!isJobTypesLoading && jobTypes && jobTypes.map(jobType => (
                        <JobTypeListCard template={jobType} key={jobType.id} />
                    ))}
                    {isJobTypesLoading ? (
                        <div
                            style={{
                                left: 0,
                                top: 0,
                                zIndex: 1050,
                                backgroundColor: 'rgba(255,255,255,0.7)',
                                bottom: 0,
                                paddingTop: 0,
                                right: 0,
                                position: 'absolute',
                            }}>
                            <LinearProgress />{' '}
                        </div>
                    ) : null}
                </Stack>
            <Stack direction={'row'} spacing={2} sx={{justifyContent: 'flex-end', mt: 2}}>
                <Button variant={'outlined'} onClick={onClose}>
                    {t('Close')}
                </Button>
            </Stack>
        </Stack>
    );
};
