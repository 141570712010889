import {FileDownload} from '@mui/icons-material';
import {Button, LinearProgress, Stack, Typography} from '@mui/material';
import {AxiosResponse} from 'axios';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import MolstarViewer from "../../molstar/MolstarViewer";
import {getMolstarContentType, isSupportedByMolstar} from "../../molstar/MolstarUtils";
import {FastaPreview} from "../../alignmentView/FastaPreview";
import {HtmlView} from "../../htmlView/HtmlView";
import {JsonPreview} from "../../json/JsonPreview";
import {getMetaFilePath, isSupportedFile} from "./filePreviewUtils";
import {downloadFile, getFileExtension} from "../fileupload/fileUtils";
import {loadFile, loadTextFile} from "./loadFile";
import TableDataViewer from "../../csvViewer/TableDataViewer";
import {VisualizationConfig} from "./VisualizationsTypes";

interface FilePreviewComponentProps {
  datasetId: string;
  filePath: string;
  fileList?: [];
}

const getTableViewSettings = (filePath: string, fileSettings: VisualizationConfig | undefined  ) => {

  if (fileSettings) {
    const { visualizations } = fileSettings;
    const tableSettings = visualizations.find( conf => conf.visualization === 'tableviewer' );

    if (tableSettings && tableSettings.configuration) {
      const {configuration} = tableSettings;

      return {
        filePath,
        ...configuration,
      }
    }
  }

  return {
    filePath
  }
}

export const FilePreviewComponent = (props: FilePreviewComponentProps): JSX.Element => {
  const {datasetId, filePath, fileList} = props;
  const [t] = useTranslation();
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [href, setHref] = useState<string | undefined>();
  const [result, setResult] = useState<AxiosResponse>();
  const [fileConfig, setFileConfig] = useState<VisualizationConfig | undefined>();
  const metaFilePath = getMetaFilePath(fileList, filePath);

  const onFileDownload = async () => {
    await downloadFile(datasetId, filePath);
  };

  const getFileContent = async () => {
    if (isSupportedFile(filePath)) {
      setIsDownloading(true);

      const result = await loadFile(filePath, datasetId)
      setResult(result);
      const blob = result.data;
      const ext = getFileExtension(filePath).toLowerCase();
      let hrefTML;
      if (ext === 'svg') {
        hrefTML = window.URL.createObjectURL(blob.slice(0, blob.size, 'image/svg+xml'));
      } else if (result?.headers['content-type']?.indexOf('image') === 0) {
        hrefTML = window.URL.createObjectURL(blob);
      } else if (result?.headers['content-type']?.indexOf('application/pdf') === 0) {
        hrefTML = window.URL.createObjectURL(blob);
      } else {
        hrefTML = await result?.data.text();
      }

      if (metaFilePath) {
        try {
          const fileSettingsRaw = await loadTextFile(metaFilePath, datasetId);
          const rawSettings: VisualizationConfig = JSON.parse(fileSettingsRaw);
          setFileConfig(rawSettings);

        } catch (e) {
          console.log("Metafile loading error => ", e);
        }
      }

      setHref(hrefTML);
      setIsDownloading(false);
    }
  };

  useEffect(() => {
    if (datasetId && filePath) {
      getFileContent();
    }
  }, [datasetId, filePath, metaFilePath]);

  const renderViewer = () => {
    if (!href) {
      return <Stack sx={{width: 600, height: 300, justifyContent: "center", alignItems: "center"}} spacing={2}>
        <Typography variant={"h6"}>{t('No preview available')}</Typography>
        <Button size={"large"} variant={"contained"} startIcon={<FileDownload/>} onClick={() => onFileDownload()}>
          {t('Download file')}
        </Button>
      </Stack>;
    }

    const ext = getFileExtension(filePath).toLowerCase();

    if (result?.headers['content-type'].indexOf('image') === 0) {
      return <img style={{maxWidth: 1200}} src={href}/>;
    }

    if (ext === 'svg') {
      return <img style={{maxWidth: 1200}} src={href}/>;
    }

    if (result?.headers['content-type'].indexOf('application/pdf') === 0) {
      return <iframe sandbox
                     style={{width: '100%', height: '100%', overflow: "auto", position: "fixed"}} src={href}/>;
    }

    if (isSupportedByMolstar(ext)) {
      return <MolstarViewer data={href} contentType={getMolstarContentType(ext)}/>
    }

    if (ext === 'fasta' || ext === 'fa') {
      return <FastaPreview data={href}/>
    }

    if (ext === 'csv') {
      return <TableDataViewer
        data={href}
        fileName={filePath}
        separator={','}
        settings={getTableViewSettings(filePath, fileConfig)}
        datasetId={datasetId}
        fileList={fileList}/>
    }

    if (ext === 'tsv') {
      return <TableDataViewer
        data={href}
        separator={'\t'}
        settings={getTableViewSettings(filePath, fileConfig)}
        datasetId={datasetId}
        fileList={fileList}/>
    }

    if (ext === 'json' || ext === 'geojson') {
      return <JsonPreview data={href}/>
    }

    if (ext === 'html' || ext === 'htm') {
      return <HtmlView data={href}/>
    }

    if (result?.headers['content-type'].indexOf('text') === 0) {
      return <pre
        style={{maxWidth: 1200, fontSize: 12, backgroundColor: '#f0f0f0', padding: 8, overflow: 'scroll'}}>{href}</pre>;
    }

    return <Stack sx={{width: 600, height: 300, justifyContent: "center", alignItems: "center"}} spacing={2}>
      <Typography variant={"h6"}>{t('No preview available')}</Typography>
      <Button size={"large"} variant={"contained"} startIcon={<FileDownload/>} onClick={() => onFileDownload()}>
        {t('Download file')}
      </Button>
    </Stack>;
  };

  return (
    <Stack spacing={2}  sx={{flex: 1, overflow:"hidden", position: "relative"}}>
      <Stack
        sx={{
          position: "absolute",
          left: 0,
          right: 0,
          top: 0,
          bottom:0,
          overflowY: 'scroll',
          overflowX: 'scroll',
          minWidth: 400,
          flex: 1,
        }}
      >
        {isDownloading ? <LinearProgress/> : renderViewer()}
      </Stack>
    </Stack>
  );
};
