import {Button, Checkbox, FormControlLabel, Stack, TextField} from '@mui/material';
import * as React from 'react';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';

import {GridCsvGetRowsToExportParams, gridExpandedSortedRowEntriesSelector} from "@mui/x-data-grid-pro";
import {CSV_ROW_ID_FIELD} from "../../csvViewer/tableUtils";


interface ExportAsFilePopupProps {
  gridRef: any;
  onClose: () => void;
  defaultFileName: string;
}

export const ExportAsFilePopup = (props: ExportAsFilePopupProps): JSX.Element => {
  const {gridRef, onClose, defaultFileName} = props;
  const [t] = useTranslation();
  const [isRowSelected, setIsRowSelected] = useState<boolean>(true);
  const [isColumnsSelected, setIsColumnSelected] = useState<boolean>(true);
  const [fileName, setFileName] = useState(defaultFileName);

  const hasSelectedRows = gridRef.current.getSelectedRows().size > 0;


  const handleRowChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsRowSelected(event.target.checked);
  };

  const handleColumnsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsColumnSelected(event.target.checked);
  };

  const onExport = () => {
    let fn = fileName && fileName.length ? fileName : defaultFileName

    if (fn.lastIndexOf( '.csv' ) !== fn.length-5)  fn = fn+'.csv';

    const getRowsToExport = isRowSelected && hasSelectedRows ? ({apiRef}: GridCsvGetRowsToExportParams) => {
      const selectedRowMap:string[] =  apiRef.current.getSelectedRows();
      const rowIds = [...selectedRowMap].map(([, value]) => value[CSV_ROW_ID_FIELD]);
      return rowIds;
    } : ({apiRef}: GridCsvGetRowsToExportParams) => {
      const rows = gridExpandedSortedRowEntriesSelector(apiRef).map(r => r.model[CSV_ROW_ID_FIELD]);
      return rows;
    }

    gridRef.current.exportDataAsCsv({
        fileName: fn,
        includeHeaders: true,
        allColumns: !isColumnsSelected,
        getRowsToExport
      }
    )
  };

  return (
    <Stack sx={{minWidth: 400}} spacing={2}>
      <Stack direction={'column'} spacing={1}>
        <TextField
          id="filled-basic"
          label="File name"
          variant="outlined"
          value={fileName}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setFileName(event.target.value);
          }}
        />
        <Stack direction={'row'} spacing={2}>
          <FormControlLabel disabled={!hasSelectedRows}  control={<Checkbox checked={isRowSelected && hasSelectedRows}
                                               disabled={!hasSelectedRows} onChange={handleRowChange}/>}
                            label="Selected rows"/>
          <FormControlLabel control={<Checkbox checked={isColumnsSelected} onChange={handleColumnsChange}/>}
                            label="Only visible columns"/>
        </Stack>
      </Stack>

      <Stack direction={'row'} spacing={2} sx={{justifyContent: 'flex-end', mt: 2}}>
        <Button variant={'outlined'}
                onClick={() => {
                  onExport();
                  onClose();
                }}>
          {t('Export')}
        </Button> <Button variant={'outlined'} onClick={onClose}>
        {t('Cancel')}
      </Button>
      </Stack>
    </Stack>
  );
};
