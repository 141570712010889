import {
  Application,
  Apps,
  currentAppIDSelector,
  HelpLink,
  HelpLinkUrl,
  userApplicationsSelector,
} from '@biostrand/components';
import {faBell} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, IconButton, Typography} from '@mui/material';
import {Stack, SxProps, Theme} from '@mui/system';
import React from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {LensAiWhiteLogo} from '../../assets/logos/LensAiWhiteLogo';
import BackgroundImage from './cbimage.png';
import AppHeaderMenu from './menus/AppHeaderMenu';
import UserAvatarMenu from './menus/UserAvatarMenu';
import {BiostrandLogo} from '../../assets/logos/BiostrandLogo';
import {HEADER_COLOR} from "../../theme/biostrandTheme";

const classes = {
  container: {
    backgroundColor: HEADER_COLOR,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },

  menuContainer: {
    display: 'flex',
    minWidth: 300,
    flexDirection: 'row',
    m: 2,
    alignItems: 'center',
    height: (theme: Theme) => theme.spacing(5),
  },
} as const;

interface Props {
  sx?: SxProps<Theme>;
  selectedAppId?: Apps;
  children?: JSX.Element;
  title?: string;
  titleComponent?: JSX.Element;
}

const PageHeader = (props: Props): JSX.Element => {
  const { sx, selectedAppId, children, title, titleComponent } = props;
  const userApps = useSelector(userApplicationsSelector, shallowEqual);
  const appId = useSelector(currentAppIDSelector) || Apps.PORTAL;
  const currentAppId = selectedAppId ? selectedAppId : appId;

  const currentApp: Application | undefined = userApps.find(app => app.id === currentAppId);

  return (
    <Stack sx={{ ...classes.container, ...sx, position: 'relative' }}>
      <Stack sx={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }} direction={'row'}>
        <div style={{ flex: 1 }} />
        <Box
          sx={{
            width: 210,
            mr: 42,
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              width: 210,
              mt: -3,
              background: `url(${BackgroundImage})`,
              backgroundSize: '190px 210px',
              backgroundRepeat: 'no-repeat',
              opacity: 0.8,
            }}
          />
          <LensAiWhiteLogo
            height={38}
            style={{
              zIndex: 1,
              position: 'absolute',
              marginLeft: -20,
              marginTop: 8,
            }}
          />
        </Box>
      </Stack>

      <Stack direction={'row'} sx = {{ml:1}}>
        <Stack spacing={2} direction={'row'} sx={{mt:1}} >
          <AppHeaderMenu userApps={userApps} currentAppId={currentAppId} />
          <BiostrandLogo height={48} />
        </Stack>

        <Stack
          sx={{
            mt: 3,
            mr: 30,
            ml: 2,
            flex: 1,
            color: '#fff',
            opacity: 0.7,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {titleComponent ? titleComponent : currentApp ? (
            <Typography
              title={title}
              variant={'subtitle1'}
              sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
            >
              {title ? title : currentApp.name}
            </Typography>
          ) : undefined}
        </Stack>
        <Box sx={classes.menuContainer}>
          <IconButton sx={{ color: 'primary.contrastText' }} size="medium">
            <FontAwesomeIcon icon={faBell} size="sm" />
          </IconButton>
          <HelpLink url={HelpLinkUrl.Home} sx={{ color: 'primary.contrastText' }} />
          <UserAvatarMenu />
        </Box>
      </Stack>
      <Stack sx={{ zIndex: 1060, flex: 1 }}>{children}</Stack>
    </Stack>
  );
};

export default PageHeader;
