import {DatasetManagerDatasetEntity} from '@biostrand/biostrandapi/javascript/dist/DatasetManagerApi';
import {Stack, Typography} from '@mui/material';
import dayjs from 'dayjs';
import * as React from 'react';
import {DatasetStatusChip} from "./DatasetStatusChip";

export const DatasetDetailsItem = (props: { selectedDataset: DatasetManagerDatasetEntity; showDate?: boolean }) => {
  const { selectedDataset, showDate = true } = props;

  return (
    <Stack direction={'column'} sx={{ flex: 1 }} spacing={1 / 2}>
      <Stack direction={'row'} sx={{ alignItems: 'flex-end' }} spacing={1}>
        <Typography variant={'subtitle2'}>{selectedDataset.name}</Typography>
        <Typography variant={'caption'}>({selectedDataset.version})</Typography>
      </Stack>

      <Stack direction={'row'} sx={{ alignItems: 'baseline' }} spacing={1}>
        {selectedDataset?.status !== 'ACTIVE' && (
          <DatasetStatusChip status={selectedDataset?.status} />
        )}
        {showDate && (
          <>
            <Typography variant={'caption'}>•</Typography>
            <Typography variant={'caption'}>
              {dayjs(new Date(String(selectedDataset.last_updated_on))).fromNow()}
            </Typography>
          </>
        )}
      </Stack>
    </Stack>
  );
};
