import {TableCell} from '@mui/material';
import * as React from 'react';
import {CellMenu} from "./CellMenu";
import {MenuItemDescription} from "./menuTypes";

export type MenuHandler<T> = (row: T, action: string) => void

interface Props<T> {
  items: MenuItemDescription[];
  row: any;
  onAction: MenuHandler<T>;
}

export function MenuCellRenderer<T>(props: Props<T>): JSX.Element {
  const {row, onAction, items} = props;
  const isMenuVisible = items.length > 0;
  const handleAction = (action: string) => {
    onAction(row, action);
  };

  return (
    <TableCell padding='none' style={{verticalAlign: 'top', paddingTop:8}}>
      {isMenuVisible &&
        <CellMenu items={items} onActionSelected={handleAction}/>
      }
    </TableCell>
  );
}
