import {createAction} from '@reduxjs/toolkit';
import {all, put, select, takeEvery} from 'redux-saga/effects';
import {ActionType} from "typesafe-actions";
import {currentAppIDSelector} from "../slices/user/userSelectors";
import {RuntimeConfig} from "../runtimeConfig/RuntimeConfig";
import {Apps} from "../applications/applicationTypes";
import {push} from "connected-react-router";

export const NAVIGATE_TO = 'route/NAVIGATE_TO';

interface NavigateParams {
  link: string,
  params?: string
}

export const navigateTo = createAction<NavigateParams>(NAVIGATE_TO);

export const isLocalLink = (link:string) => {
  return link.toLowerCase().indexOf('http') !== 0;
}

export const isThePortalApp = (link:string) => {
    const portalLink = RuntimeConfig.getAppLinks()[Apps.PORTAL]
    return link.indexOf(portalLink) === 0;
}

function* isTheSameApp(link: string) {
    const currentAppId = yield select(currentAppIDSelector);
    const currentAppLink  = RuntimeConfig.getAppLinks()[currentAppId]
    return link.indexOf(currentAppLink) === 0;
}
// There are 2 different type of navigation is possible -- go to the 'local' page or go to another app

export function* handleNavigateTo(action:ActionType<typeof navigateTo>) {
  const {link} = action.payload;
  const c = yield isTheSameApp(link);

  if (c || isLocalLink(link)){
    yield put(push(link));
  } else {
    window.location.href = decodeURIComponent(link);
  }
}

function* routeUtilSaga() {
  yield all([yield takeEvery(NAVIGATE_TO, handleNavigateTo)]);
}

export default routeUtilSaga;
