import {Switch, TableCell} from '@mui/material';
import * as React from 'react';
import {OrganizationRole} from '../../../../core/organization/organizationTypes';
import {UserManagerUserEntity} from "@biostrand/biostrandapi/javascript/dist/UserManagerApi";

interface Props {
    row: UserManagerUserEntity;
    onChange: (row: UserManagerUserEntity, newRole: OrganizationRole) => void
}

export const UserRoleCell = (props: Props): JSX.Element => {
    const { row, onChange } = props;
    const handleChange = () => {
        onChange( row, row.organization_role === OrganizationRole.ADMIN ? OrganizationRole.REGULAR : OrganizationRole.ADMIN )
    }
    return (
        <TableCell sx={{ pt: 1, pb: 1, pl: 1, textAlign: 'center' }}>
            <Switch checked={row.organization_role === OrganizationRole.ADMIN} onChange={handleChange} />
        </TableCell>
    );
};
