import { Apps } from '@biostrand/components';
import { Box, Typography } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

interface UserProfileSettingsContentProps {
    currentApplicationId?: Apps;
}

const UserProfileSettingsContent = (props: UserProfileSettingsContentProps): JSX.Element => {
    const {currentApplicationId} = props;
    const [t] = useTranslation();
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
            }}>
            <Typography>{t('User Profile')}</Typography>
            <Typography>{currentApplicationId}</Typography>
        </Box>
    );
};

export default UserProfileSettingsContent;
