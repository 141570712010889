import {GridRenderEditCellParams, useGridApiContext} from "@mui/x-data-grid-pro";
import * as React from "react";
import {IconButton, InputBase, InputBaseProps, Paper, Popper, Stack} from "@mui/material";
import {Check, Close} from "@mui/icons-material";

export function EditTextarea(props: GridRenderEditCellParams<any, string>) {
  const {id, field, value, colDef, hasFocus} = props;
  const [valueState, setValueState] = React.useState(value);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>();
  const [inputRef, setInputRef] = React.useState<HTMLInputElement | null>(null);
  const apiRef = useGridApiContext();

  React.useLayoutEffect(() => {
    if (hasFocus && inputRef) {
      inputRef.focus();
    }
  }, [hasFocus, inputRef]);

  const handleRef = React.useCallback((el: HTMLElement | null) => {
    setAnchorEl(el);
  }, []);

  const handleChange = React.useCallback<NonNullable<InputBaseProps['onChange']>>(
    (event) => {
      const newValue = event.target.value;
      setValueState(newValue);
    },
    [apiRef, field, id],
  );

  return (
    <div style={{position: 'relative', alignSelf: 'flex-start', zIndex: 19999}}>
      <div
        ref={handleRef}
        style={{
          height: 1,
          width: colDef.computedWidth,
          display: 'block',
          position: 'absolute',
          top: 0,
        }}
      />
      {anchorEl && (
        <Popper open anchorEl={anchorEl} placement="bottom-start" sx={{zIndex: 19999}}>
          <Paper elevation={1}
                 sx={{p: 2, pb: 0, minWidth: 300, zIndex: 19999}}>
            <InputBase
              multiline
              rows={4}
              value={valueState}
              sx={{textarea: {resize: 'both'}, width: '100%'}}
              onChange={handleChange}
              inputRef={(ref) => setInputRef(ref)}
            />

            <Stack direction={"row"}>
              <div style={{flex: 1}}/>
              <IconButton
                onClick={(event) => {
                  apiRef.current.stopCellEditMode({
                    id, field, cellToFocusAfter: "none", ignoreModifications: true
                  })
                }}>

                <Close/></IconButton>
              <IconButton
                onClick={(event) => {

                  apiRef.current.setEditCellValue(
                    {id, field, value: valueState},
                    event,
                  );
                  apiRef.current.stopCellEditMode({
                    id, field, cellToFocusAfter: "none"
                  })
                }}>

                <Check/></IconButton></Stack>
          </Paper>
        </Popper>
      )}
    </div>
  );
}
