import React from 'react';
import {LEGAL_LINKS} from '../commonLinks/linksContstats';
import {LinkItem} from '../commonLinks/LinkItem';
import {Box, SxProps, Theme} from '@mui/system';
import {BiostrandLogoGray} from "../assets/logos/BiostrandLogoGray";

interface Props {
  sx?: SxProps<Theme>;
}

const PageFooter = (props: Props): JSX.Element => {
  const { sx } = props;

  return (
    <Box sx={{
      backgroundColor: theme => theme.palette.grey['50'],
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      p: 3,
      height: theme => theme.spacing(8),
      ...sx,
    }}>
      <BiostrandLogoGray height={40} style={{margin:-8}}  />
      <Box sx={{flex: 1}}/>
      {LEGAL_LINKS.map(l => (
        <LinkItem description={l} key={l.name} sx={{pl: 8, pt: 0.5}}/>
      ))}
    </Box>
  );
};

export default PageFooter;
