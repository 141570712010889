export enum HelpLinkUrl {
    Intro = 'https://www.biostrand.be/en/retrieve-and-relate-wiki-intro',
    Login = 'https://www.biostrand.be/en/retrieve-and-relate-wiki-login-page',
    QuickFilterView = 'https://www.biostrand.be/en/retrieve-and-relate-wiki-quick-filter-view',
    ListView = 'https://www.biostrand.be/en/retrieve-and-relate-wiki-list-view',
    ExplorerView = 'https://www.biostrand.be/en/retrieve-and-relate-wiki-explorer-view',
    AlignmentView = 'https://www.biostrand.be/en/retrieve-and-relate-wiki-alignment-view',
    Faq = 'https://www.biostrand.be/en/retrieve-and-relate-wiki-faqs',
    Home = 'https://www.biostrand.be/en/retrieve-and-relate-wiki-home-page',
}
