import {SettingsIds} from "@biostrand/components/src/applications/SettingsIds";

export class RouteUrl {
    public static Root = '/';
    public static Login = '/login';
    public static ForgotPassword = '/forgot-password';
    public static PendingChangePasswordPage = '/forgot-password-pending';
    public static RegisterOrganization = '/register-organization';
    public static ConfirmationUnderway = '/check-confirmation';
    public static ConfirmPassword = '/confirm-pwd';

    public static Settings = '/settings';
    public static PersonalSettings = `/settings/${SettingsIds.PERSONAL}`;
    public static OrganizationSettings = `/settings/${SettingsIds.ORGANIZATION}`;
    public static BillingSettings = `/settings/${SettingsIds.BILLING}`;
    public static UsersSettings = `/settings/${SettingsIds.USERS}`;
    public static DataSetsSettings = `/settings/${SettingsIds.DATASETS}`;
    public static DataSetsLocalFileUploadManager = `/settings/${SettingsIds.DATASETS}/localFileUploadManager`;
    public static DatasetDetailsSettings = `/settings/${SettingsIds.DATASETS}/:datasetName`;
    public static JobsSettings = `/settings/${SettingsIds.JOBS}`;
    public static JobsHistorySettings = `/settings/${SettingsIds.JOBS}/history`;
}
