import {ArrowForward} from '@mui/icons-material';
import {LoadingButton} from '@mui/lab';
import {Button, Step, StepLabel, Stepper, Typography} from '@mui/material';
import {Stack} from '@mui/system';
import {Form, Formik} from 'formik';
import {FormikHelpers} from 'formik/dist/types';
import * as React from 'react';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {CreateHttpDatasetValues} from "../datasetTypes";
import {Errors, httpSettings2Config} from "../datasetFormUtils";
import {indexPipeline, qualityCheckPipeline, uploadPipeline} from "../pipelines/PipelinesConstants";
import {createDatasetRequestAction} from "../../slices/datasets/datasetsSlice";
import {PipelineType} from "../pipelines/pipelineTypes";
import {CreateHttpDatasetForm} from "../CreateHttpDatasetForm";
import {SelectPipelinesStep} from "../SelectPipelinesStep";
import {AddDatasetOverview, AddDatasetOverviewLine} from "../AddDatasetOverview";
import {closePopup} from "../../popup/popupsSlice";

interface Props {
  popupKey: string;
}

export const AddDatasetsFromHttpLinksPopupContent = (props: Props): JSX.Element => {
  const {popupKey} = props;
  const [t] = useTranslation();
  const [isBusy] = useState<boolean>(false);
  const [activeStep, setActiveStep] = React.useState(0);

  const dispatch = useDispatch();
  const steps = [t('Settings'), t('Pipelines'), t('Overview')];

  const validate = (datasetSettings: CreateHttpDatasetValues) => {
    const {datasetName, sourceLinks} = datasetSettings;
    const errors: Errors = {};
    if (!datasetName) {
      errors.datasetName = t('Dataset name is required');
    }

    let links = sourceLinks || [];
    links = links.filter(link => !!link);

    if (links.length === 0) {
      errors.sourceLinks = t('At least one link is required');
    }

    if (Object.keys(errors).length > 0) return errors;
    return undefined;
  };

  const initialValues: CreateHttpDatasetValues = {
    datasetName: '',
    sourceLinks: [],
    pipelines: [
      {pipeline: uploadPipeline, selected: true},
      {pipeline: qualityCheckPipeline, selected: false, disabled: true},
      {pipeline: indexPipeline, selected: false, disabled: true},
    ],
    formConfirmed: false,
  };

  const handleSubmit = (values: CreateHttpDatasetValues, actions: FormikHelpers<CreateHttpDatasetValues>) => {
    const actualPipelines = values.pipelines.filter(p => p.selected);
    const uploadPipeline = actualPipelines.find(p => p.pipeline.type === PipelineType.UPLOAD);
    const jobs = uploadPipeline ? ['upload'] : undefined;

    dispatch(
      createDatasetRequestAction({
        datasetRequest: {
          name: values.datasetName,
          config: httpSettings2Config(values),
          jobs
        },
        //   actions: actions,
        callback: error => {
          actions.setSubmitting(false);

          if (error === 'edit') {
            setActiveStep(0);
            return;
          }

          if (!error) {
            onClose();
            return;
          }
        },
      }),
    );
  };

  const handleNextStep = () => {
    setActiveStep(activeStep + 1);
  };

  const handlePreviousStep = () => {
    setActiveStep(activeStep - 1);
  };

  const onClose = () => {
    dispatch(closePopup(popupKey));
  };

  return (
    <Stack sx={{mt: 2}}>
      <Stepper activeStep={activeStep} sx={{mb: 2}}>
        {steps.map((label, index) => {
          return (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Formik<CreateHttpDatasetValues> initialValues={initialValues} validate={validate} onSubmit={handleSubmit}>
        {({isSubmitting, touched, handleSubmit, isValid, errors, values, setFieldValue, validateForm}) => {
          return (
            <Form id="DS" autoComplete="false">
              {activeStep === 0 ? <CreateHttpDatasetForm/> : null}
              {activeStep === 1 ? <SelectPipelinesStep/> : null}
              {activeStep === 2 ? (
                <AddDatasetOverview>
                  <>
                    <AddDatasetOverviewLine title={t('Dataset name')} value={values.datasetName}/>
                    <Stack direction={'row'} sx={{mb: 2}}>
                      <Typography sx={{minWidth: 125}} variant={'body2'}>
                        {t('Linlks')}
                      </Typography>
                      <Stack sx={{maxHeight: 190, overflowY: 'auto', flex: 1}}>
                        {values.sourceLinks?.map(link => (
                          <Typography key={link} variant={'subtitle2'}>
                            {link}
                          </Typography>
                        ))}
                      </Stack>
                    </Stack>
                  </>
                </AddDatasetOverview>
              ) : null}

              <Stack direction={'row'} sx={{mt: 1, mb: 3}}>
                {activeStep === 0 ? (
                  <Button onClick={onClose} variant={'outlined'}>
                    {t('cancel')}{' '}
                  </Button>
                ) : null}
                {activeStep > 0 ? (
                  <Button onClick={handlePreviousStep} variant={'outlined'}>
                    {t('back')}{' '}
                  </Button>
                ) : null}
                <Stack sx={{flex: 1}}/>
                {activeStep !== 2 ? (
                  <Button
                    onClick={async () => {
                      const validatedResult = await validateForm();
                      if (validatedResult.datasetName || validatedResult.sourceLinks) {
                        return;
                      }
                      handleNextStep();
                    }}
                    variant={'contained'}
                    endIcon={<ArrowForward/>}
                    disabled={!touched.datasetName || !isValid}
                  >
                    {t('continue')}{' '}
                  </Button>
                ) : null}
                {activeStep === 2 ? (
                  <LoadingButton
                    sx={{mr: 2}}
                    variant={'contained'}
                    loading={isSubmitting}
                    disabled={!values.formConfirmed || isSubmitting}
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    {t('Save dataset')}
                  </LoadingButton>
                ) : null}
              </Stack>
            </Form>
          );
        }}
      </Formik>
    </Stack>
  );
};
