import * as React from 'react';
import {Redirect, Route, RouteProps} from 'react-router-dom';
import {useSelector} from "react-redux";
import {selectAuthStatus} from "../slices/auth/authSelectors";
import {AuthenticationStatus} from "../slices/auth/authTypes";
import {DNALoading} from "../loading/DNALoading";
import {RouteUrl} from "@biostrand/portal/src/app/routeUrl";

export type AuthRouteProps = { children: JSX.Element } & RouteProps
/**
 * Most of the public pages in portal designed for unauthorized user. If user is logged in he should redirect to portal page
 */
const AuthenticatedRedirect = (props: AuthRouteProps): JSX.Element => {
  const {children, path = '/', ...rest} = props
  const auth = useSelector(selectAuthStatus);

  if (auth === AuthenticationStatus.AUTHENTICATED) {

    return <Redirect exact path={path as string} to={RouteUrl.Root} />

  }

  if (auth === AuthenticationStatus.PENDING || auth === AuthenticationStatus.INITIALIZING || !auth) {
    return <DNALoading message={' '} />

  }

  return <Route {...rest} render={() => children}/>

}
export default AuthenticatedRedirect;
