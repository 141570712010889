import {FileDownload} from '@mui/icons-material';
import {Button, Stack, Typography} from '@mui/material';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {datasetByIdSelector} from '../../slices/datasets/datasetsSelectors';
import {downloadFile} from "../fileupload/fileUtils";
import {FilePreviewComponent} from "./FilePreviewComponent";

interface FilePreviewPopupProps {
  datasetId: string;
  filePath: string;
  popupKey: string;
  fileList: [];
}

export const FilePreviewPopup = (props: FilePreviewPopupProps): JSX.Element => {
  const {datasetId, filePath, fileList} = props;
  const [t] = useTranslation();

  const dataset = useSelector(datasetByIdSelector(datasetId));

  const onFileDownload = async () => {
    await downloadFile(datasetId, filePath);
  };

  return (
    <Stack spacing={2} sx={{flex: 1}}>
      <Stack direction={'row'} sx={{alignItems: 'flex-end'}}>
        <Typography variant={'subtitle1'}>{dataset?.name}</Typography>
        <Typography variant={'h6'}>{filePath}</Typography>
        <Stack direction={'row'} sx={{ml: 4}}>
            <Button startIcon={<FileDownload/>} variant={"contained"} onClick={() => onFileDownload()}>
              {t('Download')}
            </Button>
        </Stack>
      </Stack>

      <FilePreviewComponent datasetId={datasetId} filePath={filePath} fileList={fileList}/>
    </Stack>
  );
};
