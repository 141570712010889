import React from "react";
import {SvgIcon, SvgIconProps} from "@mui/material";

export const RetrieveAndRelateIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="48" height="48" viewBox="0 0 48 48" {...props}>
      <path
        d="M45.7465 43.5868L40.6544 38.4958C41.7736 36.8488 42.2382 34.8436 41.9576 32.8722C41.6769 30.9008 40.6711 29.1049 39.1369 27.8357C37.6026 26.5664 35.6502 25.9151 33.6612 26.0089C31.6722 26.1027 29.7897 26.935 28.3818 28.3431C26.9738 29.7511 26.1415 31.6337 26.0477 33.6227C25.9539 35.6118 26.6052 37.5643 27.8744 39.0987C29.1436 40.633 30.9394 41.6388 32.9107 41.9195C34.882 42.2001 36.8871 41.7355 38.534 40.6163L43.6261 45.7074C43.8136 45.8947 44.0678 46 44.3329 46C44.5979 46 44.8522 45.8947 45.0397 45.7074L45.7465 44.9999C45.9337 44.8124 46.0388 44.5583 46.0388 44.2933C46.0388 44.0284 45.9337 43.7743 45.7465 43.5868ZM34.04 39.0001C33.0512 39.0001 32.0846 38.7069 31.2624 38.1575C30.4402 37.6081 29.7994 36.8273 29.421 35.9137C29.0426 35.0001 28.9436 33.9948 29.1365 33.0249C29.3294 32.0551 29.8056 31.1642 30.5048 30.4649C31.204 29.7657 32.0948 29.2895 33.0647 29.0966C34.0345 28.9037 35.0397 29.0027 35.9533 29.3811C36.8668 29.7595 37.6476 30.4004 38.197 31.2226C38.7464 32.0448 39.0396 33.0115 39.0396 34.0003C39.0381 35.3259 38.5109 36.5968 37.5736 37.5341C36.6363 38.4714 35.3655 38.9986 34.04 39.0001Z"/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M11.4363 25.2415C9.90419 25.9963 8.50371 26.993 7.2887 28.1934L4.6731 30.7903L2.03882 28.156L4.65442 25.5591C6.63597 23.5727 9.02057 22.0345 11.6473 21.0482C14.274 20.062 17.0817 19.6507 19.8809 19.8421C19.6895 17.0429 20.1008 14.2352 21.0871 11.6085C22.0733 8.98175 23.6115 6.59715 25.5979 4.6156L28.1948 2L30.8291 4.65297L29.3532 6.12891L36.434 13.2658L33.7997 15.9L26.831 8.93134C24.3573 12.1915 23.2394 16.2815 23.7109 20.3466C25.3926 20.5472 27.0955 20.4778 28.7553 20.1411L24.4582 15.8627L27.0552 13.1723L32.66 18.7772C34.1921 18.0224 35.5926 17.0257 36.8076 15.8253L39.4045 13.2097L42.0388 15.8627L39.4419 18.4596C37.4604 20.446 35.0758 21.9842 32.449 22.9704C32.1449 23.0846 31.8384 23.1911 31.5297 23.2898C31.1655 23.3751 30.8065 23.4788 30.4541 23.6003C28.4232 24.1243 26.3169 24.3203 24.2154 24.1766C24.3591 26.2783 24.163 28.3849 23.6389 30.416C23.5176 30.768 23.4141 31.1265 23.3289 31.4902C23.2301 31.7991 23.1235 32.1059 23.0093 32.4102C22.023 35.0369 20.4848 37.4215 18.4984 39.4031L15.9015 42L13.2672 39.3657L14.7432 37.8898L7.64368 30.809L10.2966 28.156L17.2653 35.0873C19.739 31.8272 20.8569 27.7372 20.3854 23.6721C18.7037 23.4709 17.0007 23.5403 15.341 23.8776L19.6754 28.2121L17.0412 30.8463L11.4363 25.2415Z"/>
    </SvgIcon>
  )
}
