import { CommonRuntimeVariables, RuntimeConfig } from '@biostrand/components';

export enum PortalRuntimeFeatures {
    Test = 'test_feature',
    DISABLE_REGISTRATION = 'disableRegistration',
    JOBS = 'jobs',
}

export enum PortalRuntimeVariables {
    PortalSpecificTestVariable = 'portalSpecificTestVariable',
}

export const isFeatureSupported = (feature: PortalRuntimeFeatures): boolean =>
    RuntimeConfig.isFeatureSupported(feature);

export const getRuntimeVariable = (varName: CommonRuntimeVariables | PortalRuntimeVariables): string | string[] =>
    RuntimeConfig.getRuntimeVariable(varName);
