
import { Box, Typography } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

const BillingSettingsContent = (): JSX.Element => {

    const [t] = useTranslation();
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
            }}>
            <Typography>{t('Billing settings')}</Typography>
        </Box>
    );
};

export default BillingSettingsContent;
