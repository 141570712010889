import * as React from 'react';
import SettingsPageTemplate from '../SettingsPageTemplate';
import UserProfileSettingsContent from './UserProfileSettingsContent';
import {SettingsIds} from "@biostrand/components/src/applications/SettingsIds";

const UserProfileSettingsPage = (): JSX.Element => {
    return (
        <SettingsPageTemplate selectedItem={SettingsIds.PERSONAL}>
            <UserProfileSettingsContent />
        </SettingsPageTemplate>
    );
};

export default UserProfileSettingsPage;
