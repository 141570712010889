
export enum RequestStatus {
    IN_PROGRESS = 'IN_PROGRESS',
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED',
}

export interface RequestError {
    code?: string | number
    details?: string | number
}

export interface RequestDetails {
    status: RequestStatus
    errorDetails?: RequestError,
}