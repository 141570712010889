import {DatasetManagerDatasetStatusCode} from "@biostrand/biostrandapi/javascript/dist/DatasetManagerApi";

export const getDatasetStatusColor = (status: DatasetManagerDatasetStatusCode | undefined): string|undefined => {
    switch (status) {
        case DatasetManagerDatasetStatusCode.COMPLETE:
        case DatasetManagerDatasetStatusCode.ACTIVE:
            return 'success';
        case DatasetManagerDatasetStatusCode.INCOMPLETE:
            return 'error';
        case DatasetManagerDatasetStatusCode.PROCESSING:
        case DatasetManagerDatasetStatusCode.REGISTERED:
            return 'info';
        case DatasetManagerDatasetStatusCode.UNSPECIFIED:
        case DatasetManagerDatasetStatusCode.DELETED:
            return undefined;
    }
    return undefined;
};
