import {DatasetManagerDatasetEntity} from "@biostrand/biostrandapi/javascript/dist/DatasetManagerApi";

export const VERSION_DELIMITER = "@@";

// export const getDatasetId = (dataset: DatasetManagerDatasetEntity):string => {
//     return `${dataset.name}${VERSION_DELIMITER}${dataset.version}`;
// }
//
export const getDatasetNameFromId = (datasetId: string):string => {
    return datasetId.split(VERSION_DELIMITER)[0];
}

export const getDatasetVersionFromId = (datasetId: string):string => {
    return datasetId.split(VERSION_DELIMITER)[1];
}


export const isTheSameDataset = (datasetNameWithVersion: string, dataset: DatasetManagerDatasetEntity ):boolean => {
    const name = getDatasetNameFromId(datasetNameWithVersion);
    const version = getDatasetVersionFromId(datasetNameWithVersion);

    return name === dataset.name && version === dataset.version;
}
