import {Dayjs} from "dayjs";

export interface AppWithDataFiltersState {
    dataFilters: DataFiltersMap,
}

export enum FieldFilterType {
    ENUM = 'enum',
    STRING = 'string',
    DATE = 'date',
    NUMBER = 'number',
}

export enum FilterType {
    FIELD = 'field',
    TEST_FUNCTION = 'testFunction',
}

export interface Filter {
    filterItemKey: string,
}

export interface ADataFilter<T> extends Filter {
    field: string,
    value: T,
    fieldType: FieldFilterType,
    type: FilterType;
}

export interface DataFilterPayload {
    key: string,
    filters: DataFilter[]
}

export interface DataFilterPayload {
    filterKey: string,
    filterItem: DataFilter
}

export interface EnumFilterValue {
    eq?: string | number;
    in?: string[] | number[];
    nin?: string[] | number[];
}

export interface StringFilterValue {
    eq?: string;
    like?: string;
    startsWith?: string;
    endsWith?: string;
    contains?: string;
    regex?: string;
}

export interface DateFilterValue {
    after?: number;
    before?: number;
    between?: [number | Dayjs, number | Dayjs];
}

export interface NumberFilterValue {
    eq?: number;
    gt?: number;
    lt?: number;
    ge?: number;
    le?: number;
    between?: [number, number];
}

export type SupportedValues = EnumFilterValue | StringFilterValue | NumberFilterValue | DateFilterValue;

export type FieldFilter = ADataFilter<SupportedValues>;

export interface FunctionFilter extends Filter{
    testFunction: FieldFilterType;
    type: FilterType;
    settings: any;
}

export type DataFilter = FieldFilter | FunctionFilter;

export interface FilterDimension {
    type: FieldFilterType,
}

export interface EnumDimension extends FilterDimension {
    items: string[] | number [],
}

export interface StringDimension extends FilterDimension {
}

export interface NumberDimension extends FilterDimension {
    rangeMin?: number,
    rangeMax?: number,
}

export type SupportedDimensions = NumberDimension | EnumDimension | StringDimension;

export interface DataFiltersMap {
    [key: string]: DataFilter[];
}
