import {showPopup} from '@biostrand/components/src/popup/popupsSlice';
import {Stack} from '@mui/material';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {DatasetDetailsPopup} from "../datasets/DatasetDetailsPopup";
import {isJobRunsLoadingSelector, jobRunsSelector} from "@biostrand/components/src/jobs/jobsSelectors";
import {DataGrid} from "@mui/x-data-grid";
import {getJobColumns, JobsHistoryItem} from "@biostrand/components/src/jobs/jobsGrid/jobsGridColumnUtils";
import {refreshJobRunsAction} from "@biostrand/components/src/jobs/jobsSlice";
import JobRunInfoPopup from "@biostrand/components/src/jobs/JobRunInfoPopup";
import {MultipleValueFilterSelector} from "@biostrand/components/src/datasets/filters/MultipleValueFilterSelector";
import {DateFilterInput} from "@biostrand/components/src/datasets/filters/DateFilterInput";
import {useDataFilter} from "@biostrand/components/src/datasets/filters/useDataFilter";
import {ProtoJobStatus} from "@biostrand/biostrandapi/javascript/dist/JobManagerApi";
import {DataFilter, FieldFilterType, FilterType} from "@biostrand/components/src/filters/dataFiltersTypes";
import dayjs from "dayjs";
import {jobTypesSelector} from "./jobsSelectors";


const JOBS_RUN_STATUSES = [
    ProtoJobStatus.SUCCEEDED,
    ProtoJobStatus.RUNNING,
    ProtoJobStatus.SUBMITTED,
    ProtoJobStatus.FAILED,
    ProtoJobStatus.ABORTED,
    ProtoJobStatus.ABORTING,
    ProtoJobStatus.UNSPECIFIED
]

const today = dayjs();

const initialFilterValues: DataFilter[] = [{
    filterItemKey: 'last_updated_on',
    type: FilterType.FIELD,
    value: {between: [today.subtract(7, 'day'), today.endOf('day')]},
    field: 'last_updated_on',
    fieldType: FieldFilterType.DATE
}]

function uniq(arr):[] {
    return Array.from(new Set(arr))
}

const JobsHistory = (): JSX.Element => {
    const jobHistory = useSelector(jobRunsSelector);
    const jobTypes = useSelector(jobTypesSelector);
    const isJobRunsLoading = useSelector(isJobRunsLoadingSelector);
    const name = 'settingsJobsHistoryFilterKey';

    const [currentJobsHistory] = useDataFilter<JobsHistoryItem>(name, jobHistory || [], initialFilterValues);
    const [jobTypeNames, setJobTypeNames] = useState([]);
    const dispatch = useDispatch();

    useEffect( () => {
        if (jobTypes) {
            const jobNames = uniq(jobTypes.map( jt => jt.name ));
            setJobTypeNames( jobNames );
        }
    }, [jobTypes])

    useEffect(() => {
        dispatch(refreshJobRunsAction());
    }, [])

    const onOpenDataset = (datasetName) => {
        const key = 'ds-info-popup-key';

        dispatch(
            showPopup({
                key,
                title: 'Dataset details',
                content: React.createElement(DatasetDetailsPopup, {
                    popupKey: key,
                    datasetName,
                }),
            }),
        );
    }

    const onInfoClick = (jobRunId: string) => {
        const key = 'job-run-info-key';
        dispatch(
            showPopup({
                key,
                title: 'Job run details',
                content: React.createElement(JobRunInfoPopup, {
                    popupKey: key,
                    jobRunId,
                }),
            }),
        );
    };

    const JOBS_COLUMNS = getJobColumns(onInfoClick, onOpenDataset);

    return (
        <Stack direction={'column'} sx={{flexWrap: 'wrap', flex: 1, pt: 1, pb: 1, position: 'relative'}}>
            <Stack direction={"row"} sx={{mb: 0.5, alignItems: "center"}}>
                {/*<StringValueFilterInput filterKey={name} field={'name'} filterItemKey={"name"} sx={{mr:1}} />*/}
                <MultipleValueFilterSelector values={jobTypeNames} filterKey={name} field={'job_type_name'}
                                             filterItemKey={"job_type_name"} fieldLabel={"Job Type"}/>
                <MultipleValueFilterSelector values={JOBS_RUN_STATUSES} filterKey={name} field={'status'}
                                             filterItemKey={"status"} fieldLabel={"Status"}/>
                <DateFilterInput
                    fieldLabel={"Created"}
                    filterKey={name}
                    field={'created_on'}
                    filterItemKey={"created_on"}/>
                <DateFilterInput
                    filterKey={name}
                    fieldLabel={"Last Updated"}
                    field={'last_updated_on'}
                    filterItemKey={"last_updated_on"}/>

            </Stack>
            {currentJobsHistory &&
                <DataGrid
                    sx={{
                        'mr': 3,
                        '.MuiDataGrid-root': {border: '#f00 5px'},
                        'position': 'absolute',
                        'border': '5px',
                        'top': 36,
                        'bottom': 8,
                        'left': 0,
                        'right': 0,
                        'flex': 1,
                    }}
                    sortingMode='server'
                    rows={currentJobsHistory}
                    loading={isJobRunsLoading}
                    columns={JOBS_COLUMNS}
                    disableColumnFilter
                    disableSelectionOnClick
                    keepNonExistentRowsSelected
                    density={'standard'}
                    getRowHeight={() => 'auto'}
                />
            }
        </Stack>
    );
};

export default JobsHistory;
