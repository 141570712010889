import {createTheme} from '@mui/material/styles';

export const HEADER_COLOR = "#0c2340";

export const biostrandTheme = createTheme({
    palette: {
        primary: {
            //main: '#0c2340',//'#2458A4',
            main: '#0E4581',//'#2458A4',
            dark: '#0d3d73',
            light: '#4080a4',

        },
        secondary: {
            main: '#5da589',
            dark: '#23be8d',
            light: '#cef3e4'
        },
    },
});
