import { CommonQueryParameter } from '@biostrand/components';
import { Button, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { RouteUrl } from '../../../routeUrl';
import PublicPage from '../common/publicPage/PublicPage';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: theme.spacing(12),
    },
    buttons: {
        display: 'flex',
        marginTop: theme.spacing(4),
        flexDirection: 'row',
    },
    title: {
        marginBottom: theme.spacing(4),
    },
    subTitle: {
        marginBottom: theme.spacing(2),
    },
}));

enum ViewMode {
    ForgotPassword,
    IsOnboarding,
}

const PendingRegisterOrganizationPage = () => {
    const classes = useStyles();
    const [t] = useTranslation();

    const location = useLocation();

    const queryParameters = new URLSearchParams(location.search);
    const email = queryParameters.get(CommonQueryParameter.Email) || '';
    const isOnboarding = queryParameters.get(CommonQueryParameter.IsOnboarding) || '';
    const viewMode = isOnboarding ? ViewMode.IsOnboarding : ViewMode.ForgotPassword;

    return (
        <PublicPage>
            <div className={classes.container}>
                {viewMode === ViewMode.ForgotPassword ? (
                    <>
                        <Typography variant='h4' className={classes.title}>
                            {t('Please check your inbox')}
                        </Typography>
                        <Typography variant='body1' className={classes.subTitle}>
                            {t('Email has been sent to ')}
                            <span style={{ fontWeight: 'bold' }}>{email}</span>
                        </Typography>
                    </>
                ) : (
                    <>
                        <Typography variant='h4' className={classes.title}>
                            {t('Welcome to BioStrand!')}
                        </Typography>
                        <Typography variant='body1' className={classes.subTitle}>
                            {t('Please, check your inbox')}
                        </Typography>
                        <Typography variant='body1' className={classes.subTitle}>
                            {t('An activation email was sent to ')}
                            <span style={{ fontWeight: 'bold' }}>{email}</span>
                        </Typography>
                    </>
                )}
                <div className={classes.buttons}>
                    <Button variant='outlined' component={RouterLink} color='primary' size='large' to={RouteUrl.Login}>
                        {t('Back to login')}
                    </Button>
                </div>
            </div>
        </PublicPage>
    );
};

export default PendingRegisterOrganizationPage;
