export const isSupportedByMolstar = (ext: string) => {
    if (ext) {
        switch (ext.toLowerCase()) {
            case 'pdb':
            case 'gro':
            case 'mol':
            case 'mol2':
            case 'ent':
            case 'pdbqt':
            case 'sdf':
            case 'sd':
            case 'xyz':
            case 'ccp4':
            case 'mrc':
            case 'map':
            case 'cub':
            case 'cube':
            case 'dsn6':
            case 'brix':
            case 'dx':
            case 'dxbin':
            case 'cif':
            case 'mmcif':
            case 'cimciff':
            case 'bcif':
                return true;
        }
    }
    return false;
}

export const getMolstarContentType = (ext: string) => {
    if (ext) {
        switch (ext.toLowerCase()) {
            case 'cif':
            case 'mmcif':
            case 'cimciff':
            case 'bcif':
                return 'mmcif';
            case 'ent':
                return 'pdb';
            case 'sd':
                return 'sdf';
        }
    }

    return ext.toLowerCase();
}