import {Application, Apps} from "./applicationTypes";
import {RuntimeConfig} from "../runtimeConfig/RuntimeConfig";

const portalLink = RuntimeConfig.getAppLinks()[Apps.PORTAL];


export const APP_LIST: Application[] = [
    { id: Apps.PORTAL, name: 'Portal', url:'/', permanent: true },
    { id: Apps.PROJECTS, name: 'Projects', permanent: true },
    { id: Apps.RETRIEVE_AND_RELATE, name: 'Retrieve and Relate' },
    { id: Apps.ARTICLE_LENS, name: 'Article Lens'},
    { id: Apps.MAPPER, name: 'Mapper', url: 'mapper' },
    { id: Apps.GDA, name: "Genomic Data Annotation", url: 'gda' },
    { id: Apps.REVIEW_APP, name: 'Review App', url: 'http://iris.beta.biostrand.cloud:52773/csp/biostrand/ReviewsApp.csp' },
    { id: Apps.BULK_SEARCH, name: 'Bulk Search', url: 'bulk-search'  },
    { id: Apps.ALIGNER, name: 'Aligner', url: 'aligner'  },
    { id: Apps.BILLING, name: 'Billing', url: 'billing'  },
    { id: Apps.JOBS, name: 'Jobs', url: `${portalLink}/settings/jobs`, permanent:true },
    { id: Apps.SETTINGS, name: 'Settings', url: `${portalLink}/settings`, permanent:true },
    { id: Apps.ADMIN_PORTAL, name: 'Admin-portal'},
];
