import { Apps, PageFooter } from '@biostrand/components';
import { Box } from '@mui/material';
import * as React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { currentAppIDSelector, userApplicationsSelector } from '../../slices/user/userSelectors';
import PageHeader from '../header/PageHeader';
import NoAccessPageContent from './NoAccessPageContent';

const NoAccessPage = (): JSX.Element => {
  const currentAppId = useSelector(currentAppIDSelector) || Apps.PORTAL;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <PageHeader  selectedAppId={currentAppId} />
      <NoAccessPageContent />
      <PageFooter />
    </Box>
  );
};

export default NoAccessPage;
