import React, {useEffect, useMemo, useState} from "react";
import {
  DataGridPro,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton
} from "@mui/x-data-grid-pro";
import {createGridColumn, CSV_ROW_ID_FIELD, getRowId, getTableSettings, makeRows} from "./tableUtils";
import {ColumnSettingsGridToolbar} from "../datasets/pickers/ColumnSettingsGridToolbar";
import {IconButton, Stack} from "@mui/material";
import {ArrowBack} from "@mui/icons-material";
import {closePopup, showPopup} from "../popup/popupsSlice";
import {FilePreviewPopup} from "../datasets/fileViewer/FilePreviewPopup";
import {useDispatch} from "react-redux";
import {SelectedRowGridToolbar} from "../datasets/pickers/SelectedRowGridToolbar";
import {ToolbarGridAlignSequence} from "../datasets/pickers/ToolbarGridAlignSequence";

interface TableDataViewerProps {
  data: string,
  separator: string,
  settings: any,
  datasetId: string,
  fileList: []
}


const CustomToolbar = (props) => {
  const {settings, onApplySettings, fileSettings, datasetId} = props;

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton/>
      <GridToolbarFilterButton/>
      <div style={{width: 40}}></div>
      <ColumnSettingsGridToolbar settings={settings} onApplySettings={onApplySettings}/>
      <SelectedRowGridToolbar exportSettings={fileSettings} datasetId={datasetId}/>
      <ToolbarGridAlignSequence/>

    </GridToolbarContainer>
  );
}

const mapColumnsFromMeta = (metaColumns) => {
  const mapped = {};
  if (metaColumns && metaColumns.length) {
    metaColumns.forEach((cs) => {
      mapped[cs.name] = cs;
    })
  }
  return mapped;
}


const createFileExist = (fileList) => (filePath) => {
  return fileList && filePath && fileList.find(fo => {
    return (fo.name === filePath || fo.name === '/' + filePath)
  });
}

export const TableDataViewer = (props: TableDataViewerProps) => {
  const {data, separator, settings, fileList, datasetId} = props
  const [columnNames, setColumnNames] = useState<string[]>([]);
  const [rows, setRows] = useState([]);
  const metaColumnSettings = mapColumnsFromMeta(settings?.columns);

  const [columnSettings, setColumnSettings] = useState(metaColumnSettings);
  const initialSettings = getTableSettings(settings);

  const dispatch = useDispatch()

  useEffect(() => {
    const parsed = makeRows(data, separator);
    setRows(parsed.rows);
    setColumnNames(parsed.columns);
  }, [data, separator]);

  const onFilePreview = (fileName: string) => {
    dispatch(
      showPopup({
        fullscreen: true,
        key: 'file-preview-popup-key',
        title: (
          <Stack direction={'row'} sx={{mt: 2, alignItems: 'center'}} spacing={4}>
            <IconButton
              onClick={() => {
                dispatch(closePopup('file-preview-popup-key'));
              }}
            >
              <ArrowBack/>
            </IconButton>
            {'Preview'}
          </Stack>
        ),
        content: React.createElement(FilePreviewPopup, {
          popupKey: 'file-preview-popup-key',
          filePath: fileName,
          fileList,
          datasetId
        }),
      }),
    );
  };

  const gridColumns = useMemo(() => {
      if (columnNames) {
        const newColumns = columnNames.map(name => createGridColumn(name, columnSettings, {
          'filePreview': {
            action: onFilePreview,
            isActionPossible: createFileExist(fileList)
          }
        }));

        newColumns.unshift({
          ...GRID_CHECKBOX_SELECTION_COL_DEF,
          hideable: false,
          headerName: 'Checkbox Selection'
        })
        newColumns.unshift({
          field: CSV_ROW_ID_FIELD,
          hideable: false,
          headerName: '#',
          type: 'number',
          disableExport: true,
          width: 20,
          sortable: false,
          disableColumnMenu: true,
          valueGetter: (value) => {
            if (!value) {
              return value;
            }
            // Convert the decimal value to a percentage
            return Number(value.split('#')[1])
          },
          valueFormatter: (value) => {
            return `#${value}`
          }
        })

        return newColumns;
      }
      return [];
    }, [columnNames, data, columnSettings, fileList, datasetId]
  )

  const onApplySettings = (settings) => {
    setColumnSettings(settings);
  }

  return rows && <DataGridPro
    rowHeight={28}
    checkboxSelection
    keepNonExistentRowsSelected
    disableRowSelectionOnClick
    columns={gridColumns}
    getRowId={getRowId}
    rows={rows}
    initialState={initialSettings}
    slots={{toolbar: CustomToolbar}}
    slotProps={{
      toolbar: {
        settings: columnSettings,
        onApplySettings: onApplySettings,
        fileSettings: settings,
        datasetId: datasetId,
      }
    }}

  />
}

export default TableDataViewer;
