import {closePopup} from '@biostrand/components/src/popup/popupsSlice';
import {Button} from '@mui/material';
import {Stack} from '@mui/system';
import * as React from 'react';
import {useDispatch} from 'react-redux';
import {useWindowDimensions} from "@biostrand/components/src/utilites/useWindowDimensions";
import DatasetDetails from "./DatasetDetails";

interface DatasetDetailsPopupProps {
    datasetName: string;
    datasetVersion?: string;
    popupKey: string;
}

export const DatasetDetailsPopup = (props: DatasetDetailsPopupProps): JSX.Element => {
    const {datasetName, popupKey, datasetVersion} = props;
    const dispatch = useDispatch();
    const {width = 800, height = 600} = useWindowDimensions()


    const onClose = () => {
        dispatch(closePopup(popupKey));
    };

    return (
        <Stack sx={{flex: 1, overflow: 'hidden', minWidth: width - 200, minHeight: height - 120}} direction={'column'}>
            <DatasetDetails datasetName={datasetName} datasetVersion={datasetVersion}/>
            <Stack sx={{pt: 2}} direction='row' justifyContent='flex-end' spacing={2}>
                <Button
                    onClick={() => {
                        onClose();
                    }}
                    variant='outlined'
                    color='primary'>
                    Close
                </Button>
            </Stack>
        </Stack>
    );
};
