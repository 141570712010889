import {ApplicationIcon, userApplicationsExceptCurrentSelector, Application, PortalLink} from '@biostrand/components';
import { Box, IconButton, Paper, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const classes = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: -6,
        marginBottom: 1,
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    appIcon: {
        color: 'primary.main',
        borderRadius: theme => theme.spacing(2),
        width: theme => theme.spacing(12),
        height: theme => theme.spacing(12),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    menuItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: 1.5,
        paddingRight: 1.5,
    },
    label: {
        marginTop: 2,
        maxWidth: theme => theme.spacing(12),
        textAlign: 'center',
    },
} as const;

interface BigIconProps {
    app: Application
}

const BigIcon = (props:BigIconProps) => {
    const {app} = props;
    const [t] = useTranslation();
    return <Box sx={classes.menuItem} key={app.id} title={app.name}>
        <Paper sx={classes.appIcon}>
            <IconButton
                color='primary'
                sx={{
                    width: theme => theme.spacing(12),
                    height: theme => theme.spacing(12),
                    borderRadius: theme => theme.spacing(1),
                }}>
                <ApplicationIcon appId={app.id} sx={{ fontSize: 48 }} />
            </IconButton>
        </Paper>
        <Typography variant='subtitle2' sx={classes.label}>
            {t(app.name || app.id)}
        </Typography>
    </Box>
}

const PortalPageAppLinks = (): JSX.Element => {
    const apps = useSelector(userApplicationsExceptCurrentSelector, (apps1, apps2) => apps1.length === apps2.length);
    return (
        <Box sx={classes.container}>
            {apps.map(app => {
                return (<PortalLink url={app.url} key={app.id} sx={{ zIndex: 1100 }}><BigIcon app={app}/></PortalLink>)
            })}
        </Box>
    );
};

export default PortalPageAppLinks;
