import {
  persistParsedToLocalStorage,
  retrieveParsedFromLocalStorage,
} from '@biostrand/articlelens/src/app/utils/localStorageHelpers';
import { FinalizeLocalUploadRequest } from '@biostrand/biostrandapi/javascript/dist/BFFPortalApi';
import { all, call, cancel, cancelled, put, select, take, takeEvery } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { uApi } from '../../biostrandApi/uApi';
import { userApplicationContextKeySelector } from '../user/userSelectors';
import { createFileUploadChannel, FumChannelEvent } from './createFileUploadChannel';
import { refreshDatasetsAction } from './datasetsSlice';
import {
  initFileUploadManager,
  INIT_FILE_UPLOAD_MANAGER,
  KICK_UPLOAD_PROCESS,
  partUploaded,
  partUploadError,
  partUploadProgress,
  PART_ERROR,
  PART_IS_READY,
  removeFile,
  setCurrentFiles,
  setFileManagerBusy,
  setFileManagerPending,
  setFileStatus,
  uploadManagerSaveState,
  UPLOAD_CANCELLED,
  UPLOAD_MANAGER_SAVE_STATE,
  UPLOAD_PROGRESS,
} from './fileUploadManagerSlice';
import { fumCurrentFilesSelector, fumFileSelector, fumIsFileReady, fumStatusSelector } from './fileUploadSelectors';
import {
  FilePart,
  FilePartStatus,
  FileUploadInfo,
  FileUploadManagerStatus,
  FileUploadStatus,
  UploadCompletePayload,
} from './fileUploadTypes';

interface KeyValueMap {
  [key: string]: string;
}

export function getFileManagerItems(appOrgUserKey: string): FileUploadInfo[] {
  return retrieveParsedFromLocalStorage<FileUploadInfo[]>(`${appOrgUserKey}.fileUploadManager`) || [];
}

export function updateSearchSettings(appOrgUserKey: string, status: FileUploadInfo[]): void {
  return persistParsedToLocalStorage<FileUploadInfo[]>(`${appOrgUserKey}.fileUploadManager`, status);
}

function* filePartUploadWorker(fileId: string, filePart: FilePart) {
  const fileStatus: FileUploadInfo = yield select(fumFileSelector, fileId);
  const channel = yield call(createFileUploadChannel, fileStatus, filePart);
  try {
    while (true) {
      const event = yield take(channel);

      if (event.type === PART_IS_READY || event.type === PART_ERROR) {
        return event;
      }

      if (event.type === UPLOAD_PROGRESS) {
        yield put(partUploadProgress(event.payload));
      }
      const fs: FileUploadInfo = yield select(fumFileSelector, fileId);
      if (fs.isAborted) {
        yield cancel();
      }
    }
  } finally {
    if (yield cancelled()) {
      channel.close();
      return { type: UPLOAD_CANCELLED };
    }
  }
}

const isAllPartsValid = (parts: FilePart[]): boolean => {
  return parts.every(part => part.status !== FilePartStatus.ERROR);
};

function* getNextFile() {
  const files: FileUploadInfo[] = yield select(fumCurrentFilesSelector);
  const fileReadyToUpload = files.find(
    f =>
      !!f.file &&
      isAllPartsValid(f.parts) &&
      f.status !== FileUploadStatus.FINALIZED &&
      f.status !== FileUploadStatus.FINALIZATION_ERROR,
  );
  return fileReadyToUpload;
}

function* getNextFilePart(fileId: string) {
  const fileStatus: FileUploadInfo = yield select(fumFileSelector, fileId);
  return fileStatus.parts.find(part => part.status === FilePartStatus.DRAFT);
}

function* finalizeFileUpload(fileId: string) {
  const fileStatus: FileUploadInfo = yield select(fumFileSelector, fileId);
  const validationsParts: KeyValueMap = fileStatus.parts.reduce((etagMap: KeyValueMap, currentValue) => {
    etagMap[currentValue.index] = currentValue.ETag as string;
    return etagMap;
  }, {});

  const request: FinalizeLocalUploadRequest = {
    dataset: { id: fileStatus.context.dataset?.id },
    parts: [
      {
        key: fileStatus.fileKey,
        upload_id: fileStatus.fileId,
        part_tags: validationsParts,
      },
    ],
  };

  try {
    const result = yield call([uApi.getBFFPortalApi(), 'finalizeLocalUpload'], request);
    yield put(setFileStatus({ fileId, status: FileUploadStatus.FINALIZED }));
  } catch (e) {
    yield put(setFileStatus({ fileId, status: FileUploadStatus.FINALIZATION_ERROR }));
  } finally {
    yield put(uploadManagerSaveState());
    yield put(refreshDatasetsAction());
  }
}

function unloadHandler(e: BeforeUnloadEvent) {
  // unfortunately the return value doesn't work in the modern browsers
  e.returnValue =
    'You have active uploading.\n\nAre you sure you want to navigate away from this page?\n\nPress OK to continue or Cancel to stay on the current page';
  return 'You have active uploading.\n\nAre you sure you want to navigate away from this page?\n\nPress OK to continue or Cancel to stay on the current page';
}

function watchClose() {
  window.addEventListener('beforeunload', unloadHandler);
}

function unWatchClose() {
  window.removeEventListener('beforeunload', unloadHandler);
}

function* runFileUploadWorker() {
  yield put(setFileManagerBusy());
  watchClose();
  let fileStatus: FileUploadInfo | undefined = yield getNextFile();

  while (fileStatus) {
    const { fileId } = fileStatus;
    let nextFilePart: FilePart = yield getNextFilePart(fileId);
    while (nextFilePart) {
      yield put(setFileStatus({ fileId, status: FileUploadStatus.UPLOADING }));
      const result: FumChannelEvent = yield filePartUploadWorker(fileId, nextFilePart);

      if (result.type === PART_ERROR) {
        yield put(partUploadError(result.payload as UploadCompletePayload));
        yield put(uploadManagerSaveState());
        break;
      }

      if (result.type === UPLOAD_CANCELLED) {
        yield put(removeFile(fileId));
        //todo: remove file from server (should be dataset deleted?);
        break;
      }

      if (result.type === PART_IS_READY) {
        yield put(partUploaded(result.payload as UploadCompletePayload));
        yield put(uploadManagerSaveState());
      }

      nextFilePart = yield getNextFilePart(fileId);
    }

    const isFileReady = yield select(fumIsFileReady, fileId);
    if (isFileReady) {
      yield finalizeFileUpload(fileId);
    }

    fileStatus = yield getNextFile();
  }

  yield put(setFileManagerPending());
  unWatchClose();
}

function* handleInitFileUploadManager(action: ActionType<typeof initFileUploadManager>) {
  const appOrgUserKey: string = yield select(userApplicationContextKeySelector);
  const fileUploadList = getFileManagerItems(appOrgUserKey);

  yield put(setCurrentFiles(fileUploadList));
}

function* handleFileUploadManagerSaveState(action: ActionType<typeof uploadManagerSaveState>) {
  const status = yield select(fumCurrentFilesSelector);
  const statusesToSave = status.map((item: FileUploadInfo) => ({ ...item, file: undefined }));

  const appOrgUserKey: string = yield select(userApplicationContextKeySelector);
  updateSearchSettings(appOrgUserKey, statusesToSave);
}

function* handleKickProgress(action: ActionType<typeof uploadManagerSaveState>) {
  const status = yield select(fumStatusSelector);
  if (status === FileUploadManagerStatus.PENDING) {
    yield runFileUploadWorker();
  }
}

export function* fileUploadSaga() {
  yield all([
    yield takeEvery(INIT_FILE_UPLOAD_MANAGER, handleInitFileUploadManager),
    yield takeEvery(UPLOAD_MANAGER_SAVE_STATE, handleFileUploadManagerSaveState),
    yield takeEvery(KICK_UPLOAD_PROCESS, handleKickProgress),
  ]);
}
