import {Box, Stack, Typography} from '@mui/material';
import * as React from 'react';
import {ReactNode} from 'react';
import {useTranslation} from 'react-i18next';
import BackgroundImage from '../../../../../assets/cbimage.png';
import {BiostrandLogo} from '@biostrand/components/src/assets/logos/BiostrandLogo';

type PublicPageProps = {
    children: ReactNode;
};

const PublicPage = (props: PublicPageProps) => {
    const children = props.children;
    const { t } = useTranslation();
    return (
        <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', flexDirection: 'row' }}>
            <Box sx={{ width: 478, background: `#0c2340`, pt: 3, pl: 2, position: 'relative' }}>
                <Box
                    sx={{
                        background: `#0c2340 url(${BackgroundImage}) no-repeat 0% 50%`,
                        position: 'absolute',
                        opacity: 0.8,
                        bottom: 0,
                        top: 0,
                        left: 72,
                        right: 0,
                    }}
                />
                <Box
                    sx={{
                        position: 'absolute',
                        zIndex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        ml: 1,
                    }}>
                    <div style={{ paddingTop: '48px', marginLeft: 48 }}>
                        <BiostrandLogo height={72} />
                    </div>
                    <Box sx={{ flex: 11}} />
                    <div style={{ marginLeft: 48 }}>
                        <Typography
                            sx={{
                                fontSize: 78,
                                ml: -0.25,
                                fontFamily: 'Lato',
                                lineHeight: '94px',
                                fontWeight: 'medium',
                            }}
                            color='white'>
                            {t('LENS')}
                            <sup style={{ fontSize: 48 }}>ai</sup>
                        </Typography>
                        <Typography
                            sx={{ fontSize: 28, pt: 1, fontFamily: 'Lato', fontWeight: 'medium', lineHeight: '35px' }}
                            color='white'>
                            Integrated
                            <br /> Intelligence
                            <br /> Technology
                        </Typography>

                    </div>
                    <Box sx={{ flex: 10 }} />
                        <Typography
                            sx={{
                                ml: 6,
                                mb: 7,
                                fontSize: 18,
                                pt: 2,
                                fontFamily: 'Lato',
                                fontWeight: 'regular',
                                lineHeight: '22px',
                            }}
                            color='white'>
                            Powering the HUB of Biotherapeutic Intelligence
                        </Typography>
                    </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', flex:1 }}>{children}</Box>
            <Stack sx={{ position:"absolute", left:0, bottom:0, maxWidth: 1400, width: '100%', alignItems: "flex-end"}}  >
            <Typography
                sx={{
                    color: (theme) => theme.palette.primary.light,
                    mr: 5,
                    mb: 7,
                    fontSize: 18,
                    pt: 2,
                    fontFamily: 'Lato',
                    fontWeight: 'regular',
                    lineHeight: '22px',
                }}>
                BioStrand is an independently operating subsidiary of IPA
            </Typography>
            </Stack>
        </Box>
    );
};

export default PublicPage;
