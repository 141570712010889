import {GRID_CHECKBOX_SELECTION_FIELD, GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import * as React from "react";
import {SharedMolType} from "@biostrand/biostrandapi/javascript/dist/BiostrandGatewayApi";
import {IconButton, Link, Stack, Typography} from "@mui/material";
import AlignmentViewRow from "../alignmentView/components/AlignmentViewRow";
import {Visibility} from "@mui/icons-material";

export const CSV_ROW_ID_FIELD = 'id_____key';

export const parseCSV = (str: string, separator: string = ',') => {
  const arr: string[] = [];
  let quote = false;  // 'true' means we're inside a quoted field

  // Iterate over each character, keep track of current row and column (of the returned array)
  for (let row = 0, col = 0, c = 0; c < str.length; c++) {
    let cc = str[c], nc = str[c + 1];        // Current character, next character
    arr[row] = arr[row] || [];             // Create a new row if necessary
    arr[row][col] = arr[row][col] || '';   // Create a new column (start with empty string) if necessary

    // If the current character is a quotation mark, and we're inside a
    // quoted field, and the next character is also a quotation mark,
    // add a quotation mark to the current column and skip the next character
    if (cc === '"' && quote && nc === '"') {
      arr[row][col] += cc;
      ++c;
      continue;
    }

    // If it's just one quotation mark, begin/end quoted field
    if (cc === '"') {
      quote = !quote;
      continue;
    }

    // If it's a comma and we're not in a quoted field, move on to the next column
    if (cc === separator && !quote) {
      ++col;
      continue;
    }

    // If it's a newline (CRLF) and we're not in a quoted field, skip the next character
    // and move on to the next row and move to column 0 of that new row
    if (cc === '\r' && nc === '\n' && !quote) {
      ++row;
      col = 0;
      ++c;
      continue;
    }

    // If it's a newline (LF or CR) and we're not in a quoted field,
    // move on to the next row and move to column 0 of that new row
    if (cc === '\n' && !quote) {
      ++row;
      col = 0;
      continue;
    }
    if (cc === '\r' && !quote) {
      ++row;
      col = 0;
      continue;
    }

    // Otherwise, append the current character to the current column
    arr[row][col] += cc;
  }
  return arr;
}


export const isNumberColumn = (name: string) => {
  return name.toUpperCase().indexOf('SCORE') > -1 || name.toUpperCase().indexOf('COUNT') > -1 || name.toUpperCase().indexOf('TOTAL') > -1
}

export const isSequenceColumn = (name: string) => {
  return name.toUpperCase().indexOf('SEQUENCE') > -1
}

export const rowFromArray = (columnNames: string[]) => (row: string[], index: number) => {
  const rawRow = {
    [CSV_ROW_ID_FIELD]: `#${index + 1}` //+1 is to have the proper index of the row inside the file. The first row with columns name is removed here;
  }

  for (let i = 0; i < columnNames.length; i++) {
    const key = columnNames[i];
    if (isNumberColumn(key)) {
      rawRow[key] = Number(row[i]);
    } else {
      rawRow[key] = row[i];
    }
  }
  return rawRow;
}

export interface BinderTable {
  rows: [],
  columns: string[],
}

export const makeRows = (fileContent: string, separator: string = ','): BinderTable => {
  const rows = parseCSV(fileContent, separator);
// valid only for results
  const columnNames = rows.shift();

  return {
    columns: columnNames,
    rows: rows.map(rowFromArray(columnNames))
  };
}

export interface ColumnAction<T> {
  action: (value: T) => void,
  isActionPossible: (value: T) => boolean
}

export interface ActionsMap {
  [key: string]: ColumnAction<any>
}

export declare type SpecificColTypes = "sequence";

export declare type GridColumnTypes = "sequence" | "string" | "number"  | "link"; //GridColType | SpecificColTypes;

export const columnTypes = ["string", "sequence", "number",  "link"];

export interface ColumnTypeItem {
  type: GridColumnTypes,
  link_type?: "dataset" | "regular",
  label:string,
  value:string
}

export const columnTypeItems: ColumnTypeItem[] = [
  { type: "number",   label: "Number", value: "number" },
  { type: "sequence", label: "Sequence", value: "sequence" },
  { type: "string", label: "String", value: "string" },
  { type: "link", label: "Web link", value: "link" },
  { type: "link", link_type: "dataset", label: "Dataset file reference", value: "link_dataset" },

]

export const columnToColumnTypeItem = (cd) => {
  const value = (cd.type === 'link' && cd.link_type === "dataset") ? "link_dataset" : cd.type;
  return valueToColumnTypeItem(value);
}

export const valueToColumnTypeItem = (itemValue: string): ColumnTypeItem => {
  return columnTypeItems.find(cti => cti.value === itemValue) as ColumnTypeItem
}

export const getType = (columnName: string): GridColumnTypes => {
  if (isNumberColumn(columnName)) {
    return 'number';
  }
  return 'string';
}

export const getRowId = (row): string => {
  return row[CSV_ROW_ID_FIELD];
}

export const getTableSettings = settings => {
  if (!settings) return;
  const {pinnedLeft = [], pinnedRight = []} = settings;

  return {
    pinnedColumns: {
      left: [CSV_ROW_ID_FIELD, GRID_CHECKBOX_SELECTION_FIELD, ...pinnedLeft],
      right: pinnedRight
    }
  }
}

export const createGridColumn = (name: string, columnSettings: { [key: string]: {} }, actions: ActionsMap): GridColDef => {
  const cs = columnSettings[name] || {};
  const columnType = cs?.type || getType(name)
  const sSortable = cs?.can_sort === undefined ? true : cs?.can_sort;
  const sFilterable = cs?.can_filter === undefined ? true : cs?.can_filter;

  const {field, headerName, type, sortable, filterable, width, ...restSettings} = cs;

  const commonAttr = {
    field: name,
    headerName: cs?.label || name,
    type: columnType,
    sortable:sSortable,
    filterable:sFilterable,
    //...restSettings
  }

  if (cs?.tooltip) {
    commonAttr.description = cs?.tooltip;
  }

  switch (columnType) {
    case 'sequence':
      return {
        ...restSettings,
        ...commonAttr,
        minWidth: 200,
        renderCell: (params: GridRenderCellParams<string>) => {
          return <Stack sx={{height: '100%', alignContent: 'center', justifyContent: "center"}}>
            <Stack sx={{lineHeight: 1.2}}>
              <AlignmentViewRow row={{offset: 0, sequence: params.value, id: params.row.id, type: SharedMolType.AA}}/>
            </Stack>
          </Stack>
        },

      }
    case 'link':
      if (cs.link_type === 'dataset') {
        const fpAction = actions['filePreview'];
        const {action, isActionPossible} = fpAction

        return {
          ...restSettings,
          ...commonAttr,
          minWidth: 100,
          renderCell: (params: GridRenderCellParams<string>) => {

            const labelToRender = cs.link_display === "fix" ? cs.link_fix_text : params.value;

            return <Stack sx={{alignItems: 'center', height: 28}} direction={"row"}>
              {isActionPossible(params.value)
                ? <IconButton onClick={() => action(params.value)}>
                  <Visibility/>
                </IconButton> :
                <div style={{minWidth: 40}}/>
              }
              <Typography variant={"body2"}>{labelToRender}</Typography>

            </Stack>
          }
        }
      }
      return {
        ...restSettings,
        ...commonAttr,
        renderCell: (params: GridRenderCellParams<string>) => {
          const labelToRender = cs.link_display === "fix" ? cs.link_fix_text : params.value;
          return <Stack sx={{height: '100%', alignContent: 'center', justifyContent: "center"}}>
            <Link href={params.value} target={'_blank'}>{labelToRender}</Link>
          </Stack>
        },
      }
    case 'number':
    default:
      return {
        ...commonAttr,
      }
  }
}


