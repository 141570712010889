import {Button, Typography} from '@mui/material';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {Link as RouterLink} from 'react-router-dom';
import {RouteUrl} from '../../../routeUrl';
import PublicPage from '../common/publicPage/PublicPage';
import {Box} from "@mui/system";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";

const PendingChangePasswordPage = () => {
    const [t] = useTranslation();

    const email = useSelector((state:RootState) => state.register.forgotPasswordResults.data?.CodeDeliveryDetails?.Destination )

    return (
        <PublicPage>
            <Box sx={{m:5}}>
                <Typography variant='h4' sx={{mb:4}}>
                    {t('Please check your inbox')}
                </Typography>
                <Typography variant='body1' sx={{mb:6}}>
                    {t('Email has been sent to ')}
                    <span style={{fontWeight: 'bold'}}>{email}</span>
                </Typography >
                <div>
                    <Button variant='outlined' component={RouterLink} color='primary' size='large' to={RouteUrl.Login}>
                        {t('Back to login')}
                    </Button>
                </div>
            </Box>
        </PublicPage>
    );
};

export default PendingChangePasswordPage;
