import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {removeSnackbarNotification, snackbarNotificationSelector} from './snackbarNotificationSlice';

import {SnackbarKey, useSnackbar} from 'notistack';

let displayed: SnackbarKey[] = [];

export const useSnackbarNotifier = () => {
    const dispatch = useDispatch();
    const notifications = useSelector(snackbarNotificationSelector);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const storeDisplayed = (id:SnackbarKey) => {
        displayed = [...displayed, id];
    };

    const removeDisplayed = (id:SnackbarKey) => {
        displayed = [...displayed.filter(key => id !== key)];
    };

    React.useEffect(() => {
        notifications.forEach((notification) => {
            const {key, dismissed, options = {}, message} = notification;


            if (dismissed) {
                // dismiss snackbar using notistack
                closeSnackbar(key);
                return;
            }

            // do nothing if snackbar is already displayed
            if (displayed.includes(key)) return;

            // display snackbar using notistack
            enqueueSnackbar(message, {
                key,
                ...options,
                onClose: (event, reason, myKey) => {
                    if (options.onClose) {
                        options.onClose(event, reason, myKey);
                    }
                },
                onExited: (event, myKey) => {
                    // remove this snackbar from redux store
                    dispatch(removeSnackbarNotification(myKey));
                    removeDisplayed(myKey);
                },
            });

            // keep track of snackbars that we've displayed
            storeDisplayed(key);
        });
    }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);
};

