import * as React from 'react';
import PublicPage from "../common/publicPage/PublicPage";
import ConfirmPasswordForm from "./ConfirmPasswordForm";

const ConfirmPasswordPage = () => {
  return (
      <PublicPage>
        <ConfirmPasswordForm />
      </PublicPage>
  );
};

export default ConfirmPasswordPage;
