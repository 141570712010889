import {Box} from '@mui/material';
import {SxProps, Theme} from '@mui/system';
import * as React from 'react';
import {BiostrandLogoGray} from "../../assets/logos/BiostrandLogoGray";

interface Props {
    sx?: SxProps<Theme>;
}

const BlankHeader = (props: Props): JSX.Element => {
    const {sx} = props

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            m: 2.5,
            ml: 3,
            ...sx
            }}>
            <BiostrandLogoGray height={48} style={{margin:-16}}  />
            <Box sx={{ flex: 1 }} />
        </Box>
    );
};

export default BlankHeader;
