import {SearchHistoryEntry} from './searchHistoryTypes';
import {SortDirection} from "../commonTypes";
import {DatasetManagerDatasetEntity} from "@biostrand/biostrandapi/javascript/dist/DatasetManagerApi";

import {GridColumnVisibilityModel} from "@mui/x-data-grid";
import {AlignAlignResponse, SharedMolType} from "@biostrand/biostrandapi/javascript/dist/AlignApi";
import {
    RetrieveAndRelateListResponse,
    RetrieveAndRelateQueryMetaData,
    RetrieveAndRelateTaxonomyResponse
} from "@biostrand/biostrandapi/javascript/dist/RetrieveAndRelateApi";

export enum SearchActionTypes {
    RUN_SEARCH_REQUEST = '@@search/RUN_SEARCH_REQUEST',
    RUN_ENRICH_QUERY_REQUEST = '@@search/RUN_ENRICH_QUERY_REQUEST',
    CLEAR_SEARCH_HISTORY = '@@search/CLEAR_SEARCH_HISTORY',

    ROOT_SEARCH_REQUEST = '@@search/ROOT_SEARCH_REQUEST',
    ROOT_SEARCH_SUCCESS = '@@search/ROOT_SEARCH_REQUEST_SUCCESS',
    ROOT_SEARCH_REQUEST_ERROR = '@@search/ROOT_SEARCH_REQUEST_ERROR',

    ALIGNMENT_SEARCH_REQUEST = '@@search/ALIGNMENT_SEARCH_REQUEST',
    LISTVIEW_SEARCH_REQUEST = '@@search/LISTVIEW_SEARCH_REQUEST',

    SHOW_SEQUENCE_INFO_POPUP = '@@search/SHOW_SEQUENCE_INFO_POPUP',
}

export interface AlignmentSearchParameters {
    readonly baseQueryId: string;
    readonly page: number;
    readonly pageSize: number;
    readonly molType: SharedMolType;
}

export interface ListViewSearchParameters {
    readonly baseQueryId: string;
    readonly page: number;
    readonly pageSize: number;
    readonly sortingDirection?: SortDirection;
    readonly sortingColumnKey?: string;
    readonly fields?: Array<string>;
}

export interface Dimension {
    name: string;
    displayName: string;
}

export interface DimensionElement {
    name: string;
    count: number;
}

export type DimensionWithAllElements = Dimension & {
    elements: DimensionElement[];
};

export interface DimensionPlaceholder {
    type: string;
    count: number;
}

export interface DimensionFilter {
    readonly dimensionName: string;
    includes?: string[];
    excludesAll: boolean;
    elementNamesContains?: string;
    match?: string;
}

export type DimensionWithPlaceholder = Dimension & { placehold: DimensionPlaceholder };

export interface SearchState {
    readonly rootTaxonomy?: RetrieveAndRelateTaxonomyResponse;
    readonly currentTaxonomy?: RetrieveAndRelateTaxonomyResponse;

    readonly dimensions: Dimension[];
    readonly currentDimension: Dimension[];

    readonly alignmentResultViewData?: AlignAlignResponse;
    readonly alignmentRequestInProgress: boolean;

    readonly listRequestInProgress: boolean;
    readonly listViewResults?: RetrieveAndRelateListResponse;
    readonly listViewVisibleColumns?: GridColumnVisibilityModel;

    readonly isEnriching: boolean;
    readonly isSearchInProgress: boolean;

    readonly rootQuery?: string;
    readonly rootSearchRequestInProgress: boolean;
    readonly rootSearchRequestInError?: boolean;

    readonly baseSearchRequestInProgress: boolean;
    readonly baseQueryId?: string;
    readonly baseSearchRequestInError?: boolean;

    readonly queryMatches?: number;
    readonly queryMetadata?: RetrieveAndRelateQueryMetaData;

    readonly history?: SearchHistoryEntry[];
    readonly availableDatasets?: DatasetManagerDatasetEntity[];

    readonly lastFilters?: DimensionFilter[];
}

export enum SequenceFields {
    sequenceId = 'sequenceId',
    url = 'url',
    taxonomy = 'taxonomy',
}
