import { DatasetManagerTestConnectionResponse } from '@biostrand/biostrandapi/javascript/dist/DatasetManagerApi';
import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import { SxProps, Theme } from '@mui/system';
import * as React from 'react';
import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { uApi } from '../../biostrandApi/uApi';
import { s3Settings2Config } from '../datasetFormUtils';
import { S3Settings } from '../datasetTypes';

interface TestS3ConnectionProps {
  config: S3Settings;
  sx?: SxProps<Theme>;
}

export function TestS3Connection(props: TestS3ConnectionProps): ReactElement {
  const { config, sx } = props;
  const { t } = useTranslation();
  const [isValidating, setIsValidating] = useState(false);
  const [message, setMessage] = useState<string | undefined>(undefined);
  const [isSuccess, setIsSuccess] = useState<'ok' | 'error' | null>(null);

  useEffect(() => {
    setMessage(undefined);
    setIsSuccess(null);
  }, [config]);

  const handleSubmit = async () => {
    setIsValidating(true);

    try {
      const result = await uApi.getDatasetManagerApi().datasetManagerTestConnection(s3Settings2Config(config));
      const response: DatasetManagerTestConnectionResponse = result.data;
      if (response && response.success) {
        setIsSuccess('ok');
      } else {
        setMessage(t("Can't connect"));
        setIsSuccess('error');
      }
    } catch (e) {
      setMessage(`${t('Something went wrong:')} ${e.toString()}`);
      setIsSuccess('error');
    } finally {
      setIsValidating(false);
    }
  };

  const getStartIcon = () => {
    if (isSuccess === 'ok') return <FontAwesomeIcon icon={faCheck} color="#57cc3f" />;
    if (isSuccess === 'error') return <FontAwesomeIcon icon={faTimes} color="#de5a41" />;

    return null;
  };

  const getColor = () => {
    if (isSuccess === 'ok') return 'success';
    if (isSuccess === 'error') return 'error';

    return undefined;
  };

  const getLabel = () => {
    if (isSuccess === 'ok') return t('Connected');
    if (isSuccess === 'error') return t("Can't connect");

    return t('Test connection');
  };

  return (
    <LoadingButton
      sx={sx}
      variant={'text'}
      title={message}
      color={getColor()}
      startIcon={getStartIcon()}
      loading={isValidating}
      disabled={isValidating || !config.path}
      onClick={() => {
        handleSubmit();
      }}
    >
      {getLabel()}
    </LoadingButton>
  );
}
