import React from 'react';
import {faFacebookF, faLinkedin, faTwitter, faYoutube} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {LinkDescription, SocialLinkDescription} from "./linkTypes";

export const WHAT_WE_DO_LINKS: LinkDescription[] = [
  {
    name: 'Omics Data Management And Analysis',
    link: 'https://www.biostrand.be/en/omics-analysis-platform',
  },
  {
    name: 'Retrieve & Relate Platform',
    link: 'https://www.biostrand.be/en/retrieve-and-relate',
  },
  {
    name: 'Omics Analysis',
    link: 'https://www.biostrand.be/en/omics-analysis',
  },
  {
    name: 'Pricing',
    link: 'https://www.biostrand.be/en/pricing',
  },
];

export const WHY_WE_ARE_LINKS: LinkDescription[] = [
  {
    name: 'Company',
    link: 'https://www.biostrand.be/en/about-us/company',
  },
  {
    name: 'Team',
    link: 'https://www.biostrand.be/en/about-us/team',
  },
  {
    name: 'News Room',
    link: 'https://blog.biostrand.be/en/news',
  },
  {
    name: 'Contact',
    link: 'https://www.biostrand.be/en/contact',
  },
];

export const LEGAL_LINKS: LinkDescription[] = [
  {
    name: 'Privacy policy',
    link: 'https://www.biostrand.be/en/privacy-policy',
  },
  {
    name: 'Legal noticy',
    link: 'https://www.biostrand.be/en/legal',
  },
  {
    name: 'Terms of use',
    link: 'https://www.biostrand.be/en/terms-of-use',
  },
];

export const SOCIAL_NETWORKS_LINKS: SocialLinkDescription[] = [
  {
    name: 'linked in',
    icon: <FontAwesomeIcon icon={faLinkedin} style={{ width: 24, height: 24 }} />,
    link: 'https://www.linkedin.com/company/biostrand/about/',
  },
  {
    name: 'facebook',
    icon: <FontAwesomeIcon icon={faFacebookF} style={{ width: 24, height: 24 }} />,
    link: 'https://www.facebook.com/BioStrand-103815681122843/',
  },
  {
    name: 'twitter',
    icon: <FontAwesomeIcon icon={faTwitter} style={{ width: 24, height: 24 }} />,
    link: 'https://twitter.com/BiostrandBV',
  },
  {
    name: 'YouTube',
    icon: <FontAwesomeIcon icon={faYoutube} style={{ width: 24, height: 24 }} />,
    link: 'https://www.youtube.com/channel/UChBal9jhD8v9XUzcwpgiqLQ',
  },
];
