import { TableCell } from '@mui/material';
import * as React from 'react';
import { Column, ColumnType } from './gridTypes';
import {MenuCellRenderer, MenuHandler} from './MenuCellRenderer';
import { MenuItemDescription } from './menuTypes';

export function getGridCellRenderer<T>(column: Column<T>, row: T, menuItems?: MenuItemDescription[], handleDatasetAction?: MenuHandler<T>) {
  if (column.type === ColumnType.MENU && menuItems && handleDatasetAction) {
    return <MenuCellRenderer
        key={column.id as string}
        items={menuItems || []}
        row={row}
        onAction={handleDatasetAction} />;
  }
  const value = row[column.id];
  return (
    <TableCell key={column.id as string} align={column.align}>
      {column.format ? column.format(value) : value}
    </TableCell>
  );
}
