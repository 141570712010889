export enum AuthenticationStatus {
    INITIALIZING = "INITIALIZING",
    NOT_AUTHENTICATED = "NOT_AUTHENTICATED",
    PENDING = "PENDING",
    AUTHENTICATED = "AUTHENTICATED",
}

export interface AuthenticationState {
    readonly authStatus: AuthenticationStatus;
}

export interface AuthStore {
    auth: AuthenticationState
}

export enum AuthenticationFeedbackType {
    Success,
    NewPasswordRequired,
    Failure,
}

export interface Credentials {
    email: string;
    password: string;
}

export interface User {
    email: string;
}

