import * as React from 'react';
import SettingsPageTemplate from '../SettingsPageTemplate';
import BillingSettingsContent from './BillingSettingsContent';
import {SettingsIds} from "@biostrand/components/src/applications/SettingsIds";

const BillingSettingsPage = (): JSX.Element => {
    return (
        <SettingsPageTemplate selectedItem={SettingsIds.BILLING}>
            <BillingSettingsContent />
        </SettingsPageTemplate>
    );
};

export default BillingSettingsPage;
