import {DatasetManagerDatasetEntity} from '@biostrand/biostrandapi/javascript/dist/DatasetManagerApi';
import {CloseRounded, UnfoldLess, UnfoldMore} from '@mui/icons-material';
import {IconButton, Stack, Typography,} from '@mui/material';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {datasetsSelector} from "../../slices/datasets/datasetsSelectors";

interface DGSelectedItemsListProps {
  onSelectionChange: (fileIds: string[]) => void;
  selectedItemsIds?: string[];
}

interface DSMap {
  [key: string]: DatasetManagerDatasetEntity;
}

interface DatasetItemProps {
  expanded: boolean
  datasetItem: any
  onFilesToggle: (datasetItem) => void
  onItemRemove: (datasetItem) => void
}

const DatasetItem = (props: DatasetItemProps) => {
  const {expanded, datasetItem, onFilesToggle, onItemRemove} = props;

  return <Stack spacing={1} sx={{borderBottom: 'solid 1px #dddddd', pb: 1}}>
    <Stack direction={'row'} spacing={1}>
      <IconButton onClick={() => {
        onItemRemove(datasetItem.dataset.id);
      }}>
        <CloseRounded/>
      </IconButton>
      <Stack direction={'column'} sx={{flex: 1}}>
        <Stack direction={'row'} spacing={1}><Typography
          variant={"body1"}>{datasetItem.dataset.name}</Typography><Typography
          variant={"caption"}>({datasetItem.dataset.version})</Typography></Stack>
        <Typography
          variant={"caption"}>{datasetItem.files.length > 0 ? `${datasetItem.files.length} files selected` : 'All dataset selected'}</Typography>
      </Stack>
      {datasetItem.files.length > 0 ? <IconButton onClick={() => {
        onFilesToggle(datasetItem.dataset.id)
      }}>
        {expanded ? <UnfoldLess/> : <UnfoldMore/>}
      </IconButton> : null}
    </Stack>
    {expanded ? datasetItem.files.map((filePath: string) => {
      const parts = filePath.split('/');
      parts.shift() // remove dataset id
      const fileName = parts.pop();
      const path = parts.join(' / ');

      return <Stack direction={'row'} sx={{pl: 2, pr: 2, alignItems: 'flex-end'}} spacing={1}>
        <IconButton onClick={() => {
          onItemRemove(filePath);
        }}>
          <CloseRounded/>
        </IconButton>
        <Stack direction={'column'} sx={{
          overflow: "hidden",
        }}>
          <Typography variant={"caption"} component={'div'}>/ {path}</Typography>
          <Typography component={'div'} sx={{
            overflow: "hidden",
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }} variant={"body2"}>{fileName}</Typography>
        </Stack>
      </Stack>
    }) : null}
  </Stack>
}

export const DGSelectedItemsList = (props: DGSelectedItemsListProps): JSX.Element => {
  const {selectedItemsIds, onSelectionChange} = props;

  const [itemsGroupedByDataset, setItemsGroupedByDataset] = useState([]);
  const datasets = useSelector(datasetsSelector);

  const [expandedItems, setExpandedItems] = useState({});

  useEffect(() => {

    if (selectedItemsIds) {
      const datasetsById: DSMap = {};
      datasets?.forEach(ds => {
        datasetsById[ds.id || ''] = ds;
      })

      const itemsGroupedByDsId: { [key: string]: any } = {}

      selectedItemsIds.forEach(item => {
        const dsId = item.split('/')[0];

        if (!itemsGroupedByDsId[dsId]) {
          itemsGroupedByDsId[dsId] = {dataset: datasetsById[dsId], files: []};
        }
        const dsGroup = itemsGroupedByDsId[dsId];
        if (item !== dsId) /* ignore dataset itself */ {
          dsGroup.files.push(item);
        }
      })

      const selectedDsIds = Object.keys(itemsGroupedByDsId);

      const igbd = selectedDsIds.map(dsId => itemsGroupedByDsId[dsId])
      setItemsGroupedByDataset(igbd);
    } else {
      setItemsGroupedByDataset([]);
    }

  }, [selectedItemsIds, datasets])


  const onFilesToggle = (fullFileName: string) => {
    if (expandedItems[fullFileName]) {
      delete expandedItems[fullFileName]
    } else {
      expandedItems[fullFileName] = true;
    }

    setExpandedItems({...expandedItems})
  };

  const onItemRemove = (id: string) => {
    const newSelectionIds = (selectedItemsIds || []).filter((itemId) => !(itemId.indexOf(id) === 0))
    onSelectionChange(newSelectionIds);
  };

  return (
    <Stack direction={'column'}
           sx={{minHeight: 300, overflowY: "scroll", flex: 1, minWidth: 300, alignItems: 'stretch'}} spacing={2}>
      {itemsGroupedByDataset.map(datasetItem => {

        return <DatasetItem datasetItem={datasetItem}
                            key={datasetItem.dataset.id}
                            expanded={expandedItems[datasetItem.dataset.id]}
                            onItemRemove={onItemRemove}
                            onFilesToggle={() => onFilesToggle(datasetItem.dataset.id)}
        />;
      })}
    </Stack>
  );
};
