import * as React from 'react';
import {Stack, Typography} from '@mui/material';

interface Props {
    name: string;
    value: string|JSX.Element;
}

export const InfoField = (props: Props): JSX.Element => {
    const { name, value } = props;
    return (
        <Stack direction={"row" }  spacing={1}>
            <Typography variant={'caption'} sx={{ minWidth:170,  maxWidth:170, color: theme => theme.palette.grey[500] }}>
                {name}
            </Typography>
            <Typography variant={'body2'} sx={{wordBreak: 'break-all'}} >{value}</Typography>
        </Stack>
    );
};
