import * as React from 'react';
import {Box, Skeleton} from "@mui/material";

const TableLoadingSkeleton = (): JSX.Element => {
    return (
        <Box sx={{display: 'flex', flexDirection: 'column', mt:4}}>
            <Box sx={{display: 'flex', flexDirection: 'row'}}>
                <Skeleton animation="wave" height={48} sx={{flex:1, mr: 4}}/>
                <Skeleton animation="wave" height={48} sx={{flex:1, mr: 4}} />
                <Skeleton animation="wave" height={48} sx={{flex:1, mr: 4}} />
                <Skeleton animation="wave" height={48} sx={{flex:1}} />
            </Box>
            <Skeleton animation="wave" height={40} />
            <Skeleton animation="wave" height={40} />
            <Skeleton animation="wave" height={40} />
        </Box>
    );
};

export default TableLoadingSkeleton;
