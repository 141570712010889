import {MenuItemDescription} from "@biostrand/components/src/grid/menuTypes";


export enum UserAction {
    ACTIVATE = 'ACTIVATE',
    DEACTIVATE = 'DEACTIVATE',
    MAKE_ADMIN = 'MAKE_ADMIN',
    MAKE_REGULAR = 'MAKE_REGULAR',
    DELETE_ACCOUNT = 'DELETE_ACCOUNT',
}

export const DeactivateMI: MenuItemDescription = {name: 'Deactivate account', action: UserAction.DEACTIVATE }
export const ActivateMI: MenuItemDescription = {name: 'Activate account', action: UserAction.ACTIVATE }
export const MakeAdminMI: MenuItemDescription = {name: 'Promote to Admin', action: UserAction.MAKE_ADMIN }
export const MakeRegularMI: MenuItemDescription = {name: 'Remove Admin rights', action: UserAction.MAKE_REGULAR }
export const DeleteMI: MenuItemDescription = {name: 'Delete account', action: UserAction.DELETE_ACCOUNT }

