import {Button, FormControl, Typography} from '@mui/material';
import {Stack} from '@mui/system';
import {Field} from 'formik';

import {TextField} from 'formik-mui';
import * as React from 'react';
import {ReactElement, RefObject, useCallback, useRef} from 'react';
import {useDropzone} from 'react-dropzone';
import {useTranslation} from 'react-i18next';
import uniqWith from 'lodash.uniqwith';
import difference from 'lodash.difference';
import {SelectedFileList} from "./SelectedFileList";
import {DriveFolderUpload, UploadFile} from "@mui/icons-material";
import {FolderSelectionHOC} from './fileupload/FolderSelectionHOC';
import {isTheSameFiles} from "./fileupload/fileUtils";
import {useDispatch} from "react-redux";
import {enqueueSnackbarNotification} from "../snackbarNotification/snackbarNotificationSlice";
import {CloseNotificationButton} from "../index";

const DEFAULT_FILES_TO_IGNORE = [
  '.DS_Store', // OSX indexing file
  'Thumbs.db'  // Windows indexing file
];

function shouldIgnoreFile(file: File) {
  return DEFAULT_FILES_TO_IGNORE.indexOf(file.name) >= 0;
}

const FileUpload = ({fileRef, ...props}) => {
  const {field, form} = props;
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const onDrop = useCallback(acceptedFiles => {
    const fileList = field.value ? [...field.value] : [];
    let addedFiles = 0
    for (let i = 0; i < acceptedFiles.length; i++) {
      if (!shouldIgnoreFile(acceptedFiles[i])) {
        fileList.push(acceptedFiles[i]);
        addedFiles++;
      }
    }

    const uniqFiles = uniqWith(fileList, isTheSameFiles);
    const ignoredItems = difference(fileList, uniqFiles);

    const message = ignoredItems.length > 0
      ? `The ${addedFiles - ignoredItems.length}  files are added and \n${ignoredItems.length} are ignored (already in the list)`
      : `The ${addedFiles} files are added`

    dispatch(
      enqueueSnackbarNotification({
        message: message,
        key: 'files-upload-file-selector' + (new Date()).getTime(),
        options: {
          variant: 'info',
          autoHideDuration: 9000,
          action: key => (
            <CloseNotificationButton notificationKey={key}>Close</CloseNotificationButton>
          ),
        },
      }),
    );

    form.setFieldValue(field.name, uniqFiles);
  }, [field.value]);

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, multiple: true,});

  const {onClick, ...restRootProps} = getRootProps()

  const onFilesDelete = useCallback((files) => {
    const cleaned = difference(field.value, files);
    form.setFieldValue(field.name, cleaned);
  }, [field.value])

  return (
    <Stack
      {...restRootProps}
      sx={{
        borderColor: 'primary.main',
        borderStyle: 'dashed',
        borderRadius: 1.5,
        borderWidth: 1,
        p: 2,
        mt: 2,
        mb: 2,
        position: 'relative'
      }}
      direction={'column'}
    >
      <input ref={fileRef} {...props} {...getInputProps()} />
      <Stack direction={"row"}
             spacing={4}
             minHeight={64}
             sx={{
               alignSelf: 'center',
               alignItems: 'center',
               justifyContent: 'center'
             }}>
        <Button variant={"outlined"} onClick={onClick} startIcon={<UploadFile/>}>add files</Button>
        <FolderSelectionHOC onFolderSelection={onDrop} values={field.value}>
          <Button variant={"outlined"} startIcon={<DriveFolderUpload />}>add folder</Button>
        </FolderSelectionHOC>
        <Typography color={'primary.main'} variant={"subtitle1"}>{t("Drag and drop files and folders here")}</Typography>
      </Stack>
      {field.value && <SelectedFileList fileList={field.value} onDeleteItems={onFilesDelete}/>}
      {isDragActive && (
        <Stack sx={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          background: 'rgba(14,69,129,0.3)',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <Stack sx={{p: 2, background: "#ffffff", borderRadius: 4}}>
            <Typography variant={'body2'}>{t('Drop the file here ...')}</Typography>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

interface FormProps {
  fileRef: RefObject<HTMLInputElement>;
}

export function UploadDatasetForm(props: FormProps): ReactElement {
  const [t] = useTranslation();
  const fileRef = useRef<HTMLInputElement>();

  return (
    <Stack sx={{pt: 2, minWidth: 1024}}>
      <Stack>
        <FormControl margin={'dense'} sx={{minWidth: 330, height: theme => theme.spacing(9)}}>
          <Field
            id="dataSetName"
            label={t('Data set name')}
            variant="outlined"
            required
            component={TextField}
            name="datasetName"
          />
        </FormControl>
        <Field required name="files" fileRef={fileRef} component={FileUpload}/>
      </Stack>
    </Stack>
  );
}
