import {
  CheckCircleOutline,
  Clear,
  ErrorOutline,
  QueryBuilderOutlined,
  Refresh,
  WarningAmber,
  WarningOutlined
} from '@mui/icons-material';
import {IconButton, LinearProgress, Typography} from '@mui/material';
import {Stack} from '@mui/system';
import {DataGrid, GridColDef, GridRenderCellParams, useGridApiRef} from '@mui/x-data-grid';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fumCurrentFilesSelector, hasPartError} from '../slices/datasets/fileUploadSelectors';
import {DatasetUploadInfo, FileUploadInfo} from '../slices/datasets/fileUploadTypes';
import {ReselectFileButton} from "./fileupload/ReselectFileButton";
import {formatFileSize} from "./fileupload/formatFileSize";
import {
  clearPartUploadError,
  kickUploadProcess,
  removeDataset,
  uploadManagerSaveState
} from '../slices/datasets/fileUploadManagerSlice';
import {FileProgressCell} from "./fileupload/FileProgressCell";
import {
  calculateFilesProgress,
  DatasetStatusSummary,
  DSStatuses,
  getFileStatusSummary,
  getStatusSummary
} from "../slices/datasets/fileUploadUtils";
import {useLocation} from "react-router";
import {push} from "connected-react-router";


export const UploadProgressPage = (): JSX.Element => {
  const fileList: FileUploadInfo[] = useSelector(fumCurrentFilesSelector);
  const [filesGroupedByDataset, setFilesGroupedByDataset] = useState([]);
  const [currentFiles, setCurrentFiles] = useState([]);
  const [selectedDatasetDGModel, setSelectedDatasetDGModel] = useState([]);
  const location = useLocation();
  const dispatch = useDispatch();
  const gridApiRef = useGridApiRef();


  useEffect(() => {
    const urlSearchParameters = new URLSearchParams(location.search);
    const selection = urlSearchParameters.get('selection');
    if (selection) {
      setSelectedDatasetDGModel([selection])
    } else {
      setSelectedDatasetDGModel([])
    }
  }, [location])

  useEffect(() => {
    if (fileList) {
      const idMaps = {};
      const dsIds = [];
      fileList.forEach(fileInfo => {
        let item: DatasetUploadInfo | undefined = idMaps[fileInfo.context.dataset?.id];
        if (!item) {
          dsIds.push(fileInfo.context.dataset?.id);
          item = {
            dataset: fileInfo.context.dataset,
            files: [],
          }
          idMaps[fileInfo.context.dataset?.id] = item;
        }
        item.files.push(fileInfo);
      });

      const newFilesGroupedByDataset = dsIds.map(dsId => idMaps[dsId]);
      newFilesGroupedByDataset.forEach(uploadInfo => {
        uploadInfo.status = getStatusSummary(uploadInfo.files);
        uploadInfo.progress = calculateFilesProgress(uploadInfo.files);
      });

      setFilesGroupedByDataset(newFilesGroupedByDataset);
    } else {
      setFilesGroupedByDataset([]);
    }
  }, [fileList])

  useEffect(() => {
    if (selectedDatasetDGModel && selectedDatasetDGModel.length > 0 && filesGroupedByDataset) {
      const dsId = selectedDatasetDGModel[0]
      const dsInfo = filesGroupedByDataset.find((fgbd) => fgbd.dataset.id === dsId);
      if (dsInfo) {
        setCurrentFiles(dsInfo.files);
      } else {
        setCurrentFiles([])
      }
    } else {
      setCurrentFiles([])
    }
  }, [selectedDatasetDGModel, filesGroupedByDataset])

  const filesColumns: GridColDef[] = [
    {
      field: 'fileName',
      headerName: 'File name',
      valueGetter: (params) => {
        return params.row.fileName.split('/').pop();
      },
      flex: 1,
      sortable: true,
      minWidth: 130,
    },
    {
      field: 'path',
      headerName: 'Path',
      valueGetter: (params) => {
        const parts = params.row.fileName.split('/');
        parts.pop();
        return '/' + parts.join('/');
      },
      flex: 1,
      sortable: true,
      minWidth: 130,
    },
    {
      field: 'fileSize',
      headerName: 'Size',
      width: 100,
      align: 'right',
      valueGetter: (row) => formatFileSize(row.value)
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 130,
      renderCell: (params: GridRenderCellParams<string>): JSX.Element | null => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const dispatch = useDispatch();
        const fileInfo: FileUploadInfo = params.row;
        const statusSummary = getFileStatusSummary(fileInfo)

        if (statusSummary.isReady) {
          return (
            <Stack direction="row" spacing={1} sx={{alignItems: 'center'}}>
              <CheckCircleOutline color={'success'}/>
              <Typography variant={'subtitle2'}>Ready</Typography>{' '}
            </Stack>
          );
        }

        if (statusSummary.hasError) {
          return (<Stack direction="row" spacing={1} sx={{alignItems: 'center'}}>
            <WarningOutlined color={'error'}/>
            <Typography variant={'subtitle2'}>Error</Typography>{' '}
          </Stack>)
        }

        if (statusSummary.isFileMissed) {
          return (
            <Stack direction="row" spacing={1} sx={{alignItems: 'center'}}>
              <ErrorOutline color={'warning'}/>
              <Typography variant={'subtitle2'}>Missing file</Typography>{' '}
            </Stack>
          );
        }

        if (statusSummary.isDraft) {
          return <Stack direction="row" spacing={1} sx={{alignItems: 'center'}}>
            <QueryBuilderOutlined/>
            <Typography variant={'subtitle2'}>queued</Typography>
          </Stack>
        }

        if (statusSummary.isUploading) {
          return <FileProgressCell fileInfo={fileInfo} sx={{minWidth: 300}}/>
        }

        if (hasPartError(fileInfo)) {
          return (
            <Stack direction="row" spacing={1} sx={{alignItems: 'center'}}>
              <WarningAmber color={'warning'}/>
              <Typography variant={'subtitle2'}>Upload error</Typography>{' '}
              <IconButton size={"small"} onClick={() => {
                dispatch(clearPartUploadError(fileInfo.fileId));
                dispatch(kickUploadProcess());
              }}><Refresh/></IconButton>
            </Stack>
          );
        }
        return <div/>;
      },
    },
    {
      field: 'action',
      headerName: '',
      width: 50,
      align: 'right',
      renderCell: (params: GridRenderCellParams<string>): JSX.Element | null => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const dispatch = useDispatch();
        const fileInfo: FileUploadInfo = params.row;
        const statusSummary = getFileStatusSummary(fileInfo)

        if (statusSummary.hasError) {
          return (<Stack direction="row" spacing={1} sx={{alignItems: 'center'}}>
            <IconButton size={"small"} onClick={(e) => {
              e.preventDefault();
              dispatch(clearPartUploadError(fileInfo.fileId));
              dispatch(kickUploadProcess());
            }}><Refresh/></IconButton>
          </Stack>)
        }

        if (statusSummary.isFileMissed) {
          return (
            <ReselectFileButton fileInfo={fileInfo}/>
          );
        }

        return <div/>
      },
    },
  ];

  const datasetColumns: GridColDef[] = [
    {
      field: 'datasetName',
      headerName: 'Dataset Name',
      flex: 1,
      width: 100,
      renderCell: (params: GridRenderCellParams<string>): JSX.Element | null => {
        const dataset = params.row.dataset;
        return (
          <Stack direction="row" spacing={1} sx={{alignItems: "baseline"}}>
            <Typography variant={"subtitle2"}>{dataset?.name}</Typography>
            {(dataset?.version && (dataset?.version !== 'v0.0.1')) ?
              <Typography variant={'caption'} sx={{opacity: 0.75}}>({dataset?.version})</Typography> : null}
          </Stack>
        );
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 130,
      minWidth: 130,
      renderCell: (params: GridRenderCellParams<DatasetStatusSummary>): JSX.Element | null => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const status: DatasetStatusSummary = params.row.status
        const files = params.row.files;

        if (status[DSStatuses.ERROR].length > 0) {
          return <Stack direction="row" spacing={1} sx={{alignItems: 'center'}}>
            <WarningOutlined color={'error'}/>
            <Typography variant={'subtitle2'}>Error</Typography>{' '}
          </Stack>
        }

        if (status[DSStatuses.FILE_MISSED].length > 0) {
          return (
            <Stack direction="row" spacing={1} sx={{alignItems: 'center'}}>
              <ErrorOutline color={'warning'}/>
              <Typography variant={'subtitle2'}>Missing file</Typography>
            </Stack>
          );
        }

        if (status[DSStatuses.READY].length === files.length) {
          return (
            <Stack direction="row" spacing={1} sx={{alignItems: 'center'}}>
              <CheckCircleOutline color={'success'}/>
              <Typography variant={'subtitle2'}>Ready</Typography>{' '}
            </Stack>
          );
        }

        if (status[DSStatuses.PROGRESS].length > 0) {
          return (
            <Stack direction="row" spacing={1} sx={{alignItems: 'center'}}>
              <LinearProgress sx={{minWidth: 100}} variant="determinate" value={params.row.progress * 100}/>
            </Stack>
          );
        }

        if (status[DSStatuses.PENDING].length === files.length) {
          return <Stack direction="row" spacing={1} sx={{alignItems: 'center'}}>
            <QueryBuilderOutlined/>
            <Typography variant={'subtitle2'}>Queued</Typography>
          </Stack>
        }
        return  (<Stack direction="row" spacing={1} sx={{alignItems: 'center'}}>
          <LinearProgress sx={{minWidth: 100}} variant="determinate" value={params.row.progress * 100}/>
        </Stack>)
      },
    },
    {
      field: 'actions',
      headerName: '',
      width: 50,
      align: 'center',
      renderCell: (params: GridRenderCellParams<string>): JSX.Element | null => {
        const status: DatasetStatusSummary = params.row.status
        const dispatch = useDispatch();
        if (status[DSStatuses.PROGRESS].length > 0 && status[DSStatuses.FILE_MISSED].length === 0) {
          return <div/>
        }

        return (
          <IconButton onMouseDown={(e) => {
            e.preventDefault();
            dispatch(removeDataset(params.row.dataset.id))
            dispatch(uploadManagerSaveState())
          }}>
            <Clear/>
          </IconButton>
        )
      },
    },
  ];
  return (

    <
      Stack
      sx={{pt: 1, pb: 1, flex: 1, mr: 3, position: 'relative', width: "100%"}}
      direction={"row"}>
      <Stack
        sx={{flex: 1}}>
        {
          fileList && fileList.length ? (
            <DataGrid
              sx={{
                mr: 3,
                border: '5px',
                '.MuiDataGrid-root': {border: '#f00 5px'},
              }}
              hideFooter
              sortingMode="server"
              rows={filesGroupedByDataset}
              columns={datasetColumns}
              disableColumnFilter
              getRowId={row => row.dataset.id}
              keepNonExistentRowsSelected
              selectionModel={selectedDatasetDGModel}
              onSelectionModelChange={(selectionModel, details) => {
                if (selectionModel[0]) {
                  dispatch(push(`${location.pathname}?selection=${selectionModel[0]}`))
                } else {
                  dispatch(push(`${location.pathname}`))
                }
              }}
              rowsPerPageOptions={[50]}
              density={'standard'}
            />
          ) : <Typography sx={{m: 3}}>Nothing here</Typography>
        }
      </Stack>

      <Stack sx={{flex: 1}}>
        {currentFiles && currentFiles.length ?
        <DataGrid
          sx={{
            mr: 3,
            border: '5px',
            '.MuiDataGrid-root': {border: '#f00 5px'},
          }}
          apiRef={gridApiRef}
          hideFooter
          sortingMode="server"
          rows={currentFiles}
          columns={filesColumns}
          disableColumnFilter
          getRowId={row => row.fileId}
          disableSelectionOnClick
          keepNonExistentRowsSelected
          rowsPerPageOptions={[50]}
          density={'standard'}
        /> : <Typography>Select dataset to see file list</Typography>}
      </Stack>
    </Stack>
  );
}
