import {Button, Stack, TextField} from '@mui/material';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {CSV_ROW_ID_FIELD} from "../../csvViewer/tableUtils";
import {uApi} from "../../biostrandApi/uApi";
import {LoadingButton} from "@mui/lab";
import {enqueueSnackbarNotification} from "../../snackbarNotification/snackbarNotificationSlice";
import CopyDetailsNotificationButton from "../../snackbarNotification/CopyDetailsNotificationButton";
import SendDetailsNotificationButton from "../../snackbarNotification/SendDetailsNotificationButton";
import CloseNotificationButton from "../../snackbarNotification/CloseNotificationButton";
import {useDispatch} from "react-redux";
import {sleep} from "../../utilites/sleep";
import {DebouncedTextInput} from "../../debouncedInput/DebouncedTextInput";

interface ExportAsDatasetPopupProps {
  gridRef: any;
  onClose: () => void;
  defaultFileName: string;
  defaultDatasetName: string;
  originalFilePath: string;
  datasetId: string;
}

export const ExportAsDatasetPopup = (props: ExportAsDatasetPopupProps): JSX.Element => {
  const {gridRef, onClose, defaultFileName, originalFilePath, datasetId, defaultDatasetName} = props;
  const [t] = useTranslation();
  const [datasetName, setDatasetName] = useState(defaultDatasetName);
  const [fileName, setFileName] = useState(defaultFileName);
  const [isSaving, setIsSaving] = useState(false);
  const [isValidating, setIsValidating] =  useState(false);
  const [fError, setFError] =  useState<undefined | string>(undefined);

  const dispatch = useDispatch();

  useEffect(() => {
    if (datasetName) {
      const validate = async () => {
        setIsValidating(true);
        const response = await uApi.getDatasetManagerApi().datasetManagerCheckName(datasetName);
        if (response.data?.exist) {
          setFError(`Dataset with name "${datasetName}" already exist`);
        } else {
          setFError(undefined);
        }
        setIsValidating(false);
      }
      validate();
    } else {
      setIsValidating(false);
      setFError(undefined);
    }

  }, [datasetName])

  const onExport = async () => {
    let fn = fileName && fileName.length ? fileName : defaultFileName
    if (fn.lastIndexOf('.csv') !== fn.length - 4) {
      fn = fn + '.csv';
    }
    setIsSaving(true)
    const rows = gridRef.current.getSelectedRows();
    // the row Id contains the original index of the row.
    // It's much simpler to track it like that that lookup the row in the original data
    const rowsIndecies = [...rows].map(([, value]) => Number(value[CSV_ROW_ID_FIELD].split('#')[1]));

    try {
      await uApi.getDatasetManagerApi().datasetManagerSaveSelectedRows({
        selected_row_indices: rowsIndecies,
        source_file: originalFilePath,
        target_dataset_name: datasetName,
        target_file_name: fn,
        source_dataset: {
          id: datasetId,
        }
      })
      dispatch(enqueueSnackbarNotification({
        message: `Dataset ${datasetName} is created`,
        key: 'success-key',
        options: {
          variant: "success",
          persist: false,
          action: (key) => (<CloseNotificationButton notificationKey={key}>Close</CloseNotificationButton>)
        }
      }));
    } catch (e) {
      const details = e.message ? e.message : null;
      dispatch(enqueueSnackbarNotification({
        message: "Something went wrong while export",
        key: 'export-error',
        options: {
          variant: "error",
          persist: true,
          action: (key) => (
            <>
              {details ? <CopyDetailsNotificationButton description={details}/> : null}
              {details ? <SendDetailsNotificationButton description={details}/> : null}
              <CloseNotificationButton notificationKey={key}>Close</CloseNotificationButton>
            </>
          )
        }
      }))
    } finally {
      setIsSaving(false)
    }
    await sleep(2000);
    onClose();
  };

  return (
    <Stack sx={{minWidth: 400}} spacing={2}>
      <Stack direction={'column'} spacing={1}>
        <DebouncedTextInput
          label="Dataset Name"
          initialValue={datasetName}
          error={fError}
          helperText={fError}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setDatasetName(event.target.value);
          }}
          size={'small'}
          variant='outlined'
          isValidating={isValidating}
          placeholder={"Dataset Name"}
        />
      </Stack>
      <TextField
        id="filled-basic"
        label="File name"
        variant="outlined"
        value={fileName}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setFileName(event.target.value);
        }}
      />
      <Stack direction={'row'} spacing={2} sx={{justifyContent: 'flex-end', mt: 2}}>
        <LoadingButton variant={'outlined'}
                       loading={isSaving}
                       disabled={!!fError || !!isValidating}
                       onClick={async () => {
                         await onExport();
                       }}>
          {t('Export')}
        </LoadingButton>
        <Button variant={'outlined'} onClick={() => onClose()}>
          {t('Cancel')}
        </Button>
      </Stack>
    </Stack>
  );
};
