import {Divider, Menu, MenuProps} from '@mui/material';
import * as React from 'react';
import {MenuItemDescription, MenuItemType} from "./menuTypes";
import {RichMenuItem} from "./RichMenuItem";

export interface BSMenuProps extends MenuProps {
  items: MenuItemDescription[],
}

export const BSMenu = (props: BSMenuProps): JSX.Element => {
  const {items, onClose, ...rest} = props;
  return (
    <Menu
      {...rest}
      onClose={onClose}
    >
      {items.map((item, index) => {
        switch (item.itemType) {
          case MenuItemType.RICH:
            return <RichMenuItem {...item} onSelect={() => {
              onClose && onClose();
              item.onSelect && item.onSelect();
            }} key={item.title}/>
          case MenuItemType.DIVIDER:
            return <Divider key={`divider-${index}`}/>
        }
        return <div key={props.title}/>;
      })}
    </Menu>
  );
};
