import {faSearch, faTimes} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, IconButton, InputAdornment, TextField} from '@mui/material';
import debounce from 'lodash.debounce';
import * as React from 'react';
import {useEffect, useMemo, useState} from 'react';
import {SxProps, Theme} from "@mui/system";

interface SearchInputProps {
  onChange?: (value: string) => void;
  initialValue?: string;
  ariaLabel?: string;
  helperText?: string;
  hideClear?: boolean;
  sx?: SxProps<Theme>;
  placeholder?: string;
  useClearIcon?: boolean;
}

function RawSearchInput(props: SearchInputProps) {
  const {onChange, sx, hideClear, placeholder = 'Search', useClearIcon = 'true', initialValue} = props;
  const [value, setValue] = useState(initialValue || '');

  useEffect(() => {
    setValue(initialValue || '')
  }, [initialValue])

  const setText = (text: string) => {
    setValue(text);
    onChange && onChange(text);
  }

  const changeHandler = (event: { target: { value: any; }; }) => {
    setText(event.target.value);
  };

  return (
    <TextField
      sx={{...sx, borderRadius: 16, border: '1px solid #dddddd'}}
      id="search-input"
      placeholder={placeholder}
      value={value}
      onChange={changeHandler}
      InputProps={{
        disableUnderline: true,
        startAdornment: (
          <InputAdornment position="start" sx={{ml: 1, mr: 2}}>
            <FontAwesomeIcon icon={faSearch}/>
          </InputAdornment>
        ),
        endAdornment: (value && !hideClear) ? (
          <InputAdornment position="start">
            {useClearIcon ?
              (<IconButton onClick={() => setText('')} size={"small"} style={{width: 24, height: 24}}>
                <FontAwesomeIcon icon={faTimes}/>
              </IconButton>)
              : <Button onClick={() => setText('')}>Clear</Button>}
          </InputAdornment>

        ) : undefined,
      }}
      variant="standard"
    />
  );
}

export function SearchInput(props: SearchInputProps) {
  const {onChange, ariaLabel, ...rest} = props;

  const debouncedChangeHandler = useMemo(
    () => debounce((value: string) => {
      onChange && onChange(value);
    }, 300)
    , [onChange]);

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    }
  }, []);

  return <RawSearchInput ariaLabel={ariaLabel} onChange={debouncedChangeHandler} {...rest} />
}
