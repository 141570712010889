import {ArrowForward} from '@mui/icons-material';
import {LoadingButton} from '@mui/lab';
import {Button, Step, StepLabel, Stepper} from '@mui/material';
import {Box, Stack} from '@mui/system';
import {Form, Formik} from 'formik';
import {FormikHelpers} from 'formik/dist/types';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {closePopup} from "../../popup/popupsSlice";
import {UploadDatasetValues} from "../datasetTypes";
import {Errors} from "../datasetFormUtils";
import {indexPipeline, qualityCheckPipeline, uploadPipeline} from "../pipelines/PipelinesConstants";
import {uploadDatasetRequestAction} from "../../slices/datasets/datasetsSlice";
import {UploadDatasetForm} from "../UploadDatasetForm";
import {SelectPipelinesStep} from "../SelectPipelinesStep";
import {AddDatasetOverview, AddDatasetOverviewLine} from "../AddDatasetOverview";
import {SelectedFileList} from "../SelectedFileList";

interface Props {
  popupKey: string;
}

export const UploadDatasetFromLocalFilesPopupContent = (props: Props): JSX.Element => {
  const {popupKey} = props;
  const [t] = useTranslation();
  const [activeStep, setActiveStep] = React.useState(0);

  const dispatch = useDispatch();
  const steps = [t('Settings'), t('Pipelines'), t('Overview')];

  const validate = (datasetSettings: UploadDatasetValues) => {
    const {datasetName, files} = datasetSettings;
    const errors: Errors = {};
    if (!datasetName) {
      errors.datasetName = t('Dataset name is required');
    }
    if (!files || files.length === 0) {
      errors.files = t('no file selected');
    }

    if (Object.keys(errors).length > 0) return errors;
    return undefined;
  };

  const initialValues: UploadDatasetValues = {
    datasetName: '',
    files: undefined,
    pipelines: [
      {pipeline: uploadPipeline, selected: true, disabled: true},
      {pipeline: qualityCheckPipeline, selected: false, disabled: true},
      {pipeline: indexPipeline, selected: false, disabled: true},
    ],
    formConfirmed: false,
  };

  const handleSubmit = (values: UploadDatasetValues, actions: FormikHelpers<UploadDatasetValues>) => {
    dispatch(
      uploadDatasetRequestAction({
        datasetSettings: values,
        datasetRequest: {},
        //   actions: actions,
        callback: error => {
          actions.setSubmitting(false);

          if (error === 'edit') {
            setActiveStep(0);
            return;
          }
          if (!error) {
            onClose();
            return;
          }
        },
      }),
    );
  };

  const handleNextStep = () => {
    setActiveStep(activeStep + 1);
  };

  const handlePreviousStep = () => {
    setActiveStep(activeStep - 1);
  };


  const onClose = () => {
    dispatch(closePopup(popupKey));
  };

  return (
    <Stack sx={{mt: 2}}>
      <Stepper activeStep={activeStep} sx={{mb: 2}}>
        {steps.map((label, index) => {
          return (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit}>
        {({isSubmitting, touched, handleSubmit, isValid, errors, values, setFieldValue, validateForm}) => {
          return (
            <Form id="DS" autoComplete="false">
              {activeStep === 0 ? <UploadDatasetForm /> : null}
              {activeStep === 1 ? <SelectPipelinesStep/> : null}
              {activeStep === 2 ? (
                <AddDatasetOverview>
                  <>
                    <AddDatasetOverviewLine title={t('Dataset name')} value={values.datasetName}/>
                    <AddDatasetOverviewLine title={t('Files to upload')} value={`${values.files.length} files selected`}/>
                    <Stack sx={{mt:-2, pb:3}}>
                      <SelectedFileList fileList={values.files} />
                    </Stack>
                  </>
                </AddDatasetOverview>
              ) : null}

              <Stack sx={{mt: 1, mb: 3}} direction={'row'}>
                {activeStep === 0 ? (
                  <Button onClick={onClose} variant={'outlined'}>
                    {t('cancel')}{' '}
                  </Button>
                ) : null}
                {activeStep > 0 ? (
                  <Button onClick={handlePreviousStep} variant={'outlined'}>
                    {t('back')}{' '}
                  </Button>
                ) : null}
                <Box sx={{flex: 1}}/>
                {activeStep !== 2 ? (
                  <Button
                    onClick={async () => {
                      const validatedResult = await validateForm();
                      if (validatedResult.datasetName) {
                        return;
                      }
                      handleNextStep();
                    }}
                    variant={'contained'}
                    endIcon={<ArrowForward/>}
                    disabled={!touched.datasetName || !isValid}
                  >
                    {t('continue')}{' '}
                  </Button>
                ) : null}
                {activeStep === 2 ? (
                  <LoadingButton
                    sx={{mr: 2}}
                    variant={'contained'}
                    loading={isSubmitting}
                    disabled={!values.formConfirmed || isSubmitting}
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    {t('Save dataset')}
                  </LoadingButton>
                ) : null}
              </Stack>
            </Form>
          );
        }}
      </Formik>
    </Stack>
  );
};
