export enum Apps {
  RETRIEVE_AND_RELATE = 'retrieve-and-relate',
  ARTICLE_LENS = 'article-lens',
  PROJECTS = 'projects',
  MAPPER = 'mapper',
  REVIEW_APP = 'review-app',
  BULK_SEARCH = 'bulk-search',
  ALIGNER = 'aligner',
  BILLING = 'billing',
  SETTINGS = 'settings',
  JOBS = 'jobs',
  PORTAL = 'portal',
  ADMIN_PORTAL = 'admin-portal',
  GDA = 'gda',
}

export interface Application {
  id: Apps;
  name?: string;
  url?: string; /* full url of the app. the current app will redirect to this app */
  relativePath?: string; /* path to the app or page. React route will use it to open the page */
  permanent?: boolean;
}

export interface ApplicationLinksConfig {
  [key: string]: string
}