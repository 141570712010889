import {faCreditCard, faUsersCog} from '@fortawesome/pro-regular-svg-icons';
import {faHome} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {SvgIcon, SvgIconProps} from '@mui/material';
import ReviewsIcon from '@mui/icons-material/Reviews';
import * as React from 'react';

import {MapperAppIcon} from '../assets/icons/MapperAppIcon';
import {RetrieveAndRelateIcon} from '../assets/icons/RetrieveAndRelateIcon';
import {Apps} from './applicationTypes';
import {GeneAnalysisIcon} from "../assets/icons/GeneAnalysisIcon";
import {ArticleLensIcon} from "../assets/icons/ArticleLensIcon";
import {ProjectsIcon} from "../assets/icons/ProjectsIcon";
import {VideoSettings} from "@mui/icons-material";

type Props = SvgIconProps & { appId: Apps };

export const ApplicationIcon = (props: Props) => {
  const { appId, ...iconProps } = props;
  const Icon = getApplicationIcon(appId);
  return Icon ? <Icon {...iconProps} /> : null;
};

export const DashboardIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <FontAwesomeIcon icon={faHome} />
    </SvgIcon>
  );
};

export const SettingsIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <FontAwesomeIcon icon={faUsersCog} />
    </SvgIcon>
  );
};

export const BillingIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <FontAwesomeIcon icon={faCreditCard} />
    </SvgIcon>
  );
};

export const JobsIcon = (props: SvgIconProps) => {
  return (
    <VideoSettings {...props} />
  );
};

export const ReviewAppIcon = (props: SvgIconProps) => {
  return <ReviewsIcon {...props} />;
};

export const getApplicationIcon = (appId: Apps) => {
  switch (appId) {
    case Apps.PORTAL:
      return DashboardIcon;

    case Apps.PROJECTS:
      return ProjectsIcon;
    case Apps.RETRIEVE_AND_RELATE:
      return RetrieveAndRelateIcon;
    case Apps.MAPPER:
      return MapperAppIcon;
    case Apps.GDA:
      return GeneAnalysisIcon;
    case Apps.REVIEW_APP:
      return ReviewAppIcon;
    case Apps.SETTINGS:
      return SettingsIcon;
    case Apps.BILLING:
      return BillingIcon;
    case Apps.ARTICLE_LENS:
      return ArticleLensIcon;
    case Apps.JOBS:
      return JobsIcon;

    case Apps.ALIGNER:
    default:
      return null;
  }
};
