import { Box, FormControl, Stack } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-mui';
import * as React from 'react';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateS3DatasetValues } from './datasetTypes';
import { TestS3Connection } from './s3/TestS3Connection';

interface FormProps {}
export function CreateS3DatasetForm(props: FormProps): ReactElement {
  const [t] = useTranslation();

  const { values } = useFormikContext<CreateS3DatasetValues>();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', pt: 2, minWidth: 720 }}>
      <Stack direction="column">
        <FormControl margin={'dense'} sx={{ minWidth: 330, height: theme => theme.spacing(9) }}>
          <Field
            id="dataSetName"
            label={t('Data set name')}
            variant="outlined"
            required
            component={TextField}
            name="datasetName"
          />
        </FormControl>

        <Stack direction="row" sx={{ alignItems: 'center' }}>
          <FormControl margin={'dense'} sx={{ height: theme => theme.spacing(9), flex: 1 }}>
            <Field
              component={TextField}
              name={`connectionConfig.path`}
              label={t('S3 path')}
              variant="outlined"
              required
            />
          </FormControl>

          <TestS3Connection config={values.connectionConfig} sx={{ width: theme => theme.spacing(20), mb: 1, ml: 2 }} />
        </Stack>

        <Stack direction="row" sx={{ alignItems: 'center' }}>
          <FormControl margin={'dense'} sx={{ height: theme => theme.spacing(9), flex: 1, mr: 2 }}>
            <Field
              component={TextField}
              name={`connectionConfig.access_key_id`}
              label={t('Access key')}
              variant="outlined"
            />
          </FormControl>
          <FormControl margin={'dense'} sx={{ height: theme => theme.spacing(9), flex: 1 }}>
            <Field
              component={TextField}
              name={`connectionConfig.secret_access_key`}
              label={t('Secret access key')}
              variant="outlined"
              type="password"
            />
          </FormControl>
        </Stack>

        <Stack direction="row" sx={{ alignItems: 'center' }}>
          <FormControl margin={'dense'} sx={{ height: theme => theme.spacing(9), flex: 1 }}>
            <Field
              component={TextField}
              name={`connectionConfig.s3_region`}
              label={t('S3 region')}
              variant="outlined"
              required
            />
          </FormControl>
        </Stack>
      </Stack>
    </Box>
  );
}
