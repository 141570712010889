import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {GroupIdMap, GroupingItemNode, MapItem, PMap, RelatedItems, RelatedItemsMap} from "../groupingTypes";
import {MetadataManagerGroupingTypeNode} from "@biostrand/biostrandapi/javascript/dist/GroupingApi";
import {mapGroupIds} from "../utils/groupUtils";

interface GroupState {
    groupTypesTree?: MetadataManagerGroupingTypeNode;
    rootGroup?: GroupingItemNode;
    groupsById?: GroupIdMap;
    isGroupsLoading?: boolean;
    isRelatedItemsLoading: PMap<boolean>;
    relatedDatasets: RelatedItemsMap;
    relatedJobRuns: RelatedItemsMap;
    isGroupTypesReady: boolean | undefined;
}

export enum GroupsActionTypes {
    REFRESH_GROUPS = 'groups.REFRESH_GROUPS',
    REFRESH_GROUP_TYPES = 'groups.REFRESH_GROUP_TYPES',
    REFRESH_GROUP_RELATED_ITEMS = 'groups.REFRESH_GROUP_RELATED_ITEMS',

    START_RUN_JOB_FLOW_ACTION = 'groups.START_RUN_JOB_FLOW_ACTION',
    START_LINK_JOB_FLOW_ACTION = 'groups.START_LINK_JOB_FLOW_ACTION',
    JOB_RUN_TO_LINK_SELECTED_ACTION = 'groups.JOB_RUN_TO_LINK_SELECTED_ACTION',

    DS_FTP = 'groups.flows.DS_FTP',
    DS_HTTP = 'groups.flows.DS_HTTP',
    DS_UPLOAD = 'groups.flows.DS_UPLOAD',
    DS_S3 = 'groups.flows.DS_S3',


    START_LINK_DATASET_FLOW_ACTION = 'groups.START_LINK_DATASET_FLOW_ACTION',
    DATASET_TO_LINK_SELECTED_ACTION = 'groups.DATASET_TO_LINK_SELECTED_ACTION'
}

const initialState: GroupState = {
    isRelatedItemsLoading: {},
    groupsById: {},
    relatedDatasets: {},
    relatedJobRuns: {},
    isGroupTypesReady: undefined
};

export const groupsSlice = createSlice({
    name: 'groups',
    initialState,
    reducers: {

        updateGroupsLoading: (state, action: PayloadAction<boolean>) => {
            state.isGroupsLoading = action.payload;
        },
        // Use the PayloadAction type to declare the contents of `action.payload`
        updateGroupTypeTree: (state, action: PayloadAction<MetadataManagerGroupingTypeNode>) => {
            state.groupTypesTree = action.payload;
        },
        updateRootGroups: (state, action: PayloadAction<GroupingItemNode>) => {
            state.rootGroup = action.payload;
            state.groupsById = mapGroupIds( action.payload );
        },
        updateRelatedDatasets: (state, action:PayloadAction<RelatedItems>) => {
            const {key, item} = action.payload
            state.relatedDatasets[key] = item;
        },
        updateIsGroupTypesReady: (state, action:PayloadAction<boolean>) => {
            state.isGroupTypesReady = action.payload;
        },
        updateRelatedJobRuns: (state, action:PayloadAction<RelatedItems>) => {
            const {key, item} = action.payload
            state.relatedJobRuns[key] = item;
        },
        updateRelatedItemsLoading: (state, action:PayloadAction<MapItem<boolean>>) => {
            const {key, item} = action.payload;
            state.isRelatedItemsLoading[key] = item;
        }
    },
});

export const refreshGroupsAction = createAction(GroupsActionTypes.REFRESH_GROUPS);
export const refreshGroupTypesAction = createAction(GroupsActionTypes.REFRESH_GROUP_TYPES);
export const refreshGroupRelatedItems = createAction<GroupingItemNode>(GroupsActionTypes.REFRESH_GROUP_RELATED_ITEMS);

export const startRunJobFlowAction = createAction<string>(GroupsActionTypes.START_RUN_JOB_FLOW_ACTION);

export const startLinkJobFlowAction = createAction<string>(GroupsActionTypes.START_LINK_JOB_FLOW_ACTION);
export const jobRunSelectedAction = createAction<string>(GroupsActionTypes.JOB_RUN_TO_LINK_SELECTED_ACTION);

export const startLinkDatasetFlowAction = createAction<string>(GroupsActionTypes.START_LINK_DATASET_FLOW_ACTION);
export const datasetSelectedAction = createAction<string>(GroupsActionTypes.DATASET_TO_LINK_SELECTED_ACTION);

export const addDsFtpFlowAction = createAction<string>(GroupsActionTypes.DS_FTP);
export const addDsHttpFlowAction = createAction<string>(GroupsActionTypes.DS_HTTP);
export const addDsUploadFlowAction = createAction<string>(GroupsActionTypes.DS_UPLOAD);
export const addDsS3FlowAction = createAction<string>(GroupsActionTypes.DS_S3);

export const {
    updateGroupTypeTree,
    updateGroupsLoading,
    updateRootGroups,
    updateRelatedDatasets,
    updateRelatedJobRuns,
    updateRelatedItemsLoading,
    updateIsGroupTypesReady
} = groupsSlice.actions;

export default groupsSlice.reducer;
