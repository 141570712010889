import { LinearProgress } from '@mui/material';
import { SxProps } from '@mui/system';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { getFileProgressSelector } from '../../slices/datasets/fileUploadSelectors';
import { AppWithFileUploadManagerState, FileUploadInfo } from '../../slices/datasets/fileUploadTypes';

interface Props {
  fileInfo: FileUploadInfo;
  sx?: SxProps;
}

export const FileProgressCell = (props: Props): JSX.Element => {
  const { fileInfo, sx } = props;
  const progress: number = useSelector((state: AppWithFileUploadManagerState) => {
    return getFileProgressSelector(state, fileInfo.fileId);
  });

  return <LinearProgress variant="determinate" value={progress * 100} sx={{ ...sx }} />;
};
