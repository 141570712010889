import { createSlice,PayloadAction } from '@reduxjs/toolkit';
import { OptionsObject,SnackbarKey,SnackbarMessage } from "notistack";

export interface AppWithNotificationState {
    snackbarNotifications: SnackBarsState
}

export interface SnackbarNotification {
    message: SnackbarMessage,
    options?: OptionsObject
    key: SnackbarKey,
    dismissed?: boolean
}

export type SnackBarsState = SnackbarNotification[];

export const snackbarNotificationSlice = createSlice({
         name: 'snackbarNotification',
         initialState: [] as SnackBarsState,
         reducers: {
           enqueueSnackbarNotification: (state, action: PayloadAction<SnackbarNotification>) => {
             // @ts-ignore
               state.push(action.payload);
           },
           closeSnackbarNotification: (state, action: PayloadAction<SnackbarKey>) => {
             const key = action.payload;
             state.forEach(notification => {
               if (notification.key === key) notification.dismissed = true;
             });
           },
           closeAllSnackbarNotification: state => {
             state.forEach(notification => {
               notification.dismissed = true;
             });
           },
           removeSnackbarNotification: (state, action: PayloadAction<SnackbarKey>) => {
             const key = action.payload;
             return state.filter(notification => notification.key !== key);
           },
         },
       });

export const { enqueueSnackbarNotification, closeSnackbarNotification, closeAllSnackbarNotification, removeSnackbarNotification} = snackbarNotificationSlice.actions;
export default snackbarNotificationSlice.reducer;

export const snackbarNotificationSelector = (state: AppWithNotificationState) => state.snackbarNotifications || []