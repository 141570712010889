import {useEffect, useState} from "react";
import {DatasetManagerDatasetItem} from "@biostrand/biostrandapi/javascript/dist/DatasetManagerApi";
import {uApi} from "../biostrandApi/uApi";

export const useDatasetFiles = (datasetId: string): [(DatasetManagerDatasetItem[] | undefined), boolean, (string | undefined)] => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>();
    const [dsFiles, setDsFiles] = useState<DatasetManagerDatasetItem[] | undefined>([]);

    useEffect(() => {
        if (!datasetId) return;
        const runQ = async () => {
            try {
                setIsLoading(true);
                setError(undefined);
                const result = await uApi.getDatasetManagerApi().datasetManagerGetDatasetFiles(datasetId, 10000);
                setDsFiles(result?.data?.data);
                setIsLoading(false);

            } catch (e) {
                setIsLoading(false);
                setError('file fetch error');

            }
        };
        runQ();
    }, [datasetId]);



    return [dsFiles, isLoading, error];
}