import {IconButton} from '@mui/material';
import {Stack} from '@mui/system';
import * as React from 'react';
import {ReactElement} from 'react';

import {useTranslation} from 'react-i18next';
import {formatFileSize} from "./fileupload/formatFileSize";
import {DataGrid, GridRenderCellParams} from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";

interface SelectedFileListProps {
  fileList?: Array<File>;
  onDeleteItems?: (files: Array<File>) => void
}

export function SelectedFileList(props: SelectedFileListProps): ReactElement {
  const {fileList, onDeleteItems} = props;
  const [t] = useTranslation();

  const deleteFromList = (files: Array<File>) => {
    onDeleteItems && onDeleteItems(files);
  }

  const columns = [

    {
      field: "name",
      headerName: 'Name',
      flex: 1,
      minWidth: 170,
    },
    {
      field: "path",
      headerName: 'Path',
      flex: 1,
      minWidth: 100,

      valueGetter: (params) => {
        const file: File = params.row as File;
        const pathParts = file.path ? file.path.split('/') : file.webkitRelativePath.split('/');
        pathParts.pop();
        return pathParts.join('/') || '/';
      },
    },
    {
      field: "size",
      headerName: 'Size',
      minWidth: 100,
      type: 'number',
      valueGetter: (params) => {
        const file: File = params.row as File;
        return file.size;
      },
      valueFormatter: (params) => {
        if (params.value == null) {
          return '';
        }
        return formatFileSize(params.value);
      },
    }
  ]

  if (onDeleteItems) {
    columns.push({
      field: 'delete_cel',
      headerName: '',
      width: 50,
      renderCell: (params: GridRenderCellParams<string>) => {
        return (
          <IconButton size={"small"}
                      onClick={() => {
                        deleteFromList([params.row]);
                      }}>
            <DeleteIcon/>
          </IconButton>
        );
      }
    })
  }

  return (
    <Stack sx={{minWidth: 720, minHeight: 300}}>
      {fileList ?
        <DataGrid
          sx={{
            border: '5px'
          }}
          hideFooter
          sortingMode='client'
          rows={fileList}
          getRowId={(row: File) => row.name + '-' + (row.path || row.webkitRelativePath)}
          getRowHeight={() => 'auto'}
          columns={columns}
          disableColumnMenu
          disableColumnFilter
          disableSelectionOnClick
          density={"standard"}
        /> :
        <Stack>Nothing here</Stack>
      }
    </Stack>
  );
}
