import {PipelinesListItem} from "./pipelines/pipelineTypes";


export enum DatasetAction {
    DELETE = 'delete',
    RUN_PIPELINE = 'run_pipeline',
    EDIT = 'edit',
    DOWNLOAD_DATASET = 'download_dataset'
}

export interface DatasetMenuAction {
    name: string,
    action: DatasetAction
}

export interface BasicCreateDatasetValues {
    datasetName: string;
    pipelines: PipelinesListItem<string | number>[];
    formConfirmed: boolean;
}

export interface FtpSettings {
    host?: string;
    user?: string;
    pass?: string;
    path?: string;
    filter?: '*';
}

export interface S3Settings {
    s3_region?: string;
    access_key_id?: string;
    secret_access_key?: string;
    path?: string;
}

export type UploadDatasetValues = BasicCreateDatasetValues & {
    files?: FileList;
}

export type CreateFtpDatasetValues  = BasicCreateDatasetValues & {
    connectionConfig: FtpSettings;
}

export type CreateHttpDatasetValues  = BasicCreateDatasetValues & {
    sourceLinks?: string[]
}

export type CreateS3DatasetValues  = BasicCreateDatasetValues & {
    connectionConfig: S3Settings;
}