import {Button, TextField, Theme, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {useFormik} from 'formik';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {Link as RouterLink} from 'react-router-dom';
import * as yup from 'yup';
import {createOrganizationRequestAction} from '../../../core/organization/organizationActions';
import {OrganizationRole} from '../../../core/organization/organizationTypes';
import {RouteUrl} from '../../../routeUrl';
import {LoadingButton} from "@mui/lab";
import {sleep} from "@biostrand/components/src/utilites/sleep";
import {UserManagerCreateOrganizationRequest} from "@biostrand/biostrandapi/javascript/dist/UserManagerApi";

const useStyles = makeStyles((theme: Theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: theme.spacing(12),
        height: 368,
    },
    buttons: {
        display: 'flex',
        flexDirection: 'row',
    },
    input: {
        width: 320,
        height: theme.spacing(9),
        marginBottom: theme.spacing(3),
    },
    buttonMargin: {
        marginRight: theme.spacing(3),
    },
    title: {
        marginBottom: theme.spacing(1),
    },
    subTitle: {
        marginBottom: theme.spacing(4),
    },
}));

const RegisterOrganizationFormComponent = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [t] = useTranslation();
    const validationSchema = yup.object({
        email: yup
            .string()
            .email(t('Enter a valid email'))
            .defined(t('Email is required')),
        organization: yup.string().required(t('Organization is required')),
    });

    const registerForm = useFormik({
        initialValues: {
            email: '',
            organization: '',
        },
        validationSchema: validationSchema,
        onSubmit: async values => {

            const request: UserManagerCreateOrganizationRequest = {
                email: values.email,
                organization_name: values.organization,
                organization_role: OrganizationRole.ADMIN,
            };
            dispatch(createOrganizationRequestAction(request));
            await sleep(5000);
        },
    });

    return (
        <form className={classes.form} onSubmit={registerForm.handleSubmit}>
            <div>
                <Typography variant='h4' className={classes.title}>
                    {t('Register your organization')}
                </Typography>
                <Typography variant='body1' className={classes.subTitle}>
                    {t('Explore the tool and access all our features')}
                </Typography>
            </div>

            <TextField
                className={classes.input}
                id='email'
                label={t('Email')}
                variant='outlined'
                name='email'
                value={registerForm.values.email}
                onChange={registerForm.handleChange}
                error={registerForm.touched.email && Boolean(registerForm.errors.email)}
                helperText={registerForm.touched.email && registerForm.errors.email}
            />

            <TextField
                className={classes.input}
                id='organization'
                label={t('Organization')}
                variant='outlined'
                name='organization'
                value={registerForm.values.organization}
                onChange={registerForm.handleChange}
                error={registerForm.touched.email && Boolean(registerForm.errors.organization)}
                helperText={registerForm.touched.email && registerForm.errors.organization}
            />

            <div className={classes.buttons}>
                <LoadingButton
                    loading={registerForm.isSubmitting}
                    variant='contained'
                    color='primary'
                    size='large'
                    className={classes.buttonMargin}
                    onClick={() => {
                        registerForm.handleSubmit();
                    }}>
                    {t('Sign up')}
                </LoadingButton>
                <Button
                    sx={{textTransform: "none"}}
                    component={RouterLink}
                    color='primary'
                    size='large'
                    to={RouteUrl.Login}
                    className={classes.buttonMargin}>
                    {t('Back to login')}
                </Button>
            </div>
        </form>
    );
};

const RegisterOrganizationForm = RegisterOrganizationFormComponent;

export default RegisterOrganizationForm;
