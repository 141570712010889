import {AuthRoute, NotFoundPage} from '@biostrand/components';
import AuthenticatedRedirect from '@biostrand/components/src/routing/AuthenticatedRedirect';
import * as React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import PortalPage from './pages/private/portal/PortalPage';
import BillingSettingsPage from './pages/private/settings/billing/BillingSettingsPage';
import DatasetsSettingsPage from './pages/private/settings/datasets/DatasetsSettingsPage';
import OrganizationSettingsPage from './pages/private/settings/organization/OrganizationSettingsPage';
import UserProfileSettingsPage from './pages/private/settings/userProfile/UserProfileSettingsPage';
import UsersSettingsPage from './pages/private/settings/users/UsersSettingsPage';
import ConfirmPasswordPage from './pages/public/registration/ConfirmPasswordPage';
import ForgotPasswordPage from './pages/public/registration/ForgotPasswordPage';
import LoginPage from './pages/public/registration/LoginPage';
import PendingChangePasswordPage from './pages/public/registration/PendingChangePasswordPage';
import PendingRegisterOrganizationPage from './pages/public/registration/PendingRegisterOrganizationPage';
import RegisterOrganizationPage from './pages/public/registration/RegisterOrganizationPage';
import {RouteUrl} from './routeUrl';
import JobsSettingsPage from "./pages/private/settings/jobs/JobsSettingsPage";
import {isFeatureSupported, PortalRuntimeFeatures} from "./portalRuntimeConfig";
import DatasetDetailsSettingsPage from "./pages/private/settings/datasets/DatasetDetailsSettingsPage";

const Routes: React.FunctionComponent = () => (
    <Switch>
        <AuthenticatedRedirect exact path={RouteUrl.Login}>
            <LoginPage />
        </AuthenticatedRedirect>
        <AuthenticatedRedirect exact path={RouteUrl.RegisterOrganization}>
            <RegisterOrganizationPage />
        </AuthenticatedRedirect>

        <AuthenticatedRedirect exact path={RouteUrl.ForgotPassword}>
            <ForgotPasswordPage />
        </AuthenticatedRedirect>
        <AuthenticatedRedirect exact path={RouteUrl.PendingChangePasswordPage}>
            <PendingChangePasswordPage />
        </AuthenticatedRedirect>

        <AuthenticatedRedirect exact path={RouteUrl.ConfirmationUnderway}>
            <PendingRegisterOrganizationPage />
        </AuthenticatedRedirect>
        <AuthenticatedRedirect exact path={RouteUrl.ConfirmPassword}>
            <ConfirmPasswordPage />
        </AuthenticatedRedirect>
        {/**
            TODO: create settings route and move these routes inside the settings page.
         */}
        <AuthRoute exact path={RouteUrl.Root}>
            <PortalPage />
        </AuthRoute>
        <AuthRoute exact path={RouteUrl.Settings}>
            <Redirect to={RouteUrl.PersonalSettings} />
        </AuthRoute>
        <AuthRoute exact path={RouteUrl.PersonalSettings}>
            <UserProfileSettingsPage />
        </AuthRoute>
        <AuthRoute exact path={RouteUrl.OrganizationSettings} onlyAdmin>
            <OrganizationSettingsPage />
        </AuthRoute>
        <AuthRoute exact path={RouteUrl.BillingSettings} onlyAdmin>
            <BillingSettingsPage />
        </AuthRoute>
        <AuthRoute exact path={RouteUrl.UsersSettings} onlyAdmin>
            <UsersSettingsPage />
        </AuthRoute>
        <AuthRoute exact path={RouteUrl.DataSetsSettings}>
            <DatasetsSettingsPage />
        </AuthRoute>
        <AuthRoute exact path={RouteUrl.DataSetsLocalFileUploadManager}>
            <DatasetsSettingsPage />
        </AuthRoute>

        <AuthRoute exact path={RouteUrl.DatasetDetailsSettings}>
            <DatasetDetailsSettingsPage />
        </AuthRoute>
        {isFeatureSupported(PortalRuntimeFeatures.JOBS) ?
            <AuthRoute path={RouteUrl.JobsSettings}>
                <JobsSettingsPage />
            </AuthRoute> : null
        }


        <Route component={(): JSX.Element => <NotFoundPage />} />
    </Switch>
);

export default Routes;
