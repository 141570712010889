import { faPlus, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, FormControl, IconButton, TextField as MuiTextField, Typography } from '@mui/material';
import { Field, FieldArray, useFormikContext } from 'formik';
import { TextField } from 'formik-mui';
import * as React from 'react';
import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CBox } from '../layout/CBox';
import { RBox } from '../layout/RBox';
import { CreateHttpDatasetValues } from './datasetTypes';

interface LinkItemProps {
  onDelete: () => void;
  link: string;
  hideLine?: boolean;
}

const LinkItem = (props: LinkItemProps): ReactElement => {
  const { link, onDelete, hideLine } = props;
  return (
    <RBox  sx={{ height: theme => theme.spacing(6), minHeight: theme => theme.spacing(6), alignItems: 'center', borderBottom: hideLine ? null : 'solid 1px #ddd' }}>
      <Typography sx={{flex:1, mr:2}}>{link}</Typography>
      <IconButton aria-label="delete">
        <FontAwesomeIcon icon={faTrash} onClick={onDelete} style={{fontSize: 16 }} />
      </IconButton>
    </RBox>
  );
};

interface FormProps {}
export function CreateHttpDatasetForm(props: FormProps): ReactElement {
  const [t] = useTranslation();
  const { values } = useFormikContext<CreateHttpDatasetValues>();
  const [link, setLink] = useState<string | undefined>();

  const handleLinkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLink(event.target.value);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', pt: 2, minWidth: 720 }}>
      <CBox>
        <FormControl margin={'dense'} sx={{ minWidth: 330, height: theme => theme.spacing(9) }}>
          <Field
            id="dataSetName"
            label={t('Data set name')}
            variant="outlined"
            required
            component={TextField}
            name="datasetName"
          />
        </FormControl>
        <FieldArray
          name="sourceLinks"
          render={arrayHelpers => (
            <>
              <RBox sx={{ pt: 2, alignItems: 'center' }}>
                <MuiTextField
                  value={link}
                  onChange={handleLinkChange}
                  sx={{ flex: 1,  maxWidth: 800 }}
                  variant={'outlined'}
                  label={t('HTTP address')}
                />
                <Button
                  sx={{ minWidth: 128, mr: 2, ml: 2 }}
                  disabled={!link}
                  onClick={() => {
                    arrayHelpers.insert(0, link);
                    setLink('');
                  }}
                  startIcon={<FontAwesomeIcon style={{ marginLeft: 4, marginRight: 8, fontSize: 16 }} icon={faPlus} />}
                >
                  {t('Add link')}
                </Button>
              </RBox>
              {values.sourceLinks && values.sourceLinks.length > 0 ? (
                <CBox sx={{ height: 170,  maxWidth: 850, overflowY: 'auto', mt: 2, mb:2 }}> {
                  values.sourceLinks.map((sourceLink, index) => (
                    <LinkItem
                      key={sourceLink + '-' + index}
                      link={sourceLink}
                      onDelete={() => {
                        arrayHelpers.remove(index);
                      }}
                      hideLine = {index === (values.sourceLinks?.length || 1)-1}
                    />
                  ))
                }</CBox>) : (
                <RBox sx={{ height: 170, alignItems: 'center', justifyContent: 'center' }}>
                  <Typography variant={'body1'}>{t('Nothing here')}</Typography>
                </RBox>
              )}
            </>
          )}
        />
      </CBox>
    </Box>
  );
}
