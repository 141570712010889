import {i18n} from '@biostrand/components';
import {ConnectedRouter} from 'connected-react-router';
import {SnackbarProvider} from 'notistack';
import * as React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import {LicenseInfo} from '@mui/x-license';
import {I18nextProvider} from 'react-i18next';
import {Provider} from 'react-redux';
import App from './App';

import {history, store} from './app/store';
import './index.css';

history.listen(location => {
    ReactGA.pageview(location.pathname + location.search);
});

LicenseInfo.setLicenseKey(window['zzzzzzmui']);

ReactGA.initialize('UA-153265706-1');

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <SnackbarProvider
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}>
                <I18nextProvider i18n={i18n}>
                    <ConnectedRouter history={history}>
                        <App />
                    </ConnectedRouter>
                </I18nextProvider>
            </SnackbarProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
);
