import {PipelineDescription, PipelineType} from "./pipelineTypes";

export const uploadPipeline: PipelineDescription = {
    type: PipelineType.UPLOAD,
    name: 'Upload data',
    description: 'Copy data to Biostrand servers to make work faster',
}

export const qualityCheckPipeline: PipelineDescription = {
    type: PipelineType.QUALITY_CHECK,
    name: 'Quality check (coming soon)',
    description: 'Check quality of the data',
}

export const indexPipeline: PipelineDescription = {
    type: PipelineType.HYFT_INDEXING,
    name: 'Index pipeline (coming soon)',
    description: '....',
}

export const PipelinesHelper = {
    [PipelineType.UPLOAD]: uploadPipeline,
    [PipelineType.QUALITY_CHECK]: qualityCheckPipeline,
    [PipelineType.HYFT_INDEXING]: indexPipeline
}

