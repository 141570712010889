import {Stack} from '@mui/material';
import * as React from 'react';
import {FastaPreview} from "../../alignmentView/FastaPreview";

interface Props {
  data: string;
  popupKey: string;
}

const AlignedSequencesPopup = (props: Props): JSX.Element => {
  const {data} = props;

  return (
    <Stack
      direction={'column'}
      spacing={2}
      sx={{flex: 1, pl: 3, pb: 1, position: 'relative'}}>
      <Stack sx={{top: 0, bottom: 0, left: 0, right: 0, position: 'absolute'}}>
        <FastaPreview data={data}/>
      </Stack>
    </Stack>
  );
};

export default AlignedSequencesPopup;
