import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {DataFilterPayload, DataFiltersMap} from "./dataFiltersTypes";

export enum DataFiltersActionTypes {
    TEST= "dfa.TEST"
}

const initialState: DataFiltersMap = {

};

export const dataFiltersSlice = createSlice({
    name: 'dataFilters',
    initialState,
    reducers: {
        updateDataFilter: (state, action: PayloadAction<DataFilterPayload>) => {
            state[action.payload.key] = action.payload.filters;
        },
        udpateDataFilterItem: (state, action: PayloadAction<DataFilterPayload>) => {
            state[action.payload.key] = action.payload.filters;
        },
    },
});

export const dataFiltersTestAction = createAction(DataFiltersActionTypes.TEST);
export const { updateDataFilter } = dataFiltersSlice.actions;

export default dataFiltersSlice.reducer;
