import {Box, Typography} from '@mui/material';
import * as React from 'react';
import {NavLink} from 'react-router-dom';
import {GroupedMenuItems} from './settingsMenuTypes';
import {Apps} from "@biostrand/components";
import {SettingsIds} from "@biostrand/components/src/applications/SettingsIds";

interface SettingsMenuProps {
    selectedItem: SettingsIds | Apps;
    menuItems: GroupedMenuItems[];
}

const SettingsMenu = (props: SettingsMenuProps): JSX.Element => {
    const {selectedItem, menuItems} = props;

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
            {menuItems.map(group => {
                return (
                    <Box
                        key={group.label}
                        sx={{display: 'flex', flexDirection: 'column', width: '100%', mt: 0, mb: 2}}>
                        <Typography
                            variant={'overline'}
                            sx={{
                                color: theme => theme.palette.grey[600],
                            }}>
                            {group.label}
                        </Typography>
                        {group.items.map(item => {
                            return (
                                <NavLink
                                    to={{pathname: item.link}}
                                    key={item.id}
                                    style={{textDecoration: 'none',}}>
                                    <Typography
                                        variant={'subtitle2'}
                                        sx={{
                                            mt: 1,
                                            mb: 1,
                                            color: theme => {
                                                return selectedItem === item.id
                                                    ? theme.palette.primary.dark
                                                    : theme.palette.grey[800];
                                            },
                                        }}>
                                        {item.label}
                                    </Typography>
                                </NavLink>
                            );
                        })}
                    </Box>
                );
            })}
        </Box>
    );
};

export default SettingsMenu;
