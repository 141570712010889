import React, { forwardRef, ReactElement, useImperativeHandle, useState } from 'react';
// @ts-ignore
import useDimensions from 'react-use-dimensions';
import styles from '../alignmentViewer.module.css';
import { AlignmentViewRenderData, ColoredRect, Highlight, NViewport } from '../alignmentViewTypes';
import { getHeatmapColor, getHeatmapGrayColor, getHeatmapHighlightColor } from '../utils/aligmentViewColorUtils';
import { getHighlightIndices, isHighlighted } from '../utils/prepareEchancedAlignmentViewRenderData';
import Heatmap from "../../heatmap/heatmap";

interface RowProps {
  renderData: AlignmentViewRenderData;
  normalizedViewport: NViewport;
  onPositionChange?: (nv: NViewport) => void;
  highlight?: Highlight;
}

// @ts-ignore
const AlignmentViewHeatmapNavigation = (props: RowProps, ref): ReactElement => {
  const { normalizedViewport, onPositionChange, renderData, highlight } = props;
  const [navRef, { width, height }] = useDimensions();
  const [viewport, setViewport] = useState(normalizedViewport);

  useImperativeHandle(ref, () => ({
    updateVP: (vp: NViewport) => {
      setViewport(vp);
    },
  }));

  const { nX, nY, nWidth, nHeight } = viewport;
  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const rect = e.currentTarget.getBoundingClientRect();

    const updatedViewport = {
      ...viewport,
      nX: Math.max(Math.min(1 - nWidth, (e.clientX - rect.left) / width - nWidth / 2), 0),
      nY: Math.max(Math.min(1 - nHeight, (e.clientY - rect.top) / height - nHeight / 2), 0),
    };

    setViewport(updatedViewport);

    if (onPositionChange) onPositionChange(updatedViewport);
  };

  const xScale = width / renderData.maxLength;
  const yScale = Math.min(height / renderData.sequenceRows.length, 5);

  const highlightColor = highlight?.exclude ? getHeatmapColor : getHeatmapHighlightColor;
  const regularColor = highlight?.exclude ? getHeatmapGrayColor : getHeatmapColor;

  const regularRects: ColoredRect[] = [];
  const highlightedRects: ColoredRect[] = [];
  renderData.sequenceRows.forEach((row, y) => {
    const test = getHighlightIndices(row.sequence, highlight);
    for (let i = 0; i < row.sequence.length; i++) {
      if (isHighlighted(i, test)) {
        highlightedRects.push({
          x: (row.offset + i) * xScale,
          y: y * yScale,
          width: Math.max(Math.ceil(xScale + 0.5), 1),
          height: yScale,
          color: highlightColor(row.residueValues ? row.residueValues[i] : undefined), // HEATMAP_SELECTION_COLOR,
        });
      } else {
        regularRects.push({
          x: (row.offset + i) * xScale,
          y: y * yScale,
          width: Math.max(Math.ceil(xScale + 0.5), 1),
          height: yScale,
          color: regularColor(row.residueValues ? row.residueValues[i] : undefined),
        });
      }
    }
  });

  // put highlighted item on top
  const rects: ColoredRect[] = regularRects.concat(regularRects, highlightedRects);

  return (
    <div className={styles.heatmapNavigation} ref={navRef} onMouseUp={handleClick}>
      <Heatmap rects={rects} width={width} height={height} />
      <div
        style={{
          position: 'absolute',
          left: Number.isNaN(renderData.searchOffset * xScale) ? 0 : renderData.searchOffset * xScale,
          top: -14,
          bottom: -6,
          borderLeftWidth: 1,
          borderLeftColor: '#dddddd',
          borderLeftStyle: 'solid',
        }}
      >
        <div style={{ fontSize: 12, paddingLeft: 2, marginTop: -2, color: '#666666' }}>0</div>
      </div>
      <div
        style={{
          position: 'absolute',
          left: Number.isNaN(nX * width - 2) ? 0 : nX * width - 2,
          top: Number.isNaN(nY * height - 2) ? 0 : nY * height - 2,
          width: Number.isNaN(width * nWidth + 4) ? 0 : width * nWidth + 4,
          height: Number.isNaN(height * nHeight + 4) ? 0 : height * nHeight + 4,
          borderWidth: 1,
          borderStyle: 'solid',
          color: '#333',
        }}
      />
      <div
        style={{
          position: 'absolute',
          left: Number.isNaN(nX * width - 1) ? 0 : nX * width - 1,
          top: Number.isNaN(nY * height - 1) ? 0 : nY * height - 1,
          width: Number.isNaN(width * nWidth + 2) ? 0 : width * nWidth + 2,
          height: Number.isNaN(height * nHeight + 2) ? 0 : height * nHeight + 2,
          borderWidth: 1,
          borderStyle: 'solid',
          color: '#aaaaaa',
        }}
      />
    </div>
  );
};

export default forwardRef(AlignmentViewHeatmapNavigation);
