import {call, takeEvery} from "redux-saga/effects";
import {LOGOUT_REQUEST} from "./authSlice";
import {logout} from "./cognitoAuthUtils";
import {RuntimeConfig} from "../../runtimeConfig/RuntimeConfig";

export function* handleLogout() {
    yield call(logout);
    window.location.href = RuntimeConfig.loginUrl();
}

export function* logoutSaga() {
    yield takeEvery(LOGOUT_REQUEST, handleLogout);
}