import {faFolder, faLink, faPlus, faServer} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from '@mui/material';
import * as React from 'react';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
    runCreateFtpDatasetFlowAction,
    runCreateHttpDatasetFlowAction,
    runCreateS3DatasetFlowAction,
    runUploadDatasetFlowAction
} from "@biostrand/components/src/slices/datasets/datasetsSlice";
import {useDispatch} from "react-redux";
import {BSMenu} from "@biostrand/components/src/menu/BSMenu";
import {MenuItemDescription, MenuItemType} from "@biostrand/components/src/menu/menuTypes";
import {faAws} from "@fortawesome/free-brands-svg-icons";


export const AddDatasetsButton = (): JSX.Element => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isMenuOpen = !!anchorEl;
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const menuItems: MenuItemDescription[] = [{
        onSelect: () => {
            handleClose();
            dispatch(runCreateFtpDatasetFlowAction());
        },
        icon: <FontAwesomeIcon icon={faServer} size={'lg'}/>,
        title: 'Add dataset from FTP',
        subTitle: 'Use ftp as source of dataset',
        itemType: MenuItemType.RICH
    },
        {
            onSelect: () => {
                handleClose();
                dispatch(runCreateHttpDatasetFlowAction());
            },
            icon: <FontAwesomeIcon icon={faLink} size={'lg'}/>,
            title: 'Add dataset by HTTP links',
            subTitle: 'Use HTTP links as source of dataset',
            itemType: MenuItemType.RICH
        },
        {
            onSelect: () => {
                handleClose();
                dispatch(runUploadDatasetFlowAction());
            },
            icon: <FontAwesomeIcon icon={faFolder} size={'lg'}/>,
            title: 'Upload dataset from local files',
            subTitle: 'Select files on your PC',
            itemType: MenuItemType.RICH
        },
        {
            onSelect: () => {
                handleClose();
                dispatch(runCreateS3DatasetFlowAction());
            },
            icon: <FontAwesomeIcon icon={faAws} size={'lg'}/>,
            title: 'Add dataset from AWS S3',
            subTitle: 'Use S3 as source of dataset',
            itemType: MenuItemType.RICH
        },

    ];

    return (
        <>
            <Button
                sx={{height: 32}}
                startIcon={<FontAwesomeIcon style={{marginLeft: 4, marginRight: 8, fontSize: 16}} icon={faPlus}/>}
                onClick={handleClick}
                variant={'contained'}
            >
                {t('Add dataset')}
            </Button>
            <BSMenu
                id="basic-menu"
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                items={menuItems}
            />
        </>
    );
};
