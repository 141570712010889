import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit';

import {UsersState, UserStatus} from "./usersTypes";
import {OrganizationRole} from "../../../../core/organization/organizationTypes";
import {
    InlineObject1,
    InlineObject2,
    UserManagerUserEntity
} from "@biostrand/biostrandapi/javascript/dist/UserManagerApi";

export const REFRESH_USERS = '@organisation/users/REFRESH_USERS';
export const DELETE_USER = '@organisation/users/DELETE_USER';
export const INVITE_USER_REQUEST = '@organisation/users/INVITE_USER_REQUEST';
export const UPDATE_USER_ROLE = '@organisation/users/UPDATE_USER_ROLE';
export const UPDATE_USER_STATUS = '@organisation/users/UPDATE_USER_STATUS';


interface InviteUserRequest {
    invite: InlineObject1,
    callback: (error: string | undefined) => void
}

interface UpdateUserRoleRequest {
    user: InlineObject2,
    role: OrganizationRole,
}

interface UpdateUserStatusRequest {
    user: InlineObject2,
    status: UserStatus,
}

export const refreshUsersAction = createAction( REFRESH_USERS );
export const deleteUsersAction = createAction<UserManagerUserEntity>( DELETE_USER );

export const inviteUserRequestAction = createAction<InviteUserRequest>(INVITE_USER_REQUEST);
export const updateUserRoleAction = createAction<UpdateUserRoleRequest>(UPDATE_USER_ROLE);
export const updateUserStatusAction = createAction<UpdateUserStatusRequest>(UPDATE_USER_STATUS);

const initialState: UsersState = {
    users: undefined,
    isLoading: false,

};

export const usersSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        updateUsers: (state, action: PayloadAction<UserManagerUserEntity[]>) => {
            state.users = action.payload;
        },
        setUsersLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
    },
});

export const { updateUsers, setUsersLoading } = usersSlice.actions;

export default usersSlice.reducer;

