import {
  DatasetManagerDatasetEntity,
  DatasetManagerDatasetStatusCode,
} from '@biostrand/biostrandapi/javascript/dist/DatasetManagerApi';
import {Checkbox, LinearProgress, Stack} from '@mui/material';
import {DataGrid, GridColDef, GridRenderCellParams, GridSortModel} from '@mui/x-data-grid';
import {GridSelectionModel} from '@mui/x-data-grid/models/gridSelectionModel';
import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import {NAME_WITH_STATUS_COLUMN_WITH_CLICK,} from './datasetColumns';
import {isDatasetPartiallySelected, isDatasetSelected} from "../pickers/datasetpickersUtils";
import {useDataFilter} from "../filters/useDataFilter";
import {StringValueFilterInput} from "../filters/StringValueFilterInput";
import {MultipleValueFilterSelector} from "../filters/MultipleValueFilterSelector";
import {DATASET_STATUSES} from "../filters/dsFiltersConstants";
import {GroupsFilter} from "@biostrand/projects/src/app/pages/private/group/GroupsFilter";
import {FieldFilterType, FilterType} from "../../filters/dataFiltersTypes";
import {useSelector} from "react-redux";
import {groupRelatedDatasetsSelector} from "@biostrand/projects/src/app/core/groupsSelectors";
import {DateFilterInput} from "../filters/DateFilterInput";

export type DGDatasetsSelectorProps = {
  selectedItems?: GridSelectionModel;
  datasets: DatasetManagerDatasetEntity[] | undefined;
  isDatasetsLoading: boolean;
  initialSort?: GridSortModel;
  onSelectionChange: (selection: GridSelectionModel) => void;
  onDatasetOpen: (selection: GridSelectionModel) => void;
  hideSearch?: boolean;
  hideHeader?: boolean;
  hideFooter?: boolean;
  minHeight?: number;
  showGroupFilter: boolean,
  selectedGroupId?: string,
};

const JobRunSelectorFilterKey = "JobRunSelectorFilterKey";
export const DGDatasetsSelector = (props: DGDatasetsSelectorProps): JSX.Element => {
  const {
    datasets,
    isDatasetsLoading,
    onDatasetOpen,
    onSelectionChange,
    selectedItems,
    hideHeader,
    hideFooter,
    showGroupFilter = false,
    selectedGroupId,
    initialSort = [{field: 'last_updated_on', sort: 'desc'}],
  } = props;

  const [dsColumns, setDsColumns] = useState<GridColDef[]>([]);
  const [openedDatasets, setOpenedDatasets] = useState<GridSelectionModel | undefined>()
  const relatedDatasets = useSelector(groupRelatedDatasetsSelector(selectedGroupId));

  const initialFilterValues = [{
    filterItemKey: 'status',
    type: FilterType.FIELD,
    value: {in: [DatasetManagerDatasetStatusCode.COMPLETE]},
    field: 'status',
    fieldType: FieldFilterType.ENUM
  }]

  if (showGroupFilter) {
    initialFilterValues.push({
      type: FilterType.TEST_FUNCTION,
      filterItemKey: 'groupItem',
      testFunction: (ds) => {
        return !!relatedDatasets?.find(rDs => rDs.id === ds.id);
      }
    });
  }
  const [currentDatasets] = useDataFilter(JobRunSelectorFilterKey, datasets, initialFilterValues);

  const onClick = (row: DatasetManagerDatasetEntity) => {
    onRowSelection([row.id]);
  };

  const onToggleDataset = useCallback((id: string) => {
    const sItem = selectedItems ? selectedItems : [];

    const newSelection = sItem.filter(item => item.toString().indexOf(id) === -1);

    if (newSelection.length === sItem.length) {
      newSelection.push(id);
    }

    onSelectionChange(newSelection);
  }, [selectedItems]);


  useEffect(() => {
    setDsColumns([
      {
        field: 'cb',
        headerName: ' ',
        width: 40,
        renderCell: (params: GridRenderCellParams<any>) => {
          return (<Checkbox
            checked={isDatasetSelected(params.row.id, selectedItems)}
            indeterminate={isDatasetPartiallySelected(params.row.id, selectedItems)}
            onChange={() => onToggleDataset(params.row.id)}
          />);
        },
      },
      NAME_WITH_STATUS_COLUMN_WITH_CLICK(onClick),
      //SHOW_CONTENT_COLUMN(onClick),
    ])
  }, [selectedItems]);

  const onRowSelection = (rowSelectionModel: GridSelectionModel) => {
    onDatasetOpen(rowSelectionModel);
    setOpenedDatasets(rowSelectionModel);
  };

  return (
    <Stack
      direction={'column'}
      sx={{flexWrap: 'wrap', minWidth: 250, height:600, flex: 1, position: 'relative'}}
    >
      <Stack direction={"row"} sx={{mb: 1, flexWrap: "wrap"}}>
        <StringValueFilterInput
          filterKey={JobRunSelectorFilterKey}
          field={'name'}
          filterItemKey={"name"}
          sx={{width: '99%', mb:1}}
        />
        <MultipleValueFilterSelector
          values={DATASET_STATUSES}
          filterKey={JobRunSelectorFilterKey}
          field={'status'}
          filterItemKey={"status"}/>
        <DateFilterInput
          filterKey={JobRunSelectorFilterKey}
          field={'date_inp'}
          filterItemKey={"date_inp"}/>
        {showGroupFilter &&
        <GroupsFilter
          filterKey={JobRunSelectorFilterKey}
          filterItemKey={'groupItem'}
          selectedGroupId={selectedGroupId}
        />}
      </Stack>


      {currentDatasets ? (
        <DataGrid
          sx={{
            border: '5px',
            flex: 1,
          }}
          initialState={{
            sorting: {
              sortModel: initialSort,
            },
          }}
          rows={currentDatasets}
          disableSelectionOnClick
          loading={false}
          columns={dsColumns}
          getRowHeight={() => 'auto'}
          hideFooter={hideFooter}
          headerHeight={hideHeader ? 0 : 32}
          getRowId={row => row.id || ''}
          onSelectionModelChange={onRowSelection}
          disableColumnFilter
          hideFooterRowCount
          disableMultipleSelection
          hideFooterSelectedRowCount
          keepNonExistentRowsSelected
          selectionModel={openedDatasets}
          density={"compact"}
        />
      ) : null}

      {isDatasetsLoading ? (
        <div
          style={{
            left: 0,
            top: 0,
            zIndex: 1050,
            backgroundColor: 'rgba(255,255,255,0.7)',
            bottom: 0,
            paddingTop: 0,
            right: 0,
            position: 'absolute',
          }}
        >
          <LinearProgress/>{' '}
        </div>
      ) : null}
    </Stack>
  );
};
