import * as React from 'react'
import {put} from 'redux-saga/effects';

import {enqueueSnackbarNotification} from './snackbarNotificationSlice'
import {OptionsObject} from "notistack";
import CloseNotificationButton from "./CloseNotificationButton";
import CopyDetailsNotificationButton from "./CopyDetailsNotificationButton";
import SendDetailsNotificationButton from "./SendDetailsNotificationButton";

const getErrorKey = () => `error::${Math.random()}`
const getSuccessKey = () => `success::${Math.random()}`

export function* showError(message: string, details?:string, options?: OptionsObject) {
  yield put(enqueueSnackbarNotification({
    message,
    key: getErrorKey(),
    options: {
      ...options,
      variant: "error",
      persist: true,
      action: (key) => (
        <>
          {details ? <CopyDetailsNotificationButton description={details} /> : null}
          {details ? <SendDetailsNotificationButton description={details} /> : null}
          <CloseNotificationButton notificationKey={key}>Close</CloseNotificationButton>

        </>
      )
    }
  }))
}

export function* showSuccess(message: string, options?: OptionsObject) {
  yield put(enqueueSnackbarNotification({
    message,
    key: getSuccessKey(),
    options: {
      ...options,
      variant: "success",
      persist: false,

      action: (key) => (<CloseNotificationButton notificationKey={key}>Close</CloseNotificationButton>)
    }

  }))
}



