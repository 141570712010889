import {Column, ColumnType} from "./gridTypes";

export interface GridMenuRow {
    menu: string
}

export type MenuColumnType = Column<GridMenuRow>

export const MENU_COLUMN: MenuColumnType = {
    id: 'menu',
    type: ColumnType.MENU,
    label: 'menu'
}

export interface MenuItemDescription {
    name: string,
    action: string,
}

