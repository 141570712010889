import React, {useEffect, useRef, useState} from "react";
import {DefaultPluginUISpec} from "molstar/lib/mol-plugin-ui/spec";
import {createPluginUI} from "molstar/lib/mol-plugin-ui/";
import "molstar/build/viewer/molstar.css";
import {BuiltInTrajectoryFormat} from "molstar/lib/mol-plugin-state/formats/trajectory";

interface MolstarViewerProps {
  data: string,
  contentType: string
}

export const MolstarViewer = (props: MolstarViewerProps) => {

  const {data, contentType} = props;
  const parentRef = useRef(null);
  const plugin = useRef(null);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const init = async () => {
      const spec = DefaultPluginUISpec();
      spec.layout = {
        initial: {
          isExpanded: false,
          controlsDisplay: "reactive",
          showControls: false,
          layoutShowControls: false,
          layoutShowRemoteState: false,
        }
      };
      plugin.current = await createPluginUI(parentRef.current, spec);

      await loadStructure(data, plugin.current);
      setInitialized(true);
    }

    init();

    return () => {
      plugin.current = null
    };
  }, [])


  useEffect(() => {
    if (!initialized) return;
    (async () => {
      await loadStructure(data, plugin.current);
    })();
  }, [data])

  const loadStructure = async (data, plugin) => {
    if (plugin) {
      plugin.clear();
      if (data) {
        const rawData = await plugin.builders.data.rawData({
          data
        });
        const traj = await plugin.builders.structure.parseTrajectory(rawData, contentType as BuiltInTrajectoryFormat);
        await plugin.builders.structure.hierarchy.applyPreset(traj, "default");
      }
    }
  }
  return (
    <div style={{position: "relative", flex: 1, overflow: "hidden", left: 0, top: 0, right: 0, bottom: 0}}>
      <div ref={parentRef} style={{position: "absolute", left: 0, top: 0, right: 0, bottom: 0}}/>
    </div>
  );
};

export default MolstarViewer;
