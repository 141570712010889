import {Box, Checkbox, FormControlLabel, Typography} from '@mui/material';
import {useField, useFormikContext} from 'formik';
import * as React from 'react';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {CBox} from '../layout/CBox';
import {CreateFtpDatasetValues} from "./datasetTypes";
import {RBox} from "../layout/RBox";
import {PipelineType} from "./pipelines/pipelineTypes";

interface LineProps {
  title: string,
  value?: string,
}

export const AddDatasetOverviewLine = ({title, value }: LineProps) => {
  return ( <RBox sx={{mb:2}}>
    <Typography sx={{minWidth: 125}} variant={"body2"} >{title}</Typography>
    <Typography variant={"subtitle2"}>{value}</Typography>
  </RBox>)
}

interface FormProps {
  children?: ReactElement | null
}


export function AddDatasetOverview(props: FormProps): ReactElement {
  const [t] = useTranslation();
  const { children } = props;
  const { values } = useFormikContext<CreateFtpDatasetValues>();
  const [, meta, helpers] = useField({ name: 'formConfirmed' });
  const { value } = meta;
  const { setValue } = helpers;
  const pipelines = values.pipelines;

  const uploadPipeline = pipelines.find(p => p.pipeline.type === PipelineType.UPLOAD);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', pt: 2, minWidth: 770 }}>
      <CBox sx={{ mt: 2, mb: 3 }}>
        {children}
        {
          uploadPipeline ? uploadPipeline.selected ?
            <AddDatasetOverviewLine title={t('Pipelines')} value={'The data will be uploaded to Biostrand servers to improve performance'} />
            : <AddDatasetOverviewLine title={t('Pipelines')} value={'The dataset will be only registered on our system. All data will be read from your FTP server and that can affect on performance'} />
            : null
        }

        <Box sx={{ mt: 2 }}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                value={value}
                onChange={(event, value) => {
                  setValue(value);
                }}
              />
            }
            label={t('I understand that additional monthly payment can be charged. Amount depends on data set size')}
          />
        </Box>
      </CBox>
    </Box>
  );
}
