
export enum MenuItemType {
    REGULAR,
    RICH,
    DIVIDER,
}

export interface MenuItemDescription{
    onSelect?: () => void,
    icon?: JSX.Element,
    title?: string,
    subTitle?: string,
    itemType: MenuItemType
}

