import {Checkbox, Paper, Typography} from '@mui/material';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {PipelinesListItem} from "./pipelineTypes";
import {RBox} from "../../layout/RBox";
import {CBox} from "../../layout/CBox";

interface Props {
  pipeline: PipelinesListItem<any>
  onChange: (pipeline: PipelinesListItem<any>, settings?:any) => void
}

export const SimplePipelineItem = (props: Props): JSX.Element => {
  const {onChange, pipeline} = props;
  const [t] = useTranslation();

  const handleChange = () => {
    if (!pipeline.disabled) onChange(pipeline);
  };

  return (
    <Paper variant="outlined" sx={{p:2, mb:2}} onClick={handleChange} >
      <RBox>
        <Checkbox disabled={pipeline.disabled} checked={pipeline.selected}/>
        <CBox>
          <Typography variant={"subtitle2"} sx={{opacity: pipeline.disabled ? 0.4 : 1}}>{t(pipeline.pipeline.name)}</Typography>
          <Typography variant={"caption"} sx={{opacity: pipeline.disabled ? 0.4 : 1}} >{pipeline.pipeline.description}</Typography>
        </CBox>
      </RBox>
    </Paper>
  );
};
