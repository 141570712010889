import React from 'react';
import {Button, TextField, Theme, Typography,} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {Link} from 'react-router-dom';
import * as yup from 'yup';
import {useTranslation} from 'react-i18next';
import {useFormik} from "formik";
import {RouteUrl} from "../../../routeUrl";
import {useDispatch, useSelector} from 'react-redux';
import {forgotPasswordRequestAction} from "./registerSlice";
import {LoadingButton} from "@mui/lab";
import {RootState} from "../../../store";

const useStyles = makeStyles((theme: Theme) =>
    ({
        form: {
            display: 'flex',
            flexDirection: 'column',
            marginLeft: theme.spacing(12),
        },
        buttons: {
            display: 'flex',
            flexDirection: 'row',
        },
        input: {
            width: 320,
            height: theme.spacing(9),
            marginBottom: theme.spacing(3),
        },
        buttonMargin: {
            marginRight: theme.spacing(3),
        },
        title: {
            marginBottom: theme.spacing(1),
        },
        subTitle: {
            marginBottom: theme.spacing(4),
        }
    }))
const ForgotPasswordForm = () => {
    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const forgotPasswordInProgress = useSelector( (state:RootState) => state.register.forgotPasswordInProgress  );
    const validationSchema = yup.object({
        email: yup.string()
            .email(t('Enter a valid email'))
            .defined(t('Email is required')),
    });

    const forgotForm = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            dispatch(forgotPasswordRequestAction( values.email ));
        },
    })

    return (
        <form className={classes.form} onSubmit={forgotForm.handleSubmit}>

            <Typography variant="h4" className={classes.title}>{t('Forgot your password?')}</Typography>
            <Typography variant="body1"
                        className={classes.subTitle}>{t('Don\'t worry! Just give us the email address associated with your account\n')}</Typography>
            <TextField
                className={classes.input}

                id="email"
                label={t('Email')}
                variant="outlined"
                name="email"
                value={forgotForm.values.email}
                onChange={forgotForm.handleChange}
                error={forgotForm.touched.email && Boolean(forgotForm.errors.email)}
                helperText={forgotForm.touched.email && forgotForm.errors.email}
            />

            <div className={classes.buttons}>
                <LoadingButton variant="contained" color="primary" size="large" className={classes.buttonMargin}
                        onClick={() => {
                            forgotForm.handleSubmit()
                        }
                        }
                    loading={forgotPasswordInProgress}
                >
                    {t('Reset password')}
                </LoadingButton>
                <Button color="primary"
                        size="large"
                        component={Link}
                        to={RouteUrl.Login}
                        sx={{textTransform: "none"}}
                        className={classes.buttonMargin}>
                    {t('Back to login')}
                </Button>
            </div>
        </form>
    );
};

export default ForgotPasswordForm;
