import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

i18n.use(Backend)
    .use(initReactI18next)
    .init({
        lng: 'en',
        nsSeparator: false,
        keySeparator: false,
        fallbackLng: false,
        debug: false,
        interpolation: {
            escapeValue: false,
        },
        react: {
            wait: true,
        },
        returnEmptyString: false,
    })
    .catch(() => null);

export default i18n;
