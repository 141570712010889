import {Stack, Typography} from '@mui/material';
import * as React from 'react';
import {DatasetManagerDatasetEntity} from "@biostrand/biostrandapi/javascript/dist/DatasetManagerApi";
import {DatasetStatusChip} from "../DatasetStatusChip";

interface Props {
    row: DatasetManagerDatasetEntity;
}

export const DatasetNameCell = (props: Props): JSX.Element => {
    const { row } = props;
    return (
        <Stack sx={{ pt: 1, pb: 1, pl: 1, alignItems:"flex-start" }}>
            <Typography variant='subtitle2'>{row?.name}</Typography>
            <DatasetStatusChip status={row.status} />
        </Stack>
    );
};
