import * as React from 'react';
import PublicPage from "../common/publicPage/PublicPage";
import ForgotPasswordForm from "./ForgotPasswordForm";

const ForgotPasswordPageComponent = () => {
  return (
      <PublicPage>
        <ForgotPasswordForm />
      </PublicPage>
  );
};

const ForgotPasswordPage = ForgotPasswordPageComponent;

export default ForgotPasswordPage;
