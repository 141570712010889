import {CBox, getGridCellRenderer, GridSortableHeader, RBox, SearchInput, useGridUtils} from '@biostrand/components';
import {ErrorPanel} from '@biostrand/components/src/ErrorPanel/ErrorPanel';
import {MenuHandler} from '@biostrand/components/src/grid/MenuCellRenderer';
import {MenuItemDescription} from '@biostrand/components/src/grid/menuTypes';
import {currentUserSelector} from '@biostrand/components/src/slices/user/userSelectors';
import {faPlus} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Tab, Table, TableBody, TableContainer, TableRow, Tabs, Typography} from '@mui/material';
import {useConfirm} from 'material-ui-confirm';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {OrganizationRole} from '../../../../core/organization/organizationTypes';
import TableLoadingSkeleton from '../datasets/TableLoadingSkeleton';
import {InviteUserPopup} from './InviteUserPopup';
import {ActivateMI, DeactivateMI, DeleteMI, MakeAdminMI, MakeRegularMI, UserAction} from './UserActionsList';
import {UserEmailCell} from './UserEmailCell';
import {UserRoleCell} from './UserRoleCell';
import {isUsersLoadingSelector, usersSelector} from './usersSelectors';
import {deleteUsersAction, refreshUsersAction, updateUserRoleAction, updateUserStatusAction} from './usersSlice';
import {UserStatus} from './usersTypes';
import {UserGridColumns, UserGridRowType} from './userTableUtils';
import {UserManagerUserEntity} from "@biostrand/biostrandapi/javascript/dist/UserManagerApi";

const UsersSettingsContent = (): JSX.Element => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const [selectedTab] = useState<string>('overview');
    const userList = useSelector(usersSelector);
    const isUsersLoading: boolean = useSelector(isUsersLoadingSelector);
    const currentUser: UserManagerUserEntity | undefined = useSelector(currentUserSelector);
    const confirm = useConfirm();
    const [isInviteOpen, setIsInviteOpen] = useState<boolean>(false);
    const [sortedUsers, setSortedUsers] = useState<UserManagerUserEntity[] | undefined>();
    const isAdmin = currentUser && currentUser.organization_role === OrganizationRole.ADMIN;

    const { sortOptions, handleChangeOrder, handleSearch } = useGridUtils<UserGridRowType>({
        data: userList,
        columns: UserGridColumns,
        onChange: setSortedUsers,
    });

    useEffect(() => {
        dispatch(refreshUsersAction());
    }, []);

    const onInviteUser = () => {
        setIsInviteOpen(true);
    };
    const onCancel = () => {
        setIsInviteOpen(false);
    };

    const handleRoleChange = async (user: UserManagerUserEntity, role: OrganizationRole) => {
        try {
            if (currentUser?.email === user.email && isAdmin) {
                await confirm({
                    title: t('Revoking your admin rights'),
                    description: t(`Are you sure you want to delete your admin user rights?`),
                });
            } else {
                await confirm({
                    title: t('Change user role'),
                    description: t(`This will change ${user.email} role to ${role}.`),
                });
            }
            dispatch(updateUserRoleAction({ user, role }));
        } catch (e) {
            console.log(e);
        }
    };

    const handleDeleteUser = async (user: UserManagerUserEntity) => {
        try {
            await confirm({
                title: t('Remove user'),
                description: t(`This will permanently remove ${user.email} user.`),
            });
            dispatch(deleteUsersAction(user));
        } catch (e) {
            console.log(e);
        }
    };

    const handleUserAction: MenuHandler<UserGridRowType> = async (row, action) => {
        switch (action) {
            case UserAction.DELETE_ACCOUNT:
                await handleDeleteUser(row);
                break;
            case UserAction.DEACTIVATE:
                dispatch(updateUserStatusAction({ user: row, status: UserStatus.DEACTIVATED }));
                break;
            case UserAction.ACTIVATE:
                dispatch(updateUserStatusAction({ user: row, status: UserStatus.ACTIVE }));
                break;
            case UserAction.MAKE_REGULAR:
                await handleRoleChange(row, OrganizationRole.REGULAR);
                break;
            case UserAction.MAKE_ADMIN:
                await handleRoleChange(row, OrganizationRole.ADMIN);
                break;
        }
    };

    const getMenuItems = (user: UserManagerUserEntity): MenuItemDescription[] => {
        if (currentUser?.email === user.email) {
            return [];
        }

        const items: MenuItemDescription[] = [];
        if (user.organization_role === OrganizationRole.ADMIN) {
            items.push(MakeRegularMI);
        } else {
            items.push(MakeAdminMI);
        }

        if (user.status === UserStatus.ACTIVE) {
            items.push(DeactivateMI);
        }

        if (user.status === UserStatus.DEACTIVATED) {
            items.push(ActivateMI);
        }

        if (user.status === UserStatus.DEACTIVATED || user.status === UserStatus.PENDING) {
            items.push(DeleteMI);
        }

        return items;
    };

    return (
        <>
            <CBox
                sx={{
                    flex: 1,
                    m: 4,
                    overflowY: 'hidden',
                }}>
                <RBox sx={{ mb: 2 }}>
                    <Typography variant={'h4'} sx={{ flex: 1 }}>
                        {t('Users')}
                    </Typography>
                    <Button
                        sx={{ height: 32 }}
                        startIcon={
                            <FontAwesomeIcon style={{ marginLeft: 4, marginRight: 8, fontSize: 16 }} icon={faPlus} />
                        }
                        onClick={onInviteUser}
                        variant={'contained'}>
                        {t('Invite user')}
                    </Button>
                </RBox>

                <Tabs value={selectedTab}>
                    <Tab label='Overview' value={'overview'} />
                </Tabs>
                {isUsersLoading ? (
                    <TableLoadingSkeleton />
                ) : userList ? (
                    <>
                        <SearchInput
                            onChange={handleSearch}
                            ariaLabel={'Search dataset'}
                            sx={{ mt: 2, mb: 2, maxWidth: 300 }}
                        />
                        <TableContainer sx={{ flex: 1, overflowY: 'scroll' }}>
                            <Table stickyHeader aria-label='sticky table' size='small'>
                                <GridSortableHeader<UserGridRowType>
                                    onRequestSort={handleChangeOrder}
                                    onSelectAllClick={console.log}
                                    columns={UserGridColumns}
                                    order={sortOptions?.order}
                                    orderBy={sortOptions?.orderBy}
                                />
                                <TableBody>
                                    {sortedUsers &&
                                        sortedUsers.map(row => {
                                            return (
                                                <TableRow
                                                    hover
                                                    role='checkbox'
                                                    tabIndex={-1}
                                                    key={row.email}
                                                    sx={{ height: 48 }}>
                                                    {UserGridColumns.map(column => {
                                                        if (column.id === 'email') {
                                                            return <UserEmailCell key={column.id} row={row} />;
                                                        }
                                                        if (column.id === 'organization_role') {
                                                            return (
                                                                <UserRoleCell
                                                                    key={column.id}
                                                                    row={row}
                                                                    onChange={handleRoleChange}
                                                                />
                                                            );
                                                        }
                                                        return getGridCellRenderer<UserGridRowType>(
                                                            column,
                                                            row as UserGridRowType,
                                                            getMenuItems(row),
                                                            handleUserAction,
                                                        );
                                                    })}
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                ) : (
                    <ErrorPanel
                        title={t('Empty list')}
                        subtitle={t('Empty user list something went wrong, please try to reload the page')}
                    />
                )}
            </CBox>
            <InviteUserPopup open={isInviteOpen} onResult={console.log} onCancel={onCancel} />
        </>
    );
};

export default UsersSettingsContent;
