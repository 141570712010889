import maxBy from 'lodash.maxby';
import trimStart from 'lodash.trimstart';

import {AlignmentViewRenderData, RowRenderData} from "@biostrand/components/src/alignmentView/alignmentViewTypes";
import {
    RetrieveAndRelateEnrichedData,
    RetrieveAndRelateEnrichQueryResponse,
    SharedMolType
} from '@biostrand/biostrandapi/javascript/dist/RetrieveAndRelateApi';

const aminoAcidsWithoutDNALetters: string[] = 'BDEFHIJKLMNOPQRSVWXYZ'.split('');

export function getSequenceType(sequence: string): SharedMolType.DNA | SharedMolType.AA {
    for (let i = 0; i < sequence.length; i++) {
        if (aminoAcidsWithoutDNALetters.indexOf(sequence[i]) !== -1) {
            return SharedMolType.AA;
        }
    }

    return SharedMolType.DNA;
}

const mapFastaToRenderData = (
    queryData: RetrieveAndRelateEnrichedData,
    index: number,
): RowRenderData => {
    const {alignment_info = {}, sequence_info = {}} = queryData

    const sequence: string = trimStart(sequence_info.sequence);

    const offset = alignment_info.offset || (sequence_info?.sequence?.length || sequence.length)  - sequence.length || 0;

    return {
        offset,
        sequence,
        type: sequence_info.mol_type || SharedMolType.AA,
        residueValues: alignment_info.residue_values,
        label: sequence_info.description ? sequence_info.description : `#${index}`,
        name: sequence_info.description,
        id: sequence_info.description ? sequence_info.description : `id-#${index}`,
    };
};

export function prepareAlignmentViewRenderData(
    enrichData: RetrieveAndRelateEnrichQueryResponse | null,
): AlignmentViewRenderData | null {
    if (!enrichData) return null;

    const longestFasta = maxBy(
        enrichData?.data,
        (rec: RetrieveAndRelateEnrichedData) => (rec.sequence_info?.sequence?.length || 0) + (rec.alignment_info?.offset || 0),
    );

    const maxLength = (longestFasta && longestFasta.sequence_info && longestFasta.sequence_info.sequence)
        ? (longestFasta.sequence_info.sequence?.length || 0) + (longestFasta.alignment_info?.offset || 0)
        : 0;

    const sequenceRows =  enrichData?.data?.map(mapFastaToRenderData);
    const searchOffset = sequenceRows && sequenceRows[0] ? sequenceRows[0].offset : 0

    return {
        maxLength,
        sequenceRows: sequenceRows || [],
        searchOffset,
        searchPattern: {
            offset: 0,
            sequence: '',
            type: SharedMolType.AA,
            label: '',
            name: '',
            id: '',
        },
    };
}
