import copy from 'copy-to-clipboard';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard, faHighlighter, faSearch } from '@fortawesome/pro-regular-svg-icons';

import { BASE_SIZE } from '../AligmentBaseSize';
import { AlignmentViewRenderData, RowRenderData } from '../alignmentViewTypes';
import styles from '../alignmentViewer.module.css';
import CellSelectionRect from './AlignmentViewCellSelectionRect';
import SubSelectionRect from './SubSelectionRect';
import {enqueueSnackbarNotification} from "../../snackbarNotification/snackbarNotificationSlice";
import CloseNotificationButton from "../../snackbarNotification/CloseNotificationButton";
import {runEnrichQueryRequest} from "@biostrand/retrieve-and-relate/src/app/core/search/searchSlice";
import {IconButton, Paper} from '@mui/material';

interface ChartSelectorProps {
    renderData?: AlignmentViewRenderData;
    selectedRowId: string | null;
    onSelect: (id: string | null) => void;
    onSubsequenceSelection: (subsequence: string | null) => void;
    onHighlight: (subsequence: string | undefined) => void;
    showMenu?: boolean;
    // quick workaround to hide / show search item in the menu. Ideal the menu items should be setup from patent component
    isSearchAllowed?: boolean;
}

interface StyleMap {
    [key: string]: string | number;
}

interface GridCoordinate {
    column: number;
    row: number;
}

const getGridCoordinate = (mouseX: number, mouseY: number): GridCoordinate => {
    const column = Math.trunc(mouseX / BASE_SIZE);
    const row = Math.trunc(mouseY / BASE_SIZE);

    return { column, row };
};

const getSubsequence = (
    row: RowRenderData | undefined,
    subSelectionStart: number | null,
    subSelectionEnd: number | null,
) => {
    if (row && subSelectionStart !== null && subSelectionEnd !== null) {
        const start = Math.min(subSelectionStart, subSelectionEnd) - row.offset;
        const end = Math.max(subSelectionStart + 1, subSelectionEnd + 1) - row.offset;
        return row.sequence.substring(start, end);
    }
    return null;
};

const AlignmentViewChartSelector = (props: ChartSelectorProps): ReactElement => {
    const { renderData, selectedRowId, onSelect, onSubsequenceSelection, showMenu = true, onHighlight, isSearchAllowed } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [totalWidth, setTotalWidth] = useState<number>(0);
    const [totalHeight, setTotalHeight] = useState<number>(0);
    const [selectionStyle, setSelectionStyle] = useState<StyleMap | null>(null);
    const [subSelectionStart, setSubSelectionStart] = useState<number | null>(null);
    const [subSelectionEnd, setSubSelectionEnd] = useState<number | null>(null);
    const [rowIndex, setRowIndex] = useState<number | null>(null);
    const [menuCoordinates, setMenuCoordinates] = useState<{ x: number; y: number } | null>(null);
    const selectionEndRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (renderData) {
            setTotalWidth(renderData.maxLength * BASE_SIZE);
            setTotalHeight(renderData.sequenceRows.length * BASE_SIZE);
        }
    }, [renderData]);

    useEffect(() => {
        if (selectedRowId && renderData) {
            const newRowIndex = renderData.sequenceRows.findIndex((item: RowRenderData) => item.id === selectedRowId);
            if (newRowIndex >= 0) {
                setRowIndex(newRowIndex);
                setSelectionStyle({
                    position: 'absolute',
                    top: newRowIndex * BASE_SIZE,
                    height: BASE_SIZE,
                    width: renderData.maxLength * BASE_SIZE,
                    borderTop: 'solid 1px #0000ff',
                    borderBottom: 'solid 1px #0000ff',
                    pointerEvents: 'none',
                });
                if (newRowIndex !== rowIndex) {
                    setSubSelectionEnd(null);
                    onSubsequenceSelection(null);
                }
                return;
            }
            setRowIndex(null);
        }
        setSelectionStyle(null);
    }, [renderData, selectedRowId, rowIndex, onSubsequenceSelection]);

    useEffect(() => {
        if (selectionEndRef.current) {

            const viewportOffset = selectionEndRef.current.getBoundingClientRect();

            setMenuCoordinates({
                x: viewportOffset.left,
                y: viewportOffset.top + BASE_SIZE + 2,
            });
            return;
        }
        setMenuCoordinates(null);
    }, [subSelectionEnd]);

    // @ts-ignore
  const onSelectorClick = (event): void => {
        const { row, column } = getGridCoordinate(event.nativeEvent.offsetX, event.nativeEvent.offsetY);

        const rowData: RowRenderData = renderData?.sequenceRows[row] as RowRenderData;
        const nextSelectedRowId = rowData.id;

        if (nextSelectedRowId !== selectedRowId) {
            onSelect(nextSelectedRowId || null);
            setSubSelectionStart(null);
            setSubSelectionEnd(null);
            onSubsequenceSelection(null);
        }

        if (column < rowData.offset || column > rowData.offset + rowData.sequence.length) {
            // out of sequence range
            return;
        }

        if (event.nativeEvent.shiftKey && subSelectionStart !== null) {
            setSubSelectionEnd(column);
            if (rowIndex!=null && rowIndex >= 0) {

                const label = getSubsequence(renderData?.sequenceRows[rowIndex], subSelectionStart, column);
                onSubsequenceSelection(label);
            }
            return;
        }

        setSubSelectionStart(column);
        setSubSelectionEnd(null);
        onSubsequenceSelection(null);
    };

    const onSubSelectionCopy = (event:React.MouseEvent<HTMLButtonElement>): void => {
        event.stopPropagation();
        if (rowIndex !== null) {
            const label = getSubsequence(renderData?.sequenceRows[rowIndex], subSelectionStart, subSelectionEnd);
            if (label) {
                copy(label);

                dispatch( enqueueSnackbarNotification({
                  message: t('Selection is copied'),
                  key: 'success-key',
                  options: {
                    variant: "success",
                    persist: false,
                    action: (key) => (<CloseNotificationButton notificationKey={key}>Close</CloseNotificationButton>)
                  }
                }));
            }
        }
    };

    const onHighlightHandler = (event:React.MouseEvent<HTMLButtonElement>): void => {
        event.stopPropagation();
        if (rowIndex !== null) {
            const label = getSubsequence(renderData?.sequenceRows[rowIndex], subSelectionStart, subSelectionEnd);
            if (onHighlight) {
                onHighlight(label as string);
            }
        }
    };

    const onSearch = (event:React.MouseEvent<HTMLButtonElement>): void => {
        event.stopPropagation();
        if (rowIndex !== null) {
            const label = getSubsequence(renderData?.sequenceRows[rowIndex], subSelectionStart, subSelectionEnd);
            if (label)
                dispatch(runEnrichQueryRequest(label));
        }
    };

    const isStartVisible = subSelectionStart !== null && rowIndex !== null;
    const isEndVisible = subSelectionEnd !== null && rowIndex !== null;
    const isSubSelectionVisible = isStartVisible && isEndVisible;

    return (
        <div>
            <div
                onClick={onSelectorClick}
                className={styles.selectionHolder}
                style={{ width: totalWidth, height: totalHeight }}>
                {!!selectionStyle && <div style={selectionStyle} />}
                {isSubSelectionVisible && (
                    <SubSelectionRect start={subSelectionStart || 0} end={subSelectionEnd || 0} row={rowIndex || 0} />
                )}
                {isStartVisible && <CellSelectionRect x={subSelectionStart || 0} y={rowIndex || 0} />}
                {isEndVisible && <CellSelectionRect ref={selectionEndRef} x={subSelectionEnd || 0} y={rowIndex || 0} />}
            </div>
            {showMenu && !!menuCoordinates && (
                <Paper
                    elevation={3}
                    style={{
                        backgroundColor: '#ffffff',
                        padding: 4,
                        margin: 1,
                        // display:'flex',
                        // flexDirection:'row',
                        borderRadius: 4,
                        position: 'fixed',
                        top: menuCoordinates.y || -1000,
                        left: menuCoordinates.x || -1000,
                        zIndex: 1020,
                    }}>
                    <IconButton
                        onClick={onSubSelectionCopy}>
                        <FontAwesomeIcon icon={faClipboard} size='sm' />
                    </IconButton>

                    <IconButton
                        onClick={onHighlightHandler}>
                        <FontAwesomeIcon icon={faHighlighter} size='sm' />
                    </IconButton>
                    {isSearchAllowed ?
                        <IconButton
                            onClick={onSearch}>
                            <FontAwesomeIcon icon={faSearch} size='sm' />
                        </IconButton> : null
                    }
                </Paper>
            )}
        </div>
    );
};

export default AlignmentViewChartSelector;
