import React from 'react';
import {Route, RouteProps} from 'react-router-dom';
import {useSelector} from "react-redux";

import NoAccessPage from "../pages/noAccess/NoAccessPage";
import {selectAuthStatus} from "../slices/auth/authSelectors";
import {AuthenticationStatus} from "../slices/auth/authTypes";
import {currentAppIDSelector, currentUserSelector, userApplicationsSelector} from "../slices/user/userSelectors";
import {RuntimeConfig} from "../runtimeConfig/RuntimeConfig";
import {DNALoading} from "../loading/DNALoading";
import {OrganizationRole} from "@biostrand/portal/src/app/core/organization/organizationTypes";
import {Apps} from "../applications/applicationTypes";

export type AuthRouteProps = { children: JSX.Element, onlyAdmin?: boolean } & RouteProps

const AuthRoute = (props: AuthRouteProps): JSX.Element => {
  const {children, onlyAdmin, ...rest} = props
  const auth = useSelector(selectAuthStatus);
  const userApplications = useSelector(userApplicationsSelector);
  const currentUser = useSelector(currentUserSelector)
  const currentAppId = useSelector(currentAppIDSelector);
  const loginRedirect = RuntimeConfig.loginUrl();
  const isAdmin = currentUser && currentUser.organization_role === OrganizationRole.ADMIN;

  const redirectToLoginPage =
    () => {
      const currentLocation = encodeURIComponent(window.location.href);
      const c = new URL(loginRedirect);
      const urlWithoutSearch = `${window.location.origin}${window.location.pathname}`;

      if (urlWithoutSearch === loginRedirect) {
        return null;
      }

      if (`${c.origin}/` === window.location.href) {
        window.location.href = loginRedirect;
        return null;
      }

      window.location.href = `${loginRedirect}?sourceUrl=${currentLocation}`;

      return null;
    }

  if (auth === AuthenticationStatus.NOT_AUTHENTICATED) {

    return <Route
      exact path="/"
      render={redirectToLoginPage}/>
  }

  if (auth === AuthenticationStatus.PENDING || auth === AuthenticationStatus.INITIALIZING || !auth) {
    return <DNALoading message={' '}/>
  }

  if (onlyAdmin && !isAdmin) return <NoAccessPage/>

  const isUserHashAccess = !!userApplications.find(app => app.id === currentAppId) || currentAppId === Apps.ADMIN_PORTAL;

  if (isUserHashAccess)
    return (
      <Route {...rest} render={() => children}/>
    )

  return <NoAccessPage/>
}
export default AuthRoute;
