import { PageHeader } from '@biostrand/components';
import React from 'react';

const PortalPageHeader = (): JSX.Element => {
    return (
        <PageHeader
            sx={{
                height: theme => theme.spacing(16),
                minHeight:128,
            }}
        />
    );
};

export default PortalPageHeader;
