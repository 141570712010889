import { faCopy } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@mui/material';
import copy from 'copy-to-clipboard';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import CloseNotificationButton from './CloseNotificationButton';
import { enqueueSnackbarNotification } from './snackbarNotificationSlice';

interface CopyDetailsNotificationButtonProps {
  description: string;
}

const CopyDetailsNotificationButton = (props: CopyDetailsNotificationButtonProps): JSX.Element => {
  const { description } = props;
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const onCopyDescription = async (sequenceString: string): Promise<void> => {
    try {
      await copy(sequenceString);

      dispatch(
        enqueueSnackbarNotification({
          message: t('Error was copied to clipboard.'),
          key: 'success-key',
          options: {
            variant: 'success',
            persist: false,
            action: key => <CloseNotificationButton notificationKey={key}>Close</CloseNotificationButton>,
          },
        }),
      );
    } catch (error) {
      dispatch(
        enqueueSnackbarNotification({
          message: t('Failed to copy error to clipboard :)'),
          key: 'error-key',
          options: {
            variant: 'error',
            persist: false,
            action: key => <CloseNotificationButton notificationKey={key}>Close</CloseNotificationButton>,
          },
        }),
      );
    }
  };

  return (
    <IconButton color="inherit" onClick={(): Promise<void> => onCopyDescription(description)}>
      <FontAwesomeIcon icon={faCopy} size="sm" />
    </IconButton>
  );
};

export default CopyDetailsNotificationButton;
