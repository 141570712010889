import {DatasetManagerDatasetItem, SharedObjectType} from "@biostrand/biostrandapi/javascript/dist/DatasetManagerApi";
import {GRID_CHECKBOX_SELECTION_FIELD, GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import * as React from "react";
import AlignmentViewRow from "../../alignmentView/components/AlignmentViewRow";
import {SharedMolType} from "@biostrand/biostrandapi/javascript/dist/BiostrandGatewayApi";
import {Stack} from "@mui/material";

export enum NGSFolders {
  BINDERS = 'binders',
  PDBS = 'pdbs',
  HITS = 'hits_merged',
}

export const getBindersFileNames = (files: DatasetManagerDatasetItem[]) => {
  return files
    .filter(file => file.name.indexOf(`/${NGSFolders.BINDERS}/`) > -1 && file.object_type === SharedObjectType.FILE)
    .map(file => file.name);
}

export const getBinderResultFileName = (files: DatasetManagerDatasetItem[], binderId: string) => {
  return files
    .filter(file => file.name.indexOf(`/${NGSFolders.HITS}/`) > -1 && file.name.toUpperCase().indexOf(binderId.toUpperCase()) > 0 && file.object_type === SharedObjectType.FILE)
    .map(file => file.name)[0];
}

export const getBinderPDBFileName = (files: DatasetManagerDatasetItem[], binderId: string) => {
  return files
    .filter(file => file.name.indexOf(`/${NGSFolders.PDBS}/`) > -1 && file.name.toUpperCase().indexOf(binderId.toUpperCase()) > 0 && file.object_type === SharedObjectType.FILE)
    .map(file => file.name)[0];
}

export const findPDBFileName = (files: DatasetManagerDatasetItem[], fileName: string) => {

  if (!fileName) return;

  const normalizedFileName = fileName.split('/').pop();
  return files
    .filter(file => file.name.indexOf( `/${NGSFolders.PDBS}/${normalizedFileName}`) > -1)
    .map(file => file.name)[0];
}

export const parseCSV = (str: string) => {
  const arr: string[] = [];
  let quote = false;  // 'true' means we're inside a quoted field

  // Iterate over each character, keep track of current row and column (of the returned array)
  for (let row = 0, col = 0, c = 0; c < str.length; c++) {
    let cc = str[c], nc = str[c + 1];        // Current character, next character
    arr[row] = arr[row] || [];             // Create a new row if necessary
    arr[row][col] = arr[row][col] || '';   // Create a new column (start with empty string) if necessary

    // If the current character is a quotation mark, and we're inside a
    // quoted field, and the next character is also a quotation mark,
    // add a quotation mark to the current column and skip the next character
    if (cc === '"' && quote && nc === '"') {
      arr[row][col] += cc;
      ++c;
      continue;
    }

    // If it's just one quotation mark, begin/end quoted field
    if (cc === '"') {
      quote = !quote;
      continue;
    }

    // If it's a comma and we're not in a quoted field, move on to the next column
    if (cc === ',' && !quote) {
      ++col;
      continue;
    }

    // If it's a newline (CRLF) and we're not in a quoted field, skip the next character
    // and move on to the next row and move to column 0 of that new row
    if (cc === '\r' && nc === '\n' && !quote) {
      ++row;
      col = 0;
      ++c;
      continue;
    }

    // If it's a newline (LF or CR) and we're not in a quoted field,
    // move on to the next row and move to column 0 of that new row
    if (cc === '\n' && !quote) {
      ++row;
      col = 0;
      continue;
    }
    if (cc === '\r' && !quote) {
      ++row;
      col = 0;
      continue;
    }

    // Otherwise, append the current character to the current column
    arr[row][col] += cc;
  }
  return arr;
}


export const columns_custom_settings = {
  'Sequence name': {
    minWidth: 200
  }
}

export const PINNED_BINDERS_COLUMNS = [GRID_CHECKBOX_SELECTION_FIELD,'Sequence name', 'actions', 'id', 'cActions'];

export const PINNED_RESULTS_COLUMNS = [GRID_CHECKBOX_SELECTION_FIELD, 'actions', 'id', 'cActions'];

export const SEQUENCE_COLUMNS =
  ["VH",
    "VL",
    "VH FR1",
    "VH CDR1",
    "VH FR2",
    "VH CDR2",
    "VH FR3",
    "VH CDR3",
    "VH FR4",
    "VL FR1",
    "VL CDR1",
    "VL FR2",
    "VL CDR2",
    "VL FR3",
    "VL CDR3",
    "VL FR4",
  ]
export const BINDER_NUMBER_COLUMNS = ['CD3', 'strep/bio-BSA', 'Jurkat', 'CHO CD3d/e', 'CHO paretnal', 'CD3 stringent', 'CD3 all', 'strep bio-BSA', ' Jurkat', ' CHO CD3d/e', ' CHO parental'];

export const PDB_RESULTS_COLUMNS = ['ID', 'cluster_by_rmsd'];

export const isNumberColumn = (name: string) => {
  if (BINDER_NUMBER_COLUMNS.indexOf(name) > -1) {
    return true;
  }
  return name.toUpperCase().indexOf('SCORE') > -1 || name.toUpperCase().indexOf('COUNT') > -1 || name.toUpperCase().indexOf('TOTAL') > -1
}

export const isSequenceColumn = (name: string) => {
  if (name.toLowerCase() === 'sequence name') {
    return false;
  }

  if (name.toUpperCase().indexOf('SEQUENCE') > -1) {
    return true;
  }

  return SEQUENCE_COLUMNS.indexOf(name) > -1;
}

export const isPDBResultsColumn = (name:string) => {
  return PDB_RESULTS_COLUMNS.indexOf(name) > -1;
}

export const binderFromArray = (columnNames: string[]) => (row: string[]) => {
  const binder = {}
  for (let i = 0; i < columnNames.length; i++) {
    const key = columnNames[i];

    if (isNumberColumn(key)) {
      binder[key] = Number(row[i]);
    } else {
      binder[key] = row[i];
    }
    if (key.toLowerCase() === 'sequence name') {
      binder.id = row[i];
    }
  }

  return binder;
}

export const binderResultFromArray = (columnNames: string[]) => (row: string[]) => {
  const result = {}
  for (let i = 0; i < columnNames.length; i++) {
    const key = columnNames[i]

    if (isNumberColumn(key)) {
      result[key] = Number(row[i]);
    } else {
      result[key] = row[i];
    }
  }
  return result;
}

export interface BinderTable {
  rows: [],
  columns: [],
}

export const makeBinders = (fileContent: string): BinderTable => {
  const rows = parseCSV(fileContent);
// valid only for results
  const columnNames = rows.shift();

  return {
    columns: columnNames,
    rows: rows.map(binderFromArray(columnNames))
  };
}

export const parseBinderResults = (fileContent: string): BinderTable => {
  const rows = parseCSV(fileContent);
// valid only for results
  const columnNames: [] = rows.shift();

  columnNames[0] = 'id';// because of strange column names in the files.
  // if (key.toUpperCase().indexOf('HYFT') > 0) {
  //   continue;
  // }
  return {
    columns: columnNames.filter(c => !(c.toUpperCase().indexOf('HYFT') >= 0 && c.toUpperCase().indexOf('LIST') >= 0)),
    rows: rows.map(binderResultFromArray(columnNames))
  };
}


export const createBindersGridColumn = (name: string): GridColDef => {
  const cs = columns_custom_settings[name] || {}

  if (isSequenceColumn(name)) {
    return {
      field: name,
      headerName: name,
      sortable: true,
      minWidth: 200,
      flex: 1,
      renderCell: (params: GridRenderCellParams<string>) => {
        return <Stack sx={{height: '100%', alignContent: 'center', justifyContent: "center"}}>
          <Stack sx={{lineHeight: 1.2}}>
            <AlignmentViewRow row={{offset: 0, sequence: params.value, id: params.row.id, type: SharedMolType.AA}}/>
          </Stack>
        </Stack>
      },
      ...cs
    }
  }

  if (isNumberColumn(name)) {
    return {
      field: name,
      headerName: name,
      type: "number",
      sortable: true,
      ...cs
    }
  }
  return {
    field: name,
    headerName: name,
    type: "string",
    sortable: true,
    ...cs
  }

}

