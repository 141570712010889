import * as React from 'react';
import {ReactElement, useLayoutEffect, useRef} from 'react';
import {ColoredRect} from "../alignmentView/alignmentViewTypes";

interface HeatmapProps {
  rects: ColoredRect[];
  width: number;
  height: number;
}

const renderCanvas = (canvas, rects: ColoredRect[]) => {
  const ctx = canvas.getContext('2d');
  ctx.clearRect(0, 0, window.innerHeight, window.innerWidth);
  rects.forEach((cRect) => {
    ctx.fillStyle = cRect.color;
    ctx.fillRect(cRect.x, cRect.y, cRect.width, cRect.height);
  });
};

const Heatmap = (props: HeatmapProps): ReactElement => {
  const {rects, height, width} = props;
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useLayoutEffect(() => {
    if (canvasRef && canvasRef.current) {
      renderCanvas(canvasRef.current, rects);
    }
  }, [canvasRef, width, height, rects]);

  return <canvas width={width} height={height} ref={canvasRef} style={{
    position: 'absolute', left: 0, top: 0
  }}/>;
};

export default Heatmap;
