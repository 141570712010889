// TODO: create app config.
import { Apps, APP_LIST, RuntimeConfig } from '@biostrand/components';
import { Box, Button, Typography } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import PortalLink from '../../portalLink/PortalLink';

interface NotFoundPageContentProps {
  currentApplicationId?: Apps;
  portalLink: string;
}

const BB = ({ children }: { children: JSX.Element | string }) => (
  <Box component={'span'} sx={{ color: theme => theme.palette.grey[800] }}>
    {children}
  </Box>
);

const NotFoundPageContent = (props: NotFoundPageContentProps): JSX.Element => {
  const { currentApplicationId, portalLink } = props;
  const currentApp =
    currentApplicationId !== Apps.PORTAL ? APP_LIST.find(app => app.id === currentApplicationId) : undefined;

  const [t] = useTranslation();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box>
        <Typography
          variant="h4"
          sx={{ color: theme => theme.palette.grey[200] }}
          title=">2V0W_1|Chain A|NPH1-1|AVENA SATIVA (4498)"
        >
          GEFLATTLERIEKNFVITDPRLPDNPII
          <br />
          FASDSFLQLTEYSREEILGRNCRFLQGP
          <br />
          <BB>404</BB>
          ETDRATVRKIRDAIDNQTEVTVQLI
          <br />
          <BB>PAGE</BB>N<BB>NOT</BB>Y<BB>FOUND</BB>
          TKSGKKFWNLFHLQ
          <br />
          PMRDQKGDVQYFIGVQLDGTEHVRDAAE
          <br />
          REGVMLIKKTAENIDEAAKEL...{' '}
        </Typography>
        <Typography variant="body1" sx={{ mt: 4, mb: 4 }}>
          {t('This page is out of date, has been deleted or does not exist at all...')}
        </Typography>
        <Box>
          {currentApp ? (
            <Button variant="contained" component={RouterLink} color="primary" to={'/'} sx={{ mr: 4 }}>
              {t('Back to ')}
              {currentApp.name}
            </Button>
          ) : null}

          <PortalLink url={RuntimeConfig.getPortalLink()}>
            <Button variant="outlined" color="primary">
              {t('Portal')}
            </Button>
          </PortalLink>
        </Box>
      </Box>
    </Box>
  );
};

export default NotFoundPageContent;
