import { END, eventChannel } from 'redux-saga';
import {PART_ERROR, PART_IS_READY, UPLOAD_PROGRESS} from './fileUploadManagerSlice';
import {
  CHUNK_SIZE,
  FilePart,
  FileUploadInfo,
  PartUploadErrorPayload,
  UploadCompletePayload,
  UploadProgressPayload
} from './fileUploadTypes';

export interface FileChannelOptions {
  endpoint: string;
}

export interface FumChannelEvent {
  type: string,
  payload: UploadProgressPayload | UploadCompletePayload | PartUploadErrorPayload
}

export function createFileUploadChannel(fileStatus: FileUploadInfo, filePart: FilePart) {
  return eventChannel(emitter => {
    const xhr = new XMLHttpRequest();
    const onProgress = (e: ProgressEvent | undefined) => {
      if (e && e.lengthComputable) {
        const progress = e.loaded / e.total;
        emitter({type: UPLOAD_PROGRESS, payload: { progress, filePart, fileStatus }});
      }
    };

    const onFailure = (e: ProgressEvent | undefined) => {
      emitter({type: PART_ERROR, payload: {  error: new Error('Upload failed') , filePart, fileStatus }});
      emitter(END);
    };

    xhr.upload.addEventListener('progress', onProgress);
    xhr.upload.addEventListener('error', onFailure);
    xhr.upload.addEventListener('abort', onFailure);

    xhr.onreadystatechange = () => {
      const { readyState, status } = xhr;

      if (readyState === 4) {
        if (status === 200) {
          const ETag = xhr.getResponseHeader('ETag');

          if (ETag) {
            emitter({type: PART_IS_READY, payload: {  ETag: ETag.replaceAll('"', '') , filePart, fileStatus }});
          }

          emitter(END);
        } else {
          onFailure(undefined);
        }
      }
    };
    const sentSize = (Number(filePart.index) - 1) * CHUNK_SIZE;
    const chunk = fileStatus.file?.slice(sentSize, sentSize + CHUNK_SIZE);

    xhr.open('PUT', filePart.uploadLink);
    xhr.send(chunk);

    return () => {
      xhr.upload.removeEventListener('progress', onProgress);
      xhr.upload.removeEventListener('error', onFailure);
      xhr.upload.removeEventListener('abort', onFailure);
      xhr.onreadystatechange = null;
      xhr.abort();
    };
  });
}
