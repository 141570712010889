import React, {ReactElement, useEffect, useState} from 'react';


import {Stack, Typography} from "@mui/material";
import useDimensions from "react-use-dimensions";
import {getAAColor, getDNAColor} from "./utils/aligmentViewColorUtils";
import styles from "./alignmentViewer.module.css";
import {BASE_SIZE} from "./AligmentBaseSize";
import {SharedMolType} from "@biostrand/biostrandapi/javascript/dist/AlignApi";

interface LabelProps {
  label: string | number;
  size: number;
}

interface RVProps {
  renderData;
}

const MARK_STEP = 5;

interface SFVRenderData {
  lines: string[][];
  itemsPerLine: number;
  getColor: (key: string) => string;
  className: string;
  steps;
}

const Tick = ({label, size}: LabelProps): ReactElement => (<span style={{
  minWidth: size * BASE_SIZE,
  minHeight: BASE_SIZE,
  width: BASE_SIZE * size,
  height: BASE_SIZE,
  fontWeight: 500,
  textAlign: 'left',
  fontSize: 9,
  borderLeft: '#dddddd 1px solid',
  paddingLeft: 3,
  paddingTop: 3,
}}>{label}</span>);


const renderPositive = (start: number, pointsNumber: number, step: number): ReactElement[] => {
  const points = new Array(Math.ceil(pointsNumber / step));
  points.fill(0);
  // eslint-disable-next-line react/no-array-index-key
  return points.map((xxx, index) => <Tick label={start + index * step} size={step} key={index * step}/>);
};

export const SingleFastaViewer = (props: RVProps): ReactElement => {
  const {
    renderData
  } = props;
  const sequenceInfo = renderData.data[0].sequence_info;
  const {sequence, mol_type, description} = sequenceInfo;
  const [containerRef, {width}] = useDimensions();
  const [rData, setRData] = useState<SFVRenderData | undefined>()

  useEffect(() => {
    if (width > 0 && sequence) {
      //const sizes =

      const steps = Math.ceil(width / (BASE_SIZE * MARK_STEP)) - 1;
      const itemsPerLine = steps * MARK_STEP;
      const linesNumber = Math.ceil(sequence.length / itemsPerLine);

      const lines = [];

      const getColor = mol_type === SharedMolType.AA ? getAAColor : getDNAColor;
      const className = mol_type === SharedMolType.AA ? styles.aaRect : styles.dnaRect;

      for (let i = 0; i < linesNumber; i++) {
        lines.push(sequence.substr(i * itemsPerLine, itemsPerLine).split(''));
      }

      setRData({
        lines,
        getColor,
        itemsPerLine,
        className,
        steps
      })
    }
  }, [width, renderData])

  return (
    <Stack ref={containerRef} sx={{backgroundColor: "", flex: 1}} spacing={1}>
      <Stack spacing={1} direction={"row"} sx={{alignItems: "flex-end"}}><Typography variant={'caption'}>Description: </Typography><Typography>{description}</Typography></Stack>
      <Stack spacing={1} direction={"row"} sx={{alignItems: "flex-end"}}><Typography variant={'caption'}>Mol type: </Typography><Typography>{mol_type}</Typography></Stack>
      {/*<PositionAxis offset={0} total={sequence.length} step={5} />*/}
      <Stack spacing={1}>
        {rData && rData.lines.map((line: string[], i: number) => (

          <Stack>
            <div style={{display: 'flex', flexWrap: 'wrap', flexDirection: 'row'}}>

              {line.map((l, i) => (

                <span key={`i-'+${i}+${l}`} className={rData.className} style={{backgroundColor: rData.getColor(l)}}>
                          {l}
                      </span>))
              }
            </div>
            <div style={{display: 'flex', flexWrap: 'wrap', flexDirection: 'row'}}>
              {renderPositive(i*rData.itemsPerLine, line.length, MARK_STEP)}
            </div>
          </Stack>
          // eslint-disable-next-line react/no-array-index-key

        ))}
      </Stack>
    </Stack>
  );
}
