import {faEllipsisH} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconButton, Menu, MenuItem} from '@mui/material';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {MenuItemDescription} from "./menuTypes";

interface Props {
    items: MenuItemDescription[],
    onActionSelected: (item: string) => void
}

export const CellMenu = (props: Props): JSX.Element => {
    const {items, onActionSelected} = props;
    const [t] = useTranslation();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton onClick={handleClick}>
                <FontAwesomeIcon icon={faEllipsisH} size={'xs'}/>
            </IconButton>
            <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}>
                {items.map(item => (
                    <MenuItem key={item.action} onClick={() => {
                        onActionSelected(item.action);
                        handleClose();
                    }}>
                        {t(item.name)}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};
