import {uApi} from "../../biostrandApi/uApi";

export const isTheSameFiles = (file1:File, file2:File): boolean => {
    return (file1.name === file2.name)
            && (file1.lastModified === file2.lastModified)
            && (file1.size === file2.size)
            && (file1.type === file2.type)
}

export const getFileExtension = (filePath: string): string => {
    return filePath.split('.').pop() || '';
}

export const normalizeFileName = (filePath: string) => {
    const filenameparts = filePath.split('/');
    if (filenameparts[0] === '') filenameparts.shift();
    return filenameparts.join('/');
}

export const downloadFile = async (datasetId: string, filePath: string) => {
    const fileUrlResult = await uApi.getDatasetManagerApi().datasetManagerGetDownloadUrl(datasetId, normalizeFileName(filePath));
    const link = document.createElement('a');
    //link.target = '_blank';
    link.href = fileUrlResult.data.download_url || '';
    link.click();
}