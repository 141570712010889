import {
  ProtoJobDataset,
  ProtoJobDatasetType,
  ProtoJobStatus,
  ProtoJobType
} from "@biostrand/biostrandapi/javascript/dist/JobManagerApi";
import {Chip, IconButton, Stack, Typography} from "@mui/material";
import {ChevronRight} from "@mui/icons-material";
import * as React from "react";
import dayjs from 'dayjs';
import {GridRenderCellParams} from '@mui/x-data-grid';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExternalLink} from "@fortawesome/pro-regular-svg-icons";
import {getStatusColor} from "../getStatusColor";
import {JobRun} from "@biostrand/biostrandapi/javascript/dist/BFFProjectsApi";

export const getJobColumns = (clickHandler, datasetOpenHandler) => [
  {
    field: 'job_type_name',
    headerName: 'Type',
    width: 150, //100,
    sortable: true,
    renderCell: (params: GridRenderCellParams<ProtoJobType>) => {
      const label = params?.row?.jobType?.name ? params?.row?.jobType?.name : params?.row.job_type_name
      return <Typography component={"div"} sx={{textOverflow: 'ellipsis', overflow: 'hidden'}} title={label} variant={"body2"}>{label}</Typography>;
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 120, //100,
    sortable: true,
    renderCell: (params: GridRenderCellParams<ProtoJobStatus>) => {
      return (
        <Chip
          color={getStatusColor(params.value)}
          title={params.row.failure_reason}
          label={params.row.status}
          variant={'outlined'}
          size={'small'}
        />
      );
    },
  },
  {
    field: 'created_on',
    headerName: 'Created',
    width: 180, //100,
    type: 'dataTime',
    sortable: true,
    align: 'left',
    valueGetter: (param) => {
      if (!!param.row.created_on) {
        return new Date(param.row.created_on);
      }
      return null
    },
    renderCell: (params: GridRenderCellParams<string>) => {
      return <div>{dayjs(params.row.created_on).format('MMMM DD, YYYY, hh:mm')}</div>;
    },
  },
  {
    field: 'last_updated_on',
    headerName: 'Last Updated',
    width: 180, //100,
    sortable: true,
    align: 'left',
    type: 'dateTime',
    valueGetter: (param) => {
      if (!!param.row.last_updated_on) {
        return new Date(param.row.last_updated_on);
      }
      return null
    },
    renderCell: (params: GridRenderCellParams<string>) => {
      return <div>{dayjs(params.row.last_updated_on).format('MMMM DD, YYYY, hh:mm')}</div>;
    },
  },
  {
    field: 'datasets',
    headerName: 'Input Datasets',
    flex: 1,
    sortable: true,
    align: 'left',
    renderCell: (params: GridRenderCellParams<ProtoJobType>) => {
      const datasets = params.row.datasets || params.row.job_datasets || []
      return (
        <Stack spacing={1} sx={{pt: 0.5, pb: 0.5}}>
          {datasets
            .filter((ds: ProtoJobDataset) => ds.dataset_type === ProtoJobDatasetType.INPUT)
            .filter((ds: ProtoJobDataset, index, list) => list.findIndex(item => item.id === ds.id) === index) // quick fix FIXME
            .map((ds: ProtoJobDataset, i) => {
            return (
              <Stack key={`${ds.name}-${i}`}>
                <div style={{wordBreak: "break-all"}}
                     onClick={() => datasetOpenHandler && datasetOpenHandler(ds.name)}>{ds.name} {' '}
                  <FontAwesomeIcon icon={faExternalLink} style={{
                    cursor: 'pointer',
                    marginLeft: 8,
                    fontSize: 12,
                    width: 12,
                  }}/></div>
              </Stack>
            );
          })}
        </Stack>
      );
    },
  },
  {
    field: 'job_datasets_out',
    headerName: 'Output Datasets',
    flex: 1,
    sortable: true,
    align: 'left',
    renderCell: (params: GridRenderCellParams<string>) => {
      const datasets = params.row.datasets || params.row.job_datasets || []
      return (
        <Stack spacing={1} sx={{pt: 0.5, pb: 0.5}}>
          {datasets
            .filter((ds: ProtoJobDataset) => ds.dataset_type === ProtoJobDatasetType.OUTPUT)
            .filter((ds: ProtoJobDataset, index, list) => list.findIndex(item => item.id === ds.id) === index) // quick fix FIXME
            .map((ds: ProtoJobDataset) => {
            return (
              <Stack key={ds.name}>
                <div style={{wordBreak: "break-all"}}
                     onClick={() => datasetOpenHandler && datasetOpenHandler(ds.name)}>{ds.name} {' '}
                  <FontAwesomeIcon icon={faExternalLink} style={{
                    cursor: 'pointer',
                    marginLeft: 8,
                    fontSize: 12,
                    width: 12
                  }}/></div>
              </Stack>
            );
          })}
        </Stack>
      );
    },
  },
  {
    field: 'open',
    headerName: '',
    width: 50, //100,
    sortable: false,
    align: 'right',
    renderCell: (params: GridRenderCellParams<string>) => {
      return (
        <IconButton
          onClick={() => {
            clickHandler(params.row.id);
          }}>
          <ChevronRight/>
        </IconButton>
      );
    },
  },
];

export type JobsHistoryItem = JobRun & {
  jobType?: ProtoJobType;
};
