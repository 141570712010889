import * as React from 'react';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {dataFilterSelector} from "@biostrand/components/src/filters/dataFiltersSelectors";
import {FilterType} from "@biostrand/components/src/filters/dataFiltersTypes";
import {updateDataFilter} from '@biostrand/components/src/filters/dataFiltersSlice';
import {capitalize, Input, MenuItem, Select, Stack, Typography} from '@mui/material';
import {GroupingItemNode} from "../../../groupingTypes";
import {firstLevelTypeSelector, groupRelatedDatasetsSelector, rootGroupsSelector} from "../../../core/groupsSelectors";
import {refreshGroupRelatedItems} from "../../../core/groupsSlice";


interface GroupsFilterProps {
    filterKey: string,
    filterItemKey?: string,
    selectedGroupId?: string,
}

export const GroupsFilter = (props: GroupsFilterProps): JSX.Element => {
    const {filterKey, filterItemKey, selectedGroupId} = props;
    const filters = useSelector(dataFilterSelector(filterKey));
    const [selectedGroup, setSelectedGroup] = useState();

    const groupingItems: undefined | GroupingItemNode[] = useSelector(rootGroupsSelector);
    const relatedDatasets = useSelector(groupRelatedDatasetsSelector(selectedGroup?.id));
    const rootType = useSelector(firstLevelTypeSelector);
    const dispatch = useDispatch();

    useEffect(()=> {
        if (groupingItems && selectedGroupId) {
            const group = groupingItems.find(g => g.id == selectedGroupId);
            setSelectedGroup(group);
        }
    }, [selectedGroupId, groupingItems])

    useEffect(() => {
       if (selectedGroup) {
            dispatch(refreshGroupRelatedItems(selectedGroup))
       } else {
           const newFilters = filters?.filter(filter => filter.filterItemKey !== filterItemKey) || [];
           dispatch(updateDataFilter({key: filterKey, filters: newFilters}));
       }
    }, [selectedGroup]);

    useEffect(() => {
        const newFilters = filters?.filter(filter => filter.filterItemKey !== filterItemKey) || [];

        if (relatedDatasets) {
            const filter = {
                filterItemKey, type: FilterType.TEST_FUNCTION, testFunction: (value) => {
                    return relatedDatasets.find(ds => ds.id === value.id);
                }
            }
            newFilters.push(filter);
            dispatch(updateDataFilter({key: filterKey, filters: newFilters}));
        }

    }, [relatedDatasets]);


    return (<Select
        size={"small"}
        id="multiple-limit-tags"
        value={selectedGroup?.id || 'none'}
        displayEmpty
        onChange={(event) => {
            if (event.target.value === 'none') {
                setSelectedGroup(undefined)
            } else {
                const group = groupingItems.find( gi => gi.id === event.target.value);
                setSelectedGroup(group);
            }

        }}
        sx={{
            minWidth: 120,
        }}
        input={
            <Input disableUnderline id="select-multiple-chip" placeholder="Status"/>
        }
        renderValue={() => {
            return (<Stack spacing={1} direction={'row'} sx={{alignItems: 'baseline'}}>
                <Typography variant={"body2"} sx={{mt: 0.2, pl: 2}}>{capitalize(rootType?.name || '')}: </Typography>
                <Typography variant={"subtitle2"}>{selectedGroup ? selectedGroup.name : 'None'}</Typography>
            </Stack>)
        }}>
        <MenuItem
            key={'None'}
            value={'none'}
            sx={{pr: 2, pl: 2, minWidth: 120}}
        >
            <Typography variant={"body2"}>None</Typography>
        </MenuItem>
        {groupingItems?.map((item) => (
            <MenuItem
                key={item.name}
                value={item.id}
                sx={{pr: 2, pl: 2, minWidth: 120}}
            >
                <Typography variant={"body2"}>{item.name.toLowerCase()}</Typography>
            </MenuItem>
        ))}
    </Select>)
}
