import * as React from 'react';
import {useEffect, useState} from 'react';
import {SearchInput} from "../../searchInput/SearchInput";
import {useDispatch, useSelector} from "react-redux";
import {dataFilterSelector} from "../../filters/dataFiltersSelectors";
import {FieldFilterType, FilterType} from "../../filters/dataFiltersTypes";
import {updateDataFilter} from '../../filters/dataFiltersSlice';
import {SxProps, Theme} from "@mui/system";

interface StringValueFilterInputProps {
  filterKey: string,
  filterItemKey?: string,
  field: string,
  fieldLabel?: string,
  sx?: SxProps<Theme>
}

export function StringValueFilterInput(props: StringValueFilterInputProps) {
  const {filterKey, filterItemKey, field, sx} = props;
  const filters = useSelector(dataFilterSelector(filterKey));
  const [currentValue, setCurrentValue] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    const initialFilter = filters?.find(filter => filter.filterItemKey === filterItemKey);
    setCurrentValue(initialFilter?.value?.like || '');

  }, [filters, filterKey])

  const changeHandler = (value: any) => {
    const newFilters = filters?.filter(filter => filter.filterItemKey !== filterItemKey) || [];

    if (value && value.length > 0) {
      let filter = {
        filterItemKey,
        type: FilterType.FIELD,
        value: {like: value},
        field,
        fieldType: FieldFilterType.STRING
      }
      newFilters.push(filter);
    }

    dispatch(updateDataFilter({key: filterKey, filters: newFilters}));
  }

  return <SearchInput sx={sx} initialValue={currentValue} onChange={changeHandler} />
}
