import * as React from "react";

import {ReactComponent as ReactLogo} from './LensAI.svg';

export const LensAiWhiteLogo = (props: { height?:number, style?: any}) => {
  const {height = 45, style} = props

  return (
    <ReactLogo title="Lens Ai Logo" style={{...style, height}} />
  )
}
