import React from "react";

interface HtmlViewProps {
  data: string,
}

export const HtmlView = (props: HtmlViewProps) => {
  const {data} = props;

  // fix for the relative (anchor) links;
  const data2 = data ? data + `<base href="about:srcdoc" />` : '';

  return (
    <div style={{
      position: "relative",
      flex: 1,
      overflow: "hidden",
      left: 0, top: 0, right: 0, bottom: 0,

      border: "1px solid #999999"
    }}>

      {data && <iframe id={'htmlPreviewIframe'}
                       name={'htmlPreviewIframe'}
                       sandbox={'allow-scripts allow-downloads'}
                       srcDoc={data2}
                       style={{width: '100%', height: '100%', border: "none"}}
                       referrerPolicy={"no-referrer"}
      />}
    </div>
  );
};
