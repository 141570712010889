import {Stack} from '@mui/system';
import * as React from 'react';
import {Radio, RadioGroup, SelectChangeEvent, Typography} from "@mui/material";
import {columnToColumnTypeItem, columnTypeItems} from "../../csvViewer/tableUtils";

interface ColumnTypeSelectionFieldProps {
  onChange: (c: string) => void
  column: any
}

export const ColumnTypeSelectionField = (props: ColumnTypeSelectionFieldProps) => {
  const {column, onChange} = props;

  const cti = columnToColumnTypeItem(column);

  const handleChange = (event: SelectChangeEvent) => {
    onChange(event.target.value as string);
  };

  return (
    <Stack direction={'row'} spacing={1} sx={{alignItems: "center", borderBottom: "1px solid #f2f2f2" }}>
      <Typography variant={"body1"} id="i-1"  sx={{width:300, maxWidth:300}}>{column.headerName || column.field}:</Typography>
      <RadioGroup
        value={cti.value || 'string'}
        onChange={handleChange} sx={{flex: 1}}
      >
        <Stack direction={'row'} spacing={1} sx={{alignItems: "center", minWidth: 700}}>
          {columnTypeItems.map(ct => <Stack
            sx={{
              width: 140,
              alignItems: "center",
              justifyContent: "center",
            }}>
            <Radio size={"small"} value={ct.value}/></Stack>)}
        </Stack>
      </RadioGroup>
    </Stack>
  )
};
