import {SearchHistoryEntry, SearchSettings} from "../core/search/searchHistoryTypes";

export function retrieveParsedFromLocalStorage<T>(key: string): T | null {
    return JSON.parse(localStorage.getItem(key) || 'null');
}

export function persistParsedToLocalStorage<T>(key: string, value: T): void {
    localStorage.setItem(key, JSON.stringify(value));
}

export function removeFromLocalStorage(key: string): void {
    localStorage.removeItem(key);
}

export function getSearchHistory( appOrgUserKey: string ): SearchHistoryEntry[] | null {
    return retrieveParsedFromLocalStorage<SearchHistoryEntry[]>(`${appOrgUserKey}.searchHistory`);
}

export function updateSearchHistory( appOrgUserKey: string, history: SearchHistoryEntry[] ): void {
    return persistParsedToLocalStorage<SearchHistoryEntry[]>(`${appOrgUserKey}.searchHistory`, history);
}
// TODO: @Illia: replace raw call in code

export function getSearchSettings( appOrgUserKey: string ): SearchSettings | null {
    return retrieveParsedFromLocalStorage<SearchSettings>(`${appOrgUserKey}.searchHistory`);
}

export function updateSearchSettings( appOrgUserKey: string, settings: SearchSettings ): void {
    return persistParsedToLocalStorage<SearchSettings>(`${appOrgUserKey}.searchHistory`, settings);
}
