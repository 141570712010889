import React from 'react';
import PortalPageAppLinks from './PortalPageAppLinks';
import PortalPageContent from './PortalPageContent';
import PortalPageHeader from './PortalPageHeader';
import {Box} from "@mui/material";
import {PageFooter} from "@biostrand/components";

const PortalPage = (): JSX.Element => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
        }}>
            <PortalPageHeader />
            <PortalPageAppLinks />
            <PortalPageContent />
            <PageFooter />
        </Box>
    );
};

export default PortalPage;
