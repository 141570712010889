import {Box, FormControl, FormControlLabel, Switch} from '@mui/material';
import {Field, useFormikContext} from 'formik';
import {TextField} from 'formik-mui';
import * as React from 'react';
import {ChangeEvent, ReactElement, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {TestFtpConnection} from './sftp/TestFtpConnection';
import {RBox} from "../layout/RBox";
import {CBox} from "../layout/CBox";
import {CreateFtpDatasetValues} from "./datasetTypes";

interface FormProps {
}

export function CreateFTPDatasetForm(props: FormProps): ReactElement {
  const [t] = useTranslation();

  const [isAnonymous, setIsAnonymous] = useState(false);
  const {values, setFieldValue, validateForm} = useFormikContext<CreateFtpDatasetValues>();
  const handleAnonymousChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsAnonymous(event.target.checked);
    if (event.target.checked) {
      setFieldValue(`connectionConfig.user`, 'anonymous', false);
      setFieldValue(`connectionConfig.pass`, 'anonymous', false);
    } else {
      setFieldValue(`connectionConfig.user`, '', false);
      setFieldValue(`connectionConfig.pass`, '', false);
    }
    setTimeout(() => {
      validateForm();
    }, 100);
  }

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', pt: 2, minWidth: 720}}>
      <CBox>
        <FormControl margin={'dense'} sx={{minWidth: 330, height: theme => theme.spacing(9)}}>
          <Field
            id="dataSetName"
            label={t('Data set name')}
            variant="outlined"
            required
            component={TextField}
            name="datasetName"
          />
        </FormControl>
        <RBox sx={{alignItems: 'center'}}>
          <FormControl margin={'dense'} sx={{height: theme => theme.spacing(9), flex: 1}}>
            <Field
              component={TextField}
              name={`connectionConfig.host`}
              label={t('Ftp Address')}
              variant="outlined"
              required
            />
          </FormControl>

          <TestFtpConnection config={values.connectionConfig}
                             sx={{width: (theme) => theme.spacing(20), mb: 1, ml: 2}}/>
        </RBox>

        <RBox sx={{alignItems: 'center'}}>
          <FormControl margin={'dense'} sx={{height: theme => theme.spacing(9), flex: 1, mr: 2}}>
            <Field
              component={TextField}
              name={`connectionConfig.user`}
              label={t('User')}
              variant="outlined"
              disabled={isAnonymous}
              required
            />
          </FormControl>
          <FormControl margin={'dense'} sx={{height: theme => theme.spacing(9), flex: 1}}>
            <Field
              component={TextField}
              name={`connectionConfig.pass`}
              label={t('Password')}
              variant="outlined"
              autoComplete="new-password"
              type="password"
              disabled={isAnonymous}
              required
            />
          </FormControl>

          <Box sx={{width: (theme) => theme.spacing(20), mb: 1, ml: 2}}>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  onChange={handleAnonymousChange}
                  checked={isAnonymous}
                />
              }
              label={t('Anonymous')}
            />
          </Box>
        </RBox>

        <FormControl margin={'dense'} sx={{height: theme => theme.spacing(9)}}>
          <Field component={TextField} id="path" name="connectionConfig.path" label={t('Path')} variant="outlined"/>
        </FormControl>
        <FormControl margin={'dense'} sx={{height: theme => theme.spacing(9)}}>
          <Field
            component={TextField}
            id="fileMask"
            name="connectionConfig.filter"
            label={t('Files mask, i.e. *.fa')}
            variant="outlined"
          />
        </FormControl>


      </CBox>
    </Box>
  );
}
