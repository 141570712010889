import {CommonQueryParameter, PasswordInput} from '@biostrand/components';
import {Button, Theme, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {useFormik} from 'formik';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useLocation} from 'react-router-dom';
import * as yup from 'yup';
import {RouteUrl} from '../../../routeUrl';
import {selectPasswordConfirmationStatus} from "./registrationSelectors";
import {completePasswordChallengeRequest, confirmNewPasswordRequest} from "./registerSlice";
import {sleep} from "@biostrand/components/src/utilites/sleep";
import {LoadingButton} from "@mui/lab";

const useStyles = makeStyles((theme: Theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: theme.spacing(12),
    },
    buttons: {
        display: 'flex',
        flexDirection: 'row',
    },
    input: {
        width: 320,
        height: theme.spacing(9),
        marginBottom: theme.spacing(3),
    },
    buttonMargin: {
        marginRight: theme.spacing(3),
    },
    title: {
        marginBottom: theme.spacing(1),
    },
    subTitle: {
        marginBottom: theme.spacing(4),
    },
}));

enum ViewMode {
    ResetPassword,
    AccountActivation,
}

const ConfirmPasswordForm = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const requestStatus = useSelector(selectPasswordConfirmationStatus);

    const location = useLocation();
    const queryParameters = new URLSearchParams(location.search);
    const email = queryParameters.get(CommonQueryParameter.Email) || '';
    const confirmationCode = queryParameters.get(CommonQueryParameter.ConfirmationCode) || '';
    const temporaryPassword = queryParameters.get(CommonQueryParameter.TemporaryPassword) || '';

    const viewMode = confirmationCode ? ViewMode.ResetPassword : ViewMode.AccountActivation;

    const validationSchema = yup.object({
        password: yup
            .string()
            .min(6, t('Password has to be longer than 6 characters!'))
            .defined(t('Password is required')),
    });

    const passwordForm = useFormik({
        initialValues: {
            password: '',
        },
        validationSchema: validationSchema,
        onSubmit: async values => {
            if (viewMode === ViewMode.AccountActivation) {
                dispatch(completePasswordChallengeRequest({ email, newPassword: values.password, temporaryPassword }));
            } else {
                dispatch(confirmNewPasswordRequest({ email, newPassword: values.password, confirmationCode }));
            }
            await sleep(5000);
        },
    });

    return (
        <form className={classes.form} onSubmit={passwordForm.handleSubmit}>
            {viewMode === ViewMode.AccountActivation ? (
                <>
                    <Typography variant='h4' className={classes.title}>
                        {t('Almost there...')}
                    </Typography>
                    <Typography variant='body1' className={classes.title}>
                        {t('Welcome to BioStrand!')}
                    </Typography>
                    <Typography variant='body1' className={classes.subTitle}>
                        {t('Please choose a password so we can activate your account')}
                    </Typography>
                </>
            ) : (
                <>
                    <Typography variant='h4' className={classes.title}>
                        {t('Welcome back!')}
                    </Typography>
                    <Typography variant='body1' className={classes.subTitle}>
                        {t('Please provide us with you new password')}
                    </Typography>
                </>
            )}
            <PasswordInput
                password={passwordForm.values.password}
                ariaLabel={t('toggle password visibility')}
                onChange={passwordForm.handleChange}
                error={passwordForm.touched.password && Boolean(passwordForm.errors.password)}
                helperText={passwordForm.touched.password ? passwordForm.errors.password : undefined}
            />

            <div className={classes.buttons}>
                <LoadingButton
                    loading={passwordForm.isSubmitting}
                    variant='contained'
                    color='primary'
                    size='large'
                    disabled={!!requestStatus}
                    className={classes.buttonMargin}
                    onClick={() => {
                        passwordForm.handleSubmit();
                    }}>
                    {viewMode === ViewMode.AccountActivation ? t('Get Started') : t('Confirm')}
                </LoadingButton>
                <Button
                    sx={{textTransform: "none"}}
                    color='primary'
                    size='large'
                    component={Link}
                    to={RouteUrl.Login}
                    className={classes.buttonMargin}>
                    {t('Back to login')}
                </Button>
            </div>
        </form>
    );
};

export default ConfirmPasswordForm;
