import {FilePart, FilePartStatus, FileUploadInfo} from "./fileUploadTypes";


export const calculateFileUploadProgress = (fileInfo: FileUploadInfo) => {
    function getProgress(total: number, filePart: FilePart) {
        const progress =
            filePart.status === FilePartStatus.DRAFT || filePart.status === FilePartStatus.ERROR
                ? 0
                : filePart.status === FilePartStatus.READY
                ? 1
                : filePart.progress;

        return total + (progress || 0) / fileInfo.parts.length;
    }

    return fileInfo.parts.reduce(getProgress, 0);
};

export enum DSStatuses {
    ERROR = 'error',
    READY = 'ready',
    FILE_MISSED = 'fileMissed',
    PENDING = 'pending',
    PROGRESS = 'progress',
}

export interface FileStatusSummary {
    isFileMissed: boolean;
    hasError: boolean;
    isDraft: boolean;
    isReady: boolean;
    isUploading: boolean;
}

export interface DatasetStatusSummary {
    [DSStatuses.ERROR]: FileUploadInfo[],
    [DSStatuses.READY]: FileUploadInfo[],
    [DSStatuses.PENDING]: FileUploadInfo[],
    [DSStatuses.PROGRESS]: FileUploadInfo[],
    [DSStatuses.FILE_MISSED]: FileUploadInfo[],
}

export const getFileStatusSummary = (file: FileUploadInfo): FileStatusSummary => {
    const fileStatusSummary: FileStatusSummary = {
        isFileMissed: true,
        hasError: false,
        isDraft: false,
        isReady: false,
        isUploading: false
    }
    let draftCount: number = 0;
    let errorCount: number = 0;
    let readyCount: number = 0;
    let uploadingCount: number = 0;


    for (let i = 0; i < file.parts.length; i++) {
        const part = file.parts[i];
        switch (part.status) {
            case FilePartStatus.DRAFT:
                if (part.progress && part.progress > 0) {
                    uploadingCount++;
                } else {
                    draftCount++;
                }
                break;
            case FilePartStatus.UPLOADING:
                uploadingCount++;
                break;
            case FilePartStatus.ERROR:
                errorCount++;
                break;
            case FilePartStatus.READY:
                readyCount++;
                break;
        }
    }
    fileStatusSummary.isFileMissed = !file.file && (readyCount !== file.parts.length);
    fileStatusSummary.hasError = errorCount > 0;
    fileStatusSummary.isDraft = draftCount === file.parts.length;
    fileStatusSummary.isReady = readyCount === file.parts.length;
    fileStatusSummary.isUploading = uploadingCount > 0;

    return fileStatusSummary;
}

export const getStatusSummary = (files: FileUploadInfo[]): DatasetStatusSummary => {

    const summary: DatasetStatusSummary = {
        [DSStatuses.ERROR]: [],
        [DSStatuses.READY]: [],
        [DSStatuses.PENDING]: [],
        [DSStatuses.PROGRESS]: [],
        [DSStatuses.FILE_MISSED]: [],
    }

    for (let i = 0; i < files.length; i++) {
        const fi = files[i];
        const fileStatus = getFileStatusSummary(fi);
        if (fileStatus.hasError) {
            summary[DSStatuses.ERROR].push(fi);
        }
        if (fileStatus.isReady) {
            summary[DSStatuses.READY].push(fi);
        }
        if (fileStatus.isDraft) {
            summary[DSStatuses.PENDING].push(fi);
        }
        if (fileStatus.isUploading) {
            summary[DSStatuses.PROGRESS].push(fi);
        }
        if (fileStatus.isFileMissed) {
            summary[DSStatuses.FILE_MISSED].push(fi);
        }
    }

    return summary;
}

export const calculateFilesProgress = (files: FileUploadInfo[]): number => {
    let progress = 0;
    const perFile = 1 / files.length;
    files.forEach(fileInfo => {
        progress += calculateFileUploadProgress(fileInfo) * perFile;
    });
    return progress;
}

