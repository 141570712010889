import { faPaperPlane } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@mui/material';
import * as React from 'react';

interface CopyDetailsNotificationButtonProps {
  description: string;
}

const SendDetailsNotificationButton = (props: CopyDetailsNotificationButtonProps): JSX.Element => {
  const { description } = props;
  const location = window.location;

  const getMail = (description: string) => {
    return encodeURI(`${description}\n\nPage: ${location}`);
  };

  return (
    <a href={`mailto:support@biostrand.be?subject=Error%20report&body=${getMail(description)}`}>
      <IconButton onClick={() => {}}>
        <FontAwesomeIcon icon={faPaperPlane} size="xs" color="#ffffff" />
      </IconButton>
    </a>
  );
};

export default SendDetailsNotificationButton;
