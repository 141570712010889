import {DatasetManagerDatasetEntity} from '@biostrand/biostrandapi/javascript/dist/DatasetManagerApi';
import {ChevronRight} from '@mui/icons-material';
import {IconButton, Stack} from '@mui/material';
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid';
import dayjs from 'dayjs';
import * as React from 'react';
import {useSelector} from 'react-redux';
import {CellMenu} from '../../grid/CellMenu';
import {MenuItemDescription} from '../../grid/menuTypes';
import {organizationSelector} from '../../slices/user/userSelectors';
import {DatasetDetailsItem} from '../DatasetDetailsItem';
import {DatasetNameCell} from './DatasetNameCell';

export enum COLUMN_NAMES {
  menu = 'menu',
  name_with_status = 'name_with_status',
  name = 'name',
  name_and_version = 'name_and_version',
  files = 'files',
  created_on = 'created_on',
  last_updated_on = 'last_updated_on',
  checkbox = 'checkbox'
}

export const getMenuColumn = (
  menuItems: MenuItemDescription[] & { aliasOfField?: COLUMN_NAMES },
  onMenuSelect: (row: DatasetManagerDatasetEntity[], action: string) => void,
): GridColDef => {
  return {
    field: COLUMN_NAMES.menu,
    headerName: '',
    width: 50,
    sortable: false,
    hideable: false,
    disableColumnMenu: true,
    renderCell: (params: GridRenderCellParams<DatasetManagerDatasetEntity>) => {

      // eslint-disable-next-line react-hooks/rules-of-hooks
      const organization = useSelector(organizationSelector);
      const onAction = (item: string) => {
        onMenuSelect(params.row, item);
      };
      return organization.id === params.row.organization_id ? (
        <CellMenu items={menuItems} onActionSelected={onAction}/>
      ) : (
        <div/>
      );
    },
  };
};

export const NAME_WITH_STATUS_COLUMN_WITH_CLICK = (clickHandler: (item: DatasetManagerDatasetEntity) => void) => {
  return {
    field: COLUMN_NAMES.name_with_status,
    aliasOfField: COLUMN_NAMES.name,
    headerName: 'Name',
    flex: 1,
    sortable: true,
    renderCell: (params: GridRenderCellParams<DatasetManagerDatasetEntity>) => {
      return <Stack sx={{width:'100%'}} onClick={event => {
        event.preventDefault();
        clickHandler(params.row);
      }}>
        <DatasetNameCell row={params.row}/>
      </Stack>;
    },
  }
};

export const NAME_WITH_STATUS_COLUMN = {
  field: COLUMN_NAMES.name_with_status,
  aliasOfField: COLUMN_NAMES.name,
  headerName: 'Name',
  flex: 1,
  sortable: true,
  renderCell: (params: GridRenderCellParams<DatasetManagerDatasetEntity>) => {
    return <DatasetNameCell row={params.row}/>;
  },
};

export const SELECTION_COLUMN = {
  field: COLUMN_NAMES.checkbox,
  headerName: '',
  flex: 1,
  sortable: true,
  renderCell: (params: GridRenderCellParams<DatasetManagerDatasetEntity>) => {
    return <div>{params.row.name}</div>;
  },
};

export const NAME_COLUMN = {
  field: COLUMN_NAMES.name,
  headerName: 'Name',
  flex: 1,
  sortable: true,
  renderCell: (params: GridRenderCellParams<DatasetManagerDatasetEntity>) => {
    return <div>{params.row.name}</div>;
  },
};

export const NAME_WITH_VERSION_COLUMN = {
  field: COLUMN_NAMES.name_and_version,
  aliasOfField: COLUMN_NAMES.name,
  headerName: 'Name',
  flex: 1,
  sortable: true,
  renderCell: (params: GridRenderCellParams<DatasetManagerDatasetEntity>) => {
    return <DatasetDetailsItem selectedDataset={params.row} showDate={false}/>;
  },
};


export const SHOW_CONTENT_COLUMN = (clickHandler: (item: DatasetManagerDatasetEntity) => void) => {
  return {
    field: COLUMN_NAMES.files,
    headerName: '',
    width: 60,
    sortable: false,
    renderCell: (params: GridRenderCellParams<DatasetManagerDatasetEntity>) => {
      return (
        <IconButton
          sx={{textTransform: 'none', width: 40}}
          onClick={event => {
            event.preventDefault();
            clickHandler(params.row);
          }}
        >
          <ChevronRight sx={{ml: -1, mr: -1}}/>
        </IconButton>
      );
    },
  };
};

export const CREATED_DATE_COLUMN = {
  field: COLUMN_NAMES.created_on,
  headerName: 'Created',
  minWidth: 170,
  align: 'right',
  type: 'dateTime',
  renderCell: (params: GridRenderCellParams<DatasetManagerDatasetEntity>) => {
    return <div>{params?.row?.created_on ? dayjs(params?.row?.created_on).format('MMMM DD, YYYY') : '-'}</div>;
  },
  sortable: true,
};

export const LAST_UPDATED_DATE_COLUMN = {
  field: COLUMN_NAMES.last_updated_on,
  headerName: 'Last Updated',
  minWidth: 170,
  align: 'right',
  sortable: true,
  type: 'dateTime',
  renderCell: (params: GridRenderCellParams<DatasetManagerDatasetEntity>) => {
    return (
      <div>{params?.row?.last_updated_on ? dayjs(params?.row?.last_updated_on).format('MMMM DD, YYYY') : '-'}</div>
    );
  },

};
