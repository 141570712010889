import {ListItem, ListItemIcon, ListItemText, Typography} from '@mui/material';
import * as React from 'react';
import {MenuItemDescription} from "./menuTypes";

type Props = MenuItemDescription;

export const RichMenuItem = (props: Props): JSX.Element => {
  const {icon, title, subTitle, onSelect} = props;

  return (
    <ListItem button id="select-dataset-from-lib" onClick={onSelect} sx={{pl: 2, pr: 4}}>
      <ListItemIcon sx={{minWidth: 40}}>
        {icon}
      </ListItemIcon>
      <ListItemText secondary={subTitle}>
        <Typography variant="subtitle2">{title}</Typography>
      </ListItemText>
    </ListItem>
  );
};
