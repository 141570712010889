import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export interface AppWithPopupState {
    popups: PopupsState
}

export interface Popup {
    title: JSX.Element | string;
    key: string;
    content: JSX.Element | string;
    fullscreen?: boolean;
}

export type PopupsState = Popup[];

export const popupSlice = createSlice({
         name: 'popups',
         initialState: [] as Popup[],
         reducers: {
           showPopup: (state, action: PayloadAction<Popup>) => {
             // @ts-ignore
               const newState = state.filter(popup => popup.key !== action.payload.key);
               newState.push(action.payload);
               return newState
           },
           closePopup: (state, action: PayloadAction<string>) => {
               const key = action.payload;
               return state.filter(popup => popup.key !== key);
           },
         },
       });

export const { showPopup, closePopup} = popupSlice.actions;
export default popupSlice.reducer;

export const popupsSelector = (state: AppWithPopupState) => state.popups || []