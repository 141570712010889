import {enqueueSnackbarNotification} from '@biostrand/components';
import {closePopup} from '@biostrand/components/src/popup/popupsSlice';
import CloseNotificationButton from "@biostrand/components/src/snackbarNotification/CloseNotificationButton";
import {useWindowDimensions} from '@biostrand/components/src/utilites/useWindowDimensions';
import {faCopy} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, IconButton, Stack, Typography} from '@mui/material';
import copy from "copy-to-clipboard";
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

interface JobSubmittedTemporaryPopupProps {
    jobId: string;
   // datasetId: string;
    popupKey: string;
}

export const JobSubmittedTemporaryPopup = (props: JobSubmittedTemporaryPopupProps): JSX.Element => {
    const { jobId, popupKey } = props;
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const { height = 300 } = useWindowDimensions();

    const onClose = () => {
        dispatch(closePopup(popupKey));
    };

    const onCopySequenceToClipboard = async (value: string): Promise<void> => {
        try {
            await copy(value);

            dispatch(
                enqueueSnackbarNotification({
                    message: t('Copied to clipboard.'),
                    key: 'success-key',
                    options: {
                        variant: 'success',
                        persist: false,
                        action: key => <CloseNotificationButton notificationKey={key}>Close</CloseNotificationButton>,
                    },
                }),
            );
        } catch (error) {
        }
    };


    return (
        <Stack sx={{ overflowY: 'hidden', minWidth: 400, overflow: 'hidden', maxHeight: height - 200 }} spacing={2}>
            <Stack direction={'row'} spacing={1} sx={{alignItems:'flex-end' }}>
                <Typography variant={'caption'}>Job Id:</Typography>
                <Typography>{jobId}</Typography>
                <div style={{flex:1}}/>
                <IconButton
                        color='primary'
                        size={'small'}
                        onClick={(): Promise<void> => onCopySequenceToClipboard(jobId)}>
                    <FontAwesomeIcon icon={faCopy} size='sm' />
                </IconButton>
            </Stack>
            {/*<Stack direction={'row'} spacing={1} sx={{alignItems:'flex-end' }}>*/}
            {/*    <Typography variant={'caption'}>Dataset Id:</Typography>*/}
            {/*    <Typography>{datasetId}</Typography>*/}
            {/*    <div style={{flex:1}}/>*/}
            {/*    <IconButton*/}
            {/*        color='primary'*/}
            {/*        size={'small'}*/}
            {/*        onClick={(): Promise<void> => onCopySequenceToClipboard(datasetId)}>*/}
            {/*        <FontAwesomeIcon icon={faCopy} size='sm' />*/}
            {/*    </IconButton>*/}
            {/*</Stack>*/}

            <Stack direction={'row'} spacing={2} sx={{ justifyContent: 'flex-end', mt: 2 }}>
                <Button variant={'outlined'} onClick={onClose}>
                    {t('Close')}
                </Button>
            </Stack>
        </Stack>
    );
};
