import * as React from "react";
import {ChangeEvent, ReactElement, ReactNode, useEffect, useRef, useState} from "react";
import {Stack} from "@mui/material";

interface FolderSelectionHOCProps {
  onFolderSelection: (filePath: FileList) => void,
  values: any,
  children: ReactNode|ReactNode[]
}

export const FolderSelectionHOC = ({children, onFolderSelection, values}: FolderSelectionHOCProps): ReactElement => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [ke, setKe] = useState<string>('key-0')

  useEffect(()=> {
    setKe('key'+new Date().toISOString());
  }, [values])

  const handleClick = () => {
    hiddenFileInput.current?.click();
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const fileUploaded = event.target?.files;
    if (fileUploaded) {
      onFolderSelection(fileUploaded);
    }
  };

  return (
    <Stack onClick={handleClick}>
      {children}
      <input
        key={ke}
        type="file"
        webkitdirectory=""
        onChange={handleChange}
        ref={hiddenFileInput}
        style={{display: "none"}}
      />
    </Stack>
  );
};
