import {Avatar, Box, Popover, Skeleton, Typography} from '@mui/material';
import * as React from 'react';
import {AvatarMenuList} from './AvatarMenuList';
import {Stack, Theme} from "@mui/system";
import {useSelector} from "react-redux";
import {currentUserSelector, organizationSelector} from "../../../slices/user/userSelectors";
import {
  UserManagerOrganizationEntity,
  UserManagerUserEntity
} from "@biostrand/biostrandapi/javascript/dist/UserManagerApi";

const classes = {
    labelsContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: (theme: Theme) => theme.spacing(2),
        minWidth: (theme: Theme) => theme.spacing(20),
        color: 'primary.contrastText',
    },
    toolBarColor: {
        opacity: 0.54,
    },
    avatar: {
        backgroundColor: 'primary.contrastText',
        color: 'primary.dark',
    },
} as const;

const UserAvatarMenu = (): JSX.Element => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
    const currentUser:  UserManagerUserEntity | undefined = useSelector(currentUserSelector)
    const organization:  UserManagerOrganizationEntity | undefined = useSelector(organizationSelector);

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    if (currentUser && organization) {
        return (
            <>
                <Stack sx={{display: 'flex', flexDirection: 'row', ml: 2, cursor: 'pointer'}} aria-describedby={id} onClick={handleClick}>
                    <Avatar
                        alt={currentUser.email}
                        sx={classes.avatar}>
                        {currentUser.email ? currentUser.email.substring(0, 2).toUpperCase() : '?'}
                    </Avatar>
                    <Box sx={classes.labelsContainer}>
                        <Typography variant='body2'>{currentUser.email}</Typography>
                        <Typography variant='caption' sx={classes.toolBarColor}>
                            {currentUser.organization_name}
                        </Typography>
                    </Box>
                </Stack>
                <Popover
                    sx={{m: 1}}
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}>
                    <AvatarMenuList user={currentUser} />
                </Popover>
            </>
        );
    }

    return (
        <Box sx={{display: 'flex', flexDirection: 'row', ml: 2, cursor: 'pointer', border: ' 1px red solid',}} aria-describedby={id} onClick={handleClick}>
            <Skeleton animation="wave" variant="circular" width={40} height={40}
                      sx={{bgcolor:'gray.500'}}/>
            <Box sx={classes.labelsContainer}>
                <Typography variant='body2'>
                    <Skeleton animation="wave" sx={{bgcolor:'gray.500'}}/>
                </Typography>
                <Typography variant='caption' sx={classes.toolBarColor}>
                    <Skeleton animation="wave" sx={{bgcolor:'gray.500'}}/>
                </Typography>
            </Box>
        </Box>
    );
};

export default UserAvatarMenu;
