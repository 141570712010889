import {Link} from '@mui/material';
import React from 'react';
import {useSelector} from 'react-redux';
import {Link as RouterLink} from 'react-router-dom';
import {RuntimeConfig} from '../runtimeConfig/RuntimeConfig';
import {currentAppIDSelector} from '../slices/user/userSelectors';
import {SxProps} from "@mui/system";
import {Apps} from "../applications/applicationTypes";

interface PortalLinkProps {
  url?: string;
  children: JSX.Element | JSX.Element[];
  sx?: SxProps;
}

const PortalLink = (props: PortalLinkProps): JSX.Element => {
  const { url, children, ...rest } = props;
  const currentAppId = useSelector(currentAppIDSelector);
  const currentAppLink = RuntimeConfig.getAppLinks()[currentAppId as string];
  const portalLink = RuntimeConfig.getAppLinks()[Apps.PORTAL];

  const isTheSameApp = (url || '').indexOf(currentAppLink) === 0;
  const isPortalApp = (url || '').indexOf(portalLink) === 0 ;

  if (currentAppId === Apps.PORTAL && isPortalApp) {
    const actualUrl = new URL(url as string);
    const path = (currentAppLink === url) ? '/' : url?.substring(portalLink.length);

    return (
      <Link
        component={RouterLink}
        to={path + '?' + actualUrl.search}
        color="inherit"
        underline={'none'}
        {...rest}

      >
        {children}
      </Link>
    );
  }

  if (isTheSameApp) {
    const actualUrl = new URL(url as string);
    const path = (currentAppLink === url) ? '/' : url?.substring(currentAppLink.length);

    return (
      <Link
        component={RouterLink}
        to={path + '?' + actualUrl.search}
        color="inherit"
        underline={'none'}
        {...rest}

      >
        {children}
      </Link>
    );
  }
  return (
    <Link href={url as string} color="inherit" underline={'none'} {...rest}>
      {children}
    </Link>
  );
};

export default PortalLink;
