import {DatasetManagerDatasetEntity} from '@biostrand/biostrandapi/javascript/dist/DatasetManagerApi';
import {
    CloseNotificationButton,
    DATASET_ACTIONS_LIST,
    DatasetAction,
    datasetsSelector,
    deleteDatasetsAction,
    enqueueSnackbarNotification,
    isDatasetsLoadingSelector,
    refreshDatasetsAction,
} from '@biostrand/components';
import {COLUMN_NAMES} from '@biostrand/components/src/datasets/datasetGrid/datasetColumns';
import {DatasetsListBase} from '@biostrand/components/src/datasets/datasetGrid/DatasetsListBase';
import {getDatasetNameFromId} from '@biostrand/components/src/datasets/datasetIdUtils';
import {UploadProgressPage} from '@biostrand/components/src/datasets/UploadProgressPage';
import {Stack, Tab, Tabs, Typography} from '@mui/material';
import {push} from 'connected-react-router';
import {useConfirm} from 'material-ui-confirm';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {RouteUrl} from '../../../../routeUrl';
import {AddDatasetsButton} from "./AddDatasetsButton";
import {useLocation} from "react-router-dom";

const DatasetsSettingsContent = (): JSX.Element => {
    const [t] = useTranslation();
    const [selectedTab, setSelectedTab] = useState<number>(1);
    const confirm = useConfirm();
    const dispatch = useDispatch();
    const location = useLocation();
    const datasets: DatasetManagerDatasetEntity[] | undefined = useSelector(datasetsSelector);
    const isDatasetsLoading: boolean = useSelector(isDatasetsLoadingSelector);

    useEffect(() => {
        dispatch(refreshDatasetsAction());
    }, []);

    useEffect(() => {
        if (location.pathname === RouteUrl.DataSetsLocalFileUploadManager) {
            setSelectedTab(2)
        } else {
            setSelectedTab(1)
        }
    }, [location]);


    const handleDelete = async (ds: DatasetManagerDatasetEntity) => {
        try {
            if (ds.name) {
                await confirm({
                    title: t('Delete dataset'),
                    description: t(`This will permanently delete ${ds.name} dataset.`),
                });
                dispatch(deleteDatasetsAction(ds.id || ''));
            }
        } catch (e) {
            if (e) {
                dispatch(
                    enqueueSnackbarNotification({
                        message: t('Delete dataset'),
                        key: 'delete-error',
                        options: {
                            variant: 'error',
                            persist: true,
                            action: key => (
                                <CloseNotificationButton notificationKey={key}>Close</CloseNotificationButton>
                            ),
                        },
                    }),
                );
            }
        } finally {
        }
    };

    const handleDatasetAction = async (ds: DatasetManagerDatasetEntity, action: DatasetAction | string) => {
        switch (action) {
            case DatasetAction.DELETE:
                await handleDelete(ds);
                break;
            case DatasetAction.EDIT:
                //await handleEdit(ds);
                break;
        }
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        if (newValue === 2) {
            event.preventDefault();
            dispatch(push(RouteUrl.DataSetsLocalFileUploadManager));
        }
        if (newValue === 1) {
            event.preventDefault();
            dispatch(push(RouteUrl.DataSetsSettings));
        }
        //setSelectedTab(newValue);
    };

    return (
        <Stack
            sx={{
                flex: 1,
                m: 4,
                overflowY: 'hidden',
            }}>
            <Stack direction={"row"} sx={{mb: 2}}>
                <Typography variant={'h4'} sx={{flex: 1}}>
                    {t('Datasets')}
                </Typography>
                <AddDatasetsButton />
            </Stack>

            <Tabs value={selectedTab} onChange={handleTabChange} sx={{mb: 2}}>
                <Tab label='Overview' value={1}/>
                <Tab label='Dataset uploading' value={2}/>
            </Tabs>
            {selectedTab === 2 ? <UploadProgressPage /> : undefined}
            {selectedTab === 1 ? (
                <DatasetsListBase
                    datasets={datasets}
                    name={"dsSettingsPage"}
                    isDatasetsLoading={isDatasetsLoading}
                    onDatasetSelect={datasets => {
                        console.log(datasets);
                    }}
                    onDatasetOpen={datasetsNames => {
                        const dsName = datasetsNames[0] as string;
                        dispatch(push(`${RouteUrl.DataSetsSettings}/${getDatasetNameFromId(dsName)}`));
                    }}

                    showMenu={true}
                    menuItems={DATASET_ACTIONS_LIST}
                    onMenuAction={handleDatasetAction}
                    columns={[
                        COLUMN_NAMES.name_and_version,
                        COLUMN_NAMES.created_on,
                        COLUMN_NAMES.last_updated_on,
                        COLUMN_NAMES.files,
                    ]}
                />
            ) : undefined }
        </Stack>
    );
};

export default DatasetsSettingsContent;
