import {Stack} from '@mui/system';
import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {PopupWindow} from '../../popup/PopupWindow';
import {ColumnTypeSelectionField} from "./ColumnTypeSelectionField";
import {GRID_CHECKBOX_SELECTION_FIELD} from "@mui/x-data-grid";
import {Button, FormControlLabel, Switch, Typography} from "@mui/material";
import {columnTypeItems, CSV_ROW_ID_FIELD, valueToColumnTypeItem} from "../../csvViewer/tableUtils";


interface ColumnTypesSettingsPopupProps {
  open: boolean;
  settings: any;
  columns: any;
  visibleColumns: any;
  onCancel: () => void;
  onApplySettings: (settings) => void;
}

export const ColumnTypesSettingsPopup = (props: ColumnTypesSettingsPopupProps) => {
  const {onCancel, onApplySettings, open, settings, columns, visibleColumns} = props;
  const [t] = useTranslation();
  const [currentColumns, setCurrentColumns] = useState([])
  const [onlyVisible, setOnlyVisible] = useState(false);

  useEffect(() => {
    const cols = onlyVisible ? visibleColumns : columns

    if (cols && settings) {
      const newDef = cols
        .filter(col => col.field !== GRID_CHECKBOX_SELECTION_FIELD)
        .filter(col => col.field !== CSV_ROW_ID_FIELD)
        .map((col) => {
        return {
          ...col,
          isChanged: !!settings[col.field] ? 0 : col.isChanged++
        }
      })
      setCurrentColumns(newDef)
    }
  }, [settings, columns, visibleColumns, onlyVisible]);


  const onApplyChanges = useCallback((newColSet) => {
    const updatedSettings = {...settings}
    newColSet.forEach(colSet => {
      if (updatedSettings[colSet.field]) {
        updatedSettings[colSet.field] = {...updatedSettings[colSet.field], ...colSet}
      } else {
        updatedSettings[colSet.field] = {...colSet}
      }
    })

    onApplySettings(updatedSettings);
  }, [settings, onApplySettings])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOnlyVisible(event.target.checked);
  };

  return (
    <PopupWindow open={open} title={t('Columns settings')} onClose={onCancel}>
      <Stack>
        <FormControlLabel
          control={
            <Switch checked={onlyVisible} onChange={handleChange} name="gilad"/>
          }
          label="Only visible columns"
        />
      </Stack>
      <Stack direction={'column'} sx={{minHeight: 600, minWidth: 600, flex: 1, overflow:'hidden'}}>
        <Stack direction={'row'} spacing={1} sx={{alignItems: "center", minWidth: 700, mt:1, mb:1}}>
          <Stack sx={{width: 300, maxWidth: 300}}/>
          {columnTypeItems.map(ct => <Stack
            sx={{
              width: 140,
              alignItems: "center",
              justifyContent: "center"
            }}><Typography variant={"subtitle2"}>{ct.label}</Typography></Stack>)}
        </Stack>
        <Stack sx={{overflowY: 'scroll', }}>
        {currentColumns && currentColumns.map((column, index) => {
          return <ColumnTypeSelectionField key={column.field}
                                           column={column}
                                           onChange={(updatedValue) => {
                                             const {label, value, type, link_type} = valueToColumnTypeItem(updatedValue)
                                             currentColumns[index].type = type;
                                             currentColumns[index].link_type = link_type;
                                             setCurrentColumns([...currentColumns])
                                           }}/>
        })}
        </Stack>
      </Stack>
      <Stack direction={'row'} sx={{width: "100%", mt: 2, mb: 0, mr: 2, justifyContent: 'flex-end'}} spacing={2}>
        <Button variant={'outlined'} onClick={onCancel}>
          {t('Close')}
        </Button>
        <Button sx={{mr: 2}} variant={'contained'} onClick={() => {

          onCancel()
          onApplyChanges(currentColumns)

        }}>
          {t('Apply changes')}
        </Button>

      </Stack>
    </PopupWindow>
  );
};
