import React, {ReactElement, useEffect, useState} from 'react';

import {Stack} from "@mui/system";
import {uApi} from "../biostrandApi/uApi";
import {AlignmentViewRenderData, Highlight} from "./alignmentViewTypes";
import {prepareAlignmentViewRenderData} from "@biostrand/retrieve-and-relate/src/app/pages/private/search/components/selectSequencePopup/prepareAlignmentRenderData";
import AlignmentViewer from "./AlignmentViewer";
import SelectSequenceLabelRow
  from "@biostrand/retrieve-and-relate/src/app/pages/private/search/components/selectSequencePopup/SelectSequenceLabelRow";
import {Button, Typography} from "@mui/material";
import {SingleFastaViewer} from "./SingleFastaViewer";
import {enqueueSnackbarNotification} from "../snackbarNotification/snackbarNotificationSlice";
import {CloseNotificationButton} from "../index";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {SharedQueryType} from "@biostrand/biostrandapi/javascript/dist/RetrieveAndRelateApi";

interface RVProps {
  data: string;
}

export const FastaPreview = (props: RVProps): ReactElement => {
  const {
    data
  } = props;
  const [multifastaRenderData, setMultifastaRenderData] = useState<AlignmentViewRenderData | null>(null);
  const [oneSequenceRenderData, setOneSequenceRenderData] = useState<AlignmentViewRenderData | null>(null);

  const [totalLinesIfMoreThan50lines, setTotalLinesIfMoreThan50lines] = useState<number>(-1);
  const [noticeMessage, setNoticeMessage] = useState<string | undefined>();
  const [highlighted, setHighlighted] = useState<Highlight | undefined>(undefined)
  const dispatch = useDispatch();
  const [t] = useTranslation();

  useEffect(() => {
    if (data) {
      const loadData = async () => {
        const lines = data.split('>').filter(s => s !== '');

        let trimmedQuery;
        if (lines.length > 50 /* 50 sequences */) {
          setTotalLinesIfMoreThan50lines(lines.length );
          trimmedQuery = '>' + lines.slice(0, 50).join('>'); //add '>' mark to the first line
        } else {
          trimmedQuery = '>' + lines.join('>');
          setTotalLinesIfMoreThan50lines(-1);
        }


        if (trimmedQuery.length > 50*1024) {
          trimmedQuery = trimmedQuery.substr(0, 50*1024)  // 50kb limit;
          setNoticeMessage('Fasta viewer uses only first 50kb to show.')
        }

        try {
          const result = await uApi.getRetrieveAndRelateApi().retrieveAndRelateEnrichQuery({query: trimmedQuery});
          const enrichedQuery = result.data;

          if (enrichedQuery.type === SharedQueryType.MULTI_FASTA) {
            const renderData: AlignmentViewRenderData | null = prepareAlignmentViewRenderData(enrichedQuery);
            setMultifastaRenderData(renderData);
          }
          if (enrichedQuery.type === SharedQueryType.SINGLE_FASTA) {
            setOneSequenceRenderData(enrichedQuery);
          }
        } catch (e) {
          dispatch(
            enqueueSnackbarNotification({
              message: t('Preview error.'),
              key: 'preview-error',
              options: {
                variant: 'error',
                persist: true,
                action: key => (
                  <CloseNotificationButton notificationKey={key}>Close</CloseNotificationButton>
                ),
              },
            }),
          );
        }
      }
      loadData();
    }
  }, [data]);

  const onHighlight = (highlight: Highlight | undefined) => {
    setHighlighted(highlight);
  };

  return (
    <Stack sx={{pr: 1, mb: 2}} spacing={1}>
      <Stack>
        {totalLinesIfMoreThan50lines > -1 ?
          <Typography variant={'body1'}>First 50 of {totalLinesIfMoreThan50lines} sequences are
            shown</Typography> : null}
        {noticeMessage ?  <Typography variant={'body1'}>{noticeMessage}</Typography> : null}
        {multifastaRenderData ?

          <Button onClick={() => {
            onHighlight({exclude: false, pattern: ''});
          }}>clear selection </Button> : ''}
      </Stack>
      {multifastaRenderData ?
        <AlignmentViewer
          renderData={multifastaRenderData}
          LabelRow={SelectSequenceLabelRow}
          onSubsequenceSelected={() => {
          }}
          onSequenceSelected={() => {
          }}
          showMenu
          highlighted={highlighted}
          onHighlight={onHighlight}
        />
        : null}

      {oneSequenceRenderData ?
        <SingleFastaViewer
          renderData={oneSequenceRenderData}
        />
        : null}

      {!(multifastaRenderData || oneSequenceRenderData) ? 'Aligning sequences...' : null}

    </Stack>
  );
}
