import { JobStatus } from "@biostrand/biostrandapi/javascript/dist/BFFProjectsApi";
import {ProtoJobStatus} from "@biostrand/biostrandapi/javascript/dist/JobManagerApi";

export const getStatusColor = (status: ProtoJobStatus | JobStatus | undefined): string|undefined => {
    switch (status) {
        case ProtoJobStatus.SUCCEEDED:
        case JobStatus.SUCCEEDED:
            return 'success';
        case ProtoJobStatus.FAILED:
        case JobStatus.FAILED:
            return 'error';
        case ProtoJobStatus.SUBMITTED:
        case JobStatus.SUBMITTED:
            return 'info';
        case ProtoJobStatus.RUNNING:
        case JobStatus.RUNNING:
            return 'info';
        case ProtoJobStatus.UNSPECIFIED:
        case JobStatus.UNSPECIFIED:
        case ProtoJobStatus.ABORTED:
        case JobStatus.ABORTED:
            return undefined;
    }
    return undefined;
};
