import {getAccessToken, RuntimeConfig} from '@biostrand/components';
import axios from 'axios';
import {BiostrandUnitedApi, Configuration} from "@biostrand/biostrandapi";

const addInterceptor = () => {
  axios.interceptors.response.use(
      function(response) {
        return response;
      },
      async function(error) {
        if (error.response?.status === 500 && error.response?.data?.message === "A1+Invalid authorization header") {
          console.log(error);
          window.location.href = RuntimeConfig.loginUrl();
        } else {
          return Promise.reject(error);
        }
      },
  );
}

const config = new Configuration({
  apiKey: getAccessToken,
  basePath: window.location.origin,
});

const configureUApi = (config: Configuration): BiostrandUnitedApi => {
  addInterceptor()
  return new BiostrandUnitedApi(config);
};

export const uApi = configureUApi(config);