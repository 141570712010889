import {isSupportedByMolstar} from "../../molstar/MolstarUtils";
import {getFileExtension} from "../fileupload/fileUtils";

export const HIDDEN_FILE_PREFIX = "_b_i_o_";

export const isSupportedFile = (path: string): boolean => {
    const ext = getFileExtension(path).toLowerCase();

    if (isSupportedByMolstar(ext)) return  true;
    if (ext) {
        switch (ext.toLowerCase()) {
            case 'pdf':

            case 'html':
            case 'htm':
            case 'svg':
//
            case 'txt':
            case 'csv':
            case 'tsv':
            case 'xml':

// fasta
            case 'fasta':
            case 'fa':

// json
            case 'json':

// Images
            case 'gif':
            case 'jpg':
            case 'jpeg':
            case 'jfif':
            case 'pjpeg':
            case 'pjp':
            case 'png':

            case 'apng':
            case 'webp':
            case 'bmp':
            case 'tif':
            case 'tiff':
                return true;
        }
    }

    return false;
}

export const getFileName = ( path:string ) => {
    return path.split('/').pop();
}

export const getFileFolder = ( path:string ) => {
    const parts = path.split('/');
    parts.pop();
    return parts.join('/');
}

export const getMetaFilePath = (fileList, filePath): string | undefined => {
    const fileName = getFileName(filePath);
    const fileFolder = getFileFolder(filePath);
    const metaFilePath =  fileFolder+'/'+HIDDEN_FILE_PREFIX + fileName + '.conf';

    const containsMeta = fileList && filePath && fileList.find( fo => {
        return (fo.name === metaFilePath || fo.name === '/'+metaFilePath)
    } );

    if (containsMeta) return metaFilePath;
    return;
}