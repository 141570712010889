import {DatasetManagerDatasetStatusCode} from "@biostrand/biostrandapi/javascript/dist/DatasetManagerApi";
import {FieldFilterType, FilterDimension} from "../../filters/dataFiltersTypes";

export const DATASET_STATUS_DIMENSION: FilterDimension = {
    type: FieldFilterType.ENUM,
    items: Object.keys(DatasetManagerDatasetStatusCode).map(statusKey => DatasetManagerDatasetStatusCode[statusKey])
}

export const DATASET_STATUSES = [
    DatasetManagerDatasetStatusCode.COMPLETE,
    DatasetManagerDatasetStatusCode.PROCESSING,
    DatasetManagerDatasetStatusCode.REGISTERED,
    DatasetManagerDatasetStatusCode.INCOMPLETE,
    DatasetManagerDatasetStatusCode.ACTIVE
]