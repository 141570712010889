import {Chip} from '@mui/material';
import * as React from 'react';
import {getDatasetStatusColor} from "./getDatasetStatusColor";


interface Props {
    status: string;
}

export const DatasetStatusChip = (props: Props): JSX.Element => {
    const { status } = props;
    return (
            <Chip
              variant={"outlined"}
              label={status?.toLowerCase()}
              color={getDatasetStatusColor(status)}
              size={'small'}
              sx={{ height: 20 }} />
    );
};
