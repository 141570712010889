import React, { ReactElement, useCallback } from 'react';
import styles from '../alignmentViewer.module.css';
import {LabelRowProps} from "../alignmentViewTypes";

const BasicAlignmentViewLabelRow = (props: LabelRowProps): ReactElement => {
  const { row, onSelect, selected } = props;

  const clickHandler = useCallback(() => {
    if (!onSelect) return;

    if (selected) {
      onSelect(null);
    } else {
      onSelect(row.id as string);
    }
  }, [onSelect, row.id, selected]);

  const title = row.name ? `${row.name}\n\nID: ${row.id}` : `ID: ${row.id}`;

  return (
    <div className={selected ? styles.labelRowSelected : styles.labelRow} onClick={clickHandler} title={title}>
      {row.label}
      {selected && <div className={styles.labelButtons}>btns</div>}
    </div>
  );
};

export default BasicAlignmentViewLabelRow;
