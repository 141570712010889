import { Box, Button, Typography } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import PortalLink from '../../portalLink/PortalLink';
import { RuntimeConfig } from '../../runtimeConfig/RuntimeConfig';

const BB = ({ children }: { children: JSX.Element | string }) => (
  <Box component={'span'} sx={{ color: theme => theme.palette.grey[800] }}>
    {children}
  </Box>
);

const NoAccessPageContent = (): JSX.Element => {
  const [t] = useTranslation();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box>
        <Typography
          variant="h4"
          sx={{ color: theme => theme.palette.grey[200] }}
          title=">sp|O95813|CER1_HUMAN Cerberus OS=Homo sapiens OX=9606 GN=CER1 PE=1 SV=1"
        >
          MHLLLFQLLVLLPLGKTTRHQDGRQN
          <br />
          QSSLSPVLLPRNQRELPTGNHEEAEEK
          <br />P<BB>YOU</BB>D<BB>ARE</BB>L<BB>NOT</BB>F<BB>ALLOWED</BB>V<BB>TO</BB>AVPHL
          <br />V<BB>ACCESS</BB>S<BB>THIS</BB>S<BB>PAGE</BB>GQRQREKMLS
          <br />
          RFGRFWKKPEREMHPSRDSDSEPFPPG
          <br />
          TQSLIQPIDGMKMEKSPLREEAK...
        </Typography>
        <Typography variant="body1" sx={{ mt: 4, mb: 4 }}>
          {t('Please contact your administrator for access')}
        </Typography>
        <Box>
          <PortalLink url={RuntimeConfig.getPortalLink()}>
            <Button variant="contained">{t('Back to Portal')}</Button>
          </PortalLink>
        </Box>
      </Box>
    </Box>
  );
};

export default NoAccessPageContent;
