import dayjs from 'dayjs';
import {Column, ColumnType} from "@biostrand/components";
import {GridMenuRow, MENU_COLUMN} from "@biostrand/components/src/grid/menuTypes";
import {UserManagerUserEntity} from "@biostrand/biostrandapi/javascript/dist/UserManagerApi";

export type UserGridRowType = UserManagerUserEntity & GridMenuRow;

export const UserGridColumns: Column<UserGridRowType>[] = [
    {...MENU_COLUMN},
    {
        id: 'email',
        label: 'Email',
        minWidth: 170,
        sortable: true,
    },
    {
        id: 'created_on',
        label: 'Created',
        minWidth: 170,
        align: 'right',
        format: (value) => dayjs(value).format('MMMM DD, YYYY'),
        sortable: true,
    },
    {
        id: 'last_updated_on',
        label: 'Last Updated',
        minWidth: 170,
        align: 'right',
        format: (value) => dayjs(value).format('MMMM DD, YYYY'),
        sortable: true,
    },
    // {
    //     id: "status",
    //     label: 'Status',
    //     minWidth: 100,
    //     type: ColumnType.TEXT,
    //     sortable: true,
    // },
    {
        id: "organization_role",
        label: 'Root access',
        minWidth: 50,
        type: ColumnType.TEXT,
        sortable: true,
    },
    {
        id: "applications",
        label: 'Applications',
        minWidth: 100,
        type: ColumnType.OTHER,
        format: (value) => value  ? value.join(', ') : undefined,
    },

]
