import * as React from 'react';
import {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {dataFilterSelector} from "../../filters/dataFiltersSelectors";
import {FieldFilterType, FilterType} from "../../filters/dataFiltersTypes";
import {updateDataFilter} from '../../filters/dataFiltersSlice';
import {SxProps, Theme} from "@mui/system";
import dayjs, {Dayjs} from 'dayjs';
import {styled} from '@mui/material/styles';
import {ArrowDropDown, ArrowDropUp} from '@mui/icons-material';


import {Button, capitalize, Chip, IconButton, List, ListItem, Paper, Popper, Stack, Typography} from '@mui/material';
import {
  DateRange,
  DateRangeCalendar,
  DateRangePickerDay,
  DateRangePickerDayProps,
  LocalizationProvider,
  PickersShortcutsItem,
} from "@mui/x-date-pickers-pro";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {pastelColor} from "../../colors/colorUtils";

interface DateFilterInputProps {
  filterKey: string,
  filterItemKey?: string,
  field: string,
  fieldLabel?: string,
  sx?: SxProps<Theme>
}

const shortcutsItems: PickersShortcutsItem<DateRange<Dayjs>>[] = [
  {
    label: 'Last 7 Days',
    getValue: () => {
      const today = dayjs();
      return [today.subtract(7, 'day'), today];
    },
  },
  {
    label: 'Last Month',
    getValue: () => {
      const today = dayjs();
      return [today.subtract(1, 'month'), today];
    },
  },

  {
    label: 'Last Year',
    getValue: () => {
      const today = dayjs();
      return [today.subtract(1, 'year'), today];
    },
  },
  {label: 'Reset', getValue: () => [null, null]},
];

function CustomRangeShortcuts(props) {
  const {items, onChange, changeImportance = 'accept'} = props;

  if (items == null || items.length === 0) {
    return null;
  }

  const resolvedItems = items.map((item) => {
    const newValue = item.getValue();

    return {
      label: item.label,
      onClick: () => {
        onChange(newValue, changeImportance, item);
      },
    };
  });

  return (
    <Stack
      direction={"row"}
    >
      <List
        dense
        sx={(theme) => ({
          display: 'flex',
          px: theme.spacing(4),
          '& .MuiListItem-root': {
            pt: 0,
            pl: 0,
            pr: theme.spacing(1),
          },
        })}
      >
        {resolvedItems.map((item) => {
          return (
            <ListItem key={item.label}>
              <Chip {...item} />
            </ListItem>
          );
        })}
      </List>
    </Stack>
  );
}

const DateRangePickerDay2 = styled(DateRangePickerDay)(
  ({
     theme,
     isHighlighting,
     isStartOfHighlighting,
     isEndOfHighlighting,
     outsideCurrentMonth,
   }) => ({
    ...(!outsideCurrentMonth &&
      isHighlighting && {
        // borderRadius: 0,
        borderTop: `1px dashed ${pastelColor(theme.palette.primary.dark, 80)}`,
        borderBottom: `1px dashed ${pastelColor(theme.palette.primary.dark, 80)}`,
        backgroundColor: pastelColor(theme.palette.primary.dark, 90),

        color: theme.palette.common.white,
        '&:hover, &:focus': {
          backgroundColor: pastelColor(theme.palette.primary.dark, 90),

        },
      }),
    ...(isStartOfHighlighting && {
      borderTopLeftRadius: '50%',
      borderBottomLeftRadius: '50%',
      //backgroundColor: pastelColor(theme.palette.primary.dark, 70),

    }),
    ...(isEndOfHighlighting && {
      borderTopRightRadius: '50%',
      borderBottomRightRadius: '50%',
      //backgroundColor: pastelColor(theme.palette.primary.dark, 70),

    }),
  }),
) as React.ComponentType<DateRangePickerDayProps<Dayjs>>;


export function DateFilterInput(props: DateFilterInputProps) {
  const {filterKey, filterItemKey, field, sx, fieldLabel} = props;
  const filters = useSelector(dataFilterSelector(filterKey))

  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [currentRange, setCurrentRange] = React.useState<DateRange<Dayjs> | [undefined, undefined]>([undefined, undefined]);
  const [appliedRange, setAppliedRange] = React.useState<DateRange<Dayjs> | [undefined, undefined]>([undefined, undefined]);

  useEffect(()=> {
    const cf = filters?.find(filter => filter.filterItemKey === filterItemKey);
    if (cf?.value?.between) {
      setCurrentRange(cf?.value?.between)
      setAppliedRange(cf?.value?.between)
    } else {
      setCurrentRange([undefined, undefined]);
      setAppliedRange([undefined, undefined]);
    }


  }, [filters,filterKey,filterItemKey, open] )

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  const onApply = () => {
    const newFilters = filters?.filter(filter => filter.filterItemKey !== filterItemKey) || [];
    if (currentRange && currentRange[0] && currentRange[1]) {
      let filter = {
        filterItemKey,
        type: FilterType.FIELD,
        value: {between: currentRange},
        field,
        fieldType: FieldFilterType.DATE
      }
      newFilters.push(filter);
    }
    dispatch(updateDataFilter({key: filterKey, filters: newFilters}));
  };

  const handleDelete = () => {
    setCurrentRange([undefined, undefined]);
    const newFilters = filters?.filter(filter => filter.filterItemKey !== filterItemKey) || [];
    dispatch(updateDataFilter({key: filterKey, filters: newFilters}));

  }

  const renderValue = ( appliedRange:[Dayjs | undefined, Dayjs | undefined]) => {
    if (appliedRange) {
      for (let i = 0; i < shortcutsItems.length; i++) {
        const item = shortcutsItems[i];
        const itemValues = item.getValue();
        if (itemValues[0] && dayjs(itemValues[0]).isSame(appliedRange[0], 'day') && dayjs(itemValues[1]).isSame(appliedRange[1], 'day')) {
          return <Chip size={"small"} label={item.label} onDelete={handleDelete}/>
        }
      }

      if (appliedRange[0] && appliedRange[1]) {
        const label = `${dayjs(appliedRange[0]).format('D MMM YY')} - ${dayjs(appliedRange[1]).format('D MMM YY')}`
        return <Chip size={"small"} label={label} onDelete={handleDelete}/>

      }
    }
  }

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? 'date-select-popper' : undefined;

  return <Stack>
    <Stack direction={"row"} onClick={handleClick} spacing={1} sx={{alignItems: "center"}}>
      <Typography variant={"subtitle2"} sx={{mt: 0.2, pl: 2}}>{capitalize(fieldLabel ? fieldLabel : field)}</Typography>
      {appliedRange[0] && appliedRange[1] && renderValue(appliedRange)}

      <IconButton size={"small"} sx={{width: 28, height: 28, ml:0}}>{open ? <ArrowDropUp/> : <ArrowDropDown/>}</IconButton>
    </Stack>
    <Popper id={id} open={open} anchorEl={anchorEl} placement={"bottom-start"}>
      <Paper sx={{p: 1}}>
        <CustomRangeShortcuts onChange={(newValue) => setCurrentRange(newValue)} items={shortcutsItems}/>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateRangeCalendar
            value={currentRange}
            onChange={(newValue) => setCurrentRange(newValue)}
            disableFuture
            slots={{
              day: DateRangePickerDay2
            }}
            calendars={2}
            showDaysOutsideCurrentMonth
          />
        </LocalizationProvider>
        <Stack direction={"row"} spacing={1} sx={{justifyContent: "flex-end", flex: 1, m:1}}>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button onClick={() => {
            onApply();
            setOpen(false);
          }} variant={"contained"}>Apply</Button>
        </Stack>
      </Paper>
    </Popper>
  </Stack>
}
