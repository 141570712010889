    export const LOGIN_REQUEST = '@auth/LOGIN_REQUEST';
    export const LOGIN_SUCCESS = '@auth/LOGIN_SUCCESS';
    export const LOGIN_NEW_PASSWORD_REQUIRED = '@auth/LOGIN_NEW_PASSWORD_REQUIRED';
    export const LOGIN_FAILURE = '@auth/LOGIN_FAILURE';


export enum RegistrationActionTypes {
    CONFIRM_NEW_PASSWORD_REQUEST = '@auth/CONFIRM_NEW_PASSWORD_REQUEST',
    CONFIRM_NEW_PASSWORD_SUCCESS = '@auth/CONFIRM_NEW_PASSWORD_SUCCESS',
    CONFIRM_NEW_PASSWORD_FAILURE = '@auth/CONFIRM_NEW_PASSWORD_FAILURE',
    COMPLETE_PASSWORD_CHALLENGE_REQUEST = '@auth/COMPLETE_PASSWORD_CHALLENGE_REQUEST',
    COMPLETE_PASSWORD_CHALLENGE_SUCCESS = '@auth/COMPLETE_PASSWORD_CHALLENGE_SUCCESS',
    COMPLETE_PASSWORD_CHALLENGE_FAILURE = '@auth/COMPLETE_PASSWORD_CHALLENGE_FAILURE',
    FORGOT_PASSWORD_REQUEST = '@auth/RESET_PASSWORD_REQUEST',
    FORGOT_PASSWORD_SUCCESS = '@auth/RESET_PASSWORD_SUCCESS',
    FORGOT_PASSWORD_FAILURE = '@auth/RESET_PASSWORD_FAILURE',
    INITIALIZATION_COMPLETE = '@auth/INITIALIZATION_COMPLETE',
}

export interface ConfirmPasswordParameters {
    email: string;
    confirmationCode: string;
    newPassword: string;
}

export interface PasswordChallengeParameters {
    email: string;
    temporaryPassword: string;
    newPassword: string;
}

export enum AuthenticationFeedbackType {
    Success,
    NewPasswordRequired,
    Failure,
}

