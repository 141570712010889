import {createSlice, createAction, PayloadAction} from '@reduxjs/toolkit';
import {AuthenticationState, AuthenticationStatus} from "./authTypes";

export const LOGOUT_REQUEST = '@auth/LOGOUT_REQUEST';

const initialState: AuthenticationState = {
    authStatus: AuthenticationStatus.INITIALIZING,
};

export const logoutRequest = createAction(LOGOUT_REQUEST);

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        updateAuthStatus: (state, action: PayloadAction<AuthenticationStatus>) => {
            state.authStatus = action.payload;
        },
    },
});

export const { updateAuthStatus } = authSlice.actions;
export default authSlice.reducer;
