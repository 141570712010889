import {useEffect, useState} from "react";
import {Column} from "./gridTypes";
import {ColumnType, Order} from "./gridTypes";

// type SortFunction<T> = (row1: T, row2: T) => number
// type CreateSortFunction<T, C> =  (column: Column<T>, sortOption: SortOption) => SortFunction<C>;

export interface SortOption<T> {
    order: Order;
    orderBy: keyof T;
}

export interface SortProps<T> {
    /**
     * @deprecated, please use mui DataGrid
     * @param props
     */
    data?: Array<any>;
    /**
     * @deprecated, please use mui DataGrid
     * @param props
     */
    columns: Column<T>[];
    /**
     * @deprecated, please use mui DataGrid
     * @param props
     */
    initialSort?:SortOption<T>;
    /**
     * @deprecated, please use mui DataGrid
     * @param props
     */
    onChange(data: T[]): void;
}

export function getSortFunction<T>(column: Column<T>, sortOption: SortOption<T>) {
    let sortFunction: (r1:T, r2:T)=> number;
    const { order, orderBy } = sortOption;

    if (column.sortFunction) {
        sortFunction = column.sortFunction;
    } else {
        switch (column.type) {
            case ColumnType.NUMERIC:
                sortFunction = (row1, row2) => Number(row1[orderBy])-Number(row2[orderBy]);
                break;
            case ColumnType.DATE:
                sortFunction = (row1, row2) => new Date(String(row1[orderBy])).getTime() - new Date(String(row2[orderBy])).getTime();
                break;
            default:
                sortFunction = (row1, row2) => {
                    const v1 = row1[orderBy] ? String(row1[orderBy]).toUpperCase() : '';
                    const v2 = row2[orderBy] ? String(row2[orderBy]).toUpperCase() : '';

                    return v1 > v2 ? 1 : -1;
                };
        }
    }

    return order === Order.ASC ? sortFunction : (row1:T, row2:T) => -sortFunction(row1, row2);
}


function getColumnById<T>(columnId: keyof T, columns: Column<T>[]) {
    return columns.find(c => c.id === columnId);
}

/**
 * @deprecated, please use mui DataGrid
 * @param props
 */
export function useGridUtils<T>(props: SortProps<T>) {
    const { data, columns, onChange, initialSort } = props;
    // Local state
    const [sortOptions, setSortOptions] = useState<SortOption<T> | undefined>(initialSort);
    const [searchQuery, setSearchQuery] = useState<string | undefined>();

    useEffect(() => {

        if (!data) return;

        let copiedDs = [...data];

        if (searchQuery) {
            copiedDs = copiedDs.filter((row:any) => {
                for (let i = 0; i < columns.length; i++) {
                    const c = columns[i];
                    if (c.type !== ColumnType.DATE) {
                        const value = row[c.id]?.toString().toUpperCase();

                        if (value && value.indexOf(searchQuery.toUpperCase()) > -1) return true;
                    }
                }

                return false;
            });
        }

        if (sortOptions && sortOptions.orderBy) {
            const column = getColumnById<T>(sortOptions.orderBy, columns);
            if (column) {
                const sortFunction = getSortFunction(column, sortOptions);
                onChange(copiedDs.sort(sortFunction))
                return;
            }
        }
        onChange(copiedDs)
    }, [data, sortOptions, searchQuery]);

    const handleChangeOrder = (columnId:keyof T) => {
        if (sortOptions?.orderBy === columnId && sortOptions?.order === Order.ASC) {
            setSortOptions({ orderBy: columnId, order: Order.DESC });
            return;
        }
        if (sortOptions?.orderBy === columnId && sortOptions?.order === Order.DESC) {
            setSortOptions(undefined);
            return;
        }
        setSortOptions({ orderBy: columnId, order: Order.ASC });
    };

    const handleSearch = (value:string) => {
        setSearchQuery(value);
    };

    return {
        /**
         * @deprecated, please use mui DataGrid
         * @param props
         */
        handleChangeOrder,
        /**
         * @deprecated, please use mui DataGrid
         * @param props
         */
        handleSearch,
        /**
         * @deprecated, please use mui DataGrid
         * @param props
         */
        onChange,
        /**
         * @deprecated, please use mui DataGrid
         * @param props
         */
        sortOptions,
        /**
         * @deprecated, please use mui DataGrid
         * @param props
         */
        searchQuery
    };
}
