import React, {ReactElement} from 'react';
import {Highlight, RowRenderData} from '../alignmentViewTypes';
import styles from '../alignmentViewer.module.css';
import {getAABrightColor, getAAColor, getDNABrightColor, getDNAColor} from '../utils/aligmentViewColorUtils';
import {BASE_SIZE} from '../AligmentBaseSize';
import {getHighlightIndices, isHighlighted, testContains} from '../utils/prepareEchancedAlignmentViewRenderData';
import {SharedMolType} from "@biostrand/biostrandapi/javascript/dist/AlignApi";

interface RowProps {
    row: RowRenderData;
    highlight?: Highlight;
}

const getOffset = (offset?: number): ReactElement | null => {
    if (!!offset && offset > 0) return <div style={{ minWidth: offset * BASE_SIZE }} />;

    return null;
};

const AlignmentViewRow = (props: RowProps): ReactElement => {
    const { row, highlight } = props;
    const { offset, type, maxLength } = row;

    if (!row.sequence) return <div />;
    if (!row.sequence.split) return <div />;

    const endOffset = maxLength ? maxLength - offset - row.sequence.length : 0;
    const res = row.sequence.split('');
    const getColor = type === SharedMolType.AA ? getAAColor : getDNAColor;
    const getBrightColor = type === SharedMolType.AA ? getAABrightColor : getDNABrightColor;
    const className = type === SharedMolType.AA ? styles.aaRect : styles.dnaRect;

    if (highlight && highlight.exclude) {
       const isExcluded = !testContains(row.sequence, highlight.pattern || '');
       return (<div className={styles.row}>
            {getOffset(offset)}
            {res.map((l: string, i: number) => (
                // eslint-disable-next-line react/no-array-index-key
                <span key={`i-'+${i}+${l}`} className={className} style={{ backgroundColor: isExcluded ? getColor(l) : getBrightColor(l)}}>
                    {l}
                </span>
            ))}
            {getOffset(endOffset)}
        </div>)
    }

    const test = getHighlightIndices( row.sequence, highlight );

    return (
        <div className={styles.row}>
            {getOffset(offset)}
            {res.map((l: string, i: number) => (
                // eslint-disable-next-line react/no-array-index-key
                <span key={`i-'+${i}+${l}`} className={className} style={{ backgroundColor: highlight?.pattern ? isHighlighted(i, test) ? getColor(l) : getBrightColor(l) : getColor(l)}}>
                    {l}
                </span>
            ))}
            {getOffset(endOffset)}
        </div>
    );
};

export default AlignmentViewRow;
