import {Paper, Stack, Typography} from '@mui/material';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {ApplicationIcon} from './ApplicationIcon';
import {Application} from './applicationTypes';
import PortalLink from "../portalLink/PortalLink";

interface ApplicationsMenuDropdownProps {
  applications: Application[];
  currentApplicationId?: string;
}

export const ApplicationsDropdownMenu = (props: ApplicationsMenuDropdownProps) => {
  const {applications, currentApplicationId} = props;
  const [t] = useTranslation();
  const currentUrl = window.location.href;
  const appToSelect = applications.find( app => app.url === currentUrl)

  //in case of sub pages like settings/jobs
  const idToSelect = appToSelect ? appToSelect.id : currentApplicationId;

  return (
    <Paper
      sx={{
        width: 250,
        pt: 2,
        pb: 2,
        borderRadius: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {applications.map(app => {
        return (
          <PortalLink
            sx={{
              p: 1,
              pl: 3,
              pr: 3,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              '&:hover': {
                backgroundColor: (theme) => theme.palette.grey['50'],
              },
            }}
            key={app.id} url={app.url}>
            <>
              <Stack
                sx={{
                  borderRadius: 1,
                  width: theme => theme.spacing(6),
                  height: theme => theme.spacing(6),
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: theme => idToSelect === app.id ? theme.palette.primary.contrastText : theme.palette.text.disabled,
                  backgroundColor: theme => idToSelect === app.id ? theme.palette.primary.dark : theme.palette.grey['100'],
                }}>
                <ApplicationIcon appId={app.id}/>
              </Stack>
              <Typography variant="subtitle2" sx={{ml: 2}}>
                {t(app.name || app.id)}
              </Typography>
            </>
          </PortalLink>
        )
      })}
    </Paper>
  );
};
