import {CBox, RBox} from '@biostrand/components';
import {LoadingButton} from '@mui/lab';
import {Button, FormControl, MenuItem} from '@mui/material';
import {Field, Form, Formik} from 'formik';
import {Select, TextField} from 'formik-mui';
import {FormikHelpers} from 'formik/dist/types';
import * as React from 'react';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {RoleList} from '../../../../core/organization/organizationTypes';
import {InlineObject1} from "@biostrand/biostrandapi/javascript/dist/UserManagerApi";

interface FormProps {
    onCancel: () => void;
    onSubmit: (
        values: InlineObject1,
        actions: FormikHelpers<InlineObject1>,
        callback: (error: string | undefined, actions: FormikHelpers<InlineObject1>) => void,
    ) => void;
    initialValues: InlineObject1;
    // depends on action (edit / new) some of the fields should be disabled | or have different behaviour.
    isEditing?: boolean;
}

export function InviteUserForm(props: FormProps): ReactElement {
    const [t] = useTranslation();
    const { onCancel, onSubmit, initialValues } = props;

    const validate = (values) => {
        const errors: Partial<InlineObject1> = {};
        if (!values.email) {
            errors.email = t('Required');
        } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
        ) {
            errors.email = t('Invalid email address');
        }
        return errors;
    }

    const closeWindow = () => {
        onCancel();
    };

    const finishSubmitting = (error: string | undefined, actions: FormikHelpers<InlineObject1>) => {
        actions.setSubmitting(false);
        if (!error) {
            closeWindow();
            return;
        }
    };

    const onSubmitForm = (values: InlineObject1, actions: FormikHelpers<InlineObject1>) => {
        onSubmit(values, actions, finishSubmitting);
    };

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmitForm} validate={validate}>
            {({ isSubmitting, handleSubmit, isValid}) => (
                <Form id='DS' autoComplete='false' style={{ flex: 1, alignItems: 'stretch', minWidth: 400 }}>
                    <CBox sx={{ mr: 3, mt:2, mb:5 }}>
                        <FormControl margin={'dense'} sx={{ height: theme => theme.spacing(11) }}>
                            <Field
                                component={TextField}
                                name={`email`}
                                label={t('email')}
                                variant='outlined'
                                autoComplete='off'
                                required
                            />
                        </FormControl>

                        <Field
                            component={Select}
                            id='organization_role'
                            name='organization_role'
                            labelId='organization_role-label'
                            label='Role'>
                            {RoleList.map(rd => (
                                <MenuItem value={rd.role} key={rd.role}>
                                    <em>{t(rd.label)}</em>
                                </MenuItem>
                            ))}
                        </Field>
                    </CBox>

                    <RBox sx={{ mb: 2, mt: 2, alignItems: 'flex-end' }}>
                        <LoadingButton
                            sx={{ mr: 2 }}
                            variant={'contained'}
                            loading={isSubmitting}
                            disabled={!isValid || isSubmitting}
                            onClick={() => {
                                handleSubmit();
                            }}>
                            {t('Invite user')}
                        </LoadingButton>
                        <Button variant={'outlined'} disabled={isSubmitting} onClick={closeWindow}>
                            {t('Cancel')}
                        </Button>
                    </RBox>
                </Form>
            )}
        </Formik>
    );
}
