import * as React from 'react';
import SettingsPageTemplate from '../SettingsPageTemplate';
import JobsSettingsContent from './JobsSettingsContent';
import {SettingsIds} from "@biostrand/components/src/applications/SettingsIds";

const JobsSettingsPage = (): JSX.Element => {
    return (
        <SettingsPageTemplate selectedItem={SettingsIds.JOBS}>
            <JobsSettingsContent />
        </SettingsPageTemplate>
    );
};

export default JobsSettingsPage;
