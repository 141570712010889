import { styled } from '@mui/material/styles';
import {Box} from "@mui/material";

/**
 * @deprecated, please use mui Stack
 * @param props
 */
export const CBox = styled(Box)`
    display: flex;
    flex-direction: column;
`;
