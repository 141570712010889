import {Apps, biostrandTheme, bootstrapAppAction, useSnackbarNotifier} from '@biostrand/components';
import {PopupsContainer} from '@biostrand/components/src/popup/PopupsContainer';
import {StyledEngineProvider, ThemeProvider} from '@mui/material/styles';
import {ConfirmProvider} from 'material-ui-confirm';
import React, {Suspense, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import Routes from './app/Routes';
import {UploadProgressSmallPopup} from "@biostrand/components/src/datasets/UploadProgressSmallPopup";

function App(): JSX.Element {
    useSnackbarNotifier();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(bootstrapAppAction(Apps.PORTAL));
    }, []);
    return (
        <Suspense fallback=''>
            <ThemeProvider theme={biostrandTheme}>
                <StyledEngineProvider injectFirst>
                    <PopupsContainer>
                        <div className='appWrapper'>

                            <ConfirmProvider defaultOptions={{ dialogProps: { maxWidth: 'xs' } }}>
                                <Routes />
                            </ConfirmProvider>
                            <UploadProgressSmallPopup />
                        </div>
                    </PopupsContainer>
                </StyledEngineProvider>
            </ThemeProvider>
        </Suspense>
    );
}

export default App;
