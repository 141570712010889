import {Button, FormControl, IconButton, InputLabel, MenuItem, Select, Stack} from '@mui/material';
import * as React from 'react';
import {useState} from 'react';
import {useDispatch} from 'react-redux';
import {gridExpandedSortedRowEntriesSelector, useGridApiContext} from "@mui/x-data-grid-pro";
import {isSequenceColumn} from "./ngsUtils";
import {closePopup, showPopup} from "../../popup/popupsSlice";
import AlignedSequencesPopup from "./AlignedSequencesPopup";
import {ArrowBack} from "@mui/icons-material";

export const NGXToolbarGridAlignSequence = () => {
  const apiRef = useGridApiContext();
  const columns = apiRef.current.getAllColumns();
  const sequenceColumns = columns.filter(column => isSequenceColumn(column.field)).map(c => c.field)

  const dispatch = useDispatch();
  const [selectedColumn, setSelectedColumn] = useState(sequenceColumns[0]);

  return (
    <Stack direction={"row"}>
      <FormControl size={"small"}  sx={{ m: 1, minWidth: 170 }}>
      <InputLabel id="column-to-align">Column to align</InputLabel>
      <Select
        labelId="column-to-align"
        id="column-to-align-s"
        label="Column to align"
        value={selectedColumn}
        onChange={(event) => setSelectedColumn(event.target.value)}
      >
        {sequenceColumns && sequenceColumns.map(c => {
          return <MenuItem value={c} key={c}>{c}</MenuItem>
        })}
      </Select>
      </FormControl>
      <Button onClick={async () => {

        if (!selectedColumn) return;
        const selectedRows = apiRef.current.getSelectedRows();
        const rrr = gridExpandedSortedRowEntriesSelector(apiRef);
        const rows = selectedRows && selectedRows.size > 0 ? selectedRows : rrr;

        const sequences = []
        rows.forEach((s, key) => {
          sequences.push('>' + s.id + '\n' + (s[selectedColumn] || s.model[selectedColumn]));
        })

        if (apiRef.current.state.rows.additionalRowGroups?.pinnedRows?.top?.length > 0) {
          const refRow = apiRef.current.state.rows.additionalRowGroups?.pinnedRows?.top[0].model;
          if (refRow) {
            sequences.unshift('>' + refRow.id + '\n' + (refRow[selectedColumn] || refRow.model[selectedColumn]));
          }
        }

        dispatch(
          showPopup({
            fullscreen: true,
            key: 'a-key',
            title: (
              <Stack direction={'row'} sx={{mt: 2, alignItems: 'center'}} spacing={4}>
                <IconButton
                  onClick={() => {
                    dispatch(closePopup('a-key'));
                  }}
                >
                  <ArrowBack/>
                </IconButton>
                {'Aligned sequences'}
              </Stack>
            ),
            content: React.createElement(AlignedSequencesPopup, {
              data: sequences.join('\n'),
              popupKey: 'a-key',
            }),
          }),
        );

      }}>Align</Button>
    </Stack>
  );
};
