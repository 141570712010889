import {CreateHttpDatasetValues, FtpSettings, S3Settings} from "./datasetTypes";
import {
    DatasetManagerConnectionInfo,
    DatasetManagerFileType
} from "@biostrand/biostrandapi/javascript/dist/DatasetManagerApi";
import {SharedMolType} from "@biostrand/biostrandapi/javascript/dist/RetrieveAndRelateApi";

export const molTypes = [
    { label: SharedMolType.MRNA, value: SharedMolType.MRNA },
    { label: SharedMolType.DNA, value: SharedMolType.DNA },
    { label: SharedMolType.AA, value: SharedMolType.AA },
];

export const fileTypes = [
    { label: DatasetManagerFileType.FASTA, value: DatasetManagerFileType.FASTA },
    { label: DatasetManagerFileType.FASTQ, value: DatasetManagerFileType.FASTQ },
    { label: DatasetManagerFileType.GENBANKFLATFILE, value: DatasetManagerFileType.GENBANKFLATFILE },
];

export type Errors = {
    [key: string]: string | any;
};

export const ftpSettings2Config = (ftpSettings:FtpSettings): DatasetManagerConnectionInfo => {
    return {
        ftp: {...ftpSettings},
    }
}

export const httpSettings2Config = (httpSettings:CreateHttpDatasetValues): DatasetManagerConnectionInfo => {
    const { sourceLinks } = httpSettings;
    return {
        http: {
            urls: sourceLinks
        },
    }
}

export const s3Settings2Config = (s3Settings:S3Settings): DatasetManagerConnectionInfo => {
    return {
        s3: {...s3Settings},
    }
}

