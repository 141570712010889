import {Button, Stack} from '@mui/material';
import * as React from 'react';
import {useState} from 'react';
import {useGridApiContext} from "@mui/x-data-grid-pro";
import {ColumnTypesSettingsPopup} from "./ColumnTypesSettingsPopup";

interface GSGTProps {
  settings: any,
  onApplySettings: any
}

export const ColumnSettingsGridToolbar = (props: GSGTProps) => {
  const {settings, onApplySettings} = props;
  const apiRef = useGridApiContext();
  const columns = apiRef.current.getAllColumns();
  const visibleColumns = apiRef.current.getVisibleColumns();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Stack direction={"row"}>
      <Button onClick={() => {
        setIsOpen(true)
      }}>Columns settings</Button>
      <ColumnTypesSettingsPopup
        open={isOpen}
        settings={settings}
        columns={columns}
        visibleColumns={visibleColumns}
        onCancel={() => {
          setIsOpen(false);
        }}
        onApplySettings={(settings) => {
          onApplySettings(settings)
        }}
      />
    </Stack>
  );
};
