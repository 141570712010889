// import {DatasetManagerDatasetEntity,} from '@biostrand/biostrandapi/javascript/dist/DatasetManagerApi';
// import {CloseNotificationButton, deleteDatasetsAction, enqueueSnackbarNotification,} from '@biostrand/components';
import {ArrowBack} from '@mui/icons-material';
import {Button} from '@mui/material';
import {Stack} from '@mui/system';
import {push} from 'connected-react-router';
//import {useConfirm} from 'material-ui-confirm';
import * as React from 'react';
import {useEffect} from 'react';
//import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {useParams} from 'react-router';
import {RouteUrl} from '../../../../routeUrl';
import DatasetDetails from "./DatasetDetails";
import {DatasetManagerDatasetEntity} from '@biostrand/biostrandapi/javascript/dist/DatasetManagerApi';
import {datasetByIdSelector} from "@biostrand/components/src/slices/datasets/datasetsSelectors";
import {refreshDatasetsAction} from '@biostrand/components/src/slices/datasets/datasetsSlice';

interface IDatasetParams {
    datasetName: string;
}

const DatasetDetailsSettingsContent = (): JSX.Element => {
    const {datasetName} = useParams<IDatasetParams>();


    // const [t] = useTranslation();
    // const confirm = useConfirm();
    const dispatch = useDispatch();
    const dataset: DatasetManagerDatasetEntity | undefined = useSelector(datasetByIdSelector(datasetName));
    useEffect(() => {
        dispatch(refreshDatasetsAction());
    }, [datasetName]);
    // const handleDelete = async (ds: DatasetManagerDatasetEntity) => {
    //     try {
    //         if (ds.name) {
    //             await confirm({
    //                 title: t('Delete dataset'),
    //                 description: t(`This will permanently delete ${ds.name} dataset.`),
    //             });
    //             dispatch(deleteDatasetsAction(ds.id));
    //             dispatch(push(RouteUrl.DataSetsSettings));
    //
    //         }
    //     } catch (e) {
    //         if (e) {
    //             dispatch(
    //                 enqueueSnackbarNotification({
    //                     message: t('Delete dataset'),
    //                     key: 'delete-error',
    //                     options: {
    //                         variant: 'error',
    //                         persist: true,
    //                         action: key => (
    //                             <CloseNotificationButton notificationKey={key}>Close</CloseNotificationButton>
    //                         ),
    //                     },
    //                 }),
    //             );
    //         }
    //     } finally {
    //     }
    // };

    return (
        <Stack sx={{m: 2, ml: 4, flex: 1, overflow: "hidden", mr: 1}} direction={'column'}>
            <Stack direction={'row'}>
                <Button
                    startIcon={<ArrowBack/>}
                    onClick={() => {
                        dispatch(push(RouteUrl.DataSetsSettings));
                    }}>
                    Back to datasets
                </Button>
            </Stack>

            {dataset?.name &&  <DatasetDetails datasetName={dataset?.name} datasetVersion={dataset?.version} />}


        </Stack>
    );
};

export default DatasetDetailsSettingsContent;
