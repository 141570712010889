import {DatasetManagerDatasetItem, SharedObjectType} from "@biostrand/biostrandapi/javascript/dist/DatasetManagerApi";

export const sortOnName = (so1: DatasetManagerDatasetItem, so2: DatasetManagerDatasetItem) => {
    return so1.name && so2.name && so1.name.toLowerCase() > so2.name.toLowerCase() ? 1 : -1;
};

export const isDatasetSelected = ( datasetId:string, selectedItems) => {
    if (!selectedItems) return false;
    for (let i = 0; i < selectedItems.length; i++) {
        if (selectedItems[i] === datasetId) return true;
    }
    return false
}

export const isDatasetPartiallySelected = ( datasetId:string, selectedItems ) => {
    if (!selectedItems) return false;
    for (let i = 0; i < selectedItems.length; i++) {
        if (selectedItems[i] !== datasetId && selectedItems[i].indexOf(datasetId) === 0) return true;
    }
    return false
}

export const isVisibleFile = (s3obj:DatasetManagerDatasetItem) => {
    return !s3obj.is_hidden;//?.indexOf('meta.json') === -1;
}

export const isFolderSelected = (folderName: string, fileList: DatasetManagerDatasetItem[], selectedItems) => {
    if (!selectedItems) return false;

    const folderFiles = fileList.filter(isVisibleFile).filter(
        f => f.object_type !== SharedObjectType.FOLDER && (f.name || '').indexOf(folderName) === 0,
    );
    const folderFilesFromSelection = selectedItems.filter(path => path && path.indexOf(folderName) === 0);

    return folderFiles.length === folderFilesFromSelection.length;
};

export const isFolderIndeterminate = (folderName: string, fileList: DatasetManagerDatasetItem[], selectedItems) => {
    const folderFiles = fileList.filter(isVisibleFile).filter(
        f => f.object_type !== SharedObjectType.FOLDER && (f.name || '').indexOf(folderName) === 0,
    );
    const folderFilesFromSelection = selectedItems.filter(path => path.indexOf(folderName) === 0);

    return folderFiles.length !== folderFilesFromSelection.length && folderFilesFromSelection.length > 0;
};

export const collapseSelectedFolder = (fileList: DatasetManagerDatasetItem[], selectedItems: string[]) => {
    const fullFolders = fileList.filter(isVisibleFile).filter(
        item => item.object_type === SharedObjectType.FOLDER && isFolderSelected(item.name || '', fileList, selectedItems),
    );
    const rootFullFolders = fullFolders.filter((folder, index, folders) => {
        for (let i = 0; i < folders.length; i++) {
            const f = folders[i];
            if (folder.name !== f.name && folder.name && f.name && folder.name.indexOf(f.name) === 0) {
                return false;
            }
        }
        return true;
    });

    const files = selectedItems.filter(file => {
        for (let i = 0; i < rootFullFolders.length; i++) {
            const folder = rootFullFolders[i];
            if (folder.name && file.indexOf(folder.name) === 0) {
                return false;
            }
        }
        return true;
    });

    const folderNames = rootFullFolders.map(f => f.name);
    return folderNames.concat(files);
};