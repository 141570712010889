import {Highlight} from '../alignmentViewTypes';
import {SharedMolType} from "@biostrand/biostrandapi/javascript/dist/AlignApi";

const aminoAcidsWithoutDNALetters: string[] = 'BDEFHIJKLMNOPQRSVWXYZ'.split('');

export function getSequenceType(sequence: string): SharedMolType.DNA | SharedMolType.AA {
  for (let i = 0; i < sequence.length; i++) {
    if (aminoAcidsWithoutDNALetters.indexOf(sequence[i]) !== -1) {
      return SharedMolType.AA;
    }
  }

  return SharedMolType.DNA;
}

const ALLOWED_PATTERN_SYMBOL_MAP: {[key:string]: boolean}= (() => {
  const sym = {};
  'qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM*?'.split('').forEach(s => {
    // @ts-ignore
    sym[s] = true;
  });
  return sym;
})();

export const excludeRegex = (regexString: string) => `^((?!${regexString}).)*$`;

export const patternToRegexString = (pattern: string): string => {
  // @ts-ignore
  const cleaned = pattern
    .split('')
    .filter((s:string) => ALLOWED_PATTERN_SYMBOL_MAP[s])
    .join('')
    .split('?')
    .join('.')
    .split('*')
    .join('.*?')
    .toUpperCase();
  return cleaned;
};

export const highlightPattern2Regexp = (pattern: string, exclude?: boolean): RegExp | null => {
  const highlight = exclude ? excludeRegex(patternToRegexString(pattern)) : patternToRegexString(pattern);
  try {
    return new RegExp(highlight, 'ig');
  } catch (e) {
    return null;
  }
};

export const testContains = (sequence: string, pattern: string): boolean => {
  try {
    const regexp = new RegExp(patternToRegexString(pattern), 'ig');
    const m = sequence.match(regexp);
    return !!m;
  } catch (e) {
    return false;
  }
};

export const getHighlightIndices = (str: string, highlight?: Highlight) => {
  const hi: { start: number; end: number }[] = [];
  if (highlight && highlight.pattern) {
    const { pattern, exclude } = highlight;
    const regexp = highlightPattern2Regexp(pattern || '', exclude);
    if (!regexp) {
      return hi;
    }

    const matches = str.matchAll(regexp);

    // eslint-disable-next-line no-restricted-syntax
    // @ts-ignore
      for (const match of matches) {
      if (match) {
        const index: number = match.index || 0;
        hi.push({
          start: index,
          end: index + match[0].length,
        });
      }
    }
  }
  return hi;
};

export const isHighlighted = (index: number, test: { start: number; end: number }[]) => {
  return test.find(item => index >= item.start && index < item.end);
};
