import {Avatar, Button, Chip, List, ListItem, Paper, Radio, RadioGroup, Stack, Typography} from '@mui/material';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {logoutRequest} from '../../../slices/auth/authSlice';
import {userFacesSelector} from '../../../slices/user/userSelectors';
import {getSettingsPagesLink} from "../../../applications/applicationUtils";
import {SettingsIds} from "../../../applications/SettingsIds";
import {navigateTo} from "../../../portalLink/routeUtilSagas";
import {UserManagerUserEntity} from "@biostrand/biostrandapi/javascript/dist/UserManagerApi";

interface Props {
  user: UserManagerUserEntity;
}

export const AvatarMenuList = (props: Props): JSX.Element => {
  const { user } = props;
  const userFaces = useSelector(userFacesSelector);
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const switchOrganization = (value: string | undefined) => {
    console.log(value);
  };

  const openOrganizationSettings = () => {

    dispatch(navigateTo({link: getSettingsPagesLink(SettingsIds.ORGANIZATION)}));
  };

  const openUserSettings = () => {
    dispatch(navigateTo({link: getSettingsPagesLink(SettingsIds.PERSONAL)}));
  };

  return (
    <Paper sx={{ maxWidth: '100%', width: 300 }}>
      <Stack>
        <Stack spacing={2} sx={{ alignItems: 'center', p: 2, pt: 4, borderBottom: 'solid 1px #EEE' }}>
          <Avatar sx={{ width: 72, height: 72 }} alt={user.email}>
            {user.email ? user.email.substring(0, 2).toUpperCase() : '?'}
          </Avatar>
          <Typography variant="body2">{user.email}</Typography>
          {/*<Typography variant="caption">{user.email}</Typography>*/}
          <Stack direction={'row'} spacing={2}>
            <Button variant={'outlined'} onClick={openUserSettings}>
              {t('Settings')}
            </Button>
            <Button
              variant={'outlined'}
              onClick={() => {
                dispatch(logoutRequest());
              }}
            >
              {t('Logout')}
            </Button>
          </Stack>
        </Stack>
        <RadioGroup defaultValue={user.organization_id}>
          <List>
            {userFaces
              ? userFaces.map(userFace => {
                  return (
                    <ListItem
                      onClick={() => switchOrganization(userFace.organization_id)}
                      sx={{ alignItems: 'flex-start' }}
                      key={userFace.organization_id}
                      dense
                      selected={userFace.organization_id === user.organization_id}
                    >
                      <Radio value={userFace.organization_id} />
                      <Stack sx={{ p: 1, pt: 1.4 }}>
                        <Typography
                          variant={'subtitle2'}
                          color={userFace.organization_id === user.organization_id ? 'primary' : 'primary.contrast'}
                        >
                          {userFace.organization_name}
                        </Typography>
                        <Chip size={'small'} label={userFace.organization_role?.toLowerCase()} />
                      </Stack>
                    </ListItem>
                  );
                })
              : null}
          </List>
        </RadioGroup>
        {user.organization_role === 'ADMIN' ? (
          <Stack sx={{ alignItems: 'center', p: 2, pt: 2, borderTop: 'solid 1px #EEE' }}>
            <Button variant={'outlined'} onClick={openOrganizationSettings}>
              {t('Manage Organization')}
            </Button>
          </Stack>
        ) : null}
      </Stack>
    </Paper>
  );
};
