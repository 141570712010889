import {CommonRuntimeVariables} from "./CommonRuntimeVariables";
import {Apps} from "../applications/applicationTypes";

export class RuntimeConfig {
    private static getFeatures(): string[] {
        return this.getRuntimeVariable<string[]>(CommonRuntimeVariables.Features);
    }

    public static isFeatureSupported(featureName: string): boolean {
        return RuntimeConfig.getFeatures() && RuntimeConfig.getFeatures().includes(featureName);
    }

    public static getPortalLink():string {
        const apps:any = RuntimeConfig.getRuntimeVariable(CommonRuntimeVariables.AppsLinks);
        return apps[Apps.PORTAL] as string;
    }

    public static getAppLinks():any {
        return RuntimeConfig.getRuntimeVariable(CommonRuntimeVariables.AppsLinks);
    }

    public static loginUrl():string {
        return RuntimeConfig.getRuntimeVariable(CommonRuntimeVariables.LoginUrl) || '/login';
    }

    public static getRuntimeVariable<T>(configProp: string): T {
        return (window as Window & typeof globalThis & { runConfig: { [key: string]: T } }).runConfig[configProp];
    }
}
