import * as React from 'react';
import LoginForm from "./LoginForm";
import PublicPage from "../common/publicPage/PublicPage";

const LoginPageComponent = () => {
  return (
      <PublicPage>
        <LoginForm />
      </PublicPage>
  );
};

const LoginPage = LoginPageComponent;

export default LoginPage;

