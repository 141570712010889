import * as React from 'react';
import { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closePopup, Popup, popupsSelector } from './popupsSlice';
import {FullScreenPopupWindow, PopupWindow} from './PopupWindow';

interface PopupProps {
  children?: JSX.Element | React.FunctionComponent;
}

export function PopupsContainer(props: PopupProps): ReactElement {
  const { children } = props;
  const dispatch = useDispatch();
  const popups = useSelector(popupsSelector);

  return (
    <>
      {children}
      {popups.map((popup: Popup) => {

        if (popup.fullscreen) {
          return (
            <FullScreenPopupWindow
              title={popup.title}
              key={popup.key}
              open
              onClose={() => {
                dispatch(closePopup(popup.key));
              }}
            >
              {popup.content}
            </FullScreenPopupWindow>
          );
        }

        return (
          <PopupWindow
            title={popup.title}
            key={popup.key}
            open
            onClose={() => {
              dispatch(closePopup(popup.key));
            }}
          >
            {popup.content}
          </PopupWindow>
        );
      })}
    </>
  );
}
