import * as React from 'react';
import {SizeProp} from '@fortawesome/fontawesome-svg-core';
import {faQuestionCircle} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {IconButton} from "@mui/material";
import {SxProps} from "@mui/system";

interface Props {
  url: string;
  size?: SizeProp | number;
  color?: string;
  className?: string;
  sx?: SxProps;
}

export const HelpLink = (props: Props) => {
  const {url, className, color, size = 'sm', sx} = props;

  return (
    <IconButton href={url} aria-label="help" target='_blank' className={className} sx={sx} >
      {typeof size === 'number'
        ? <FontAwesomeIcon icon={faQuestionCircle} style={{fontSize: size, color}}/>
        : <FontAwesomeIcon icon={faQuestionCircle} size={size} style={{fontSize: size, color}}/>
      }

    </IconButton>
  );
}
